import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CubeCreatorFile } from 'src/app/model/CubeCreatorFile';
import { CubeCreatorLayout } from 'src/app/model/CubeCreatorLayout';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-cube-creator',
  templateUrl: './cube-creator.component.html',
  styleUrls: ['./cube-creator.component.scss']
})
export class CubeCreatorComponent implements OnInit {

  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  @ViewChild('printPreview', null) printPreview: ElementRef;

  // Selection
  step: number = 1;
  side: number = 1;

  previewImage: any;

  nameRequiredError: boolean = false;

  cubeCompleteError: boolean = false;

  cube1top: string;
  cube1middle: string;
  cube1bottom: string;
  cube2top: string;
  cube2middle: string;
  cube3top: string;
  cube3middle: string;
  cube4top: string;
  cube4middle: string;
  cube5top: string;
  cube5middle: string;
  cube6top: string;
  cube6middle: string;
  exitPrint: string;

  cubes: CubeCreatorFile = new CubeCreatorFile();
  layout: CubeCreatorLayout = new CubeCreatorLayout();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
  }
  reset(){
    this.side = 1;
    this.step = 1;
    this.cubes = new CubeCreatorFile();
  }
  initCube(type: string) {
    this.nameRequiredError = false;

    if(!this.cubes.name){
      this.nameRequiredError = true;
      return;
    }
    this.cubeCompleteError = false;
    this.cubes.type = type;
    this.step = 2;
    this.side = 1;

    switch (type) {
      case 'bio':
        this.layout.name = "Bio Cube";
        this.layout.cubeImage = "assets/cube-creator/bio-cube.png";
        this.layout.side1image = 'assets/cube-creator/1-cube.png';
        this.layout.side2image = 'assets/cube-creator/2-cube.png';
        this.layout.side3image = 'assets/cube-creator/3-cube.png';
        this.layout.side4image = 'assets/cube-creator/4-cube.png';
        this.layout.side5image = 'assets/cube-creator/5-cube.png';
        this.layout.side6image = 'assets/cube-creator/6-cube.png';
        this.layout.cubeBackgroundClass = 'cube-edit-';

        break;
      case 'mystery':
        this.layout.name = "Mystery Cube";
        this.layout.cubeImage = "assets/cube-creator/mystery-cube.png";
        this.layout.side1image = 'assets/cube-creator/mystery-1.png';
        this.layout.side2image = 'assets/cube-creator/mystery-2.png';
        this.layout.side3image = 'assets/cube-creator/mystery-3.png';
        this.layout.side4image = 'assets/cube-creator/mystery-4.png';
        this.layout.side5image = 'assets/cube-creator/mystery-5.png';
        this.layout.side6image = 'assets/cube-creator/mystery-6.png';
        this.layout.cubeBackgroundClass = 'mystery-edit-';

        break;
      case 'story':
        this.layout.name = "Story Cube";
        this.layout.cubeImage = "assets/cube-creator/story-cube.png";
        this.layout.side1image = 'assets/cube-creator/story-1.png';
        this.layout.side2image = 'assets/cube-creator/story-2.png';
        this.layout.side3image = 'assets/cube-creator/story-3.png';
        this.layout.side4image = 'assets/cube-creator/story-4.png';
        this.layout.side5image = 'assets/cube-creator/story-5.png';
        this.layout.side6image = 'assets/cube-creator/story-6.png';
        this.layout.cubeBackgroundClass = 'story-edit-';
        break;
      case 'custom':
        this.layout.name = "Create Your Own Cube";
        this.layout.cubeImage = "assets/cube-creator/create-your-own-cube.png";
        this.layout.side1image = 'assets/cube-creator/create-1.png';
        this.layout.side2image = 'assets/cube-creator/create-2.png';
        this.layout.side3image = 'assets/cube-creator/create-3.png';
        this.layout.side4image = 'assets/cube-creator/create-4.png';
        this.layout.side5image = 'assets/cube-creator/create-5.png';
        this.layout.side6image = 'assets/cube-creator/create-6.png';
        this.layout.cubeBackgroundClass = 'create-edit-';

        break;

    }
  }

  selectCube(type: string) {
    this.cubes.type = type;
    this.nameRequiredError = !this.cubes.name;;
    if (!this.nameRequiredError) {
      this.initCube(this.cubes.type);
    }
  }

  prevSide() {
    this.side--;
  }
  nextSide() {
    this.side++;
  }

  sideCompleted(side: number) {
    switch (this.cubes.type) {
      case "bio":
        switch (side) {
          case 1:
            return !!this.cubes.bioName && !!this.cubes.bioTime && this.cubes.bioPlace;
          case 2:
            return !!this.cubes.bioBackground;
          case 3:
            return !!this.cubes.bioPersonality;
          case 4:
            return !!this.cubes.bioSignificance;
          case 5:
            return !!this.cubes.bioObstacles;
          case 6:
            return !!this.cubes.bioQuote;
        }
        break;
      case "mystery":
        switch (side) {
          case 1:
            return !!this.cubes.mysterySetting;
          case 2:
            return !!this.cubes.mysteryDetective;
          case 3:
            return !!this.cubes.mysteryCrime;
          case 4:
            return !!this.cubes.mysteryVictim;
          case 5:
            return !!this.cubes.mysteryClues;
          case 6:
            return !!this.cubes.mysterySolution;
        }

        break;
      case "story":
        switch (side) {
          case 1:
            return !!this.cubes.storyCharacters;
          case 2:
            return !!this.cubes.storySetting;
          case 3:
            return !!this.cubes.storyConflict;
          case 4:
            return !!this.cubes.storyResolution;
          case 5:
            return !!this.cubes.storyTheme;
          case 6:
            return !!this.cubes.storyFavorite;
        }

        break;
      case "custom":
        switch (side) {
          case 1:
            return !!this.cubes.createQuestion && !!this.cubes.createResponse;
          case 2:
            return !!this.cubes.createQuestion2 && !!this.cubes.createResponse2;
          case 3:
            return !!this.cubes.createQuestion3 && !!this.cubes.createResponse3;
          case 4:
            return !!this.cubes.createQuestion4 && !!this.cubes.createResponse4;
          case 5:
            return !!this.cubes.createQuestion5 && !!this.cubes.createResponse5;
          case 6:
            return !!this.cubes.createQuestion6 && !!this.cubes.createResponse6;
        }

        break;

    }
  }

  clickPlanningSheet($event) {
    $event.stopPropagation();
  }

  cubeBackground() {
    return this.layout.cubeBackgroundClass + this.side.toString();
  }

  initPrint() {

    this.cube1top = null;
    this.cube1bottom = null;
    this.cube1middle = null;
    this.cube2top = null;
    this.cube2middle = null;
    this.cube3top = null;
    this.cube3middle = null;
    this.cube4top = null;
    this.cube4middle = null;
    this.cube5top = null;
    this.cube5middle = null;
    this.cube6top = null;
    this.cube6middle = null;

    switch (this.cubes.type) {
      case 'bio':
        this.cube1top = 'Name:<br>' + (this.cubes.bioName || '');
        this.cube1middle = 'Time Period:<br>' + (this.cubes.bioTime || '');
        this.cube1bottom = 'Place:<br>' + (this.cubes.bioPlace || '');

        this.cube2top = 'Personal Background:<br>' + (this.cubes.bioBackground || '');

        this.cube3top = 'Personality Traits:<br>' + (this.cubes.bioPersonality || '');

        this.cube4top = 'Significance:<br>' + (this.cubes.bioSignificance || '');

        this.cube5top = 'Obstacles:<br>' + (this.cubes.bioObstacles || '');

        this.cube6top = 'Important Quote:<br>' + (this.cubes.bioQuote || '');
        break;
      case 'mystery':
        this.cube1top = 'Setting:<br>' + (this.cubes.mysterySetting || '');

        this.cube2top = 'Detective:<br>' + (this.cubes.mysteryDetective || '');

        this.cube3top = 'Crime or Mystery:<br>' + (this.cubes.mysteryCrime || '');

        this.cube4top = 'Victim:<br>' + (this.cubes.mysteryCrime || '');

        this.cube5top = 'Clues:<br>' + (this.cubes.mysteryClues || '');

        this.cube6top = 'Solution:<br>' + (this.cubes.mysterySolution || '');
        break;
      case 'story':
        this.cube1top = 'Characters:<br>' + (this.cubes.storyCharacters || '');

        this.cube2top = 'Setting:<br>' + (this.cubes.storySetting || '');

        this.cube3top = 'Conflict:<br>' + (this.cubes.storyConflict || '');

        this.cube4top = 'Resolution:<br>' + (this.cubes.storyResolution || '');

        this.cube5top = 'Theme:<br>' + (this.cubes.storyTheme || '');

        this.cube6top = 'Favorite:<br>' + (this.cubes.storyFavorite || '');
        break;
      case 'custom':
        this.cube1top = (this.cubes.createQuestion || '');
        this.cube1middle = (this.cubes.createResponse || '');

        this.cube2top = (this.cubes.createQuestion2 || '');
        this.cube2middle = (this.cubes.createResponse2 || '');

        this.cube3top = (this.cubes.createQuestion3 || '');
        this.cube3middle = (this.cubes.createResponse3 || '');

        this.cube4top = (this.cubes.createQuestion4 || '');
        this.cube4middle = (this.cubes.createResponse4 || '');

        this.cube5top = (this.cubes.createQuestion5 || '');
        this.cube5middle = (this.cubes.createResponse5 || '');

        this.cube6top = (this.cubes.createQuestion6 || '');
        this.cube6middle = (this.cubes.createResponse6 || '');
        break;

    }

    switch (this.cubes.type) {
      case 'bio':
        this.cubeCompleteError = !this.cubes.bioName || !this.cubes.bioTime || !this.cubes.bioPlace || !this.cubes.bioBackground || !this.cubes.bioPersonality || !this.cubes.bioSignificance || !this.cubes.bioObstacles || !this.cubes.bioQuote
        break;
      case 'mystery':
        this.cubeCompleteError = !this.cubes.mysterySetting || !this.cubes.mysteryDetective || !this.cubes.mysteryCrime || !this.cubes.mysteryVictim || !this.cubes.mysteryClues || !this.cubes.mysterySolution
        break;
      case 'story':
        this.cubeCompleteError = !this.cubes.storyCharacters || !this.cubes.storySetting || !this.cubes.storyConflict || !this.cubes.storyResolution || !this.cubes.storyTheme || !this.cubes.storyFavorite
        break;
      case 'custom':
        this.cubeCompleteError = !this.cubes.createQuestion || !this.cubes.createResponse || !this.cubes.createQuestion2 || !this.cubes.createResponse2 || !this.cubes.createQuestion3 || !this.cubes.createResponse3 || !this.cubes.createQuestion4 || !this.cubes.createResponse4 || !this.cubes.createQuestion5 || !this.cubes.createResponse5 || !this.cubes.createQuestion6 || !this.cubes.createResponse6
        break;
    }

    if (!this.cubeCompleteError) {
      this.step = 3;
    }
    else {
      if (!this.sideCompleted(1)) {
        this.side = 1;
      }
      else if (!this.sideCompleted(2)) {
        this.side = 2;
      }
      else if (!this.sideCompleted(3)){
        this.side = 3;
      }
      else if (!this.sideCompleted(4)){
        this.side = 4;
      }
      else if (!this.sideCompleted(5)){
        this.side = 5;
      }
      else if (!this.sideCompleted(6)){
        this.side = 6;
      }
    }
    this.previewImage = null;
    setTimeout(async () => {
      this.previewImage = await this.printer.initPrintPreview([this.printCanvas], PrintOrientation.Landscape);
    }, 100);


  }

  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Landscape, true, true);
  }
  async export() {
    this.printer.export([this.printCanvas], 'Cube.pdf', PrintOrientation.Landscape, true, true);
  }
  save() {
    this.fileUtil.save(this.cubes, 'Cube.json')
  }

  fileInput: any;
  openCube(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.cubes = JSON.parse(json);
        this.initCube(this.cubes.type);
        this.step = 2;
        this.side = 1;
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }
}
