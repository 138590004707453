import { DragUtilService } from "../service/drag-util.service";
import { DragElement } from "./DragElement";

export class ConstructAWordDragElement extends DragElement {
    public originalX: number;
    public originalY: number;
    public dragging: boolean;
    public chosen: boolean;

    constructor(originalX: number, originalY: number) {
        super();
        this.originalX = originalX;
        this.originalY = originalY;
        this.x = this.originalX;
        this.y = this.originalY;
        
        let dragUtil = new DragUtilService();
        this.id = dragUtil.uid();
    }
}