export class GraphicMap {
    title?: string;
    author?: string;

    items?: string;
    otherItem?: string;
    rating?: string;
    entries: GraphicMapEntry[] = [new GraphicMapEntry()];
    
}

export class GraphicMapEntry {
    items?: string;
    topic?: string;
    description?: string;
    rating?: string;
    imgType?: string;
    img?: string;
}