
export class ConstructAWordFullWords {

    lenMap: Map<string, number> = new Map([
        ["an", 9],
        ["ed", 8],
        ["ig", 9],
        ["at", 13],
        ["et", 11], 
        ["in", 11],
        ["op", 13],
        ["ot", 14],
        ["un", 8]
    ]);

    wordMap: Map<string, string[]> = new Map([
        ["an", [
            "ban",
            "can",
            "fan",
            "man",
            "pan",
            "ran",
            "tan",
            "van",
            "clan"
        ]],
        
        ["ed", [
            "bed",
            "fed",
            "led",
            "red",
            "wed",
            "bled",
            "shed",
            "sled"
        ]],

        ["ig", [
            "big",
            "dig",
            "fig",
            "gig",
            "jig",
            "pig",
            "rig",
            "wig",
            "zig"
        ]],

        ["at", [
            "bat",
            "cat",
            "fat",
            "hat", 
            "mat",
            "pat",
            "rat",
            "sat",
            "vat",
            "oat",
            "eat",
            "chat",
            "slat"
        ]],

        ["et", [
            "bet",
            "get",
            "let",
            "met",
            "net",
            "pet",
            "set",
            "vet",
            "wet",
            "yet",
            "jet"
        ]],

        ["in", [
            "bin",
            "fin",
            "gin",
            "kin",
            "pin",
            "sin",
            "tin",
            "win",
            "chin",
            "shin",
            "din"
        ]],

        ["op", [
            "bop",
            "cop",
            "hop",
            "mop",
            "pop",
            "top",
            "chop",
            "clop",
            "drop",
            "slop",
            "lop",
            "sop",
            "shop"
        ]],

        ["ot", [
            "cot",
            "dot",
            "got",
            "hot",
            "lot",
            "not",
            "pot",
            "tot",
            "clot",
            "shot",
            "slot",
            "blot",
            "rot",
            "jot"
        ]],

        ["un", [
            "bun",
            "fun",
            "gun",
            "nun",
            "pun",
            "run",
            "sun",
            "shun"
        ]]
    ])
}