export class StoryMap {

    graphicOrganizer?: string;
    title?: string;
    author?: string;

    charName?: string;
    charLooks?: string;
    charAct?: string;
    charReact?: string;

    conflictDesc?: string;
    conflictOccur?: string;
    resolutions?: string;

    conflictResolution?: string;
    afterResolution?: string;
    resolutionAffect?: string;

    storyLocation?: string;
    storyTime?: string;
    settingDesc?: string;



}
