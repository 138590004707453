import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GraphicMap, GraphicMapEntry } from 'src/app/model/GraphicMap';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
    selector: 'app-graphic-map',
    templateUrl: './graphic-map.component.html',
    styleUrls: ['./graphic-map.component.scss']
})
export class GraphicMapComponent implements OnInit {

    step: number = 1;

    animation: number = 0
    animationInterval: number;

    graphicMap: GraphicMap = new GraphicMap;

    warning: boolean = false;

    tipsOpen: boolean = false;
    tipNum: number;

    ratings: object = {
        "high": ["high", "medium", "low"],
        "positive": ["positive", "negative"],
        "numbers": ["3", "2", "1", "-1", "-2", "-3"],
        "smiley": ["smiley", "frown"]
    };

    currentEntry: number = 0;

    picturesOpen: boolean = false;

    pictures: object = {
        "Animals": this.getPictures("animals"),
        "Community": this.getPictures("community"),
        "Food": this.getPictures("food"),
        "Hobbies": this.getPictures("hobbies"),
        "Home": this.getPictures("home"),
        "Miscellaneous": this.getPictures("miscellaneous"),
        "School": this.getPictures("school"),
        "Transportation": this.getPictures("transportation"),
    };

    printing: boolean = false;
    @ViewChild('printCanvas', null) printCanvas: ElementRef;

    constructor(
        private fileUtil: FileUtilService,
        private printer: PrintService,
    ) { }

    ngOnInit() {
    }

    getPictures(type: string) {
        let links = [];
        for (let num of [...Array(16).keys()]) {
            links.push("gm-" + type + (num + 1) + ".jpg");
        }
        return links;
    }

    range(max) {
        return [...Array(max).keys()]
    }

    nextStep() {
        if (this.step === 1) this.checkInput(this.graphicMap.title);
        else if (this.step === 2) this.checkInput(this.graphicMap.items);
        else if (this.step === 3) this.checkInput(this.graphicMap.rating);
        else this.step++;
    }

    checkInput(input: string) {
        if (input) {
            this.step++;
            this.warning = false;
        }
        else this.warning = true;
    }

    startAnimation() {
        setTimeout(() => {
            this.animationInterval = setInterval(() => {
                this.animation += 1;
                if (this.animation >= 7) {
                    clearInterval(this.animationInterval);
                    setTimeout(() => {
                        this.nextStep();
                    }, 1000);
                }
            }, 1000);
        }, 1000);
    }

    toggleTips(tip) {
        if (tip !== this.tipNum) {
            this.tipsOpen = true;
            this.tipNum = tip
        }
        else {
            this.tipsOpen = false;
            delete this.tipNum;
        }
    }

    prevEntry() {
        if (!this.graphicMap.entries[this.currentEntry].items) this.graphicMap.entries.splice(this.currentEntry, 1);
        this.warning = false;
        this.currentEntry--;
    }

    addEntry() {
        if (this.graphicMap.entries[this.currentEntry].items) {
            this.graphicMap.entries.push(new GraphicMapEntry());
            this.currentEntry++;
            this.warning = false;
        }
        else this.warning = true;
    }

    nextEntry() {
        if (!this.graphicMap.entries[this.currentEntry].items) this.graphicMap.entries.splice(this.currentEntry, 1);
        else this.currentEntry++;
        this.warning = false;
    }

    finish() {
        this.step = 5;
    }

    back() {
        this.step = 4;
        this.currentEntry = this.graphicMap.entries.length - 1;
    }

    restart() {
        this.step = 0;

        this.animation = 0
        delete this.animationInterval;

        this.graphicMap = new GraphicMap;

        this.warning = false;

        this.tipsOpen = false;
        delete this.tipNum;

        this.graphicMap.entries = [new GraphicMapEntry()]
        this.currentEntry = 0;

        this.picturesOpen = false;
    }

    openPictures() {
        this.picturesOpen = true;
        delete this.graphicMap.entries[this.currentEntry].imgType;
    }

    getKeys(o) {
        return Object.keys(o);
    }

    choosePictureType(choice) {
        this.graphicMap.entries[this.currentEntry].imgType = choice;
    }

    choosePicture(link) {
        this.graphicMap.entries[this.currentEntry].img = '/assets/graphic-map/graphic-map-images/' + this.graphicMap.entries[this.currentEntry].imgType.toLowerCase() + '/' + link;
        this.picturesOpen = false;
    }

    getPreviewMarginTop(entry) {
        let ratingsSet = this.ratings[this.graphicMap.rating]
        let vertLines = document.getElementById("vert-lines");
        let currentY;

        if (ratingsSet.includes(entry.rating)) currentY = (vertLines.clientHeight - 66) / (ratingsSet.length - 1) * ratingsSet.indexOf(entry.rating);
        else currentY = (vertLines.clientHeight - 66) / (ratingsSet.length - 1) * ((ratingsSet.length / 2 + 0.5) - 1);

        return (Math.round(currentY - (currentY / (vertLines.clientHeight)) * 50)).toString() + "px";
    }

    getPreviewMarginRight() {
        let hozoLines = document.getElementById("hozo-lines");
        let margin = (hozoLines.clientWidth - 35 - 50 * this.graphicMap.entries.length) / this.graphicMap.entries.length - 1;

        if (margin >= 170) return "170px";
        else return margin + "px";
    }

    getPrintLineRotation(idx) {
        try {
            let y1 = parseInt((document.getElementsByClassName('print-container-' + (idx))[0] as any).offsetTop);
            let x2 = 50;//parseInt((document.getElementsByClassName('print-container-' + (idx))[0] as any).offsetWidth);
            let y2 = parseInt((document.getElementsByClassName('print-container-' + (idx + 1))[0] as any).offsetTop) - y1;
            return Math.atan(y2 / x2);
        }
        catch { }

    }
    getPrintLineWidth(idx) {
        try {
            let y1 = parseInt((document.getElementsByClassName('print-container-' + (idx))[0] as any).offsetTop);
            let x2 = 50;//parseInt((document.getElementsByClassName('print-container-' + (idx))[0] as any).offsetWidth);
            let y2 = parseInt((document.getElementsByClassName('print-container-' + (idx + 1))[0] as any).offsetTop) - y1;

            return Math.sqrt(x2 ** 2 + y2 ** 2) + 2 + "px";
        }
        catch { }
    }
    getPrintLineLeft(idx) {
        try {
            return (document.getElementsByClassName('print-container-' + idx)[0] as any).offsetWidth +
                (document.getElementsByClassName('print-container-' + idx)[0] as any).offsetLeft + 'px';
        }
        catch { }

    }
    getPrintLineTop(idx) {
        try {
            let ret = ((document.getElementsByClassName('print-container-' + idx)[0] as any).offsetHeight /2.00);
            return (-ret) + 'px';
        }
        catch { }

    }

    getLineRotation(entry) {
        if (this.graphicMap.entries.indexOf(entry) < this.graphicMap.entries.length - 1) {
            let y1 = parseInt(this.getPreviewMarginTop(entry));
            let x2 = parseInt(this.getPreviewMarginRight() + 1);
            let y2 = parseInt(this.getPreviewMarginTop(this.graphicMap.entries[this.graphicMap.entries.indexOf(entry) + 1])) - y1;
            return Math.atan(y2 / x2);
        }
    }

    getLineWidth(entry) {
        if (this.graphicMap.entries.indexOf(entry) < this.graphicMap.entries.length - 1) {

            let y1 = parseInt(this.getPreviewMarginTop(entry));
            let x2 = parseInt(this.getPreviewMarginRight() + 1);
            let y2 = parseInt(this.getPreviewMarginTop(this.graphicMap.entries[this.graphicMap.entries.indexOf(entry) + 1])) - y1;

            return Math.sqrt(x2 ** 2 + y2 ** 2) + 2 + "px";
        }
    }

    save() {
        this.fileUtil.save(this.graphicMap, 'GraphicMap.json')
    }

    centerPreview() {
        let hozoLines = document.getElementById("hozo-lines");
        let entriesLength = this.graphicMap.entries.length
        let previewWidth = 50 * entriesLength + parseInt(this.getPreviewMarginRight()) * (entriesLength - 1);


        return (hozoLines.clientWidth / 2 - previewWidth / 2 - 35) + "px"
    }

    goToEntry(entry) {
        entry.hovered = false;
        this.step = 4;
        this.currentEntry = this.graphicMap.entries.indexOf(entry);
    }

    fileInput: any;
    openGraphicMap(event: any) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            try {
                let json = e.target.result;
                this.graphicMap = JSON.parse(json);
                this.step = 1;
            }
            catch (e) {
                alert('File cannot be opened');
                this.fileInput = null;
            }
        };
        reader.readAsText(event.target.files[0]);
    }

    async print() {
        this.printing = true;

        setTimeout(async () => {
            await this.printer.print([this.printCanvas], PrintOrientation.Landscape, false, true, false);
            this.printing = false;
        }, 200);
    }
}
