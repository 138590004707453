import { Directive, HostListener, EventEmitter, Output, HostBinding, Input, ElementRef, Renderer2 } from '@angular/core';
import { DragUtilService } from '../service/drag-util.service';
import { DragElement } from '../model/DragElement';
import * as _ from 'lodash';

@Directive({
  selector: '[drag-canvas]',
  exportAs: 'dragCanvasDirective'
})
export class DragCanvasDirective {

  @Input() dragElements: DragElement[]
  selectedElement: DragElement;

  constructor(private renderer: Renderer2, private hostElement: ElementRef){
    renderer.addClass(hostElement.nativeElement, "drag-canvas");
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(e: MouseEvent) {
    if(this.selectedElement) DragElement.removeDrag(this.selectedElement);
    this.selectedElement = _.find(this.dragElements, (x:DragElement)=>x.mouseDown);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {
    this.dragElements.forEach(x=>{DragElement.deselect(x)})
    this.initSelected();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.initSelected();

    if(this.selectedElement && this.selectedElement.mouseDown){
      this.selectedElement.x += (e.clientX - this.selectedElement.startX);
      this.selectedElement.y += (e.clientY - this.selectedElement.startY);
      this.selectedElement.startX = e.clientX;
      this.selectedElement.startY = e.clientY;

      if(this.selectedElement.x < 0) {
        this.selectedElement.x = 0;
      }
      if(this.selectedElement.y < 0) {
        this.selectedElement.y = 0;
      }

      let bottom = this.selectedElement.nativeElement.offsetHeight + this.selectedElement.nativeElement.offsetTop;
      if(bottom > this.hostElement.nativeElement.offsetHeight){
        this.selectedElement.y = this.hostElement.nativeElement.offsetHeight - this.selectedElement.nativeElement.offsetHeight;
      }

      let right = this.selectedElement.nativeElement.offsetWidth + this.selectedElement.nativeElement.offsetLeft;
      if(right > this.hostElement.nativeElement.offsetWidth){
        this.selectedElement.x = this.hostElement.nativeElement.offsetWidth - this.selectedElement.nativeElement.offsetWidth;
      }
    }
  }

  getDragElements(){
    return this.dragElements;
  }
  initSelected(){
    if(!this.selectedElement){
      let s = _.find(this.dragElements, (x:DragElement)=>x.mouseDown);
      if(s) {
        this.selectedElement = s;
      }

    }
  }
}

