import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';

import * as _ from "lodash";

import { TradingCardSession } from "src/app/model/TradingCard/TradingCardSession";
import { ITradingCard } from 'src/app/model/TradingCard/ITradingCard';
import { TradingCardEvent } from 'src/app/model/TradingCard/TradingCardEvent';
import { TradingCardFictionalPerson } from 'src/app/model/TradingCard/TradingCardFictionalPerson';
import { TradingCardPhysicalObject } from 'src/app/model/TradingCard/TradingCardPhysicalObject';
import { TradingCardRealPerson } from 'src/app/model/TradingCard/TradingCardRealPerson';
import { TradingCardFictionalPlace } from 'src/app/model/TradingCard/TradingCardFictionalPlace';
import { TradingCardAbstractConcept } from "src/app/model/TradingCard/TradingCardAbstractConcept";
import { TradingCardRealPlace } from 'src/app/model/TradingCard/TradingCardRealPlace';
import { TradingCardCreateYourOwn } from 'src/app/model/TradingCard/TradingCardCreateYourOwn';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';


@Component({
  selector: 'app-trading-card-creator',
  templateUrl: './trading-card-creator.component.html',
  styleUrls: ['./trading-card-creator.component.scss']
})
export class TradingCardCreatorComponent implements OnInit {
  step: number = 0;
  type: string;
  insertingFile: boolean = false;

  session: TradingCardSession = new TradingCardSession();
  newCard: ITradingCard;
  newCreateYourOwn: TradingCardCreateYourOwn;

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {

  }

  initCard(type: string) {
    this.type = type;
    if (!!this.session.name && !!this.session.topic) {
      switch (this.type) {
        case "Event":
            this.newCard = new TradingCardEvent(this.session.name, this.session.topic);
            break;
        case "Fictional Person":
            this.newCard = new TradingCardFictionalPerson(this.session.name, this.session.topic);
            break;
        case "Physical Object":
            this.newCard = new TradingCardPhysicalObject(this.session.name, this.session.topic);
            break;
        case "Real Person":
            this.newCard = new TradingCardRealPerson(this.session.name, this.session.topic);
            break;
        case "Fictional Place":
            this.newCard = new TradingCardFictionalPlace(this.session.name, this.session.topic);
            break;
        case "Abstract Concept":
            this.newCard = new TradingCardAbstractConcept(this.session.name, this.session.topic);
            break;
        case "Real Place":
            this.newCard = new TradingCardRealPlace(this.session.name, this.session.topic);
            break;
        case "Create Your Own":
            this.newCard = new TradingCardCreateYourOwn(this.session.name, this.session.topic);
            break;
      }
      console.log('initCard: ', this.session);
      console.log('newCard', this.newCard);
      this.step = 2;
    }
  }

  editCard(card: ITradingCard) {
    this.step = 2;
    this.newCard = card;
  }


  cardFinished() {
    if (!_.find(this.session.cards, x => x.id == this.newCard.id)) {
      this.session.cards.push(this.newCard);
    }
    this.newCard = null;
    this.step = 0;
    this.session.topic = null;
  }

  startOver() {
    this.step = 0;
    this.session = new TradingCardSession();
  }

  makeNewCard() {
    this.step = 1;
  }

  @ViewChildren('printCanvas', {read: ElementRef}) printCanvas: QueryList<ElementRef>;
  async print() {
    this.printer.print(this.printCanvas.toArray());
  }
  async export() {
    this.printer.export(this.printCanvas.toArray(), 'Trading-Cards.pdf');
  }
  save(){
    this.fileUtil.save(this.session, 'Trading-Cards.json')
  }

  fileInput: any;
  openSession(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.session = this.restoreSession(json);
        this.step = 0;
        this.fileInput = null;
        this.insertingFile = false;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  restoreSession(json: string): TradingCardSession {
    const session = JSON.parse(json) as TradingCardSession;
    const cards = session.cards.map(card => {
      let newCard : ITradingCard = null;
      switch (card.type) {
        case "Event":
            newCard = new TradingCardEvent(this.session.name, this.session.topic);
            break;
        case "Fictional Person":
            newCard = new TradingCardFictionalPerson(this.session.name, this.session.topic);
            break;
        case "Physical Object":
            newCard = new TradingCardPhysicalObject(this.session.name, this.session.topic);
            break;
        case "Real Person":
            newCard = new TradingCardRealPerson(this.session.name, this.session.topic);
            break;
        case "Fictional Place":
            newCard = new TradingCardFictionalPlace(this.session.name, this.session.topic);
            break;
        case "Abstract Concept":
            newCard = new TradingCardAbstractConcept(this.session.name, this.session.topic);
            break;
        case "Real Place":
            newCard = new TradingCardRealPlace(this.session.name, this.session.topic);
            break;
        case "Create Your Own":
            newCard = new TradingCardCreateYourOwn(this.session.name, this.session.topic);
            break;
      }
      Object.assign(newCard, card);
      return newCard;
    })
    session.cards = cards;
    return session;
  }
}
