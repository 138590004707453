<div class="app-border">
    <div class="title-bar">
        <span>ABC Match</span>
    </div>

    <div *ngIf="getGeneralStep()==1" class="intro-background frame">
        <button class="choose-play-mode-button" (click)="interactive.step=2"></button>
    </div>

    <div *ngIf="getGeneralStep()==2" class="choose-background frame">
        <button class="learn-mode-button" (click)="beginLearnGame()"></button>
        <button class="play-mode-button" (click)="beginPlayGame()"></button>
    </div>

    <div *ngIf="getGeneralStep()==3" class="game-over-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="score-text">Score: {{ gameController.score }}</p>
        </div>
        <button class="print-game-cards-button" (click)="printGameCards()"></button>
        <button class="play-again-button" (click)="interactive.step=1"></button>
    </div>

    <div *ngIf="getLearnStep()==1" class="learn-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="matches-text">Matches: {{ gameController.matchesFound }} of {{ gameController.getNumTiles() }}</p>
        </div>
        <div class="picture-match-tiles" [style.display]="gameController.hideCards">
            <div *ngFor="let i of getOrderedArray(gameController.getNumTiles())" (click)="gameController.tileClicked('picture', i)" [ngClass]="{'match-tile': !gameController.pictureTileArray[i].selected, 'selected-match-tile': gameController.pictureTileArray[i].selected}">
                <img *ngIf="gameController.pictureTileArray[i].selected" [src]="gameController.pictureTileArray[i].pictureImage" class="tile-image"/>
            </div>
        </div>

        <div class="letter-match-tiles" [style.display]="gameController.hideCards">
            <div *ngFor="let i of getOrderedArray(gameController.getNumTiles())" (click)="gameController.tileClicked('letter', i)" [ngClass]="{'match-tile': !gameController.letterTileArray[i].selected, 'selected-match-tile': gameController.letterTileArray[i].selected}">
                <img *ngIf="gameController.letterTileArray[i].selected" [src]="gameController.letterTileArray[i].letterImage" class="tile-image"/>
            </div>
        </div>
        <button class="exit-button" (click)="interactive.learnStep=3"></button>
    </div>

    <div *ngIf="getLearnStep()==2" class="learn-champ-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="matches-text">Matches: {{ gameController.matchesFound }} of {{ gameController.getNumTiles() }}</p>
        </div>
        <button class="print-game-cards-button" (click)="printGameCards()"></button>
        <button class="play-again-button" (click)="interactive.learnStep=0; interactive.step=1"></button>
    </div>

    <div *ngIf="getLearnStep()==3" class="learn-thanks-for-playing-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="matches-text">Matches: {{ gameController.matchesFound }} of {{ gameController.getNumTiles() }}</p>
        </div>
        <button class="play-again-button" (click)="interactive.learnStep=0; interactive.step=1"></button>
    </div>

    <div *ngIf="getPlayStep()==1" class="play-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="score-text">Score: {{ gameController.score }}</p>
        </div>
        <p class="timer"> {{ gameController.timeLeft }} </p>
        <div class="picture-match-tiles" [style.display]="gameController.hideCards">
            <div *ngFor="let i of getOrderedArray(gameController.getNumTiles())" (click)="gameController.tileClicked('picture', i)" [ngClass]="{'match-tile': !gameController.pictureTileArray[i].selected, 'selected-match-tile': gameController.pictureTileArray[i].selected}">
                <img *ngIf="gameController.pictureTileArray[i].selected" [src]="gameController.pictureTileArray[i].pictureImage" class="tile-image"/>
            </div>
        </div>

        <div class="letter-match-tiles" [style.display]="gameController.hideCards">
            <div *ngFor="let i of getOrderedArray(gameController.getNumTiles())" (click)="gameController.tileClicked('letter', i)" [ngClass]="{'match-tile': !gameController.letterTileArray[i].selected, 'selected-match-tile': gameController.letterTileArray[i].selected}">
                <img *ngIf="gameController.letterTileArray[i].selected" [src]="gameController.letterTileArray[i].letterImage" class="tile-image"/>
            </div>
        </div>
        <button class="exit-button" (click)="interactive.playStep=3"></button>
    </div>

    <div *ngIf="getPlayStep()==2" class="play-champ-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="score-text">Score: {{ gameController.score }}</p>
        </div>
        <button class="print-game-cards-button" (click)="printGameCards()"></button>
        <button class="play-again-button" (click)="interactive.playStep=0; interactive.step=1"></button>
    </div>

    <div *ngIf="getPlayStep()==3" class="play-thanks-for-playing-background frame">
        <div class="text-container">
            <p class="level-text">Level: {{ gameController.level }}</p>
            <p class="score-text">Score: {{ gameController.score }}</p>
        </div>
        <button class="play-again-button" (click)="interactive.playStep=0; interactive.step=1"></button>
    </div>

    <div class="game-cards">
        <div class="page-1" #printout1></div>
        <div class="page-2" #printout2></div>
        <div class="page-3" #printout3></div>
    </div>

</div>