
<main class='app-main' [ngClass]="{'app-full' : currentUrl=='/library'}">
    <div class='account-outer'>

        <div class='account-inner'>
            <div class='account-body'>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</main>
<div class='app-print-canvas' id='app-print-canvas'></div>
<div id='printContainer'></div>
<div id='printDialog'>
  <span id='printDialogTitle'>Printing ...</span>
  <div class='print-status-container'>
    <img #printPreview src="/assets/common/loading-small.gif" class='loading-small'  />
    <span id='printDialogStatus'>Page 1 of 1</span>
  </div>

</div>
