
    <div class="page-container">
        <div *ngIf="step==0" class="card-collection-container">
            <div class="card-collection">
                <div *ngFor="let card of session.cards" class="card-thumbnail">
                    <div class="card-frame">
                      
                        <div class='card-frame-header' (click)="editCard(card)">
                            <ul>
                                <li class='card-frame-topic'>
                                    {{card.topic}}
                                </li>
                                <li class='card-frame-type'>
                                    {{card.type}}
                                </li>
                            </ul>
                        </div>

                        <div class="section-list" (click)="editCard(card)">
                            <ul>
                                <li *ngIf="card.activeSection < 3" class="image-selector">
                                    <div class="image-selector">
                                        <img [src]='card.imageUrl' *ngIf='card.imageUrl' class='img'>
                                    </div>
                                </li>
                                <li *ngFor="let s of card.displayedSections" class='card-section'>
                                    <span class="section-header">{{s.sectionName}}</span>
                                    <ul>
                                        <li class="section-field" *ngFor="let field of s.fields">
                                            <span>{{field.fieldName}}:</span>
                                            <span class="section-field-value">{{field.value}}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <span class="flip" (click)='card.flip()'>Flip<i class='fa fa-lg fa-repeat'></i></span>
                    </div>

                </div>
            </div>




            <div class="bottom-menu">
                <button (click)="makeNewCard()">
                    {{ session.cards.length > 0 ? 'New Card' : 'Start' }}
                </button>
                <button (click)="insertingFile=true">
                    Open
                </button>
                <ng-container *ngIf='session.cards.length > 0'>
                    <button (click)="print()">
                        Print
                    </button>
                    <button (click)="save()">
                        Save
                    </button>
                    <button (click)="export()">
                        Export
                    </button>
                </ng-container>
            </div>

            <div class="print-container">
              <div #printCanvas *ngFor='let card of session.cards'>
                <app-print-card [card]='card' [printVersion]='true' ></app-print-card>
              </div>
            </div>
        </div>


        <!-------------------------------------------- Step 1 ---------------------------------------------->

        <div class="name-entry-container" *ngIf="step==1">
            <div class='name-entry-step'>
                <h1>Trading Card Creator</h1>
                <div class="name-entry">
                    <label>Enter your name:</label>
                    <input type='text' [(ngModel)]='session.name' maxlength="22">
                    <label>Please enter your topic:</label>
                    <input type ='text' [(ngModel)]='session.topic' maxlength="22">
                </div>
                <div class="select-type">
                    <div class='type-row'>
                        <button (click)='initCard("Fictional Person")'>
                            Fictional Person
                        </button>

                        <button (click)='initCard("Real Person")'>
                            Real Person
                        </button>

                        <button (click)='initCard("Fictional Place")'>
                            Fictional Place
                        </button>

                        <button (click)='initCard("Real Place")'>
                            Real Place
                        </button>
                    </div>

                    <div class='type-row'>
                        <button (click)='initCard("Physical Object")'>
                            Physical Object
                        </button>

                        <button (click)='initCard("Event")'>
                            Event
                        </button>

                        <button (click)='initCard("Abstract Concept")'>
                            Abstract Concept
                        </button>

                        <button (click)='initCard("Create Your Own")'>
                            Create Your Own
                        </button>
                    </div>




                </div>
            </div>

        </div>
        <!---------------------------------------------Step 2---------------------------------------------------->

        <div *ngIf='step==2'  class="card-editor">

                <!-- The Modal -->
                <div id="myModal" class="modal">

                    <!-- Modal content -->
                    <div class="modal-content">
                        <span class="close">&times;</span>
                    </div>

                </div>
                <app-card [card]="newCard"></app-card>

        </div>

        <div class="bottom-menu" *ngIf='step==2'>
            <button (click)='startOver()'>
                Start Over
            </button>

            <button (click) = 'cardFinished()' class='finish-editing-button'>
                Finish Editing
            </button>
        </div>

        <div id="loadModal" class="modal" [class.show]='insertingFile'>

            <div class="modal-content">
                <input type="file" [(ngModel)]='fileInput'  (change)='openSession($event)' placeholder="Select File">

                <div class="row center">
                    <button (click)="insertingFile=false">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
