import { DragElement } from "./DragElement";

export class WordMover {
  title?: string;
  name?: string;

  elements?: DragElement[] = [];
  editId?: string;
  inputLabelText?: string;
  inputLabelDescription?: string;
  editingLabel?: boolean = false;

}
