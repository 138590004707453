import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardRealPlace extends TradingCard implements ITradingCard {

    descriptionFields: TradingCardField[] = [
        new TradingCardField("Location", "Where is __topic__?"),
        new TradingCardField("Appearance", "What does __topic__ look like.")
    ];

    purposeFields: TradingCardField[] = [
        new TradingCardField("Origin", "How or why did __topic__ come to exist?"),
        new TradingCardField("Time Period", "When was it built, discovered, or most important?")
    ];

    importanceFields: TradingCardField[] = [
        new TradingCardField("Events", "What important events have occured at __topic__?"),
        new TradingCardField("Effects", "What effects have __topic__ or the events there had? Why is it memorable?"),
        new TradingCardField("To Whom", "To whom is __topic__ significant, and why?")
    ];

    interstingFactsFields: TradingCardField[] = [
        new TradingCardField("Additional Information", "What else have you learned about __topic__? What details or other facts stand out to you?")
    ];

    personalConnectionFields: TradingCardField[] = [
        new TradingCardField("Personal Connection", "What are  your impressions of __topic__? Have you ever been there? If not, would you like to visit? Why or why not?")
    ];

   
    
    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Purpose", this.purposeFields),
        new TradingCardSection("Importance", this.importanceFields),
        new TradingCardSection("Interesting Facts", this.interstingFactsFields),
        new TradingCardSection("Personal Connection", this.personalConnectionFields)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Real Place');
    }
}
