
export class FlipAChipTextChoices {
    choices = [
        [
            ["?", "start", "end"],
            ["?", "ing", "ed"]
        ],

        [
            ["?", "hard", "mean"],
            ["?", "est", "er"]
        ],

        [
            ["?", "im", "sup"],
            ["?", "press", "pose"]
        ],

        [
            ["?", "dog", "aunt"],
            ["?", "'s", "s"]
        ],

        [
            ["?", "cap", "ten"],
            ["?", "able", "acity"]
        ],

        [
            ["?", "bat", "set"],
            ["?", "ting", "tle"]
        ]
    ]       
}