import { Injectable } from '@angular/core';
import { DragElement } from '../model/DragElement';

@Injectable({
  providedIn: 'root'
})
export class DragUtilService {
  selectedElement: DragElement;

  constructor() { }
  // create unique id
  uid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  createElement(list: DragElement[]) {
    // find the next unused position
    let elem: DragElement = { id: this.uid(), x: 0, y: 0 };
    let x = 0;
    let y = 0;
    list.forEach(evt => {
      let moved = false;
      if (evt.x == x) { x += 20; moved = true; }
      if (evt.y == y) { y += 20; moved = true; }
      if (!moved) return;
    });
    elem.x = x;
    elem.y = y;
    return elem;
  }

  initPrint(dragElements: DragElement[], canvasName: string, backgroundImageSrc?: string, backgroundX?: number, backgroundY?: number, backgroundWidth: number = 300, backgroundHeight: number = 300, minWidth?: number): Promise<any> {
    let that = this;
    return new Promise<any>(function (resolve) {


      setTimeout(() => {


        let canvas = document.getElementById(canvasName) as HTMLCanvasElement;
        var ctx = canvas.getContext("2d");

        let width = backgroundWidth;
        let height = backgroundHeight;
        dragElements.forEach((elem: DragElement) => {
          if (!elem.width) elem.width = 0;
          if (!elem.height) elem.height = 0;

          if (elem.type == 'text') {
            /// get width of text
            ctx.font = "20px Georgia";
            ctx.save();
            var elemWidth = ctx.measureText(elem.text).width + 10;
            if (elem.width < width) elem.width = elemWidth;
          }

          if ((elem.x + elem.width) > width) width = elem.x + elem.width;
          if ((elem.y + elem.height) > height) height = elem.y + elem.height;
        });
        canvas.width = width;
        canvas.height = height;

        if (backgroundImageSrc) {
          if (canvas.width < (backgroundWidth + backgroundX)) canvas.width = (backgroundWidth + backgroundX);
          if (canvas.height < (backgroundHeight + backgroundY)) canvas.height = (backgroundHeight + backgroundY);
        }



        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let imgCnt = 0;
        if (backgroundImageSrc) {
          imgCnt++;
          var img = new Image();
          img.src = backgroundImageSrc;
          img.addEventListener('load', function () {
            ctx.drawImage(img, backgroundX, backgroundY, backgroundWidth, backgroundHeight);
            imgCnt--;
            if (imgCnt == 0) {
              that._initPrintAfterImages(ctx, dragElements);
              resolve(true);
            }
          }, false);
        }

        dragElements.forEach((elem: DragElement) => {
          switch (elem.type) {
            case 'image':
              imgCnt++;
              var img = new Image();
              img.src = elem.imageSrc;
              img.addEventListener('load', function () {
                ctx.drawImage(img, elem.x, elem.y, elem.width, elem.height);
                imgCnt--;
                if (imgCnt == 0) {
                  that._initPrintAfterImages(ctx, dragElements);
                  resolve(true);
                }
              }, false);
              break;
          }
        });

        if (imgCnt == 0) {
          that._initPrintAfterImages(ctx, dragElements);
          resolve(true);
        }
      }, 200);
    })
  }

  private _initPrintAfterImages(ctx, dragElements) {
    dragElements.forEach((elem: DragElement) => {
      switch (elem.type) {
        case 'text':
          if (elem.text) {
            // ctx.fillStyle = 'black';
            // ctx.fillText(elem.text, elem.x + 10, elem.y + 12);

            ctx.font = "20px Georgia";
            this.drawTextBG(ctx, elem.text, '20', elem.x + 10, elem.y + 12);
          }
          break;
      }

    });
  }

  private drawTextBG(ctx, txt, font, x, y) {
    /// lets save current state as we make a lot of changes
    ctx.save();

    /// draw text from top - makes life easier at the moment
    ctx.textBaseline = 'top';

    /// color for background
    ctx.fillStyle = 'white';

    /// get width of text
    var width = ctx.measureText(txt).width;

    /// draw background rect assuming height of font
    ctx.fillRect(x, y, width, parseInt(font, 10));

    /// text color
    ctx.fillStyle = '#000';

    /// draw text on top
    ctx.fillText(txt, x, y);

    /// restore original state
    ctx.restore();
  }
}
