import { ITradingCard } from "./ITradingCard";
import { TradingCard } from "./TradingCard";
import { TradingCardSection, TradingCardField } from './TradingCardSection';

export class TradingCardCreateYourOwn extends TradingCard implements ITradingCard {
    sections: TradingCardSection[] = [
        new TradingCardSection("", [ new TradingCardField("", ""), new TradingCardField("", "") ]),
        new TradingCardSection("", [ new TradingCardField("", ""), new TradingCardField("", "") ]),
        new TradingCardSection("", [ new TradingCardField("", ""), new TradingCardField("", "") ]),
        new TradingCardSection("", [ new TradingCardField("", ""), new TradingCardField("", "") ]),
        new TradingCardSection("", [ new TradingCardField("", ""), new TradingCardField("", "") ])
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Create Your Own');
        this.isCreateYourOwn = true;
    }
}
