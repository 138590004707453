<div class="app-border">
    <div class="title-bar">
        <span>Flip a Chip</span>
    </div>

    <div *ngIf="interactive.step==1" class="intro-background frame">
        <button class="exit-button" (click)="exit()"></button>
        <button class="next-button" (click)="interactive.step=2"></button>
        <input class="name-input" [(ngModel)]="interactive.name">
    </div>

    <div *ngFor="let element of backgroundElementLoopArray; index as i" drag-canvas [dragElements]="interactive.dragElements">
        <div *ngIf="interactive.step==(i * 2 + 2)" class="exercise-{{ i + 1 }}-background frame">
            <button class="flip-button" (click)="flipCoins(i)"></button>
            <img *ngIf="!interactive.showDragWord[i]" class="blank-drag-word"src="../../../assets/flip-a-chip/fc-blank-drag-word.png"/>

            <button *ngIf="interactive.showDragWord[i]" (mouseup)="dragMouseUp(i)" class="drag-object" drag-object [dragElement]="interactive.dragElements[i]" [dragElements]="interactive.dragElements"> {{ getDragWord(i) }} </button>
            
            <div class="left-coin-container">
                <p class="left-coin"> {{ getLeftCoin(i) }} </p>
            </div>
            <div class="right-coin-container">
                <p class="right-coin"> {{ getRightCoin(i) }} </p>
            </div>

            <div *ngFor="let placement of placementLoopArray; index as j" class="placement-{{ j + 1 }}-container placement-container">
                <p class="placement-{{ j + 1 }} placement"> {{ interactive.placements.text[i][j]}} </p>
            </div>

            <button class="exit-button" (click)="exit()"></button>
            <button class="next-button" (click)="checkPrint(i)"></button>
        </div>
        <div *ngIf="interactive.step==(i * 2 + 3)" class="complete-{{ i + 1 }}-background frame">
            <button class="back-button" (click)="interactive.step = (interactive.step - 1)"></button>
            <button class="next-button" (click)="interactive.step = (interactive.step + 1)"></button>
            <button class="exit-button" (click)="exit()"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==14" class="congratulations-background frame">
        <button class="exit-button" (click)="exit()"></button>
        <button class="next-button" (click)="exit()"></button>
    </div>

    <div *ngFor="let element of backgroundElementLoopArray; index as i" class="print-page-{{ i + 1 }} print-page" #printPage>
        <p class="name"> {{ interactive.name }} </p>
        <div *ngFor="let placement of placementLoopArray; index as j" class="placement-container-{{ j + 1 }} placement-container">
            <p class="placement-{{ j + 1 }} placement"> {{ interactive.placements.text[i][j]}} </p>
        </div>
    </div>