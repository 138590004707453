import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DragCanvasDirective } from 'src/app/directives/drag-canvas.directive';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { DragElement } from 'src/app/model/DragElement';
import { DragUtilService } from 'src/app/service/drag-util.service';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  step = 1;
  vars: any = {};
  @ViewChild("timelineCanvas", null) timelineCanvas: ElementRef;  
  @ViewChild("itemCanvas", null) itemCanvas: ElementRef;
  previewImage: any;
  titleRequiredError = false;
  nameRequiredError = false;
  readonly = false;
  zoom = 1.0;
  dragNode = null;
 
  constructor(
    private dragUtil: DragUtilService,
    private fileUtil: FileUtilService,  
    private printer: PrintService) { }

  ngOnInit() {
    this.reset();
    let zoom = window.outerWidth / 1500;
    if(zoom > 1) zoom = 1;
    this.zoom = zoom;

  }
  
  startDragNode(node){
    this.dragNode = node;
  }
  dropNode(node){
    this.dragNode.selected = false;
    node.selected = true;
    for(var i=0; i< this.vars.elements.length; i++){
      if(this.vars.elements[i].index == this.dragNode.index){ 
        this.vars.elements[i].index = node.index;
   
          this.moveLine(this.vars.elements[i]);
 
      }
    } 
    this.deleteElement(this.dragNode);
    
  }

  reset() {
    this.vars = {};
    this.vars.nodes = [];
    for (var i = 0; i < 35; i++) {
      this.vars.nodes.push({ index: i });
    }
  }

  nextStep() {
    this.nameRequiredError = false;
    this.titleRequiredError = false;
   
    switch (this.step) {
      case 1:
        if (!this.vars.name) {
          this.nameRequiredError = true;
          return;
        }
        if (!this.vars.title) {
          this.titleRequiredError = true;
          return;
        }
        break;
    }
    this.step++; 
  }
  prevStep() {
    this.step--;
  }

  pythagorean(sideA, sideB) {
    return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
  }

  getDegrees(a: number, b: number, c: number) {
    // applied cosine rule
    let A: number = Math.atan(a / b);

    // convert into degrees
    return A * 180 / Math.PI;
  }

  moveLine(elem: any) {
    let line: any = document.getElementsByClassName('drag-line-' + elem.index)[0];
    let x1 = elem.x + elem.nativeElement.offsetWidth / 2;
    let y1 = elem.y;
    line.style.display = 'block';
    line.style.width = '200px';

    let circle: any = document.getElementsByClassName('t-node-' + elem.index)[0];
    let x2 = circle.offsetLeft + circle.offsetWidth / 2;
    let y2 = circle.offsetTop + circle.offsetHeight / 2;

 
    if (y1 < y2) {
      if (x1 < x2) {
        y1 += elem.nativeElement.offsetHeight;

        line.style.top = y1 + 'px';
        line.style.left = x1 + 'px';
        line.style.transformOrigin = 'top left';
      }
      else {
        y1 += elem.nativeElement.offsetHeight;
        line.style.top = y2 + 'px';
        line.style.left = x2 + 'px';
        line.style.transformOrigin = 'top left';
      }
    }
    else {
      if (x1 < x2) {
        line.style.top = y1 + 'px';
        line.style.left = x1 + 'px';
        line.style.transformOrigin = 'top left';
      }
      else {
        line.style.top = y2 + 'px';
        line.style.left = x2 + 'px';
        line.style.transformOrigin = 'top left';
      }
    }
    let a = Math.abs(x1 - x2);
    let b = Math.abs(y1 - y2)
    let c = this.pythagorean(a, b);
    let deg = 0;


    if (y1 < y2) {
      if (x1 < x2) {
        deg = -this.getDegrees(b, a, c);
      }
      else {
        deg = this.getDegrees(b, a, c);
      }
    }
    else {
      if (x1 < x2) {
        deg = this.getDegrees(b, a, c);
      }
      else {
        deg = -this.getDegrees(b, a, c);
      }
    }

    line.style.width = c + 'px';
    line.style.transform = 'rotate(' + -deg + 'deg)';




  }

  hasSelectedNodes(){
    return !!_.find(this.vars.nodes, x=>!!x.selected);
  }

  addElement(node) {
    if (node.selected || this.readonly) return;

    if (!this.vars.elements) this.vars.elements = [];


    let elem: any = this.dragUtil.createElement(this.vars.elements);
    elem.type = "text";
    elem.index = node.index;
    this.vars.editId = elem.id;
    this.vars.elements.push(elem);

    // this.vars.elements.push(elem);
    node.selected = true;

    setTimeout(() => {

      let circle: any = document.getElementsByClassName('t-node-' + (elem as any).index)[0];
      let x2 = circle.offsetLeft + circle.offsetWidth / 2;
      let y2 = circle.offsetTop + circle.offsetHeight / 2;
      elem.x = x2 + 150;
      elem.y = y2 - 300;
      elem.edit = true;
      this.moveLine(elem);
      // elem.selectedElement = elem;
      // elem.mouseDown = true;
    }, 100);
  }

  showEdit(elem) {
    elem.edit = true;
    setTimeout(() => {
      this.moveLine(elem);
    }, 100);
  }
  closeEdit(elem) {
    elem.edit = false;
    setTimeout(() => {
      this.moveLine(elem);
    }, 100);
  } 
  deleteElement(elem){ 
    delete this.vars.nodes[elem.index].selected; 
    (document.getElementsByClassName('drag-line-' + elem.index)[0] as any).style.display='none';  
  }

  drop(elem, ev) { 
    this.clearUpload(elem);
    this.setUpload(elem, ev);
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  clearUpload(elem) {
    elem.fileList = null;
    elem.imagePreview = null;
    elem.fileUploadData = new FormData(); 
  }

  setUpload(elem, files: any) {
    elem.fileList = files;
    if (elem.fileList.length > 0) {
      let file: File = elem.fileList[0].file;
      elem.fileUploadData.append('file', file, file.name);


      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        elem.imagePreview = reader.result;
      }
    }

  }



  async save(){
    let v = _.cloneDeep(this.vars);
    v.elements.forEach(e => {
      delete e.nativeElement
    });
    this.fileUtil.save(v, this.vars.title + '.json');
  }

  prnt: any;
  async print() {

    if(!this.vars.elements || !this.vars.elements.length){
      alert('You must first add items to the timeline.');
      return;
    }

    this.readonly = true; 
    setTimeout(async () => {
      await this.printer.print(
        [this.timelineCanvas, this.itemCanvas],
        PrintOrientation.Landscape, true, true, false
      ); 
      this.readonly = false;
    }, 100);
  }
  async export() {

    if(!this.vars.elements || !this.vars.elements.length){
      alert('You must first add items to the timeline.');
      return;
    }
    
    this.readonly = true;
    setTimeout(async () => {
      await this.printer.export( 
        [this.timelineCanvas, this.itemCanvas],'timeline.pdf',
        PrintOrientation.Landscape, true, true, false
      ); 
      this.readonly = false;
    }, 100);
  }

  fileInput: any;
  open(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json); 
        this.step = 2;

        this.fileInput = null;
        this.fileUtil.setStep(2, this.vars);
        setTimeout(() => { 

          this.vars.elements.forEach(elem => { 
            elem.nativeElement = document.querySelector('[data-elem-id="' + elem.id + '"]');
            this.moveLine(elem);
          });
        }, 200);
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }


}
