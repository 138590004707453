import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardEvent extends TradingCard implements ITradingCard {

    //section 1 Description
    descriptionFields: TradingCardField[] = [
        new TradingCardField("What", "What was __topic__? What happened at this event?"),
        new TradingCardField("Time Period", "When did __topic__ take place (e.g., specific date, general time of year, part of day)?"),
        new TradingCardField("Location", "Where did __topic__ happen? You can give a general location or a specific place.")
    ];

    causeAndOutcomeFields: TradingCardField[] = [
        new TradingCardField("Cause", "What happened that caused __topic__ to occur?"),
        new TradingCardField("Reason", "Why did __topic__ occur? What was its purpose?"),
        new TradingCardField("Result", "What happened after __topic__ occured?")
    ];

    importanceFields: TradingCardField[] = [
        new TradingCardField("Significance", "What was important about __topic__? Did the event achieve its intended purpose?"),
        new TradingCardField("To Whom", "Who was most affected by __topic__")
    ];

    interestingFacts: TradingCardField[] = [
        new TradingCardField("Additional Information", "What interesting facts or details about __topic__ stand out to you?")
    ];

    personalConnection: TradingCardField[] = [
        new TradingCardField("Personal Connection", "What do you think about __topic__? What does it remind you of? Do you think __topic__ was influential? Why or why not?")
    ];


    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Cause and Outcome", this.causeAndOutcomeFields),
        new TradingCardSection("Importance", this.importanceFields),
        new TradingCardSection("Interesting Facts", this.interestingFacts),
        new TradingCardSection("Personal Connection", this.personalConnection)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Event');
    }
}
