import { AcrosticInput } from 'src/app/model/AcrosticPoemsInput';
export class AcrosticPoemsFile{

    name: string;
    topicWord: string;

    //brainstorming inputs
    brainstormingWord1: string;
    brainstormingWord2: string;
    brainstormingWord3: string;
    brainstormingWord4: string;
    brainstormingWord5: string;
    brainstormingWord6: string;
    brainstormingWord7: string;
    brainstormingWord8: string;

    inputValues: AcrosticInput[] = [];


}
