import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardRealPerson extends TradingCard implements ITradingCard {

    backgroundFields: TradingCardField[] = [
        new TradingCardField("Time Period", "When did __topic__ live or when did the important events of this person's life occur?"),
        new TradingCardField("Location", "Where did __topic__ live or experience an important event?"),
        new TradingCardField("Role", "What was __topic__'s job or work?")
    ];

    majorEventsFields: TradingCardField[] = [
        new TradingCardField("Events", "Describe the one or two most important events that happened in __topic__'s life"),
        new TradingCardField("Response", "How did __topic__ react or change after these events?")
    ];

    developmentFields: TradingCardField[] = [
        new TradingCardField("Problem", "What problem or challenge was __topic__ trying to solve?"),
        new TradingCardField("Goal", "What outcome or end result was __topic__ trying to achieve?"),
        new TradingCardField("Outcome", "Did __topic__ succeed? What happened as a result?")
    ];

    memorableInteractionsFields: TradingCardField[] = [
        new TradingCardField("Quote", "What memorable thing did __topic__ say?"),
        new TradingCardField("Action", "What was the one thing __topic__ did that really got your attention?"),
        new TradingCardField("Interactions", "How did __topic__ get along with others?")
    ];

    personalImpressionFields: TradingCardField[] = [
        new TradingCardField("Personal Impression", "Do you like __topic__? Why or why not? What do you thing about __topic__'s life or actions? What about the person inspired you to make a card?")
    ];

    sections: TradingCardSection[] = [
        new TradingCardSection("Background", this.backgroundFields),
        new TradingCardSection("Major Events", this.majorEventsFields),
        new TradingCardSection("Development", this.developmentFields),
        new TradingCardSection("Memorable Interactions", this.memorableInteractionsFields),
        new TradingCardSection("Personal Impression", this.personalImpressionFields)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Real Person');
    }

}
