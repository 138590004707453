<div class='postcard'>
    <div class='header orange'>TIP: Look here on each screen for tips on what to do next</div>
    <div *ngIf='step != 4' class='stamp'>
        <table>
            <tr>
                <td [ngClass]="{'orange-highlight': step==1}">Postcard Description</td>
                <td [ngClass]="{'orange-highlight': step==3}">
                    <div class='mini-stamp'></div>
                </td>
            </tr>
            <tr>
                <td [ngClass]="{'orange-highlight': step==2}" class='border-right'>Postcard Message</td>
                <td [ngClass]="{'orange-highlight': step==0}">Postal Address</td>
            </tr>
        </table>
    </div>
    <div class='logo'>
        post<img class='logo-dot' src='assets/postcard/logo-dot.png'>
        card<img class='logo-dot' src='assets/postcard/logo-dot.png'>
        creator
    </div>

    <ng-container *ngIf='step==0'>
        <h2>Mailing Address</h2>
        <div class='panels'>
            <div class='leftPanel'>
                <div class='yellow border'>
                    <strong>INSTRUCTIONS</strong><BR>
                    Type the name and address
                    of the person or people who
                    will receive the postcard. The
                    address appears on the lower right
                    part of the postcard.<br><br>
                    General address format:<br>
                    <div class='indent'>
                        Person's name<br>
                        Street Address<br>
                        City, State Zip
                    </div>
                    <br>
                    Example address:<br>
                    <div class='indent'>
                        Walter Lee Younger<br>
                        406 Clybourne Street
                        Clybourne Park, IL 62865
                    </div>
                </div>
            </div>
            <div class='rightPanel'>
                <label>Mailing Address</label>
                <textarea [(ngModel)]='vars.mailingAddress'></textarea>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf='step==1'>
        <h2>Postcard Description</h2>
        <div class='panels'>
            <div class='leftPanel'>
                <div class='yellow border'>
                    <strong>INSTRUCTIONS</strong><BR>
                    Type the description of the scene or
                    images that you will create for the front of the postcard.
                    The description appears on the upper left corner of the
                    postcard.<br><br>
                    If you need to document the source of an image,
                    be sure to include that information here too.
                </div>
            </div>
            <div class='rightPanel'>
                <label>Image Title</label>
                <input [(ngModel)]='vars.iamgeTitle' />
                <label>Image Description</label>
                <textarea [(ngModel)]='vars.imageDescription'></textarea>
                <label>Image Credit</label>
                <input [(ngModel)]='vars.imageCredit' />
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf='step==2'>
        <h2>Postcard Message</h2>
        <div class='panels'>
            <div class='leftPanel'>
                <div class='yellow border'>
                    <strong>INSTRUCTIONS</strong><BR>
                    Type the message for your
                    postcard here, in each of
                    the parts. The message
                    appears in the lower left
                    part o fthe postcard.<br><br>
                    Example;<br>
                    <div class='indent'>
                        Dear Dad,<br>
                        I just visited this great museum in Chicago that
                        has dinosaurs and stuff on science. It was really
                        a lot of fun. I wish you could have come with us.
                        <div>
                            <div class='pull-right'>
                                Love,<br>Travis
                            </div>
                        </div>
                        <div class='clear'></div>
                    </div>
                </div>
            </div>
            <div class='rightPanel'>
                <label>Greeting</label>
                <input [(ngModel)]='vars.greeting' />
                <label>Body</label>
                <textarea [(ngModel)]='vars.body'></textarea>
                <label>Closing</label>
                <input [(ngModel)]='vars.closing' />
                <label>Sender's Name</label>
                <input [(ngModel)]='vars.sendersName' />
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf='step==3'>
        <h2>Choose a Stamp:</h2>
        <div class='panels'>
            <div class='leftPanel'>
                <div class='yellow border'>
                    <strong>INSTRUCTIONS</strong><BR>
                    Click on the stamp that you want
                    to use on your postcard.
                </div>
            </div>
            <div class='rightPanel'>
                <div class='stamps' *ngFor='let stamp of stamps'>
                    <img [src]='"assets/postcard/stamps/" + stamp + ".png"' (click)='setStamp(stamp)'>
                </div>
                <div class='clear'></div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf='step==4'>
        <div class='printlayout' #printPage>
            <table width='100%' height='100%'>
                <tr>
                    <td class="small-print">

                        <div [innerHTML]='vars.iamgeTitle'></div>
                        <div [innerHTML]='vars.imageDescriptionFormatted'></div>
                        <div [innerHTML]='vars.imageCredit'></div>
                    </td>
                    <td align="right">
                        <img class='print-stamp' [src]='"assets/postcard/stamps/" + vars.stamp + ".png"'>
                    </td>
                </tr>
                <tr>
                    <td width='50%' style='border-right:1px solid black;'>
                        <div [innerHTML]='vars.greeting'></div>
                        <div [innerHTML]='vars.bodyFormatted || "&nbsp;"'></div>
                        <div [innerHTML]='vars.closing'></div>
                        <div [innerHTML]='vars.sendersName'></div>
                    </td>
                    <td>
                        <div [innerHTML]='vars.mailingAddressFormatted'></div>
                    </td>
                </tr>
            </table>
        </div>

    </ng-container>


    <div class='pull-right'>
        <button *ngIf='step == 0' (click)='showOpen=true'>Open</button>
        <button *ngIf='step > 0' (click)='step=step-1'>Back</button>
        <button *ngIf='step < 3' (click)='nextStep()'>Next</button>
        <button *ngIf='step == 4' (click)='save()'>Save</button>
        <button *ngIf='step == 4' (click)='print()'>Print</button>
    </div>

    <div *ngIf='showOpen'> 
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openPostcard($event)">
    </div>



    <div class='clear'></div>
</div>