<div class="app-border">
    <div class="title-bar">
        <span>Profile Publisher</span>
    </div>

    <div *ngIf="getIntroStep()==2" class="info-background frame">
        <button class="create-profile-button" (click)="interactive.step=3"></button>
    </div>

    <div *ngIf="getIntroStep()==3" class="choose-background frame">
        <button class="personal-profile-button" (click)="interactive.personalInteractive.step=1"></button>
        <button class="character-profile-button" (click)="interactive.characterInteractive.step=1"></button>
    </div>

    <div *ngIf="getPersonalStep()==1" class="personal-names-background names-background frame">
        <button class="names-active"></button>
        <button class="personal-info-button" (click)="interactive.personalInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.personalInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.personalInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.personalInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.personalInteractive.step=2"></button>
        <input class="name-input" [(ngModel)]="interactive.personalInteractive.name">
        <input class="profile-name-input" [(ngModel)]="interactive.personalInteractive.profileName">
    </div>

    <div *ngIf="getPersonalStep()==2" class="personal-personal-info-background personal-info-background frame">
        <button class="names-button" (click)="interactive.personalInteractive.step=1"></button>
        <button class="personal-info-active"></button>
        <button class="about-me-button" (click)="interactive.personalInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.personalInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.personalInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.personalInteractive.step=3"></button>
        <textarea class="quote-input" [(ngModel)]="interactive.personalInteractive.quote"></textarea>
        <textarea class="song-input" [(ngModel)]="interactive.personalInteractive.song"></textarea>
    </div>

    <div *ngIf="getPersonalStep()==3" class="personal-about-me-background about-me-background frame">
        <button class="names-button" (click)="interactive.personalInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.personalInteractive.step=2"></button>
        <button class="about-me-active"></button>
        <button class="latest-blog-button" (click)="interactive.personalInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.personalInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.personalInteractive.step=4"></button>
        <textarea class="about-me-input" [(ngModel)]="interactive.personalInteractive.aboutMe"></textarea>
    </div>

    <div *ngIf="getPersonalStep()==4" class="personal-latest-blog-background latest-blog-background frame">
        <button class="names-button" (click)="interactive.personalInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.personalInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.personalInteractive.step=3"></button>
        <button class="latest-blog-active"></button>
        <button class="interests-button" (click)="interactive.personalInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.personalInteractive.step=5"></button>
        <textarea class="latest-blog-input" [(ngModel)]="interactive.personalInteractive.latestBlog"></textarea>
    </div>

    <div *ngIf="getPersonalStep()==5" class="personal-interests-background interests-background frame">
        <button class="names-button" (click)="interactive.personalInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.personalInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.personalInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.personalInteractive.step=4"></button>
        <button class="interests-active"></button>
        <button class="add-changes-and-preview" (click)="interactive.personalInteractive.step=6"></button>
        <textarea class="interests-input" [(ngModel)]="interactive.personalInteractive.interests"></textarea>
    </div>

    <div *ngIf="getPersonalStep()==6" class="personal-finished-background finished-background">
        <h1>Congrats! You have finished your Personal Profile.</h1>
        <p>
            Review your profile. If you want to make changes, use the Back button.
        </p>
        <p>
            When it is finished, you can ...
        </p>
        <div class="handler-buttons">
            <button (click)='printFinal()'>
              <i class='fa fa-print'></i>&nbsp;Print
            </button>
            <button (click)='exportFinal()'>
              <i class='fa fa-download'></i>&nbsp;Export
            </button>
            <button (click)='saveInProgress()'>
              <i class='fa fa-save'></i>&nbsp;Save
            </button>
            <button (click)="startNew()">Start New Project</button>
        </div>
        <br>
        <div class="file-name-container">
            <label for="file-name-input">Enter a name for your profile: 
                <input class="file-name-input" id="file-name-input" [(ngModel)]="personalFileName">
            </label>
        </div>
        <div class="personal-profile-background profile-background">
            <input class="name-input" [(ngModel)]="interactive.personalInteractive.name">
            <input class="profile-name-input" [(ngModel)]="interactive.personalInteractive.profileName">
            <textarea class="about-me-input" [(ngModel)]="interactive.personalInteractive.aboutMe"></textarea>
            <textarea class="quote-input" [(ngModel)]="interactive.personalInteractive.quote"></textarea>
            <textarea class="song-input" [(ngModel)]="interactive.personalInteractive.song"></textarea>
            <textarea class="latest-blog-input" [(ngModel)]="interactive.personalInteractive.latestBlog"></textarea>
            <textarea class="interests-input" [(ngModel)]="interactive.personalInteractive.interests"></textarea>
        </div>
        <div class="personal-profile-background profile-background" id="profile-copy" #personalPrintCanvas>
            <p class="name-input"> {{ interactive.personalInteractive.name }} </p>
            <p class="profile-name-input"> {{ interactive.personalInteractive.profileName }} </p>
            <p class="about-me-input"> {{ interactive.personalInteractive.aboutMe }} </p>
            <p class="quote-input"> {{ interactive.personalInteractive.quote }} </p>
            <p class="song-input"> {{ interactive.personalInteractive.song }} </p>
            <p class="latest-blog-input"> {{ interactive.personalInteractive.latestBlog }} </p>
            <p class="interests-input"> {{ interactive.personalInteractive.interests }} </p>
        </div>
    </div>

    <div *ngIf="getCharacterStep()==1" class="character-names-background names-background frame">
        <button class="names-active"></button>
        <button class="personal-info-button" (click)="interactive.characterInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.characterInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.characterInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.characterInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.characterInteractive.step=2"></button>
        <input class="name-input" [(ngModel)]="interactive.characterInteractive.name">
        <input class="profile-name-input" [(ngModel)]="interactive.characterInteractive.profileName">
    </div>

    <div *ngIf="getCharacterStep()==2" class="character-personal-info-background personal-info-background frame">
        <button class="names-button" (click)="interactive.characterInteractive.step=1"></button>
        <button class="personal-info-active"></button>
        <button class="about-me-button" (click)="interactive.characterInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.characterInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.characterInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.characterInteractive.step=3"></button>
        <textarea class="quote-input" [(ngModel)]="interactive.characterInteractive.quote"></textarea>
        <textarea class="song-input" [(ngModel)]="interactive.characterInteractive.song"></textarea>
    </div>

    <div *ngIf="getCharacterStep()==3" class="character-about-me-background about-me-background frame">
        <button class="names-button" (click)="interactive.characterInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.characterInteractive.step=2"></button>
        <button class="about-me-active"></button>
        <button class="latest-blog-button" (click)="interactive.characterInteractive.step=4"></button>
        <button class="interests-button" (click)="interactive.characterInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.characterInteractive.step=4"></button>
        <textarea class="about-me-input" [(ngModel)]="interactive.characterInteractive.aboutMe"></textarea>
    </div>

    <div *ngIf="getCharacterStep()==4" class="character-latest-blog-background latest-blog-background frame">
        <button class="names-button" (click)="interactive.characterInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.characterInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.characterInteractive.step=3"></button>
        <button class="latest-blog-active"></button>
        <button class="interests-button" (click)="interactive.characterInteractive.step=5"></button>
        <button class="add-changes-and-next-button" (click)="interactive.characterInteractive.step=5"></button>
        <textarea class="latest-blog-input" [(ngModel)]="interactive.characterInteractive.latestBlog"></textarea>
    </div>

    <div *ngIf="getCharacterStep()==5" class="character-interests-background interests-background frame">
        <button class="names-button" (click)="interactive.characterInteractive.step=1"></button>
        <button class="personal-info-button" (click)="interactive.characterInteractive.step=2"></button>
        <button class="about-me-button" (click)="interactive.characterInteractive.step=3"></button>
        <button class="latest-blog-button" (click)="interactive.characterInteractive.step=4"></button>
        <button class="interests-active"></button>
        <button class="add-changes-and-preview" (click)="interactive.characterInteractive.step=6"></button>
        <textarea class="interests-input" [(ngModel)]="interactive.characterInteractive.interests"></textarea>
    </div>

    <div *ngIf="getCharacterStep()==6" class="character-finished-background finished-background">
        <h1>Congrats! You have finished your Character Profile.</h1>
        <p>
            Review your profile. If you want to make changes, use the Back button.
        </p>
        <p>
            When it is finished, you can ...
        </p>
        <div class="handler-buttons">
            <button (click)='printFinal()'>
              <i class='fa fa-print'></i>&nbsp;Print
            </button>
            <button (click)='exportFinal()'>
              <i class='fa fa-download'></i>&nbsp;Export
            </button>
            <button (click)='saveInProgress()'>
              <i class='fa fa-save'></i>&nbsp;Save
            </button>
            <button (click)="startNew()">Start New Project</button>
        </div>
        <br>
        <div class="file-name-container">
            <label for="file-name-input">Enter a name for your final profile: 
                <input class="file-name-input" id="file-name-input" [(ngModel)]="characterFileName">
            </label>
        </div>
        <div class="character-profile-background profile-background">
            <input class="name-input" [(ngModel)]="interactive.characterInteractive.name">
            <input class="profile-name-input" [(ngModel)]="interactive.characterInteractive.profileName">
            <textarea class="about-me-input" [(ngModel)]="interactive.characterInteractive.aboutMe"></textarea>
            <textarea class="quote-input" [(ngModel)]="interactive.characterInteractive.quote"></textarea>
            <textarea class="song-input" [(ngModel)]="interactive.characterInteractive.song"></textarea>
            <textarea class="latest-blog-input" [(ngModel)]="interactive.characterInteractive.latestBlog"></textarea>
            <textarea class="interests-input" [(ngModel)]="interactive.characterInteractive.interests"></textarea>
        </div>
        <div class="character-profile-background profile-background" id="profile-copy" #characterPrintCanvas>
            <p class="name-input"> {{ interactive.characterInteractive.name }} </p>
            <p class="profile-name-input"> {{ interactive.characterInteractive.profileName }} </p>
            <p class="about-me-input"> {{ interactive.characterInteractive.aboutMe }} </p>
            <p class="quote-input"> {{ interactive.characterInteractive.quote }} </p>
            <p class="song-input"> {{ interactive.characterInteractive.song }} </p>
            <p class="latest-blog-input"> {{ interactive.characterInteractive.latestBlog }} </p>
            <p class="interests-input"> {{ interactive.characterInteractive.interests }} </p>
        </div>
    </div>
    

    <div>
        <h2>OPEN SAVED PROFILE</h2>
        <input [(ngModel)]='fileInput' type="file" (change)="openProfile($event)">
    </div>

</div>

