export class WordFamily {
    name?: string;
    availableWords?: object = {
        "a": ["tap", "van", "plan", "sack", "sat", "clap", "can", "fan", "ran", "hat", "sap", "snack", "pat", "track", "nap", "snap", "black", "tack", "pan", 
              "pack", "mat", "rack", "flat", "bat", "lap", "tan", "map", "rat"],
        "e": ["sled", "then", "den", "net", "men", "well", "shed", "get", "pen", "bell", "shell", "set", "bet", "met", "wet", "when", "bed", "sell", "fed", 
              "led", "ten", "fell", "jet", "let"],
        "i": ["kit", "chin", "trick", "sit", "bit", "brick", "tin", "jig", "thick", "stick", "pig", "kick", "twig", "fig", "pick", "pin", "knit", "tick", 
              "fin", "skin", "fit", "wig", "dig", "spin", "pit", "thin", "lick", "kin"],
        "o": ["spot", "clock", "dock", "rob", "shot", "dot", "cop", "hop", "pop", "got", "stop", "pot", "mob", "lock", "lot", "job", "chop", "lob", 
              "drop", "cob", "not", "rock", "block", "mop", "knoc"],
        "u": ["tuck", "truck", "luck", "hut", "gun", "mug", "slug", "gun", "shut", "spun", "plug", "rug", "buck", "dug", "stuck", "suck", "fun", "tug", 
              "hug", "sun", "nut", "but"]
    };
    
    currentWords: object = {
        "a": [],
        "e": [],
        "i": [],
        "o": [],
        "u": []
    };
}