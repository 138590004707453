import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { CompareContrastGuide } from "src/app/model/CompareContrastGuide";

@Component({
  selector: "app-compare-contrast-guide",
  templateUrl: "./compare-contrast-guide.component.html",
  styleUrls: ["./compare-contrast-guide.component.scss"],
})
export class CompareContrastGuideComponent implements OnInit {
  step: number = 0;
  currentApp: string = "guide";
  currentSelect: number[];
  warnEnd: boolean = false;
  currentFrame: string;
  currentColor: string;
  helpClicked: boolean = false;
  mouseDown: boolean;
  clickPosition: number[];
  helpBoxPos: number[];
  helpBoxPosStr: string;
  guideOptions: string[][] = [
    ["Overview", "Purpose", "Choosing Items"],
    ["Comparison", "Contrast"],
    ["Sample Situation", "Comparison", "Contrast", "Evaluating"],
    [
      "Organizing a Paper",
      "Balanced Ideas",
      "Three Strategies",
      "Whole-to-Whole Overview",
      "Whole-to-Whole Outline",
      "Similarities-to-Differences Overview",
      "Similarities-to-Differences Outline",
      "Point-by-Point Overview",
      "Point-by-Point Outline",
    ],
    [
      "Graphic Organizers",
      "2-Circle Venn Diagram",
      "3-Circle Venn Diagram",
      "Charts",
    ],
    ["Transitions", "For Comparison", "For Contrast"],
    ["Checklist", "Purpose", "Organization", "Transitions"],
  ];

  mapData: CompareContrastGuide = new CompareContrastGuide();

  constructor() {}

  ngOnInit() {
    console.log("currentFrame", this.currentFrame);
    this.step = 0;
    this.mapData.guideSelection = "0";
    this.updatecurrentSelect();
    console.log("currentSelect", this.currentSelect);
    this.helpBoxPos = [339, 188];
    this.helpBoxPosStr = "339px 188px";
    this.mouseDown = false;
  }

  updatecurrentSelect() {
    this.currentSelect = [this.step, Number(this.mapData.guideSelection)];
    this.currentFrame = this.changeBackground();
    this.currentColor = this.changeHeader();
  }

  prevButton() {
    if (this.currentSelect[1] === 0 && this.step != 0) {
      let prevArray = this.currentSelect[0] - 1;
      this.step--;
      this.mapData.guideSelection = `${
        this.guideOptions[prevArray].length - 1
      }`;
    } else if (this.currentSelect[1] != 0) {
      this.mapData.guideSelection = `${this.currentSelect[1] - 1}`;
    }
    this.warnEnd = false;
    this.updatecurrentSelect();
  }

  isEqual(arr1: any[], arr2: any[]) {
    if (arr1 === arr2) return true;
    if (arr1 == null || arr2 == null) return false;
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  nextButton() {
    let currentArray = this.guideOptions[this.currentSelect[0]];
    if (this.isEqual(this.currentSelect, [6, 3])) {
      this.warnEnd = true;
    } else if (
      this.currentSelect[1] === currentArray.length - 1 &&
      this.step != this.guideOptions.length - 1
    ) {
      this.step++;
      this.mapData.guideSelection = `0`;
    } else if (this.currentSelect[1] != currentArray.length - 1) {
      this.mapData.guideSelection = `${this.currentSelect[1] + 1}`;
    }
    this.updatecurrentSelect();
  }

  skipToArray(newArray) {
    this.step = newArray[0];
    this.currentSelect = newArray;
    this.mapData.guideSelection = `${this.currentSelect[1]}`;
    this.warnEnd = false;
    this.updatecurrentSelect();
  }
  changeBackground() {
    if (this.currentSelect[0] < 4) {
      return (
        "url(assets/compare-contrast-map/guide/frame" +
        (this.currentSelect[0] + 1) +
        ".png)"
      );
    } else {
      return "url(assets/compare-contrast-map/guide/frame1.png)";
    }
  }

  changeHeader() {
    switch (this.currentSelect[0]) {
      default:
        return "#6600cc";
      case 1:
        return "#009999";
      case 2:
        return "#993300";
      case 3:
        return "#9966cc";
    }
  }

  clickHelpBox(event) {
    this.mouseDown = true;
    this.clickPosition = [event.clientX - this.helpBoxPos[1], event.clientY - this.helpBoxPos[0]];
  }

  releaseHelpBox() {
    this.mouseDown = false;
  }

  dragHelpBox(event) {
    let dragPosition = [event.clientX, event.clientY]
    if (this.mouseDown === true) {
      this.helpBoxPosStr = `${dragPosition[1] - this.clickPosition[1]}px ${dragPosition[0] - this.clickPosition[0]}px`;
      this.helpBoxPos = [dragPosition[1] - this.clickPosition[1], dragPosition[0] - this.clickPosition[0]];
    }
  }


}
