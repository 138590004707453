<div class='app-border diamante-container'>
  <div class='title-bar'>
    <span>Diamante Poems</span>
  </div>

  <div *ngIf='step==1' class='step'>
    <div class='page-background'>
      <div *ngIf='showingExamples' class='dim'></div>
      <div class='page-block-1'>
        <div *ngIf='!showingExamples' class='description'>
          <img class='see-examples-button' (click)='showingExamples=true'
            src='assets/diamante-poems/see-examples-button.png'>
        </div>

        <img *ngIf='showingExamples' class='synonym-poem' src='assets/diamante-poems/synonym-poem.png'>
        <img *ngIf='showingExamples' class='antonym-poem' src='assets/diamante-poems/antonym-poem.png'>

        <img *ngIf='showingExamples' class='close-examples-button' (click)='showingExamples=false'
          src='assets/diamante-poems/close-examples-button.png'>
      </div>
      <div class='page-block-2'>
        <div *ngIf='!showingExamples' class='input-container'>
          <span class='page-1-label'>Your Name:</span>
          <input class='page-1-input' [ngClass]="{'input-error': nameError}" type='text' maxlength='20'
            [(ngModel)]='poem.name' (change)='nameError = false'>

          <span class='page-1-label'>Poem Title:</span>
          <input class='page-1-input' [ngClass]="{'input-error': titleError}" type='text' maxlength='20'
            [(ngModel)]='poem.title' (change)='titleError = false'>
        </div>

        <a class='print-examples-button' (click)='clickPrintExample()' target='_blank'
          href='assets/diamante-poems/diamante_examples.pdf'></a>
        <img class='start-button' src='assets/diamante-poems/start-button.png' (click)='initPoem()'>
      </div>
    </div>
    <h2>OPEN SAVED POEM</h2>

    <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openPoem($event)">
  </div>

  <div *ngIf='step==2' class='step'>
    <div class='page-background'>
      <div *ngIf='restartConfirm' class='restart-dim'></div>
      <div class='page-block-1'>
        <span class='title-label'>Poem Title:</span>
        <input type='text' class='title-input' [(ngModel)]='poem.title'>
        <div class='get-started'>
          <input type='text' maxlength='25' class='beginning-topic-input' [(ngModel)]='poem.beginningTopic'>
          <input type='text' maxlength='25' class='ending-topic-input' [(ngModel)]='poem.endingTopic'>

          <div class='antonym-info-button' (click)='showingAntonymInfo = true'></div>
          <div class='synonym-info-button' (click)='showingSynonymInfo = true'></div>

          <div *ngIf='showingAntonymInfo' class='antonym-info'>
            <img class='close-info-button' src='assets/diamante-poems/close-info-button.png'
              (click)='showingAntonymInfo = false'>
          </div>
          <div *ngIf='showingSynonymInfo' class='synonym-info'>
            <img class='close-info-button' src='assets/diamante-poems/close-info-button.png'
              (click)='showingSynonymInfo = false'>
          </div>
        </div>
      </div>

      <div class='page-block-2'>
        <img class='restart-button' src='assets/diamante-poems/restart-button.png' (click)='restartConfirm = true'>
        <img class='next-button' src='assets/diamante-poems/next-button.png' (click)='step=3'>
      </div>

      <div class='start-over' *ngIf='restartConfirm'>
        <img class='confirm-button' src='assets/alphabet-organizer/confirm-button.png' (click)='restart()'>
        <img class='restart-cancel-button' src='assets/alphabet-organizer/restart-cancel-button.png'
          (click)='restartConfirm = false'>
      </div>
    </div>
  </div>

  <div *ngIf='step==3' class='step'>
    <div class='page-background'>
      <div class='page-block-1'>
        <span class='title-label'>Poem Title:</span>
        <input type='text' class='title-input' [(ngModel)]='poem.title'>
        <div class='write-poem'>
          <div class='line-1-label-container'>
            <span class='line-label-1'>Line 1:</span>
            <span class='line-label-2'>Your beginning topic</span>
          </div>
          <input class='topic-input' type='text' maxlength='25' [(ngModel)]='poem.beginningTopic'>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 2:</span>
            <span class='line-label-2'>Two</span>
            <div class='line-info-container'>
              <div *ngIf='showingAdjectivesInfo' class='adjectives-info'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingAdjectivesInfo = false'>
              </div>
              <div class='adjectives-info-button' (click)='showingAdjectivesInfo = true'>
                <span class='adjectives-info-text'>adjectives</span>
              </div>
            </div>
            <span class='line-label-2'>about {{ poem.beginningTopic }}</span>
          </div>
          <div class='line-input-container'>
            <input class='two-input-1' type='text' maxlength='15' [(ngModel)]='poem.adjective1'>
            <input class='two-input-2' type='text' maxlength='15' [(ngModel)]='poem.adjective2'>
          </div>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 3:</span>
            <span class='line-label-2'>Three</span>

            <div class='line-info-container'>
              <div *ngIf='showingIngInfo' class='ing-info'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingIngInfo = false'>
              </div>

              <div class='ing-info-button' (click)='showingIngInfo = true'>
                <span class='line-info-text'>-ing words</span>
              </div>
            </div>
            <span class='line-label-2'>about {{ poem.beginningTopic }}</span>
          </div>
          <div class='line-input-container'>
            <input class='three-input-1' type='text' maxlength='15' [(ngModel)]='poem.ing1'>
            <input class='three-input-2' type='text' maxlength='15' [(ngModel)]='poem.ing2'>
            <input class='three-input-2' type='text' maxlength='15' [(ngModel)]='poem.ing3'>
          </div>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 4:</span>
            <span class='line-label-2'>Four</span>

            <div class='line-info-container'>
              <div class='noun-info' *ngIf='showingNounInfo'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingNounInfo = false'>
              </div>

              <div class='noun-info-button' (click)='showingNounInfo = true'>
                <span class='line-info-text'>nouns</span>
              </div>
            </div>

            <span class='line-label-2'>or a</span>

            <div class='line-info-container'>
              <div class='short-phrase-info' *ngIf='showingShortPhraseInfo'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingShortPhraseInfo = false'>
              </div>

              <div class='short-phrase-info-button' (click)='showingShortPhraseInfo = true'>
                <span class='line-info-text'>short phrase</span>
              </div>
            </div>

            <span class='line-label-2'>linking your topic or topics</span>
          </div>

          <div class='line-input-container'>
            <input class='noun-input' type='text' maxlength='80' [(ngModel)]='poem.nouns'>
          </div>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 5:</span>
            <span class='line-label-2'>Three</span>

            <div class='line-info-container'>
              <div *ngIf='showingIng2Info' class='ing-info-2'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingIng2Info = false'>
              </div>

              <div class='ing-info-button' (click)='showingIng2Info = true'>
                <span class='line-info-text'>-ing words</span>
              </div>
            </div>

            <span class='line-label-2'> about {{ poem.endingTopic }}</span>
          </div>

          <div class='line-input-container'>
            <input class='three-input-1' type='text' maxlength='15' [(ngModel)]='poem.ing4'>
            <input class='three-input-2' type='text' maxlength='15' [(ngModel)]='poem.ing5'>
            <input class='three-input-2' type='text' maxlength='15' [(ngModel)]='poem.ing6'>
          </div>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 6:</span>
            <span class='line-label-2'>Two</span>

            <div class='line-info-container'>
              <div *ngIf='showingAdjectives2Info' class='adjectives-2-info'>
                <img class='close-info-button' src='assets/diamante-poems/close-info-button.png' (click)='showingAdjectives2Info = false'>
              </div>

              <div class='adjectives-info-button' (click)='showingAdjectives2Info = true'>
                <span class='adjectives-info-text'>adjectives</span>
              </div>
            </div>

            <span class='line-label-2'>about {{ poem.endingTopic }}</span>
          </div>

          <div class='line-input-container'>
            <input class='two-input-1' type='text' maxlength='15' [(ngModel)]='poem.adjective3'>
            <input class='two-input-2' type='text' maxlength='15' [(ngModel)]='poem.adjective4'>
          </div>

          <div class='line-label-container'>
            <span class='line-label-1'>Line 7:</span>
            <span class='line-label-2'>Your ending topic</span>
          </div>

          <input class='topic-input' type='text' maxlength='25' [(ngModel)]='poem.endingTopic'>
        </div>
      </div>

      <div class='page-block-2'>
        <img class='back-button' src='assets/diamante-poems/back-button.png' (click)='step=2'>
        <img class='next-button' src='assets/diamante-poems/finish-button.png' (click)='initPrint()'>
      </div>
    </div>
  </div>

  <div *ngIf='step==4' class='step'>
    <div class='toolbar'>
      <button (click)="step=3">
        <i class='fa fa-chevron-left'></i> &nbsp; Back
      </button>

      <button (click)='save()'><i class='fa fa-save'></i> Save</button>

      <button (click)='print()'>
        <i class='fa fa-print'></i>&nbsp;Print
      </button>
    </div>

    <div class='print-preview-landscape'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas class='print-canvas print-content'>
      <img class='print-logo' src='assets/diamante-poems/logo.png'>

      <div class='print-credit'>
        <p class='print-title'>{{ poem.title }}</p>
        <p class='print-name'>By: {{ poem.name }}</p>
      </div>

      <div class='print-diamond'>
        <p class='print-beginning-topic'><b>{{ poem.beginningTopic }}</b></p>
        <p class='print-label'><b>{{ poem.adjective1 }} {{ poem.adjective2 }}</b></p>
        <p class='print-label'><b>{{ poem.ing1 }} {{ poem.ing2 }} {{ poem.ing3 }}</b></p>
        <p class='print-label'><b>{{ poem.nouns }}</b></p>
        <p class='print-label'><b>{{ poem.ing4 }} {{ poem.ing5 }} {{ poem.ing6 }}</b></p>
        <p class='print-label'><b>{{ poem.adjective3 }} {{ poem.adjective4 }}</b></p>
        <p class='print-label'><b>{{ poem.endingTopic }}</b></p>

      </div>
    </div>
  </div>
</div>
