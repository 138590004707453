import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LetterGenerator } from 'src/app/model/LetterGenerator';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-letter-generator',
  templateUrl: './letter-generator.component.html',
  styleUrls: ['./letter-generator.component.scss']
})
export class LetterGeneratorComponent implements OnInit {
  previewImage: any;
  step: number = -1;
  yourNameError: boolean = false;
  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvasFriendly', null) printCanvasFriendly: ElementRef;
  @ViewChild('printCanvasBusiness', null) printCanvasBusiness: ElementRef;

  letterData: LetterGenerator = new LetterGenerator();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit(): void {
    var images = new Array()
		let preload = [
      "next-button.png",
			"next-hover.png",
			"lg-page-1.png",
      "sample-page-button.png",
      "lg-page-1-continue.png",
      "lg-page-1-continue-hover.png",
      "lg-page-2.png",
      "sample-page.png",
      "back-button.png",
      "back-hover.png",
      "sample-continue.png",
      "sample-continue-hover.png",
      "friendly-letter-button.png",
      "business-letter-button.png",
      "create-letter1.png",
      "create-letter2.png",
      "create-letter3.png"

    ];
    let i = 0;
			for (i = 0; i < preload.length; i++) {
				images[i] = new Image()
				images[i].src = preload[i]
			}


  }

  prevStep() {
    this.step--;
  }

  initBusiness(){
    this.letterData.letterType = 'business';
    this.nextStep();
  }
  initFriendly(){
    this.letterData.letterType = 'friendly';
    this.nextStep();
  }
  businessInteractClick(blockNumber: number){

  }
  friendlyInteractClick(blockNumber: number){
    switch (this.letterData.letterType){
      case 'friendly':
        this.step = blockNumber;
      break;
      case 'business':
        this.step = blockNumber
      break;
    }
  }

  async nextStep() {

    this.yourNameError = false;
    switch (this.step){
      case -1:
        if (!this.letterData.yourName) {
          this.yourNameError = true;
          return;
        }
      break;

    }
    this.step++;

    switch (this.step){
      case 7:
        let that = this;
        this.previewImage = null;
        setTimeout(async () => {
          this.previewImage =  await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Portrait);
        }, 100);

      break;
    }
  }
  showSampleLetter() {
    this.step=-2;
  }
  writeYourOwnLetter() {

    this.step=0;
  }
  get printCanvas(){
    if(this.letterData.letterType == 'friendly'){
      return this.printCanvasFriendly;
    }
    else{
      return this.printCanvasBusiness;
    }
  }
  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Portrait);
  }
  async export() {
    this.printer.export([this.printCanvas], 'Letter.pdf', PrintOrientation.Portrait);
  }
  save(){
    this.fileUtil.save(this.letterData, 'Letter.json')
  }

  fileInput: any;
  openLetter(event: any){
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try{
      let json = e.target.result;
      this.letterData = JSON.parse(json);
      this.step = 1;
      }
      catch(e){
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  formatText(){
    this.letterData.body = this.letterData.bodyUnformatted;
    while(this.letterData.body.indexOf('\n')>=0){
      this.letterData.body = this.letterData.body.replace('\n', '<br>');
    }
    this.letterData.postscript = this.letterData.postscriptUnformatted;
    while(this.letterData.postscript.indexOf('\n')>=0){
      this.letterData.postscript = this.letterData.postscript.replace('\n', '<br>');
    }
  }
}
