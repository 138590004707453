import { SafeUrl } from "@angular/platform-browser";

export class ExampleTale {
    ex: string[] = ["Once upon a time there was a lonely prince.", "One day, he was sitting by a pond when a frog popped out of the water.", "\"Kiss me,\" the frog said to the prince, \"and you'll never be lonely again.\"", "\"Kissing frogs is for princesses, not princes!\" the prince said.", "But he was so lonely that he decided to give the frog a big SMOOCH!", "The prince turned into a frog. He and the other frog lived happily ever after on their lily pad. THE END", "Are you ready to write your own fractured fairy tale?"];


   constructor() {

   }

}
