import { Component, ElementRef, OnInit, SystemJsNgModuleLoader, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';
import { ThemePoemsFile } from '../../model/ThemePoemsFile';

@Component({
  selector: 'app-theme-poems',
  templateUrl: './theme-poems.component.html',
  styleUrls: ['./theme-poems.component.scss']
})
export class ThemePoemsComponent implements OnInit {
  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  previewImage: any;

  step: number = 1;
  showInfo: boolean = false;

  poem: ThemePoemsFile = new ThemePoemsFile();

  nameError: boolean = false;
  
  selectedTheme: string = '';

  startOverConfirm: boolean = false;

  backgroundImage: string;

  printing: boolean = false;

  constructor(private printer: PrintService) { }

  ngOnInit() {
    for (let i=0; i<8; i++) {
      this.poem.brainstormWords.push({brainstormWord: ''});
    }
  }


  initPoem() {
    if (!this.poem.name) {
      this.nameError = true;
    }
    else {
      this.step++;
    }
  }

  restart() {
    this.poem.title = "";
    this.poem.selectedShape = "";
    this.selectedTheme = "";
    this.poem.brainstormWords.splice(0, 8);

    for (let i = 0; i<8; i++) {
      this.poem.brainstormWords.push({brainstormWord: ''});
    }

    this.startOverConfirm = false;
    this.step = 3;
  }

  submitBrainstormWords() {
    let addedWords = [];
    for (let w of this.poem.brainstormWords) {
      if (w.brainstormWord != '') {
        addedWords.push(w);
      }
    }

    this.poem.brainstormWords = addedWords;
    this.step = 5;
    console.log(this.poem.brainstormWords)
  }

  cancelPoem() {
    let addedWords = [];
    for (let i=0; i<8; i++) {
      if (this.poem.brainstormWords[i]) {
        addedWords.push({brainstormWord: this.poem.brainstormWords[i].brainstormWord});
      }       
      else {
        addedWords.push({brainstormWord: ''});
      }
    }

    this.poem.brainstormWords = addedWords;
    this.poem.title = '';
    this.poem.poemText = '';
    this.step = 4;
    console.log(this.poem.brainstormWords);
  }

  initPrint() {
    const backgroundImageURL = `url(../../../assets/theme-poems/${this.poem.selectedShape.toLowerCase()}-print.png)`
    document.documentElement.style.setProperty('--background-image', backgroundImageURL);
    this.printing = true;
    let that = this;
    setTimeout(async () => {
      this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Portrait);
    }, 100);

    this.step++;
  }

  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Portrait);
  }
}