<div class="app-border">
    <div class="title-bar">
        <span>Literary Elements Mapping</span>
        <select class="graphic-organizer-menu" [(ngModel)]="projectData.graphicOrganizer"
            (ngModelChange)="handleSelection()">
            <option value="graphicOrganizers">Graphic Organizers</option>
            <option *ngIf="projectData.author && projectData.title" value="characterMap">
                Character Map
            </option>
            <option *ngIf="projectData.author && projectData.title" value="conflictMap">
                Conflict Map
            </option>
            <option *ngIf="projectData.author && projectData.title" value="resolutionMap">
                Resolution Map
            </option>
            <option *ngIf="projectData.author && projectData.title" value="settingMap">
                Setting Map
            </option>
        </select>
        <br />
        <span *ngIf="step >= 1">{{ projectData.title }} by {{ projectData.author }}</span>
    </div>
    <div class="header">

        <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'characterMap'">
            <label class="header-label"> Character Map </label>
            <p *ngIf="step === 1" class="header-instruction">
                Enter the character's name.
            </p>
            <p *ngIf="step === 2" class="header-instruction">
                What does the character look like?
            </p>
            <p *ngIf="step === 3" class="header-instruction">
                How does the character act?
            </p>
            <p *ngIf="step === 4" class="header-instruction">
                How do other characters in the story react to this character?
            </p>

            <button *ngIf="step === 5" class="goto-print" (click)="initPrint()">
                Next
            </button>
        </div>
        <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'conflictMap'">
            <label class="header-label"> Conflict Map </label>
            <p *ngIf="step === 1" class="header-instruction">
                What is the conflict? (another person, a thing, or thoughts and feelings of the character)
            </p>
            <p *ngIf="step === 2" class="header-instruction">
                Why does this conflict occur?
            </p>
            <p *ngIf="step === 3" class="header-instruction">
                What are some ways the conflict could be resolved?
            </p>
            <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
                <i class="fa fa-print"></i>
                Print
            </button>
        </div>
        <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'resolutionMap'">
            <label class="header-label"> Resolution Map </label>
            <p *ngIf="step === 1" class="header-instruction">
                How is the conflict resolved?
            </p>
            <p *ngIf="step === 2" class="header-instruction">
                What happens after the conflict is resolved?
            </p>
            <p *ngIf="step === 3" class="header-instruction">
                How does the conflict and its resolution affect the character?
            </p>
            <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
                <i class="fa fa-print"></i>
                Print
            </button>
        </div>
        <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'settingMap'">
            <label class="header-label"> Setting Map </label>
            <p *ngIf="step === 1" class="header-instruction">
                Where does the story take place? (city, castle, ship, cemetery, moon)
            </p>
            <p *ngIf="step === 2" class="header-instruction">
                When does the story take place? (afternoon, fall, morning, date)
            </p>
            <p *ngIf="step === 3" class="header-instruction">
                Write a detailed description of the setting. (weather, noise, colors)
            </p>
            <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
                <i class="fa fa-print"></i>
                Print
            </button>
        </div>
    </div>

    <!------------------------------------------  Intro Screen  -------------------------------------------------------->
    <div *ngIf="step === 0" class="step-0">
        <h1>Project Labels</h1>
        <p>Enter a title for your project and your name.</p>

        <div class="block">
            <label>Title:</label>
            <input type="text" id="title" name="title" [(ngModel)]="projectData.title" />
        </div>

        <div class="block">
            <label>By:</label>
            <input type="text" id="author" name="author" [(ngModel)]="projectData.author" />
        </div>

        <div class="choose-graphic">
            <h1>Choose a Graphic Organizer</h1>
            <p *ngIf="showWarning" class="warning">
                You must first enter a title for your project and your name.
            </p>

            <div [ngStyle]="{ 'margin-top': graphicMargin }" (mouseenter)="enterInitGraphOrg()"
                (mouseleave)="leaveInitGraphOrg()" (click)="initGraphicOrganizer('characterMap')"
                class="choose-graphic-button1">
                <img src="assets/lit-elements/lit-char.png" />
                <label class="choose-graphic-label">Character <br />
                    Map</label>
            </div>
            <div (mouseenter)="enterInitGraphOrg()" (mouseleave)="leaveInitGraphOrg()"
                (click)="initGraphicOrganizer('conflictMap')" class="choose-graphic-button2">
                <img src="assets/lit-elements/lit-conf.png" />
                <label class="choose-graphic-label">Conflict <br />
                    Map</label>
            </div>
            <div (mouseenter)="enterInitGraphOrg()" (mouseleave)="leaveInitGraphOrg()"
                (click)="initGraphicOrganizer('resolutionMap')" class="choose-graphic-button3">
                <img src="assets/lit-elements/lit-res.png" />
                <label class="choose-graphic-label">Resolution <br />
                    Map</label>
            </div>
            <div (mouseenter)="enterInitGraphOrg()" (mouseleave)="leaveInitGraphOrg()"
                (click)="initGraphicOrganizer('settingMap')" class="choose-graphic-button4">
                <img src="assets/lit-elements/lit-set.jpg" />
                <label class="choose-graphic-label">Setting <br />
                    Map</label>
            </div>
        </div>
    </div>
    <!------------------------------------------- Character Map ------------------------------------->
    <div class="animal-facts" *ngIf="
        1 <= step && step <= 5 && projectData.graphicOrganizer === 'characterMap'
      ">
        <img class="facts-img" src="assets/lit-elements/lit-char.png" />
        <div class="name-input-div">
            <input id="name-input" (keyup.enter)="onEnter(2)" [(ngModel)]="projectData.charName" />
            <label>Character Name</label>
        </div>
        <p *ngIf="step === 5" class="choose-go-prompt">
            Select another graphic <br />organizer or choose print.
        </p>
        <br />

        <div class="animal-facts-looks" *ngIf="step >= 2">
            <div class="char-diagonal1"></div>
            <textarea class="animal-description looks-input" (keyup.enter)="onEnter(3)"
                [(ngModel)]="projectData.charLooks"></textarea>
        </div>

        <div class="animal-facts-move" *ngIf="step >= 3">
            <div class="line1"></div>
            <textarea class="animal-description move-input" (keyup.enter)="onEnter(4)"
                [(ngModel)]="projectData.charAct"></textarea>
        </div>

        <div class="animal-facts-eat" *ngIf="step >= 4">
            <div class="char-diagonal2"></div>
            <textarea class="animal-description eat-input" (keyup.enter)="onEnter(5)"
                [(ngModel)]="projectData.charReact"></textarea>
        </div>

        <p *ngIf="1 <= step && step <= 4" class="press-enter">
            Press the enter key <br />
            to see the next prompt.
        </p>
    </div>

    <!----------------------------------  Conflict Map  ------------------------------------>
    <div class="animal-babies" *ngIf="
        1 <= step && step <= 4 && projectData.graphicOrganizer == 'conflictMap'
      ">
        <img class="facts-img" src="assets/lit-elements/lit-conf.png" />
        <p *ngIf="step === 4" class="choose-go-prompt2">
            Select another graphic <br />organizer or choose print.
        </p>
        <br />
        <div class="animal-facts-looks" *ngIf="step >= 1">
            <div class="conf-diagonal1"></div>
            <textarea class="animal-description looks-input" (keyup.enter)="onEnter(2)"
                [(ngModel)]="projectData.conflictDesc"></textarea>

            <div class="animal-babies-move" *ngIf="step >= 2">
                <div class="conf-line1"></div>
                <textarea class="animal-description move-input" (keyup.enter)="onEnter(3)"
                    [(ngModel)]="projectData.conflictOccur"></textarea>
            </div>

            <div class="animal-facts-eat" *ngIf="step >= 3">
                <div class="conf-diagonal2"></div>
                <textarea class="animal-description eat-input" (keyup.enter)="onEnter(4)"
                    [(ngModel)]="projectData.resolutions"></textarea>
            </div>

            <p *ngIf="1 <= step && step <= 3" class="press-enter">
                Press the enter key <br />
                to see the next prompt.
            </p>
        </div>
    </div>
    <!---------------------------------  Resolution Map  --------------------------------->
    <div class="animal-babies" *ngIf="
        1 <= step &&
        step <= 4 &&
        projectData.graphicOrganizer == 'resolutionMap'
      ">
        <img class="facts-img" src="assets/lit-elements/lit-res.png" />
        <p *ngIf="step === 4" class="choose-go-prompt2">
            Select another graphic <br />organizer or choose print.
        </p>
        <br />
        <div class="animal-facts-looks" *ngIf="step >= 1">
            <div class="res-diagonal1"></div>
            <textarea class="animal-description looks-input" (keyup.enter)="onEnter(2)"
                [(ngModel)]="projectData.conflictResolution"></textarea>

            <div class="animal-babies-move" *ngIf="step >= 2">
                <div class="res-line1"></div>
                <textarea class="animal-description move-input" (keyup.enter)="onEnter(3)"
                    [(ngModel)]="projectData.afterResolution"></textarea>
            </div>

            <div class="animal-facts-eat" *ngIf="step >= 3">
                <div class="res-diagonal2"></div>
                <textarea class="animal-description eat-input" (keyup.enter)="onEnter(4)"
                    [(ngModel)]="projectData.resolutionAffect"></textarea>
            </div>

            <p *ngIf="1 <= step && step <= 3" class="press-enter">
                Press the enter key <br />
                to see the next prompt.
            </p>
        </div>
    </div>

    <!----------------------------------  Setting Map  ---------------------------------->
    <div class="animal-babies" *ngIf="
        1 <= step && step <= 4 && projectData.graphicOrganizer == 'settingMap'
      ">
        <img class="facts-img" src="assets/lit-elements/lit-set.jpg" />
        <p *ngIf="step === 4" class="choose-go-prompt2">
            Select another graphic <br />organizer or choose print.
        </p>
        <br />
        <div class="animal-facts-looks" *ngIf="step >= 1">
            <div class="diagonal1"></div>
            <textarea class="animal-description looks-input" (keyup.enter)="onEnter(2)"
                [(ngModel)]="projectData.storyLocation"></textarea>

            <div class="animal-babies-move" *ngIf="step >= 2">
                <div class="set-line1"></div>
                <textarea class="animal-description move-input" (keyup.enter)="onEnter(3)"
                    [(ngModel)]="projectData.storyTime"></textarea>
            </div>

            <div class="animal-facts-eat" *ngIf="step >= 3">
                <div class="diagonal2"></div>
                <textarea class="animal-description eat-input" (keyup.enter)="onEnter(4)"
                    [(ngModel)]="projectData.settingDesc"></textarea>
            </div>

            <p *ngIf="1 <= step && step <= 3" class="press-enter">
                Press the enter key <br />
                to see the next prompt.
            </p>
        </div>
    </div>
    <!-------------------------------------------  Printing   ----------------------------------------------->
    <div *ngIf="step === 6">
        <button class="print-button" (click)='print()'>
            <i class="fa fa-print"></i>
            Print
        </button>
        <br />
        <br />

        <div class='print-preview-landscape'>
            <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
            <img *ngIf='previewImage' [src]='previewImage'>
        </div>

        <div #printCanvas class='print-box print-canvas'>
            <div>
                <!---------------------------  Character Map Printing  ------------------------------------->
                <div *ngIf="projectData.graphicOrganizer === 'characterMap'">
                    <p class="print-name-title">
                        {{ projectData.title }} by {{ projectData.author }}
                    </p>
                    <img class="char-print-image" src="assets/lit-elements/lit-char.png" />
                    <p class="char-print-name">{{ projectData.charName }}</p>
                    <div class="inputs">
                        <div class="char-input-box1">
                            <h3 class="input-label">What does the character look like?</h3>
                            <div class="print-diagonal1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.charLooks }}</p>
                            </div>
                        </div>

                        <div class="input-box2">
                            <h3 class="input-label">How does the character act?</h3>
                            <div class="print-line1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.charAct }}</p>
                            </div>
                        </div>

                        <div class="input-box3">
                            <h3 class="input-label">How do other characters in the story react to this character?</h3>
                            <div class="print-diagonal2"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.charReact }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------------------------------------  Conflict Map Printing  ----------------------------------------->
                <div *ngIf="projectData.graphicOrganizer === 'conflictMap'">
                    <p class="print-name-title">
                        {{ projectData.title }} by {{ projectData.author }}
                    </p>
                    <img class="conf-print-image" src="assets/lit-elements/lit-conf.png" />
                    <div class="inputs">
                        <div class="conf-input-box1">
                            <h3 class="input-label3">
                                What is the conflict? (another person, a thing, or thoughts and feelings of the character)
                            </h3>
                            <div class="print-diagonal1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.conflictDesc }}</p>
                            </div>
                        </div>
                        <div class="input-box2">
                            <h3 class="input-label">
                                Why does this conflict occur?
                            </h3>
                            <div class="print-line1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">
                                    {{ projectData.conflictOccur }}
                                </p>
                            </div>
                        </div>

                        <div class="input-box3">
                            <h3 class="input-label2">
                                What are some ways the conflict could be resolved?
                            </h3>
                            <div class="print-diagonal2"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.resolutions }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------------------------------------  Resolution Map Printing  ----------------------------------------->
                <div *ngIf="projectData.graphicOrganizer === 'resolutionMap'">
                    <p class="print-name-title">
                        {{ projectData.title }} by {{ projectData.author }}
                    </p>
                    <img class="res-print-image" src="assets/lit-elements/lit-res.png" />
                    <div class="inputs">
                        <div class="input-box1">
                            <h3 class="input-label">
                                How is the conflict resolved?
                            </h3>
                            <div class="print-diagonal1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.conflictResolution }}</p>
                            </div>
                        </div>
                        <div class="input-box2">
                            <h3 class="input-label">
                                What happens after the conflict is resolved? 
                            </h3>
                            <div class="print-line1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">
                                    {{ projectData.afterResolution }}
                                </p>
                            </div>
                        </div>

                        <div class="res-input-box3">
                            <h3 class="input-label">
                                How does the conflict and its resolution affect the character?
                            </h3>
                            <div class="print-diagonal2"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">
                                    {{ projectData.resolutionAffect }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------------------------------------  Setting Map Printing  ----------------------------------------->
                <div *ngIf="projectData.graphicOrganizer === 'settingMap'">
                    <p class="print-name-title">
                        {{ projectData.title }} by {{ projectData.author }}
                    </p>
                    <img class="setting-print-image" src="assets/lit-elements/lit-set.jpg" />
                    <div class="inputs">
                        <div class="input-box1">
                            <h3 class="input-label">
                                Where does the story take place? (city, castle, ship, cemetery, moon)
                            </h3>
                            <div class="print-diagonal1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">{{ projectData.storyLocation }}</p>
                            </div>
                        </div>
                        <div class="input-box2">
                            <h3 class="input-label">
                                When does the story take place? (afternoon, fall, morning, date)
                            </h3>
                            <div class="print-line1"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">
                                    {{ projectData.storyTime }}
                                </p>
                            </div>
                        </div>

                        <div class="input-box3">
                            <h3 class="input-label">
                                Write a detailed description of the setting. (weather, noise, colors)
                            </h3>
                            <div class="print-diagonal2"></div>
                            <div class="print-output-box">
                                <p class="print-output-text">
                                    {{ projectData.settingDesc }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>