<div class='app-border'>
  <div class='title-bar'>
    <span>Acrostic Poems</span>
  </div>

  <!-- Step 1 -->
  <div *ngIf="step=='step-1'" class='step'>
    <div class='first-page'>
      <img src='assets/acrostic-poems/see-answer-button.png' class='see-answer-button' (click)="setStep('answer')">
      <img src='assets/acrostic-poems/get-started-button.png' class='get-started-button' (click)="setStep('step-2')">
    </div>
    <hr>
    <h2>OPEN SAVED POEM</h2>

    <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openPoem($event)">

  </div>

  <!-- Answer Page -->
  <div *ngIf="step=='answer'" class='answer-page'>

    <img src='assets/acrostic-poems/continue-button.png' class='answer-page-continue' (click)="setStep('step-2')">


  </div>


  <!-- Step 2 -->
  <div *ngIf="step=='step-2'" class='step'>

    <div class='second-page'>
      <label class='name-label'>Your Name: </label>
      <input [ngClass]="{'has-error':nameRequiredError}" (change)='nameRequiredError=false' class='name-input' maxlength='25'
        [(ngModel)]='vars.name'>

      <label class='topic-word-label'>Topic Word: </label>
      <input [ngClass]="{'has-error':topicWordRequiredError}" (change)='topicWordRequiredError=false' class='name-input' maxlength='15'
        [(ngModel)]='vars.topicWord'>

      <div class='clear'></div>


      <img src='assets/acrostic-poems/continue-button.png' class='step-2-continue-button' (click)="initLetter()">
      <img src='assets/acrostic-poems/back-button.png' class='step-2-back-button' (click)="setStep('step-1')">
      <div class='clear'></div>
    </div>
  </div>

  <!-- Step 3 -->
  <div *ngIf="step=='step-3'" class='step'>


    <div class='third-page'>
      <label class='brainstorming-label'>{{ vars.topicWord }}</label>
      <table class='brainstorming-table' cellspacing="0">
        <tr>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord1'></td>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord2'></td>
        </tr>
        <tr>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord3'></td>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord4'></td>
        </tr>
        <tr>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord5'></td>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord6'></td>
        </tr>
        <tr>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord7'></td>
          <td><input style='margin-bottom:0px;' [(ngModel)]='vars.brainstormingWord8'></td>
        </tr>
      </table>

      <img src='assets/acrostic-poems/continue-button.png' class='step-3-continue-button' (click)="setStep('step-4')">
      <img src='assets/acrostic-poems/back-button.png' class='step-3-back-button' (click)="setStep('step-2')">
    </div>




  </div>

  <!-- Step 4 -->
  <div *ngIf="step=='step-4'" class='step'>

    <div class='fourth-page'>

      <div class='brainstorm-words'>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord1 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord2 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord3 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord4 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord5 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord6 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord7 }}</label>
        <label class='brainstorm-words-label'>{{ vars.brainstormingWord8 }}</label>
      </div>

      <div class='up-arrow' (click)='indexDecrement()'></div>

      <ul class='letter-list'>
        <li *ngFor="let char of getChars()">
          <div class='letter-img'>
            <span class='letter'>{{ char.displayValue }}</span>
            <input class='letter-input' [(ngModel)]='char.inputValue' maxlength='44' placeholder="Click here to type">
          </div>
        </li>
      </ul>

      <div class='down-arrow' (click)='indexIncrement()'></div>
      <img src='assets/acrostic-poems/continue-button.png' class='step-4-continue-button' (click)="setStep('print')">
      <img src='assets/acrostic-poems/back-button.png' class='step-4-back-button' (click)="setStep('step-3')">
    </div>




  </div>

  <div *ngIf="step=='print'" class='step'>
    <div class='toolbar'>
      <button (click)="setStep('step-4')">
        <i class='fa fa-chevron-left'></i> &nbsp; Back
      </button>
    </div>

    <h1>Congrats!</h1>
    <p>
      Review your poem.  If you want to make changes, use the Back button.
    </p>
    <p>
      When your poem is finished, you can ...
    </p>

    <div>
      <button (click)='print()'>
        <i class='fa fa-print'></i>&nbsp;Print
      </button>
      <button (click)='export()'>
        <i class='fa fa-download'></i>&nbsp;Export
      </button>
      <button (click)='save()'>
        <i class='fa fa-save'></i>&nbsp;Save
      </button>
      <button (click)='reset()'>Start New Project</button>
    </div>
    <div #printPage class='print-page'>
      <span class='topic-word-header'>{{ vars.topicWord }}</span>
      <br>
      <span class='name-header'>By: {{ vars.name }}</span>
      <li *ngFor="let value of vars.inputValues">
        <div class='print-line'>
          <span class='print-letter'>{{ value.displayValue }}</span>
          <span class='print-poem-line'>{{ value.inputValue }}</span>
        </div>
      </li>
    </div>

    <div class='print-page print-preview'>
      <span class='topic-word-header'>{{ vars.topicWord }}</span>
      <br>
      <span class='name-header'>By: {{ vars.name }}</span>
      <li *ngFor="let value of vars.inputValues">
        <div class='print-line'>
          <span class='print-letter'>{{ value.displayValue }}</span>
          <span class='print-poem-line'>{{ value.inputValue }}</span>
        </div>
      </li>
    </div>
  </div>
</div>
