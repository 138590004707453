import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { KWLCreatorHeading } from 'src/app/model/KWLCreatorHeading';
import { KWLCreatorData } from 'src/app/model/KWLCreatorData';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
@Component({
  selector: 'app-kwl-creator',
  templateUrl: './kwl-creator.component.html',
  styleUrls: ['./kwl-creator.component.scss']
})
export class KwlCreatorComponent implements OnInit {
  soundEnabled: boolean = true;
  step: number = 1;
  checkboxState: number;
  vars: KWLCreatorData = {};
  main_menu_input_valid_name: boolean = true;
  main_menu_input_valid_topic: boolean = true;
  showOpen = false;
  @ViewChild('printKContainer', null) printKContainer: ElementRef;
  @ViewChild('printWContainer', null) printWContainer: ElementRef;
  @ViewChild('printLContainer', null) printLContainer: ElementRef;
  @ViewChild('printAllContainer', null) printAllContainer: ElementRef;

  setBold() {
    if (!this.vars.focuscontrol) return;
    this.vars.focuscontrol.bold = !this.vars.focuscontrol.bold;
  }
  setItalic() {
    if (!this.vars.focuscontrol) return;
    this.vars.focuscontrol.italic = !this.vars.focuscontrol.italic;
  }
  addHeading() {
    this.getHeadings().push({
      heading: ""
    });
  }
  addSubHeading(heading: KWLCreatorHeading, index) {
    let headings = this.getHeadings();
    let h: KWLCreatorHeading = {
      isSub: true
    };
    headings.splice(index + 1, 0, h);
  }
  makeHead() {
    if (!this.vars.focuscontrol) return;
    this.vars.focuscontrol.isSub = false;
  }
  makeSub() {
    if (!this.vars.focuscontrol) return;
    this.vars.focuscontrol.isSub = true;
  }
  getHeadings() {
    switch (this.step) {
      case 2: return this.vars.kheadings;
      case 3: return this.vars.wheadings;
      case 4: return this.vars.lheadings;
    }
  }

  checkNextStep() {
    this.main_menu_input_valid_name = ((this.vars.name != undefined) && (this.vars.name.length >= 2))

    this.main_menu_input_valid_topic = ((this.vars.topic != undefined) && (this.vars.topic.length >= 2))

    if (this.main_menu_input_valid_name && this.main_menu_input_valid_topic) {
      this.step++
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--
  }

  toggleSound() {
    this.soundEnabled = !this.soundEnabled
  }

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
    this.createNew();
  }

  save() {
    this.fileUtil.save(this.vars, 'kwl.json')
  }
  createNew() {
    this.step = 1;
    this.vars = {
      kheadings: [
        {
          heading: ""
        }
      ],

      wheadings: [
        {
          heading: ""
        }
      ],

      lheadings: [
        {
          heading: ""
        }
      ],
      onepage: true,
      checkboxlinks: true
    };
  }
  fileInput: any;
  openKwl(event: any) {
    this.showOpen = false;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json);
        this.step = 2;
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  print() {
    if (this.vars.onepage) {
      this.printer.print([this.printAllContainer], PrintOrientation.Landscape, true, true, false);
    }
    else {
      this.printer.print([this.printKContainer, this.printWContainer, this.printLContainer], PrintOrientation.Portrait, true, true, false);
    }
  }

}
