import { AlphabetOrganizerAlphabet } from "./AlphabetOrganizerAlphabet";
import { AlphabetOrganizerLetter } from "./AlphabetOrganizerLetter";
import { AlphabetOrganizerWordImg } from "./AlphabetOrganizerWordImg";

export class AlphabetOrganizerFile {
  name: string;
  title: string;
  printType: string; 
  printViewNum: number;
  organizerType: string;
  letter: string;

  nameEmpty: boolean = false;
  titleEmpty: boolean = false;

  wordError: boolean = false;
  startOverConfirm: boolean = false;

  wordsOnlyLetters: AlphabetOrganizerLetter[] = [];
  inputtedWordsOnlyLetters: AlphabetOrganizerLetter[] = [];

  wordAndImgLetters: AlphabetOrganizerWordImg[] = [];
  inputtedWordImgLetters: AlphabetOrganizerWordImg[] = [];

  inputtedLettersGenerated: boolean = false;


  wrongLetterError: boolean = false;

  letterRows: AlphabetOrganizerAlphabet[] = [];
}
