export class StaplelessBook {
    title?: string;
    author?: string;

    headline?: string;
    bullet1?: string;
    bullet2?: string;
    bullet3?: string;
    
    smallText?: string;


}

export class StaplelessTemplate {
    templateName?: string;
}

export class StaplelessMenuItem {
    open?: boolean = null;
    animating?: boolean = false;

    toggle(menus) {
        for (let menu of menus) {
            if (menu.open) {
                return;
            }
        }
        if (this.open == null) {
            this.open = true;
            this.animating = true;

            setTimeout(() => {
                this.animating = false;
            }, 500);
        }
        else if (!this.animating) {
            this.open = !this.open;
            this.animating = true;

            setTimeout(() => {
                this.animating = false;
            }, 500);
        }
    }
}