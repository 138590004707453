import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AcrosticPoemsFile } from 'src/app/model/AcrosticPoemsFile';
import { AcrosticInput } from 'src/app/model/AcrosticPoemsInput';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-acrostic-poems',
  templateUrl: './acrostic-poems.component.html',
  styleUrls: ['./acrostic-poems.component.scss']
})
export class AcrosticPoemsComponent implements OnInit {

  step: string = "step-1";



  nameRequiredError: boolean = false;
  topicWordRequiredError: boolean = false;

  get letters(): string[] {
    if(!this.vars || !this.vars.topicWord) return [];
    return this.vars.topicWord.split('');
  }

  startIndex = 0;
  charLength = 5;
  vars: AcrosticPoemsFile = new AcrosticPoemsFile();

  constructor(
    private printer: PrintService,
    private fileUtil: FileUtilService, private router: Router) {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.step = this.fileUtil.getStep() || "step-1"; // initialize to step 1 if no step in query string 
        this.vars = this.fileUtil.getVars() || (new AcrosticPoemsFile()); // initialize vars if none found
        if(this.step == 'print'){
          this.initPreview();
        }
      }
    });
  }

  ngOnInit() {

  }

  reset() {
    this.vars = new AcrosticPoemsFile();
    this.fileUtil.setStep("step-2", this.vars);
  }

  fileInput: any;
  openPoem(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json);
        this.initLetter();
        this.fileInput = null;
        this.fileUtil.setStep("step-2", this.vars);
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  initLetter() {
    window.scroll(0, 190);
    this.nameRequiredError = !this.vars.name;
    this.topicWordRequiredError = !this.vars.topicWord;

    this.vars.inputValues = []; 

    for (let i = this.startIndex; i < this.letters.length; i++) {


      let letter: string = this.letters[i]
      let iv: AcrosticInput = {
        displayValue: letter,
        inputValue: ''
      };
      this.vars.inputValues.push(iv);
    }

    if (!this.nameRequiredError && !this.topicWordRequiredError) {
      this.fileUtil.setStep("step-3", this.vars);
    }
    this.startIndex = 0;
  }

  getChars() {
    let currentView: AcrosticInput[] = [];

    let end = (this.startIndex + this.charLength);

    if (this.charLength > this.letters.length) {
      end = this.letters.length;
    }

    for (let i = this.startIndex; i < end; i++) {
      currentView.push(this.vars.inputValues[i])
    }
    return currentView;
  }

  indexIncrement() {
    if (this.startIndex != this.letters.length - 5) {
      this.startIndex++;
    }
  }

  indexDecrement() {
    if (this.startIndex != 0) {
      this.startIndex--;
    }
  }

  setStep(step){
    this.fileUtil.setStep(step, this.vars);
  }

  initPrint() {

    this.fileUtil.setStep("print", this.vars);
  }
  initPreview(){

  }

  prnt: any;
  @ViewChild('printPage', null) printPage: ElementRef;
  async print() {
    this.printer.print([this.printPage]);
  }
  async export() {
    this.printer.export([this.printPage], 'Acrostic-Poem.pdf');
  }
  save() {
    this.fileUtil.save(this.vars, 'Acrostic-Poem.json')
  }
}
