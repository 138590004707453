export class LetterGenerator {


    yourName?: string;
    letterType?: string;

    //common among all letter types
    address?: string;
    address2?: string;
    date?: string;
    salutation?: string;
    body?: string
    bodyUnformatted?: string;
    closing?: string
    signature?: string

    // business letter specific
    toName?: string;
    toTitle?: string;
    toCompany?: string;
    toAddress?: string;
    toAddress2?: string;


    // friendly letter specific
    postscript?: string
    postscriptUnformatted?: string;
}
