import { Component, OnInit, Input, ViewChildren, ElementRef } from '@angular/core';
import { ITradingCard } from 'src/app/model/TradingCard/ITradingCard';
import * as _ from 'lodash';

@Component({
  selector: 'app-print-card',
  templateUrl: './print-card.component.html',
  styleUrls: ['./print-card.component.scss']
})
export class PrintCardComponent implements OnInit {
  @Input() card: ITradingCard;
  @Input() printVersion?: boolean
  cardFront: ITradingCard;
  cardBack: ITradingCard;

  constructor() { }

  ngOnInit() {
    this.cardFront = _.clone(this.card);
    this.cardBack = _.clone(this.card);
    this.cardBack.activeSection = 3;
  }

}
