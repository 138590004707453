import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PersuasionMapInteractiveFile } from 'src/app/model/PersuasionMapInteractiveFile';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';


@Component({
  selector: 'app-persuasion-map',
  templateUrl: './persuasion-map.component.html',
  styleUrls: ['./persuasion-map.component.scss']
})
export class PersuasionMapComponent implements OnInit {

  @ViewChild("file-input", null) fileInput: ElementRef;

  currentOpacity: number = 1;
  showSaveFinalDialog: boolean = false;
  showOpenDialog: boolean = false;
  showShareFinalDialog: boolean = false;
  showSaveDialog: boolean = false;
  showSendDialog: boolean = false;
  showStartNewDialog: boolean = false;
  saveInProgressName: string = "";
  openName: string = "";
  finalName: string = "";

  interactive: PersuasionMapInteractiveFile = new PersuasionMapInteractiveFile();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  //Mimicking flash app's title and name requirements for saving/continuing
  checkForSaving() {
    this.interactive.unGraySave = this.checkIntroInputs();
  }

  checkIncomplete() {
    if (this.checkIntroInputs()) {
      this.interactive.step = 2;
    }

    this.interactive.hasBeenChecked = true;
  }

  checkIntroInputs() {
    this.interactive.nameValid = (this.interactive.name.length >= 2);
    this.interactive.titleValid = (this.interactive.title.length >= 2);

    return this.interactive.nameValid && this.interactive.titleValid;
  }

  //Handles showing and closing of dialogs in app
  handleDialog (dialog: string, action: boolean) {
    //True for show; false for close
    //If it shows the dialog, half the opacity; if closing, return to normal
    this.currentOpacity = action ? 0.5:1;

    switch (dialog) {

      case "open": {
        this.showOpenDialog = action;
        break;
      }

      case "save": {
        this.showSaveDialog = action;
        break;
      }

      case "startNew": {
        this.showStartNewDialog = action;
        break;
      }
    }
  }
  
  //Print a blank Persuasion Map
  @ViewChild('blankMap', null) blankMap: ElementRef;
  printBlankMap() {
    this.printer.print([this.blankMap], PrintOrientation.Portrait);
  }

  //Print the full version of user's Persuasion Map
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  printFinal() {
    this.printer.print([this.printCanvas], PrintOrientation.Portrait);
  }

  //Allows users to save Persuasion Map as pdf
  exportFinal() {
    let name = this.finalName ? this.finalName:"Persuasion-Map";
    this.printer.export([this.printCanvas], name + '.pdf');
  }

  //Allows user to save the project later as an rwt file
  saveInProgress() {
    let name = this.saveInProgressName ? this.saveInProgressName:"Persuasion-Map";
    this.fileUtil.save(this.interactive, name + ".rwt");
  }

  //Starts new Persuasion Map project
  startNew() {
    this.handleDialog('startNew', false)
    this.interactive = new PersuasionMapInteractiveFile();
  }

  //Allows for file input to read a file from user
  prepareFile() {
    let fileInputElement = this.fileInput.nativeElement;
    fileInputElement.click();
  }

  //Attempts to open file supplied by user
  openFile(event: any) {
    const filereader = new FileReader();
    filereader.onload = (e: any) =>
    {
      try {
        this.interactive = JSON.parse(e.target.result);
      }
      catch(e) {
        alert("File cannot be opened");
        return;
      }
    }

    let file = event.target.files[0];
    filereader.readAsText(file);
    this.saveInProgressName = this.openName ? this.openName:this.saveInProgressName;
    this.handleDialog('open', false);
  }

  ngOnInit() {
  }

}
