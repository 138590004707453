import { DragElement } from "./DragElement";

export class PlotDiagram {

    projectTitle: string;
    author: string;
    triangleChoice: string = "middle";


    editId?: string;
    now?: Date;
    addingCircle?: boolean = false;
    editingLabel?: boolean = false;

    // inputs
    inputDiagrammedBy?: string;
    inputTitle?: string;
    inputLabelText?: string;
    inputLabelDescription?: string;

    // event array
    elements?: DragElement[] = [];
}
