import { ThemePoemsWord } from "src/app/model/ThemePoemsWord";
export class ThemePoemsFile {
    name: string;

    selectedShape: string;

    brainstormWords: ThemePoemsWord[] = [];

    title: string;
    poemText: string;
}