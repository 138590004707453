import { EssayMapIdea } from "./EssayMapIdea";

export class EssayMapInteractiveFile {
    step: number = 1;

    name: string = "";
    topic: string = "";

    introduction: string = "";

    ideas: EssayMapIdea[] = [
        new EssayMapIdea(),
        new EssayMapIdea(),
        new EssayMapIdea()
    ];

    conclusion: string = "";

    nameValid: boolean = false;
    topicValid: boolean = false;
    hasBeenChecked: boolean = false;
    unGraySave: boolean = false;
}