export class ResumeGenerator {
    resumeStyle?: string;

    fullName?: string;
    address1?: string;
    address2?: string;
    cityStateZip?: string;
    phoneNumber?: string;
    email?: string;

    otherInterests?: string;
    accomplishments?: string;

    templateSelection?: number = 0;

    includeObjective?: boolean = false;
    objectiveStatement?: string;

    experiences: ExperienceData[] = [new ExperienceData()];
    educations: EducationData[] = [new EducationData()];
    skills: SkillsData[] = [new SkillsData()];
}

export class ResumeSelection {
    title?: string;
    hovered?: boolean = false;
    selected?: boolean = false;

    constructor(initTitle) {
        this.title = initTitle;
    }
}

export class ExperienceData {
    companyName?: string;
    companyAddress?: string;
    jobTitle?: string;
    startMonth?: string = "";
    startYear?: string = "";
    endMonth?: string = "";
    endYear?: string = "";
    stillEmployed?: boolean = false;
    skills?: string;
    include?: boolean = false;
}

export class EducationData {
    school?: string;
    cityState?: string;
    degree?: string;
    graduationMonth?: string = "";
    graduationYear?: string = "";
    include?: boolean = false;
}

export class SkillsData {
    category?: string;
    accomplishments?: string;
    include?: boolean = false;
}