import { Component, OnInit, Input } from '@angular/core';
import { DragElement } from 'src/app/model/DragElement';

@Component({
  selector: 'drag-status',
  templateUrl: './drag-status.component.html',
  styleUrls: ['./drag-status.component.css']
})
export class DragStatusComponent implements OnInit {
  @Input() dragElement: DragElement;
  @Input() allowSize?: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  bigger(){
    this.dragElement.width += 10;
    this.dragElement.height += 10;
    this.dragElement.x -= 10;
    this.dragElement.y -= 10;
    if(this.dragElement.x < 0) this.dragElement.x = 0;
    if(this.dragElement.y < 0) this.dragElement.y = 0;
  }

  smaller(){
    this.dragElement.width -= 10;
    this.dragElement.height -= 10;
    this.dragElement.x += 10;
    this.dragElement.y += 10;
  }

}
