<div class="app-border">
    <div class="title-bar">
        <span>Word Matrix</span>
    </div>
    <div class="app">
        <div class="left"></div>
        <div class="right"></div>

        <div class="middle">
            <img class="logo" src="/assets/word-matrix/word-matrix-logo.jpg">

            <div class="top-bar"></div>

            <div class="name-bar">
                <label *ngIf="step >= 3">Name: {{ projectData.author }}</label>
            </div>

            <div [ngClass]="{'above-menu-0': step >= 0 && step < 2,
                                 'above-menu-1': step >= 2}" class="above-menu">
                <label *ngIf="step >= 3 && !directionsOpen && !conceptSelected">
                    Select a concept
                </label>
                <label *ngIf="conceptSelected">
                    {{ currentConcept }}
                </label>
            </div>
        </div>
        <div class="content-container">
            <div class="content" #printCanvas [ngClass]="{'print-content': printing}">
                <!------------------------------------- Input page ------------------------------------------------->
                <div *ngIf="step === 0">
                    <br />
                    <h1>Welcome to the Word Matrix.</h1>
                    <br />

                    <p>
                        You'll have the chance to <br />
                        explore the meanings of words <br />
                        and discover the fine differences <br />
                        among words expressing the same concept.
                    </p>

                    <br />

                    <div class="name-container">
                        <label>Enter your name to get started:</label>
                        <input [(ngModel)]="projectData.author" maxlength="50" type="text" />
                        <button (click)="submitName()">Submit</button>
                    </div>

                    <br />
                    <p class="name-warning" *ngIf="nameWarning">Please enter your name.</p>
                </div>

                <!------------------------------------- Selection page ------------------------------------------------->
                <div *ngIf="step === 1">
                    <h1 class="welcome-header">Welcome, {{ projectData.author }}</h1>

                    <table class="selections">
                        <td>
                            <div (click)="chooseSelection('connotation')" (mouseenter)="hoverSelect(0)"
                                (mouseleave)="unhoverSelect()" class="select-connotation">
                                <div class="text-container">
                                    <p>Click here to organize words by <span
                                            [ngClass]="{'green': hoveredSelection === 0}">connotation</span></p>
                                </div>

                                <div class="connotation-image" [ngClass]="{'hovered-image': hoveredSelection === 0,
                                                                           'unhovered-image': hoveredSelection !== 0}">
                                    <div class="line"></div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div (click)="chooseSelection('register')" (mouseenter)="hoverSelect(1)"
                                (mouseleave)="unhoverSelect()" class="select-register">
                                <div class="text-container">
                                    <p>Click here to organize words by <span
                                            [ngClass]="{'green': hoveredSelection === 1}">register</span></p>
                                </div>

                                <div class="register-image" [ngClass]="{'hovered-image': hoveredSelection === 1,
                                                                        'unhovered-image': hoveredSelection !== 1}">
                                    <div class="line"></div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div (click)="chooseSelection('both')" (mouseenter)="hoverSelect(2)"
                                (mouseleave)="unhoverSelect()" class="select-both">
                                <div class="text-container">
                                    <p>Click here to organize words by <span
                                            [ngClass]="{'green': hoveredSelection === 2}">connotation and
                                            register</span></p>
                                </div>

                                <div class="both-image" [ngClass]="{'hovered-image': hoveredSelection === 2,
                                                                    'unhovered-image': hoveredSelection !== 2}">
                                    <div class="horizontal-line"></div>
                                    <div class="vertical-line"></div>
                                    <div class="horizontal-line"></div>
                                </div>
                            </div>
                        </td>
                    </table>

                    <div (click)="learnMore()" (mouseenter)="hoverLearnMore()" (mouseleave)="unhoverLearnMore()"
                        class="learn-more-box">
                        <p [ngClass]="{'orange': !learnMoreHovered,
                                    'white': learnMoreHovered}" class="learn-more"><img
                                src="/assets/word-matrix/word-matrix-about.png">Learn more</p>
                        <p class="white">About the concepts of connotation and register.</p>
                    </div>
                </div>

                <!------------------------------------- Learn more page ------------------------------------------------->
                <div *ngIf="step === 2">
                    <div class="about-paragraph">
                        <h1>About Connotation and Register</h1>
                        <p>
                            Writers and speakers use the concepts of <b>connotation</b> and <b>register</b> to think
                            carefully about groups of
                            words that seem to have the same basic meaning and choose the right one for the specific
                            case at
                            hand.
                            <br />
                            The WordMatrix tool allows you to arrange words by <b>connotation</b> on the horizontal axis
                            (from more
                            negative on the left to more positive on the right) and by <b>register</b> on the vertical
                            axis (from informal at
                            the bottom to formal at the top).
                            <br />
                            <b>Connotation</b> refers to the ideas, emotions, and associations that a word carries
                            beyond its literal
                            definition. The word <i>home</i> means "one's place of residence," but it can have positive
                            connotations of
                            safety, warmth, and togetherness for some people. On the other hand, some people may find
                            the word
                            <i>home</i> to have negative connotations such as a place of abuse or neglect.
                            <br />
                            <b>Register</b> refers the level of formality associated with a word, usually depending on
                            the circumstances
                            surrounding the use of the word. For example, both <i>shack</i> and <i>dump</i> carry
                            negative connotations as
                            an undesirable place to live, but <i>dump</i> is much more informal than <i>shack</i>.
                            Similarly, the word <i>discuss</i> is in a more formal <b>register</b> than <i>tell
                                about</i>, <i>examine</i> is more formal than
                            <i>check out</i>, and so on. Typically, writers or speakers determine the register of their
                            word choice
                            depending on the audience, purpose, and situation surrounding the writing or speech event.
                            The online sources to the right will help you learn more about <b>connotation</b> and
                            <b>register</b> and provide
                            access to definitions, synonyms, and other information about word meaning.
                        </p>
                    </div>

                    <div class="resource-sidebar">
                        <div class="resource-container">
                            <div class="resources">
                                <label>Online Resources</label>
                                <p><a href="http://writingguide.geneseo.edu/?pg=topics/formalinformal.html"
                                        target="_blank" rel="noopener noreferrer">Formal and Informal Writing</a></p>
                                <p><a href="http://www.merriam-webster.com/" target="_blank"
                                        rel="noopener noreferrer">Merriam-Webster Online Dictionary</a></p>
                                <p><a href="http://www.wordsmyth.net/" target="_blank"
                                        rel="noopener noreferrer">Wordsmyth</a></p>
                            </div>
                        </div>
                        <br />
                        <button (click)="goLastStep()">Back to the Word Matrix</button>
                    </div>
                </div>

                <!------------------------------------- Placement page ------------------------------------------------->
                <div *ngIf="step === 3">
                    <div class="directions-container" *ngIf="directionsOpen && !printing">
                        <div class="directions">
                            <button (click)="hideDirections()">[Close Window]</button>
                            <h1>Word Matrix Directions</h1>
                            <ol>
                                <li>
                                    <p>
                                        Select a concept (adding new words, if you wish), or build a
                                        concept of your own.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Drag and drop each word into the Word Matrix, positioning it
                                        in terms of positive/negative connotation and
                                        formal/informal register.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Explain the placement of each word by double clicking on it
                                        after you've placed it.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        When you're satisfied with your placements, print the Word
                                        Matrix.
                                    </p>
                                </li>
                            </ol>
                            <p>
                                Note: Printing is the only way to have a record of your work. Be sure
                                to print before selecting a new concept or going back to the Word
                                Matrix home.
                            </p>
                        </div>
                    </div>

                    <div class="print-warning-container" *ngIf="printWarning && !printing">
                        <div class="print-warning">
                            <h1 class="gray">Warning</h1>
                            <h1 class="white">Print your Word Matrix</h1>

                            <p>Your printout will show the matrix, words, and descriptions you created.</p>
                            <p>Printing is the only way to keep a record of your work. If you choose to continue, you
                                will lose your work.</p>

                            <button (click)="printWarning = false">Cancel</button>
                            <button (click)="newConcept()" class="continue-button">Continue</button>
                        </div>
                    </div>

                    <div class="label-container" [ngClass]="{ 'label-printing': printing }" *ngIf="!directionsOpen">
                        <div class="label"
                            *ngIf="projectData.organizeSelection == 'connotation' || projectData.organizeSelection == 'both'">
                            <label class="negative">NEGATIVE</label>
                            <div class="horizontal-line"></div>
                            <label class="positive">POSITIVE</label>
                        </div>

                        <div class="label" *ngIf="projectData.organizeSelection == 'register'">
                            <label class="formal">FORMAL</label>
                            <div class="vertical-line"></div>
                            <label class="informal">INFORMAL</label>
                        </div>
                    </div>

                    <div class="label-container" [ngClass]="{ 'label-printing': printing }" *ngIf="!directionsOpen && projectData.organizeSelection == 'both'">
                        <div class="label">
                            <label class="formal">FORMAL</label>
                            <div class="vertical-line"></div>
                            <label class="informal">INFORMAL</label>
                        </div>
                    </div>

                    <div class="placement">
                        <div class='drag-canvas' drag-canvas [dragElements]='projectData.elements'>
                            <div drag-object *ngFor='let elem of projectData.elements' [dragElement]='elem'
                                [dragElements]='projectData.elements' [allowSelect]='true'>
                                <drag-toolbar *ngIf='!printing' [dragElement]='elem'
                                    [dragElements]='projectData.elements' [showEdit]='elem.type=="text"'
                                    (onEdit)='editLabel(elem, $event)'></drag-toolbar>
                                <label *ngIf='elem.type=="text"'>{{elem.text || 'New Label'}}</label>
                                <drag-status *ngIf='!printing' [dragElement]='elem' [allowSize]='elem.type=="image"'>
                                </drag-status>
                            </div>
                        </div>
                    </div>

                    <div class="resource-sidebar" *ngIf="!printing">
                        <div *ngIf="projectData.editingLabel" class="drag-object-attribute">
                            <div class="attribute-title">Add Label</div>
                            <label>Label Text</label>
                            <input type="text" [(ngModel)]="projectData.inputLabelText"
                                [ngClass]="{ 'has-error': emptyLabelError }" />
                            <label>Explanation</label>
                            <textarea [(ngModel)]="projectData.inputLabelDescription"></textarea>
                            <button class="original-button" (click)="saveLabel()">Save</button>
                            <button class="original-button" (click)="projectData.editingLabel = false">
                                Cancel
                            </button>
                        </div>

                        <div *ngIf="!projectData.editingLabel">
                            <div class="resource-container">
                                <div *ngIf="!directionsOpen && !conceptSelected">
                                    <button *ngFor="let concept of getConcepts()"
                                        (click)="chooseConcept(concept)">{{ concept }}</button>
                                    <button (click)="newConceptOpened = !newConceptOpened" class="green">New
                                        Concept</button>
                                </div>

                                <div class="words-container" *ngIf="conceptSelected">
                                    <div class="left-words">
                                        <button *ngFor="let word of getCurrentWords()[0]"
                                            (click)="addLabel(word)">{{ word }}</button>
                                    </div>
                                    <div class="right-words">
                                        <button *ngFor="let word of getCurrentWords()[1]"
                                            (click)="addLabel(word)">{{ word }}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="new-concept" [ngClass]="{'new-concept-opened': newConceptOpened,
                                                                 'new-concept-closed': newConceptOpened === false}"
                                *ngIf="!conceptSelected">
                                <div>
                                    <input [(ngModel)]="newConceptTitle" type="text" maxlength="18" />
                                    <button (click)="addNewConcept()">Add</button>
                                </div>
                            </div>

                            <div class="new-word" [ngClass]="{'new-word-opened': newWordOpened,
                                                                 'new-word-closed': newWordOpened === false}"
                                *ngIf="conceptSelected">
                                <div>
                                    <input [placeholder]="newWordPlaceholder" [(ngModel)]="newWord" type="text"
                                        maxlength="13" />
                                    <button (click)="addNewWord()">Add</button>
                                </div>
                            </div>

                            <div *ngIf="conceptSelected">
                                <button (click)="newWordOpened = !newWordOpened" class="more-words-button">
                                    <img src="/assets/word-matrix/word-matrix-plus.png">
                                    <label>Add more words</label>
                                </button>

                                <p class="sidebar-directions">
                                    Click the concept words to add them to the matrix. Explain the placement of each
                                    word in
                                    the
                                    sidebar.
                                </p>

                                <button (click)="print()" class="print-button">Print</button>

                                <div class="change-matrix">
                                    <label>Change Matrix</label>
                                    <button (click)="changeSelection('connotation')" class="connotation">
                                        <div class="horizontal-line"></div>
                                    </button>
                                    <button (click)="changeSelection('register')" class="register">
                                        <div class="vertical-line"></div>
                                    </button>
                                    <button (click)="changeSelection('both')" class="both">
                                        <div class="line-container">
                                            <div class="horizontal-line-container">
                                                <div class="horizontal-line"></div>
                                            </div>
                                            <div class="vertical-line"></div>
                                            <div class="horizontal-line-container">
                                                <div class="horizontal-line"></div>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                <button (click)="printWarning = true" class="start-new-concept">
                                    <img src="/assets/word-matrix/word-matrix-refresh.png">
                                    <label>Start new concept</label>
                                </button>

                                <button (click)="learnMore()" class="about-button">
                                    <img src="/assets/word-matrix/word-matrix-question.png">
                                </button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>

<div #printCanvas2 class='print-canvas-2'>
    <div *ngFor='let elem of projectData.elements'>
        <strong>{{elem.text}}</strong> - {{elem.description}}<br>
    </div>

</div>