<div class="app-border">
    <div class="title-bar">
        <span class="logo" *ngIf="step === 0">Graphic Map</span>
        <span *ngIf="step >= 1">{{graphicMap.title}}</span>
        <br />
        <span *ngIf="step >= 1">by {{graphicMap.author}}</span>
    </div>

    <div class="header">
        <table>
            <td>
                <div class="left-header">
                    <div *ngIf="warning === true">
                        <p *ngIf="step === 1">
                            The title field is required.
                        </p>
                        <p *ngIf="step === 2 || step === 3">
                            Please make a selection below before continuing.
                        </p>
                        <p *ngIf="step === 4">
                            Please enter the chapter before adding a new entry.
                        </p>
                    </div>
                </div>
            </td>

            <td>
                <button (click)="nextStep()" class="next-button" *ngIf="step >= 1 && step <= 3">
                    <p>Next <span>▶</span></p>
                </button>

                <div *ngIf="step === 4">
                    <div class="previous-entry-container">
                        <button *ngIf="currentEntry !== 0" (click)="prevEntry()" class="prev-entry">
                            <p><span>◀</span>Previous Entry</p>
                        </button>
                    </div>

                    <div class="add-entry-container">
                        <button *ngIf="currentEntry === graphicMap.entries.length - 1 && currentEntry < 14"
                            (click)="addEntry()" class="add-entry">
                            <p>Add an Entry <span>▶</span></p>
                        </button>

                        <button *ngIf="currentEntry < graphicMap.entries.length - 1 && currentEntry < 14"
                            (click)="nextEntry()" class="add-entry next-entry">
                            <p>Next <span>▶</span></p>
                        </button>
                    </div>

                    <div class="finished-container">
                        <button (click)="finish()" class="finished-button">
                            <p>Finished <span>⚐</span></p>
                        </button>
                    </div>
                </div>

                <div *ngIf="step === 5">
                    <button (click)="back()" class="back-button">
                        <p><span>◀</span>Back</p>
                    </button>
                    <div class="divider"></div>
                    <button (click)="print()" class="print-button">
                        <p>Print <span><i class='fa fa-print'></i></span></p>
                    </button>
                    <div class="divider"></div>
                    <button (click)="restart()" class="header-button">
                        <p>Start New Project</p>
                    </button>
                    <div class="divider"></div>
                    <button (click)="save()" class="header-button">Save</button>

                </div>
            </td>
        </table>
    </div>
    <div class="app">

        <!------------------------------------- Start Animation ---------------------------------------->
        <div class="start-animation" *ngIf="step === 0">
            <h1 class="logo">Graphic Map</h1>
            <table>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 1,
                        'img1': animation >= 1
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/community/gm-community7.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 2,
                        'img2': animation >= 2
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/transportation/gm-transportation1.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 3,
                        'img3': animation >= 3
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/school/gm-school1.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 4,
                        'img4': animation >= 4
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/hobbies/gm-hobbies14.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 5,
                        'img5': animation >= 5
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/miscellaneous/gm-miscellaneous12.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 6,
                        'img6': animation >= 6
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/home/gm-home13.jpg">
                        <div class="line"></div>
                    </div>
                </td>
                <td>
                    <div [ngClass]="{
                        'hidden':animation < 7,
                        'img7': animation >= 7
                    }">
                        <img src="/assets/graphic-map/graphic-map-images/gm-trophy.jpg" (load)="startAnimation()">
                    </div>
                </td>
            </table>
        </div>

        <!------------------------------------- Author and title inputs ---------------------------------------->
        <div class="start-page" *ngIf="step === 1">
            <label for="title">
                <p>Enter title of your graphic map.</p>
            </label>

            <input maxlength="40" id="title" [(ngModel)]="graphicMap.title" />

            <label for="author">
                <p>Enter author(s) of your graphic map.</p>
            </label>

            <input maxlength="40" id="author" [(ngModel)]="graphicMap.author" />
            <br />
<hr>
            <h2>Open Saved Project</h2>
            
            <input [(ngModel)]="fileInput" id="preview" type="file" (change)="openGraphicMap($event)" />
        </div>

        <!------------------------------------- Choose how you want to list items ---------------------------------------->
        <div class="list-items" *ngIf="step === 2">
            <div *ngIf="tipsOpen === true && tipNum === 0" class="help-tips list-tips">
                <div class="keep-position">
                    <h1>Help Tips</h1>
                    <div class="tip-content">
                        <p>The items you list are placed from left to right on your map. You usually move from the
                            lowest
                            item
                            to
                            the highest item when you add details to your map.</p>
                        <br />
                        <p>Example: <br />
                            If you select Time, you can use this tool as a prewriting exercise for an autobiography to
                            list
                            events
                            that happened every year of your life.</p>
                        <h1>What if none of these items fit exactly?</h1>
                        <p>Use the Other label when no other labels apply.</p>
                        <h1>Can I change my item label later?</h1>
                        <p>You can change the label that you've chosen later by clicking the Edit tab on the right side
                            of
                            the
                            tool.
                        </p>
                    </div>
                </div>
            </div>

            <div class="list-header">
                <p class="what-items">What items are you going to list?</p>
                <button class="toggle-tips" tabindex="-1" (click)="toggleTips(0)">
                    <p>?</p>
                </button>
            </div>

            <div class="radio-inputs">
                <input type="radio" id="chapter" name="items" value="chapter" [(ngModel)]="graphicMap.items">
                <label for="chapter">Chapter - the number of the chapter in a book you've read</label><br>
                <input type="radio" id="money" name="items" value="money" [(ngModel)]="graphicMap.items">
                <label for="money">Money - the amount of money spent or received</label><br>
                <input type="radio" id="time" name="items" value="time" [(ngModel)]="graphicMap.items">
                <label for="time">Time - the hour, day, month, or year something happened</label><br>
                <input type="radio" id="scene" name="items" value="scene" [(ngModel)]="graphicMap.items">
                <label for="scene">Scene - the scene in a play you've read or seen</label><br>
                <input type="radio" id="other" name="items" value="other" [(ngModel)]="graphicMap.items">
                <label for="other">Other - for items that don't fit anywhere else</label><br>

                <div *ngIf="graphicMap.items === 'other'">
                    <label class="other-label">Describe the item here:</label>
                    <input class="other-input" type="text" maxlength="10" [(ngModel)]="graphicMap.otherItems" />
                </div>
            </div>
        </div>

        <!------------------------------------- Choose how you want to rate items ---------------------------------------->
        <div class="rate-items" *ngIf="step === 3">
            <div *ngIf="tipsOpen === true && tipNum === 1" class="help-tips rating-tips">
                <div class="keep-position">
                    <div>
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The rating for your items assigns a value.</p>
                        <br />
                        <p>Example:<br />
                            If you select the 3, 2, 1 option for a prewriting exercise for an autobiography, you would
                            pick
                            higher
                            numbers for positive things that have happened to you and lower numbers for negative things
                            that
                            have
                            happened to you.
                        </p>
                        <h1>Can I change my rating label?</h1>
                        <p>You can change the rating that you have chosen later by clicking the Edit tab on the right
                            side
                            of
                            the
                            tool.
                        </p>
                        <p>Example:
                            If you selected the Positive/Negative rating and want to have more options, you can change
                            the
                            rating to
                            the 3, 2, 1 option.
                        </p>
                    </div>
                </div>
            </div>

            <div class="rating-header">
                <p>How do you want to rate your items?</p>
                <button class="toggle-tips" tabindex="-1" (click)="toggleTips(1)">
                    <p>?</p>
                </button>
            </div>

            <div class="radio-inputs">
                <input type="radio" id="high" name="rating" value="high" [(ngModel)]="graphicMap.rating">
                <label for="high">High / Medium / Low</label><br>
                <input type="radio" id="positive" name="rating" value="positive" [(ngModel)]="graphicMap.rating">
                <label for="positive">Positive / Negative</label><br>
                <input type="radio" id="numbers" name="rating" value="numbers" [(ngModel)]="graphicMap.rating">
                <label for="numbers">3, 2, 1 / -1, -2, -3</label><br>
                <input type="radio" id="smiley" name="rating" value="smiley" [(ngModel)]="graphicMap.rating">
                <label for="smiley">Smiley <img src="/assets/graphic-map/graphic-map-images/gm-smiley.jpg"> / Frown <img
                        src="/assets/graphic-map/graphic-map-images/gm-frown.jpg"></label>
            </div>
        </div>

        <!------------------------------------- Entries inputs ---------------------------------------->
        <div class="entries" *ngIf="step === 4">
            <div *ngIf="tipsOpen === true && tipNum === 2" class="help-tips tips2">
                <div class="keep-position">
                    <div class="tip-header">
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The picture for an entry represents or illustrates the item. The picture that you choose will
                            be
                            linked on your printout. </p>
                        <br />
                        <p>Example:<br />
                            If you are using the tool as a prewriting exercise for an autobiography, you can choose the
                            picture
                            of a school bus to illustrate the entry for a field trip that you took, or you can choose
                            the
                            picture of a band aid to represent a time when you got hurt.
                        </p>
                        <h1>How do I choose a picture?</h1>
                        <p>Click on the Choose menu to see the list of picture categories. Next, click on the category
                            name
                            to
                            see the related pictures. Finally, click on the picture that you want for your entry.
                        </p>
                        <h1>Can I change a picture?</h1>
                        <p>You can change a picture by clicking on the list of categories and making a new choice. The
                            new
                            picture will replace the old one.
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="tipsOpen === true && tipNum === 3" class="help-tips tips3">
                <div class="keep-position">
                    <div class="tip-header">
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The item label field (chapter, money, time, scene, or other) gives your entry a name. The
                            item
                            label
                            that you have selected does not appear on the printout. Only the information that you type
                            in
                            the
                            field appears on the printout.</p>
                        <br />
                        <p>Example:<br />
                            If you are using the tool as a prewriting exercise for an autobiography, you can choose the
                            picture
                            of a school bus to illustrate the entry for a field trip that you took, or you can choose
                            the
                            picture of a band aid to represent a time when you got hurt.
                        </p>
                        <h1>How do I choose a picture?</h1>
                        <p>Click on the Choose menu to see the list of picture categories. Next, click on the category
                            name
                            to
                            see the related pictures. Finally, click on the picture that you want for your entry.
                        </p>
                        <h1>Can I change a picture?</h1>
                        <p>You can change a picture by clicking on the list of categories and making a new choice. The
                            new
                            picture will replace the old one.
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="tipsOpen === true && tipNum === 4" class="help-tips tips4">
                <div class="keep-position">
                    <div class="tip-header">
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The topic for an entry is the word or phrase that tells what the item is. The topic will
                            appear
                            under the image on the printout.</p>
                        <br />
                        <p>Example:<br />
                            If you are using the tool as a prewriting exercise for an autobiography, a topic might be
                            'Visit
                            Lakota Reservation' or 'Earn Black Belt.'
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="tipsOpen === true && tipNum === 5" class="help-tips tips5">
                <div class="keep-position">
                    <div class="tip-header">
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The description for an entry is a longer explanation of the topic. You can include a sentence
                            or
                            two that gives more details. The description will be under the topic on the printout.</p>
                        <br />
                        <p>Example:<br />
                            If you are using the tool as a prewriting exercise for an autobiography, for the topic
                            'Visit
                            Lakota Reservation,' you might include the description, 'Saw Lakota ceremonies and learned
                            about
                            tribal heritage.'
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="tipsOpen === true && tipNum === 6" class="help-tips tips6">
                <div class="keep-position">
                    <div class="tip-header">
                        <h1>Help Tips</h1>
                    </div>

                    <div class="tip-content">
                        <p>The rating for your items assigns a value. </p>
                        <br />
                        <p>Example:<br />
                            If you select the 3, 2, 1 option for a prewriting exercise for an autobiography, you would
                            pick
                            higher numbers for positive things that have happened to you and lower numbers for negative
                            things that have happened to you.
                        </p>
                        <h1>Can I change my rating label?</h1>
                        <!-- TODO: change this to how you actually edit -->
                        <p>You can change the rating that you have chosen later by clicking the Edit tab on the right
                            side
                            of the tool.
                        </p>
                        <br />

                        <p>Example:<br />
                            If you selected the Positive/Negative rating and want to have more options, you can change
                            the
                            rating to the 3, 2, 1 option.
                        </p>
                    </div>
                </div>
            </div>

            <div class="pictures-container" *ngIf="picturesOpen === true && graphicMap.entries[currentEntry].imgType">
                <div class="keep-position">
                    <img *ngFor="let link of pictures[graphicMap.entries[currentEntry].imgType]"
                        [src]="'/assets/graphic-map/graphic-map-images/' + graphicMap.entries[currentEntry].imgType.toLowerCase() + '/' + link"
                        (click)="choosePicture(link)">
                </div>
            </div>

            <div class="label-picture">
                <div class="no-picture" *ngIf="!graphicMap.entries[currentEntry].img"></div>
                <img class="chosen-picture" *ngIf="graphicMap.entries[currentEntry].img"
                    [src]="graphicMap.entries[currentEntry].img" />
                <p>{{graphicMap.entries[currentEntry].topic}}</p>
            </div>

            <div class="add-picture">
                <p class="picture-label">Add a picture:</p>
                <div (click)="openPictures()" class="choose"
                    *ngIf="picturesOpen === false && !graphicMap.entries[currentEntry].imgType">
                    <p>Choose</p>
                </div>

                <div class="pictures-box" *ngIf="picturesOpen === true && !graphicMap.entries[currentEntry].imgType">
                    <p *ngFor="let pictureLabel of getKeys(pictures)" (click)="choosePictureType(pictureLabel)">
                        {{pictureLabel}}</p>
                </div>

                <div (click)="openPictures()" class="choose chosen" *ngIf="graphicMap.entries[currentEntry].imgType">
                    <p>{{graphicMap.entries[currentEntry].imgType}}</p>
                </div>

                <button class="toggle-tips" tabindex="-1" (click)="toggleTips(2)">
                    <p>?</p>
                </button>
            </div>

            <table class="entry-inputs">
                <tr>
                    <td class="label-container">
                        <label *ngIf="graphicMap.items === 'chapter'">Chapter:</label>
                        <label *ngIf="graphicMap.items === 'money'">Costs:</label>
                        <label *ngIf="graphicMap.items === 'time'">Time:</label>
                        <label *ngIf="graphicMap.items === 'scene'">Scene:</label>
                        <label *ngIf="graphicMap.items === 'other'">{{graphicMap.otherItems}}:</label>
                    </td>
                    <td>
                        <input class="scene-input" type="text" maxlength="15"
                            [(ngModel)]="graphicMap.entries[currentEntry].items" />
                        <button class="toggle-tips" tabindex="-1" (click)="toggleTips(3)">
                            <p>?</p>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="label-container">
                        <label>Topic:</label>
                    </td>
                    <td>
                        <input type="text" maxlength="30" [(ngModel)]="graphicMap.entries[currentEntry].topic" />
                        <button class="toggle-tips" tabindex="-1" (click)="toggleTips(4)">
                            <p>?</p>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="label-container">
                        <label>Description:</label>
                    </td>
                    <td>
                        <textarea type="text" maxlength="150"
                            [(ngModel)]="graphicMap.entries[currentEntry].description"></textarea>
                        <button class="toggle-tips" tabindex="-1" (click)="toggleTips(5)">
                            <p>?</p>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="label-container">
                        <label (click)="logRating()">Rating:</label>
                    </td>
                    <td>
                        <div class="rating">
                            <div *ngIf="graphicMap.rating === 'high'">
                                <input type="radio" id="high" name="rating" value="high"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="high">High</label><br>
                                <input type="radio" id="medium" name="rating" value="medium"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="medium">Medium</label><br>
                                <input type="radio" id="low" name="rating" value="low"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="low">Low</label>
                            </div>

                            <div *ngIf="graphicMap.rating === 'positive'">
                                <input type="radio" id="positive" name="rating" value="positive"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="positive">Positive</label><br>
                                <input type="radio" id="negative" name="rating" value="negative"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="negative">Negative</label>
                            </div>

                            <div *ngIf="graphicMap.rating === 'numbers'">
                                <input type="radio" id="3" name="rating" value="3"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="3">3</label><br>
                                <input type="radio" id="2" name="rating" value="2"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="2">2</label><br>
                                <input type="radio" id="1" name="rating" value="1"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="1">1</label><br>
                                <input type="radio" id="-1" name="rating" value="-1"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="-1">-1</label><br>
                                <input type="radio" id="-2" name="rating" value="-2"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="-2">-2</label><br>
                                <input type="radio" id="-3" name="rating" value="-3"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="-3">-3</label>
                            </div>
                            <div *ngIf="graphicMap.rating === 'smiley'">
                                <input type="radio" id="smiley" name="rating" value="smiley"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="smiley">
                                    <img src="/assets/graphic-map/graphic-map-images/gm-smiley.jpg">
                                </label><br>

                                <input type="radio" id="frown" name="rating" value="frown"
                                    [(ngModel)]="graphicMap.entries[currentEntry].rating">
                                <label for="frown">
                                    <img src="/assets/graphic-map/graphic-map-images/gm-frown.jpg">
                                </label>
                            </div>

                            <button class="toggle-tips" tabindex="-1" (click)="toggleTips(6)">
                                <p>?</p>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="finished" *ngIf="step === 5">
            <div *ngIf="printing === false">
                <div *ngIf="tipsOpen === true && tipNum === 7" class="help-tips tips7">
                    <div class="keep-position">
                        <h1>Help Tips</h1>
                        <div class="tip-content">
                            <p>Print Preview lets you compare your entries. You can check the order of your entries and
                                compare the rating you have assigned to them.</p>
                            <br />
                            <p>Example: <br />
                                Upon selecting Print Preview, you might notice that you rated getting an A on this
                                week’s
                                spelling test as more positive than winning your district soccer championship. You
                                decide to
                                change that rating by clicking on the picture to edit.</p>
                            <h1>How do I change an entry?</h1>
                            <p>Just click on the picture to edit the entry.</p>
                        </div>
                    </div>
                </div>
                <div class="grid-background">
                    <div id="vert-lines">
                        <div *ngFor="let i of range(10)" class="vert-line"></div>
                    </div>
                    <div id="hozo-lines">
                        <div *ngFor="let i of range(6)" class="hozo-line"></div>
                    </div>
                </div>
                <h1 (click)="lastEntry()">Print Preview</h1>
                <button class="toggle-tips" tabindex="-1" (click)="toggleTips(7)">
                    <p>?</p>
                </button>
                <br>
                <p class="preview-paragraph">Mouse over an icon in the map to view its title. To edit an entry, click on
                    its
                    picture.</p>
                <div class="preview" [ngStyle]="{'margin-left': centerPreview()}">
                    <div class="image" (mouseover)="entry.hovered = true" (mouseout)="entry.hovered = false"
                        *ngFor="let entry of graphicMap.entries"
                        [ngStyle]="{'margin-top': getPreviewMarginTop(entry), 'margin-right': getPreviewMarginRight()}">
                        <img *ngIf="entry.img" [src]="entry.img" (click)="goToEntry(entry)" />
                        <img *ngIf="!entry.img" src="/assets/graphic-map/gm-default.jpg" (click)="goToEntry(entry)" />
                        <div class="line" [ngStyle]="{'transform': 'rotate(' + getLineRotation(entry) + 'rad)',
                                                      'width': getLineWidth(entry)}"></div>
                        <br *ngIf="entry.hovered">
                        <p *ngIf="entry.hovered">{{entry.topic}}</p>
                    </div>
                </div>
            </div>
 
        </div>
    </div>
</div>

<div class='print-div' #printCanvas>
    <h1 class="logo">Graphic Map</h1>
    <span class='print-title' [innerHTML]='graphicMap.title'></span>
    <span class='print-author'>by {{graphicMap.author}}</span>
    <div class='print-line-container'>

        <div class='print-line'></div>

        <table>
            <tr>
                <td>
                    <div class='print-legend' *ngIf="graphicMap.rating === 'high'">
                        <div style='height:33%'>
                            High
                        </div>
                        <div style='height:33%'>
                            Med
                        </div>
                        <div style='height:33%'>
                            Low
                        </div>
                    </div>

                    <div class='print-legend' *ngIf="graphicMap.rating === 'positive'">
                        <div style='height:50%'>
                            Pos
                        </div>
                        <div style='height:50%'>
                            Neg
                        </div>
                    </div>

                    <div class='print-legend' *ngIf="graphicMap.rating === 'numbers'">
                        <div style='height:16.666%'>
                            3
                        </div>
                        <div style='height:16.666%'>
                            2
                        </div>
                        <div style='height:16.666%'>
                            1
                        </div>
                        <div style='height:16.666%'>
                            -1
                        </div>
                        <div style='height:16.666%'>
                            -2
                        </div>
                        <div style='height:16.666%'>
                            -3
                        </div>
                    </div>
                    <div class='print-legend' *ngIf="graphicMap.rating === 'smiley'">
                        <div style='height:50%'>
                            <img src="/assets/graphic-map/graphic-map-images/gm-smiley.jpg">
                        </div>
                        <div style='height:50%'>
                            <img src="/assets/graphic-map/graphic-map-images/gm-frown.jpg">
                        </div>
                    </div>


                </td>
                <td valign='top' *ngFor='let entry of graphicMap.entries; let idx=index'>
                    <ng-container *ngIf='!!entry.rating'>
                        <ng-container *ngIf="graphicMap.rating === 'high'">
                            <div class='print-block' *ngIf='["low", "medium"].includes(entry.rating)'></div>
                            <div class='print-block' *ngIf='["low"].includes(entry.rating)'></div>
                        </ng-container>

                        <ng-container *ngIf="graphicMap.rating === 'positive'">
                            <div class='print-block' *ngIf='entry.rating=="negative"'></div>
                        </ng-container>

                        <ng-container *ngIf="graphicMap.rating === 'numbers'">
                            <div class='print-block' *ngIf='entry.rating<3'></div>
                            <div class='print-block' *ngIf='entry.rating<2'></div>
                            <div class='print-block' *ngIf='entry.rating<1'></div>
                            <div class='print-block' *ngIf='entry.rating<-1'></div>
                            <div class='print-block' *ngIf='entry.rating<-2'></div>
                        </ng-container>

                        <ng-container *ngIf="graphicMap.rating === 'smiley'">
                            <div class='print-block' *ngIf='entry.rating=="frown"'></div>
                        </ng-container>
                    </ng-container>


             
              
                    <div class="print-container print-container-{{idx}}">
                         <div class='print-container-back'></div>
                                    <img [src]="entry.img">
                                
                                    <span class='print-chapter' [innerHTML]='entry.items'></span><br>
                                    <span class='print-topic' [innerHTML]='entry.topic'></span>
                               


                        <div class='clear'></div> {{entry.rating}}
                        <p [innerHTML]='entry.description'></p>
                    </div>
                    <div *ngIf='idx != (graphicMap.entries.length -1)' class="print-diag-line" [ngStyle]="{'transform': 'rotate(' + getPrintLineRotation(idx) + 'rad)',
                                                  'width': getPrintLineWidth(idx),
                                                  'margin-left': getPrintLineLeft(idx),
                                                  'margin-top': getPrintLineTop(idx)
                                                }"></div>
                </td>
            </tr>
        </table>


    </div>

</div>