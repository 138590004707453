import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PlotDiagram } from "src/app/model/plotdiagram";
import { DragUtilService } from "src/app/service/drag-util.service";
import { DragElement } from "../../model/DragElement";
import * as _ from "lodash";
import { calculatePaddingBoxPath } from "html2canvas/dist/types/render/bound-curves";
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';
import { FileUtilService } from "src/app/service/file-util.service";

@Component({
    selector: "app-plot-diagram",
    templateUrl: "./plot-diagram.component.html",
    styleUrls: ["./plot-diagram.component.scss"],
})
export class PlotDiagramComponent implements OnInit {
    step: number = 0;
    labelError = false;
    plotDiagram: PlotDiagram = new PlotDiagram();
    previewImage: any;
    printing = false;
    triangleV1: number[];
    triangleV2: number[];
    triangleV3: number[];
    triangleV4: number[];
    triangleV5: number[];
    triangleMiddle: string[] = ["0px", "0px"];
    triangleRising: string[] = ["0px", "0px", "0"];
    triangleFalling: string[] = ["0px", "0px", "0"];

    triangleRisingRect: any;
    triangleFallingRect: any;

    sliderValue: number = 50;

    emptyLabelError = false;

    @ViewChild('printPreview', null) printPreview: ElementRef;
    @ViewChild('printCanvas', null) printCanvas: ElementRef; 


    constructor(
        private dragUtil: DragUtilService,
        private printer: PrintService,
        private fileSvc: FileUtilService
    ) { }

    ngOnInit() {
    }

    moveEvent($event: MouseEvent, elem: DragElement) { }
    addLabel() {
        let elem = this.dragUtil.createElement(this.plotDiagram.elements);
        elem.type = "text";
        this.plotDiagram.editId = elem.id;
        this.plotDiagram.elements.push(elem);
        this.editLabel(elem);
    }

    // Clear and start over
    reset() {
        this.plotDiagram.inputDiagrammedBy = "";
        this.plotDiagram.inputTitle = "";
        this.step = 1;
        this.plotDiagram.elements = [];
        this.clearSelection();
    }

    // edit the event
    editLabel(elem: DragElement) {
        DragElement.select(elem);
        this.plotDiagram.editId = elem.id;
        this.plotDiagram.inputLabelDescription = elem.description;
        this.plotDiagram.inputLabelText = elem.text;
        this.plotDiagram.editingLabel = true;
    }

    // Save the event
    saveLabel() {
        // clear error
        this.emptyLabelError = false;

        // Ensure event name is entered.
        if (!this.plotDiagram.inputLabelText) {
            this.emptyLabelError = true;
            return;
        }
        // update event
        let elem = _.find(this.plotDiagram.elements, (x) => x.id == this.plotDiagram.editId);
        elem.text = this.plotDiagram.inputLabelText;
        elem.description = this.plotDiagram.inputLabelDescription;
        elem.selected = true;

        this.clearSelection();
        this.plotDiagram.editingLabel = false;
    }

    // clear the selected event
    clearSelection() {
        this.plotDiagram.inputLabelText = null;
        this.plotDiagram.inputLabelDescription = null;
        this.plotDiagram.editId = null;
    }

    nextStep() {
        console.log(this.plotDiagram.triangleChoice);
        if (this.step == 1) {
            this.labelError = false;
            if (this.plotDiagram.projectTitle && this.plotDiagram.author) {
                this.step++;
            }
            else{
                this.labelError = true;
            }
        } else {
            this.step++;
        }
        console.log(this.step);
    }
    async print() {
        this.printing = true;

        this.plotDiagram.elements.forEach(x=>{x.selected=false;});

        await this.printer.print([this.printCanvas], PrintOrientation.Landscape, true, false, false);
        this.printing = false;
    } 

    new(){
        this.step = 0;
        (this.plotDiagram as any) = {};
    }

    async export() {
        this.printing = true;

        this.plotDiagram.elements.forEach(x=>{x.selected=false;});

        await this.printer.export([this.printCanvas], 'plot-diagram', PrintOrientation.Landscape, true, false, false);
        this.printing = false;
    } 

    async save() {
        this.fileSvc.save(this.plotDiagram, 'plot-diagram.json');
    }


  fileInput: any;
  open(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.plotDiagram = JSON.parse(json);
        this.nextStep()
        this.fileInput = null; 
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }


    makeTriangle() {
        let canvas = <HTMLCanvasElement>document.getElementById("triangle-canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;


        // bottom left  
        this.triangleV1 = [0, canvas.height];

        // bottom right
        this.triangleV2 = [canvas.width, canvas.height];

        // slider point 
        this.triangleV3 = [(this.sliderValue / 100) * canvas.width, 0];

        let y = canvas.height * 0.2;

        // The equation for a line (y = mx + b) extrapolated 
        let x1 = (y - canvas.height) / (-canvas.height / this.triangleV3[0]);
        this.triangleV4 = [x1, y];

        let x2 = ((canvas.width - this.triangleV3[0]) / canvas.height) * y + this.triangleV3[0];
        this.triangleV5 = [x2, y];

        let canvasRect = canvas.getBoundingClientRect();
        let triangleMiddleRect = document.getElementById("triangle-label").getBoundingClientRect();

        let scrollLeft = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body).scrollLeft;
        let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body).scrollTop;

        // Gets position for the top triangle label
        let triangleMiddle = [];
        triangleMiddle[0] = Math.round(canvasRect.left + x1 + (x2 - x1) / 2 - triangleMiddleRect.width / 2 + scrollLeft);
        triangleMiddle[1] = Math.round(canvasRect.top + y - triangleMiddleRect.height / 2 + scrollTop);
        this.triangleMiddle = [`${triangleMiddle[0]}px`, `${triangleMiddle[1]}px`];

        // Gets positions for the side triangle labels
        if (this.plotDiagram.triangleChoice === "climax") {
            if (!this.triangleRisingRect) {
                this.triangleRisingRect = document.getElementById("rising-label").getBoundingClientRect();
            }
            if (!this.triangleFallingRect) {
                this.triangleFallingRect = document.getElementById("falling-label").getBoundingClientRect();
            }

            let risingY = (canvas.height - y) / 2 + y;
            let risingX = ((risingY - canvas.height) / (-canvas.height / this.triangleV3[0]) + this.triangleRisingRect.height);
            let risingCoords = [risingX, risingY];

            let fallingX = ((canvas.width - this.triangleV3[0]) / canvas.height) * risingY + this.triangleV3[0] - this.triangleRisingRect.height;
            let fallingCoords = [fallingX, risingY];

            let leftLength = Math.sqrt((this.triangleV3[0] - this.triangleV1[0]) ** 2 + (this.triangleV3[1] - this.triangleV1[1]) ** 2);
            let triangleRising = [];
            triangleRising[0] = risingCoords[0] - this.triangleRisingRect.width / 2 + canvasRect.left + scrollLeft;
            triangleRising[1] = risingCoords[1] - this.triangleRisingRect.height / 2 + canvasRect.top + scrollTop;
            this.triangleRising = [`${triangleRising[0]}px`, `${triangleRising[1]}px`, `rotate(-${Math.asin(canvas.height / leftLength)}rad)`];


            let rightLength = Math.sqrt((this.triangleV3[0] - this.triangleV2[0]) ** 2 + (this.triangleV3[1] - this.triangleV2[1]) ** 2);
            let triangleFalling = [];
            triangleFalling[0] = fallingCoords[0] - this.triangleFallingRect.width / 2 + canvasRect.left + scrollLeft;
            triangleFalling[1] = fallingCoords[1] - this.triangleFallingRect.height / 2 + canvasRect.top + scrollTop;
            this.triangleFalling = [`${triangleFalling[0]}px`, `${triangleFalling[1]}px`, `rotate(${Math.asin(canvas.height / rightLength)}rad)`];
        }

        // Draw Triangle
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.beginPath();
        ctx.moveTo(this.triangleV1[0], this.triangleV1[1]);
        ctx.lineTo(this.triangleV2[0], this.triangleV2[1]);
        ctx.lineTo(this.triangleV3[0], this.triangleV3[1]);
        ctx.closePath();

        ctx.fillStyle = "#b380e6";
        ctx.fill();

        return true;
    }


}
