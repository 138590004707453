<div>
    <div class='mainPage' *ngIf="page==0">
        <audio autoplay #welcome_page_1 (ended)="playNextSound(choose_game)">
            <source src="../../../assets/picture-match/msg_welcome.mp3">
        </audio>

        <audio #choose_game>
            <source src="../../../assets/picture-match/msg_choose.mp3">
        </audio>

        <!--The different section buttons-->
        <div class='beginning-letter-button'>
            <img src='../../../assets/picture-match/beginning_letter_sounds.png' (click)="changePage(1)">
        </div>
        <div class='short-vowel-button'>
            <img src='../../../assets/picture-match/short_vowel_sounds.png' (click)="selectShortVowel()">
        </div>
        <div class='long-vowel-button'>
            <img src='../../../assets/picture-match/long_vowel_sounds_button.png' (click)="selectLongVowel()">
        </div>
    </div>

    <div class='beginning-letter-intro-page' *ngIf="page==1">
        
        <audio autoplay #beginning_letter_sounds_intro (ended)="playNextSound(enter_name_page_2)">
            <source src="../../../assets/picture-match/beginning_letter_sounds_setup.mp3">
        </audio>
        
        <audio #enter_name_page_2>
            <source src="../../../assets/picture-match/msg_enter_your_name.mp3">
        </audio>
        
        
        <img src='../../../assets/picture-match/beginning_letter_sounds.png' class='beginning-letter-logo'>
        <div class='beginning-letter-introduction'>
            <span>Let's match pictures to their beginning-letter</span>
            <p class='beginning-letter-introduction-pt-2'>sounds by sorting them into boxes.</p>
        </div>

        <div class='name'>
            <label>Enter your name: </label>
            <input type='text' (keyup)="nameBeingFilled()" [(ngModel)]='this.name' maxlength="25" class='name-input-box'>
        </div>

        <span style='margin-left: 250px; color:white; font-weight: bolder; font-size: 22px; margin-top: 50px;'>Letters for this game:</span>
        <div style='margin-left: 250px;'>
            <input (keyup)="changeFocus(0, input1.value, input2)" [(ngModel)]='letter1' [value]="letter1" maxlength="1" class='letter-input-boxes' #input1>
            <input (keyup)="changeFocus(1, input2.value, input3)" [(ngModel)]='letter2' maxlength="1" class='letter-input-boxes' #input2>
            <input (keyup)="changeFocus(2, input3.value, input4)" [(ngModel)]='letter3' maxlength="1" class='letter-input-boxes' #input3>
            <input (keyup)="changeFocus(3, input4.value, input5)" [(ngModel)]='letter4' maxlength="1" class='letter-input-boxes' #input4>
            <input (keyup)="changeFocus(4, input5.value, input5)" [(ngModel)]='letter5' maxlength="1" class='letter-input-boxes' #input5>
            <img src='../../../assets/picture-match/random.png' (click)='randomLetters()' style='cursor: pointer'> 
        </div>

        <div *ngIf='matchingInputs==true'>
            <img src='../../../assets/picture-match/please_enter_5_unique_letters.png' class='enter_5_unique_letters'>
        </div>

        <div *ngIf='inputsFilled==false'>
            <img src='../../../assets/picture-match/please_enter_5_unique_letters.png' class='enter_5_unique_letters'>
        </div>

        <div *ngIf='nameFilled==false' >
            <img src='../../../assets/picture-match/please_enter_your_name.png' class='enter_5_unique_letters'>
        </div>

        <div>
            <img src='../../../assets/picture-match/begin.png' (click)="startBeginning()" class='begin-button-for-page-2'>
        </div>

        <div>
            <img src='../../../assets/picture-match/main.png' (click)='mainButton()' class='main-button-for-page-2'>
        </div>

        <div>
            
        </div>
    </div>

    <div class='beginning-letter-game-page' *ngIf="page==2">
        <div>
            <img src='../../../assets/picture-match/beginning_letter_sounds.png' class='beginning-letter-logo-page-3'>
        </div>

        <audio #b_l_s_i autoplay (ended)="playNextSound(current_image_sound)">
            <source src='../../../assets/picture-match/beginning_letter_sounds_game_intro.mp3'>
        </audio>

        <audio #current_image_sound>
            <source [src]="gameImages[gameImageIterator].soundURL">
        </audio>

        <audio #image_sound>
            <source [src]="gameImages[gameImageIterator+1]?.soundURL">
        </audio>

        <div *ngIf="rightOrWrong == false">
            <p class='match-these-pictures-page-3'>Match these pictures to their beginning-letter</p>
            <p class='sounds-by-clicking-page-3'>sounds by clicking on the correct boxes</p>
        </div>

        <div *ngIf="rightOrWrong == true">
            <p class='correct'>CORRECT!</p>
            <p class='uses_blank_letter'>{{gameImages[gameImageIterator].imageName}} uses the beginning letter sound '{{gameImages[gameImageIterator].beginningLetter}}'</p>
        </div>

        <div class='game-image'>
            <img src={{gameImages[gameImageIterator].imageURL}} (click)='playNextSound(current_image_sound)'>
        </div>

        <div style='margin-left: 20px; margin-top: 30px;'>
            <img src={{boxes[0].displayImage}} class='boxes' (click)='checkBeginningMatch(0, incorrectSound, correctSound)'>
            <img src={{boxes[1].displayImage}} class='boxes' (click)='checkBeginningMatch(1, incorrectSound, correctSound)'>
            <img src={{boxes[2].displayImage}} class='boxes' (click)='checkBeginningMatch(2, incorrectSound, correctSound)'>
            <img src={{boxes[3].displayImage}} class='boxes' (click)='checkBeginningMatch(3, incorrectSound, correctSound)'>
            <img src={{boxes[4].displayImage}} class='boxes' (click)='checkBeginningMatch(4, incorrectSound, correctSound)'>
        </div>

        <div class='box-letters'>
            <div class='letters-on-box'>
                <span>{{boxes[0].letter}}</span>
            </div>

            <div class='letters-on-box'>
                <span>{{boxes[1].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[2].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[3].letter}}</span>
            </div>
            <div class='letters-on-box'>
                <span>{{boxes[4].letter}}</span>
            </div>
        </div>

        <div style='margin-top: 30px; margin-left: 15px;'>
            <img src='../../../assets/picture-match/exit_button.png' (click)='changePage(7)' style="cursor: pointer;">
            <div *ngIf='gameImageIterator==0' class='progress-bar-image'>
                <img src='../../../assets/picture-match/0_filled_progress_bar.png'>
            </div>

            <div *ngIf='gameImageIterator==1' class='progress-bar-image'>
                <img src='../../../assets/picture-match/1_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==2' class='progress-bar-image'>
                <img src='../../../assets/picture-match/2_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==3' class='progress-bar-image'>
                <img src='../../../assets/picture-match/3_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==4' class='progress-bar-image'>
                <img src='../../../assets/picture-match/4_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==5' class='progress-bar-image'>
                <img src='../../../assets/picture-match/5_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==6' class='progress-bar-image'>
                <img src='../../../assets/picture-match/6_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==7' class='progress-bar-image'>
                <img src='../../../assets/picture-match/7_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==8' class='progress-bar-image'>
                <img src='../../../assets/picture-match/8_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==9' class='progress-bar-image'>
                <img src='../../../assets/picture-match/9_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==10' class='progress-bar-image'>
                <img src='../../../assets/picture-match/10_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==11' class='progress-bar-image'>
                <img src='../../../assets/picture-match/11_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==12' class='progress-bar-image'>
                <img src='../../../assets/picture-match/12_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==13' class='progress-bar-image'>
                <img src='../../../assets/picture-match/13_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==14' class='progress-bar-image'>
                <img src='../../../assets/picture-match/14_filled_progress_bar.png'>
            </div>

        </div>
        
        <audio #incorrectSound>
            <source src={{randomWrongSound}}>
        </audio>

        <audio #correctSound (ended)="playNextSound(image_sound)">
            <source src={{randomRightSound}}>
        </audio>

    </div>

    <div class='short-vowel-sounds-intro-page' *ngIf="page==3">
        
        <audio autoplay #short_vowel_sounds_intro (ended)="playNextSound(enter_name_page_3)">
            <source src="../../../assets/picture-match/short_vowel_sounds_setup.mp3">
        </audio>
        
        <audio #enter_name_page_3>
            <source src="../../../assets/picture-match/msg_enter_your_name.mp3">
        </audio>
        
        
        <img src='../../../assets/picture-match/short_vowel_sounds.png' class='short-vowel-logo'>
        
        <div>
            <span class='short-vowel-introduction'>Let's match pictures to their short-vowel</span>
            <p class='short-vowel-introduction-pt-2'>sounds by sorting them into boxes.</p>
        </div>

        <div class='name'>
            <label>Enter your name: </label>
            <input type='text' (keyup)="nameBeingFilled()" [(ngModel)]='this.name' maxlength="25" class='name-input-box'>
        </div>

        <span style='margin-left: 250px; color:white; font-weight: bolder; font-size: 22px; margin-top: 50px;'>Letters for this game:</span>
        <div style="margin-left: 250px;">
            <input [(ngModel)]='letter1' [value]="letter1" maxlength="1" class='letter-input-boxes' #input1 readonly>
            <input [(ngModel)]='letter2' maxlength="1" class='letter-input-boxes' #input2 readonly>
            <input [(ngModel)]='letter3' maxlength="1" class='letter-input-boxes' #input3 readonly>
            <input [(ngModel)]='letter4' maxlength="1" class='letter-input-boxes' #input4 readonly>
            <input [(ngModel)]='letter5' maxlength="1" class='letter-input-boxes' #input5 readonly>
        </div>

        
        <div *ngIf='nameFilled==false' >
            <img src='../../../assets/picture-match/please_enter_your_name.png' class='enter_5_unique_letters'>
        </div>

        <div>
            <img src='../../../assets/picture-match/begin.png' (click)="startShortVowel()" class='begin-button-for-page-2'>
        </div>

        <div>
            <img src='../../../assets/picture-match/main.png' (click)='mainButton()' class='main-button-for-page-2'>
        </div>


    </div>

    <div class='beginning-letter-game-page' *ngIf="page==4">
        <div>
            <img src='../../../assets/picture-match/short_vowel_sounds.png' class='beginning-letter-logo-page-3'>
        </div>

        <audio #b_l_s_i autoplay (ended)="playNextSound(current_image_sound)">
            <source src='../../../assets/picture-match/short_vowel_sounds_introduction.mp3'>
        </audio>

        <audio #current_image_sound>
            <source [src]="gameImages[gameImageIterator].soundURL">
        </audio>

        <audio #image_sound>
            <source [src]="gameImages[gameImageIterator+1]?.soundURL">
        </audio>

        <div *ngIf="rightOrWrong == false">
            <p class='match-these-pictures-page-3'>Match these pictures to their short-vowel</p>
            <p class='sounds-by-clicking-page-3'>sounds by clicking on the correct boxes</p>
        </div>

        <div *ngIf="rightOrWrong == true">
            <p class='correct'>CORRECT!</p>
            <p class='uses_blank_letter'>{{gameImages[gameImageIterator].imageName}} uses the short-vowel sound '{{gameImages[gameImageIterator].shortVowel}}'</p>
        </div>

        <div class='game-image'>
            <img src={{gameImages[gameImageIterator].imageURL}} (click)='playNextSound(current_image_sound)'>
        </div>

        <div style='margin-left: 20px; margin-top: 30px;'>
            <img src={{boxes[0].displayImage}} class='boxes' (click)='checkShortVowelMatch(0, incorrectSound, correctSound)'>
            <img src={{boxes[1].displayImage}} class='boxes' (click)='checkShortVowelMatch(1, incorrectSound, correctSound)'>
            <img src={{boxes[2].displayImage}} class='boxes' (click)='checkShortVowelMatch(2, incorrectSound, correctSound)'>
            <img src={{boxes[3].displayImage}} class='boxes' (click)='checkShortVowelMatch(3, incorrectSound, correctSound)'>
            <img src={{boxes[4].displayImage}} class='boxes' (click)='checkShortVowelMatch(4, incorrectSound, correctSound)'>
        </div>

        <div class='box-letters'>
            <div class='letters-on-box'>
                <span>{{boxes[0].letter}}</span>
            </div>

            <div class='letters-on-box'>
                <span>{{boxes[1].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[2].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[3].letter}}</span>
            </div>
            <div class='letters-on-box'>
                <span>{{boxes[4].letter}}</span>
            </div>
        </div>

        <div style='margin-top: 30px; margin-left: 15px;'>
            <img src='../../../assets/picture-match/exit_button.png' (click)='changePage(7)' style="cursor: pointer;">
            <div *ngIf='gameImageIterator==0' class='progress-bar-image'>
                <img src='../../../assets/picture-match/0_filled_progress_bar.png'>
            </div>

            <div *ngIf='gameImageIterator==1' class='progress-bar-image'>
                <img src='../../../assets/picture-match/1_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==2' class='progress-bar-image'>
                <img src='../../../assets/picture-match/2_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==3' class='progress-bar-image'>
                <img src='../../../assets/picture-match/3_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==4' class='progress-bar-image'>
                <img src='../../../assets/picture-match/4_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==5' class='progress-bar-image'>
                <img src='../../../assets/picture-match/5_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==6' class='progress-bar-image'>
                <img src='../../../assets/picture-match/6_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==7' class='progress-bar-image'>
                <img src='../../../assets/picture-match/7_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==8' class='progress-bar-image'>
                <img src='../../../assets/picture-match/8_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==9' class='progress-bar-image'>
                <img src='../../../assets/picture-match/9_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==10' class='progress-bar-image'>
                <img src='../../../assets/picture-match/10_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==11' class='progress-bar-image'>
                <img src='../../../assets/picture-match/11_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==12' class='progress-bar-image'>
                <img src='../../../assets/picture-match/12_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==13' class='progress-bar-image'>
                <img src='../../../assets/picture-match/13_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==14' class='progress-bar-image'>
                <img src='../../../assets/picture-match/14_filled_progress_bar.png'>
            </div>

        </div>
        
        <audio #incorrectSound>
            <source src={{randomWrongSound}}>
        </audio>

        <audio #correctSound (ended)="playNextSound(image_sound)">
            <source src={{randomRightSound}}>
        </audio>

    </div>

    <div class='short-vowel-sounds-intro-page' *ngIf="page==5">
        
        <audio autoplay #short_vowel_sounds_intro (ended)="playNextSound(enter_name_page_3)">
            <source src="../../../assets/picture-match/long_vowel_sounds_setup.mp3">
        </audio>
        
        <audio #enter_name_page_3>
            <source src="../../../assets/picture-match/msg_enter_your_name.mp3">
        </audio>
        
        
        <img src='../../../assets/picture-match/long_vowel_sounds_button.png' class='short-vowel-logo'>
        
        <div>
            <span class='short-vowel-introduction'>Let's match pictures to their long-vowel</span>
            <p class='short-vowel-introduction-pt-2'>sounds by sorting them into boxes.</p>
        </div>

        <div class='name'>
            <label>Enter your name: </label>
            <input type='text' (keyup)="nameBeingFilled()" [(ngModel)]='this.name' maxlength="25" class='name-input-box'>
        </div>

        <span style='margin-left: 250px; color:white; font-weight: bolder; font-size: 22px; margin-top: 50px;'>Letters for this game:</span>
        <div style="margin-left: 250px;">
            <input [(ngModel)]='letter1' [value]="letter1" maxlength="1" class='letter-input-boxes' #input1 readonly>
            <input [(ngModel)]='letter2' maxlength="1" class='letter-input-boxes' #input2 readonly>
            <input [(ngModel)]='letter3' maxlength="1" class='letter-input-boxes' #input3 readonly>
            <input [(ngModel)]='letter4' maxlength="1" class='letter-input-boxes' #input4 readonly>
            <input [(ngModel)]='letter5' maxlength="1" class='letter-input-boxes' #input5 readonly>
        </div>

        
        <div *ngIf='nameFilled==false' >
            <img src='../../../assets/picture-match/please_enter_your_name.png' class='enter_5_unique_letters'>
        </div>

        <div>
            <img src='../../../assets/picture-match/begin.png' (click)="startLongVowel()" class='begin-button-for-page-2'>
        </div>

        <div>
            <img src='../../../assets/picture-match/main.png' (click)='mainButton()' class='main-button-for-page-2'>
        </div>


    </div>

    <div class='beginning-letter-game-page' *ngIf="page==6">
        <div>
            <img src='../../../assets/picture-match/long_vowel_sounds_button.png' class='beginning-letter-logo-page-3'>
        </div>

        <audio #b_l_s_i autoplay (ended)="playNextSound(current_image_sound)">
            <source src='../../../assets/picture-match/long_vowel_sounds_game_introduction.mp3'>
        </audio>

        <audio #current_image_sound>
            <source [src]="gameImages[gameImageIterator].soundURL">
        </audio>

        <audio #image_sound>
            <source [src]="gameImages[gameImageIterator+1]?.soundURL">
        </audio>

        <div *ngIf="rightOrWrong == false">
            <p class='match-these-pictures-page-3'>Match these pictures to their long-vowel</p>
            <p class='sounds-by-clicking-page-3'>sounds by clicking on the correct boxes</p>
        </div>

        <div *ngIf="rightOrWrong == true">
            <p class='correct'>CORRECT!</p>
            <p class='uses_blank_letter'>{{gameImages[gameImageIterator].imageName}} uses the long-vowel sound '{{gameImages[gameImageIterator].longVowel}}'</p>
        </div>

        <div class='game-image'>
            <img src={{gameImages[gameImageIterator].imageURL}} (click)='playNextSound(current_image_sound)'>
        </div>

        <div style='margin-left: 20px; margin-top: 30px;'>
            <img src={{boxes[0].displayImage}} class='boxes' (click)='checkLongVowelMatch(0, incorrectSound, correctSound)'>
            <img src={{boxes[1].displayImage}} class='boxes' (click)='checkLongVowelMatch(1, incorrectSound, correctSound)'>
            <img src={{boxes[2].displayImage}} class='boxes' (click)='checkLongVowelMatch(2, incorrectSound, correctSound)'>
            <img src={{boxes[3].displayImage}} class='boxes' (click)='checkLongVowelMatch(3, incorrectSound, correctSound)'>
            <img src={{boxes[4].displayImage}} class='boxes' (click)='checkLongVowelMatch(4, incorrectSound, correctSound)'>
        </div>

        <div class='box-letters'>
            <div class='letters-on-box'>
                <span>{{boxes[0].letter}}</span>
            </div>

            <div class='letters-on-box'>
                <span>{{boxes[1].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[2].letter}}</span>
            </div>
            
            <div class='letters-on-box'>
                <span>{{boxes[3].letter}}</span>
            </div>
            <div class='letters-on-box'>
                <span>{{boxes[4].letter}}</span>
            </div>
        </div>

        <div style='margin-top: 30px; margin-left: 15px;'>
            <img src='../../../assets/picture-match/exit_button.png' (click)='changePage(7)' style="cursor: pointer;">
            <div *ngIf='gameImageIterator==0' class='progress-bar-image'>
                <img src='../../../assets/picture-match/0_filled_progress_bar.png'>
            </div>

            <div *ngIf='gameImageIterator==1' class='progress-bar-image'>
                <img src='../../../assets/picture-match/1_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==2' class='progress-bar-image'>
                <img src='../../../assets/picture-match/2_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==3' class='progress-bar-image'>
                <img src='../../../assets/picture-match/3_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==4' class='progress-bar-image'>
                <img src='../../../assets/picture-match/4_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==5' class='progress-bar-image'>
                <img src='../../../assets/picture-match/5_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==6' class='progress-bar-image'>
                <img src='../../../assets/picture-match/6_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==7' class='progress-bar-image'>
                <img src='../../../assets/picture-match/7_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==8' class='progress-bar-image'>
                <img src='../../../assets/picture-match/8_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==9' class='progress-bar-image'>
                <img src='../../../assets/picture-match/9_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==10' class='progress-bar-image'>
                <img src='../../../assets/picture-match/10_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==11' class='progress-bar-image'>
                <img src='../../../assets/picture-match/11_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==12' class='progress-bar-image'>
                <img src='../../../assets/picture-match/12_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==13' class='progress-bar-image'>
                <img src='../../../assets/picture-match/13_filled_progress_bar.png'>
            </div>
            
            <div *ngIf='gameImageIterator==14' class='progress-bar-image'>
                <img src='../../../assets/picture-match/14_filled_progress_bar.png'>
            </div>

        </div>
        
        <audio #incorrectSound>
            <source src={{randomWrongSound}}>
        </audio>

        <audio #correctSound (ended)="playNextSound(image_sound)">
            <source src={{randomRightSound}}>
        </audio>

    </div>








    <div class='unfinished-print-page' *ngIf="page==7">
        <div>
            <img src='../../../assets/picture-match/beginning_letter_sounds_logo.png' *ngIf="logo==1" class='print-page-logo'>
            <img src='../../../assets/picture-match/short_vowel_sounds.png' *ngIf="logo==2" class='print-page-logo'>
            <img src='../../../assets/picture-match/long_vowel_sounds_button.png' *ngIf="logo==3" class='print-page-logo'>
        </div>
        <p class='thanks-for-playing'>Thanks for playing! You've matched {{gameImageIterator}} of the {{gameImages.length}}</p>
        <p class='-pictures'>pictures.</p>

        <div class='prePrintImage'>
            <div class='print-name'>
                {{name}}
            </div>
            <div class='backgroundTemplate'>
                <img src='../../../assets/picture-match/pre_print_render.png'>
            </div>

            <!-- letters on the boxes and their images -->
            <div class=letter-images>
                <div style="height: 43px;">
                    
                    <img src={{letterImages[0].images[0]}} class="printingImages" [class.hidden]="!letterImages[0].images[0]">
                    <img src={{letterImages[0].images[1]}} class="printingImages" [class.hidden]="!letterImages[0].images[1]">
                    <img src={{letterImages[0].images[2]}} class="printingImages" [class.hidden]="!letterImages[0].images[2]">
                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[1].images[0]}} class="printingImages" [class.hidden]="!letterImages[1].images[0]">
                    <img src={{letterImages[1].images[1]}} class="printingImages" [class.hidden]="!letterImages[1].images[1]">
                    <img src={{letterImages[1].images[2]}} class="printingImages" [class.hidden]="!letterImages[1].images[2]">

                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[2].images[0]}} class="printingImages" [class.hidden]="!letterImages[2].images[0]">
                    <img src={{letterImages[2].images[1]}} class="printingImages" [class.hidden]="!letterImages[2].images[1]">
                    <img src={{letterImages[2].images[2]}} class="printingImages" [class.hidden]="!letterImages[2].images[2]">

                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[3].images[0]}} class="printingImages" [class.hidden]="!letterImages[3].images[0]">
                    <img src={{letterImages[3].images[1]}} class="printingImages" [class.hidden]="!letterImages[3].images[1]">
                    <img src={{letterImages[3].images[2]}} class="printingImages" [class.hidden]="!letterImages[3].images[2]">

                </div>

                <div style="height: 43px;">

                    <img src={{letterImages[4].images[0]}} class="printingImages" [class.hidden]="!letterImages[4].images[0]">
                    <img src={{letterImages[4].images[1]}} class="printingImages" [class.hidden]="!letterImages[4].images[1]">
                    <img src={{letterImages[4].images[2]}} class="printingImages" [class.hidden]="!letterImages[4].images[2]">

                </div>
                
                <div style="margin-top: -365px;">
                    <p class="printing-box-letters">{{letterImages[0].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[1].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[2].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[3].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[4].letter}}</p>    
                </div>

                <div style="margin-top: -88px;">
                    <img src='../../../assets/picture-match/beginning_letter_sounds_logo.png' *ngIf="logo==1" class='print-preview-logo'>
                    <img src='../../../assets/picture-match/short_vowel_sounds_logo.png' *ngIf="logo==2" class='print-preview-logo'>
                    <img src='../../../assets/picture-match/long_vowel_sounds_button.png' *ngIf="logo==3" class='print-preview-logo'>
                </div>
                
<<<<<<< HEAD
                <div style="margin-left: 165px; margin-top: -45px;">
                    <p class='what-do-you-want'>What do you want to do next?</p>
=======
                <div style="margin-left: 232px; margin-top: -45px;">
                    <p class='what-do-you-want'>What do you want to do next</p>
>>>>>>> master
                    <img src='../../../assets/picture-match/keep_playing.png' class='what-to-do-now-buttons' (click)='returnToGame()'>
                    <!-- <img src='../../../assets/picture-match/print_my_work.png' class='what-to-do-now-buttons'(click)='goToPrint()'> -->
                    <img src='../../../assets/picture-match/start_over.png' class='what-to-do-now-buttons' (click)='startOver()'>
                </div>
            </div>
        
        </div>
    </div>

    <div class='finished-print-page' *ngIf="page==8">
        <div>
            <img src='../../../assets/picture-match/beginning_letter_sounds_logo.png' *ngIf="logo==1" class='print-page-logo'>
            <img src='../../../assets/picture-match/short_vowel_sounds_logo.png' *ngIf="logo==2" class='print-page-logo'>
            <img src='../../../assets/picture-match/long_vowel_sounds_button.png' *ngIf="logo==3" class='print-page-logo'>
        </div>
        <p class='great-job'>Great Job! You've matched all the pictures and</p>
        <p class='completed-the-exercise'>completed the exercise.</p>

        <div class='prePrintImage'>
            <div class='print-name'>
                {{name}}
            </div>
            <div class='backgroundTemplate'>
                <img src='../../../assets/picture-match/pre_print_render.png'>
            </div>

            <!-- letters on the boxes and their images -->
            <div class=letter-images>
                <div style="height: 43px;">
                    
                    <img src={{letterImages[0].images[0]}} class="printingImages" [class.hidden]="!letterImages[0].images[0]">
                    <img src={{letterImages[0].images[1]}} class="printingImages" [class.hidden]="!letterImages[0].images[1]">
                    <img src={{letterImages[0].images[2]}} class="printingImages" [class.hidden]="!letterImages[0].images[2]">
                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[1].images[0]}} class="printingImages" [class.hidden]="!letterImages[1].images[0]">
                    <img src={{letterImages[1].images[1]}} class="printingImages" [class.hidden]="!letterImages[1].images[1]">
                    <img src={{letterImages[1].images[2]}} class="printingImages" [class.hidden]="!letterImages[1].images[2]">

                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[2].images[0]}} class="printingImages" [class.hidden]="!letterImages[2].images[0]">
                    <img src={{letterImages[2].images[1]}} class="printingImages" [class.hidden]="!letterImages[2].images[1]">
                    <img src={{letterImages[2].images[2]}} class="printingImages" [class.hidden]="!letterImages[2].images[2]">

                </div>

                <div style="height: 43px;">
                    <img src={{letterImages[3].images[0]}} class="printingImages" [class.hidden]="!letterImages[3].images[0]">
                    <img src={{letterImages[3].images[1]}} class="printingImages" [class.hidden]="!letterImages[3].images[1]">
                    <img src={{letterImages[3].images[2]}} class="printingImages" [class.hidden]="!letterImages[3].images[2]">

                </div>

                <div style="height: 43px;">

                    <img src={{letterImages[4].images[0]}} class="printingImages" [class.hidden]="!letterImages[4].images[0]">
                    <img src={{letterImages[4].images[1]}} class="printingImages" [class.hidden]="!letterImages[4].images[1]">
                    <img src={{letterImages[4].images[2]}} class="printingImages" [class.hidden]="!letterImages[4].images[2]">

                </div>
                
                <div style="margin-top: -365px;">
                    <p class="printing-box-letters">{{letterImages[0].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[1].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[2].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[3].letter}}</p>
                    <p class="printing-box-letters">{{letterImages[4].letter}}</p>    
                </div>                
            </div>
            
            <div style="margin-top: -88pxpx;">
                <img src='../../../assets/picture-match/beginning_letter_sounds_logo.png' *ngIf="logo==1" class='finished-print-preview-logo'>
                <img src='../../../assets/picture-match/short_vowel_sounds_logo.png' *ngIf="logo==2" class='finished-print-preview-logo'>
                <img src='../../../assets/picture-match/long_vowel_sounds_button.png' *ngIf="logo==3" class='finished-print-preview-logo'>
            </div>

<<<<<<< HEAD
            <div style="margin-left: 165px; margin-top: -45px;">
                <p class='finished-what-do-you-want'>What do you want to do next?</p>
                <img src='../../../assets/picture-match/print_my_work.png' class='finished-what-to-do-now-buttons'>
                <img src='../../../assets/picture-match/start_over.png' class='finished-what-to-do-now-buttons' (click)="startOver()">
=======
            <div style="margin-left: 232px; margin-top: -45px;">
                <p class='what-do-you-want'>What do you want to do next</p>
                <!-- <img src='../../../assets/picture-match/print_my_work.png' class='what-to-do-now-buttons' (click)='goToPrint()'> -->
                <img src='../../../assets/picture-match/start_over.png' class='what-to-do-now-buttons' (click)="startOver()">
>>>>>>> master
            </div>

        </div>
    </div>
    <div #printCanvas class='real-print' *ngIf='page==9'>



        <div  >
       
            <div class='real-print-name'>
                {{this.name}}
            </div>
    
            <div class='real-print-logo'>
                <img src='../../../assets/picture-match/beginning_letter_sounds_logo.png' *ngIf='this.logo==1' style="width: 200px; height: 24px;">
                <img src='../../../assets/picture-match/short_vowel_sounds.png' *ngIf="this.logo==2" style="width: 200px; height: 24px;">
                <img src='../../../assets/picture-match/long_vowel_sounds_button.png' *ngIf="this.logo==3" style="width: 200px; height: 24px;">
            </div>
    
            <div class=real-letter-images>
                <div style="height: 110px;">
                    
                    <img src={{letterImages[0].images[0]}} class="real-printingImages" [class.hidden]="!letterImages[0].images[0]">
                    <img src={{letterImages[0].images[1]}} class="real-printingImages" [class.hidden]="!letterImages[0].images[1]">
                    <img src={{letterImages[0].images[2]}} class="real-printingImages" [class.hidden]="!letterImages[0].images[2]">
                </div>
    
                <div style="height: 110px;">
                    <img src={{letterImages[1].images[0]}} class="real-printingImages" [class.hidden]="!letterImages[1].images[0]">
                    <img src={{letterImages[1].images[1]}} class="real-printingImages" [class.hidden]="!letterImages[1].images[1]">
                    <img src={{letterImages[1].images[2]}} class="real-printingImages" [class.hidden]="!letterImages[1].images[2]">
    
                </div>
    
                <div style="height: 110px;">
                    <img src={{letterImages[2].images[0]}} class="real-printingImages" [class.hidden]="!letterImages[2].images[0]">
                    <img src={{letterImages[2].images[1]}} class="real-printingImages" [class.hidden]="!letterImages[2].images[1]">
                    <img src={{letterImages[2].images[2]}} class="real-printingImages" [class.hidden]="!letterImages[2].images[2]">
    
                </div>
    
                <div style="height: 110px;">
                    <img src={{letterImages[3].images[0]}} class="real-printingImages" [class.hidden]="!letterImages[3].images[0]">
                    <img src={{letterImages[3].images[1]}} class="real-printingImages" [class.hidden]="!letterImages[3].images[1]">
                    <img src={{letterImages[3].images[2]}} class="real-printingImages" [class.hidden]="!letterImages[3].images[2]">
    
                </div>
    
                <div style="height: 110px;">
    
                    <img src={{letterImages[4].images[0]}} class="real-printingImages" [class.hidden]="!letterImages[4].images[0]">
                    <img src={{letterImages[4].images[1]}} class="real-printingImages" [class.hidden]="!letterImages[4].images[1]">
                    <img src={{letterImages[4].images[2]}} class="real-printingImages" [class.hidden]="!letterImages[4].images[2]">
    
                </div>
                
                <div style="margin-top: -660px; margin-left: -55px">
                    <p class="real-printing-box-letters">{{letterImages[0].letter}}</p>
                    <p class="real-printing-box-letters" style="margin-bottom: 8px;">{{letterImages[1].letter}}</p>
                    <p class="real-printing-box-letters" style="margin-bottom: 13px;">{{letterImages[2].letter}}</p>
                    <p class="real-printing-box-letters" style="margin-bottom: 15px;">{{letterImages[3].letter}}</p>
                    <p class="real-printing-box-letters">{{letterImages[4].letter}}</p>    
                </div>
    
    
            </div>
        </div>
        <div *ngIf='!printing'>
            <img src='../../../assets/picture-match/print_my_work.png' class='real-print-my-work' (click)='print()'>
        </div>

        <div class='clear'></div>
    </div>