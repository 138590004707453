<div class="app-border">
  <div class="title-bar">
    <span>Book Cover Guide</span>
  </div>
  <div class="app">
    <!--------------------------------- Header ------------------------------------------>

    <div class="header">
      <img src="assets\book-cover-guide\banner.jpg" />
    </div>

    <!--------------------------------- Tabs ------------------------------------------>

    <div class="tabs">
      <div class="bulleted" [ngStyle]="{ 'margin-left': bulletMargin }"></div>
      <button (click)="changeCurrentTab(0)" class="first">Overview</button>
      <button (click)="changeCurrentTab(1)">Front Cover</button>
      <button (click)="changeCurrentTab(2)">Back Cover</button>
      <button (click)="changeCurrentTab(3)">Spine</button>
      <button (click)="changeCurrentTab(4)">Flaps</button>
    </div>
    <div class="body">
      <!--------------------------------- Body Images ------------------------------------------>

      <div class="body-image">
        <img src="assets\book-cover-guide\left1.jpg" *ngIf="currentTab === 0" />
        <img src="assets\book-cover-guide\left2.jpg" *ngIf="currentTab === 1" />
        <img src="assets\book-cover-guide\left3.jpg" *ngIf="currentTab === 2" />
        <img
          src="assets\book-cover-guide\left4.jpg"
          *ngIf="currentTab === 3"
          class="image-4"
        />
        <div *ngIf="currentTab === 4">
          <img src="assets\book-cover-guide\frontflap.jpg" />
          <img src="assets\book-cover-guide\backflap.jpg" />
        </div>
      </div>

      <!--------------------------------- Body Texts ------------------------------------------>

      <div class="body-text">
        <div class="text1" *ngIf="currentTab === 0">
          <h1>Overview</h1>
          <p>
            Book covers usually give us our first clue of what a book will
            explore. From a book cover, we can often tell what genre of book,
            the age level of the readers, the information that the book will
            discuss, and even what other readers think about the book. Every
            book has a front cover, back cover, and a spine. Because they help
            protect the paper pages of the book, these parts are usually made of
            heavier paper or cardboard. In addition, these parts of the book's
            cover help readers identify the book as well as persuade potential
            readers to consider the title. Many hardcover books also have a dust
            jacket, a piece of paper that wraps around the book's cover to help
            protect it. Literally, dust jackets were wrapped around the book
            like a <i>jacket</i> and were Intended to keep dust off of books.
            Dust jackets have front and back covers, front and back flaps, and a
            spine.
          </p>
        </div>
        <div class="text2" *ngIf="currentTab === 1">
          <h1>Front Cover</h1>
          <label
            >[Enlarge
            <a
              href="assets\book-cover-guide\left2.jpg"
              target="_blank"
              rel="noopener noreferrer"
              >front cover image</a
            >]
          </label>

          <p>
            The front cover of a book typically features the title of the bookin
            a clear and obvious way. The letters usually stand out in some way
            from the other details that may be included on the cover. You will
            often find the name of the book's authors and illustrators on the
            front cover as well. Normally, these names are In a smaller-sized
            text than the title. The front cover of a book often includes a
            picture that illustrates any of the following:
          </p>
          <ul>
            <li>one or more characters</li>
            <li>the setting</li>
            <li>a specific event</li>
          </ul>
          <p>
            The front cover can include many other features of course. For
            instance, the sample book cover shown here includes an award medal,
            indicating that the book won the NCTE Award for Poetry for Children.
          </p>
        </div>
        <div class="text3" *ngIf="currentTab === 2">
          <h1>Back Cover</h1>
          <label
            >[Enlarge
            <a
              href="assets\book-cover-guide\left3.jpg"
              target="_blank"
              rel="noopener noreferrer"
              >back cover image</a
            >]
          </label>
          <p>
            The back cover of a book can include any information that might
            interest readers in the book. The example back cover shown here
            lists the names of the poets whose poems are included in the books.
            On another book, you might find an excerpt from the story, a short
            summary of what the book's contents, or reviews of the book by other
            readers. You might also find a short outline or shortened table of
            contents. On some books the illustration from the front cover wraps
            all the way around the book, so you will see a continuation of the
            scene from the front of the book extended across the back. Back
            covers may also include graphics that highlight important text, such
            as a box that highlights a review. Back covers sometimes include the
            book's title, authors, illustrators, and publisher as well. You will
            also be likely to see an ISBN symbol, the barcode symbol that is
            used for pricing and inventory by publishers and book sellers. The
            list price of the book, the price suggested by the publisher, may be
            included on the back cover.
          </p>
        </div>
        <div class="text4" *ngIf="currentTab === 3">
          <h1>Spine</h1>
          <label
            >[Enlarge
            <a
              href="assets\book-cover-guide\left4.jpg"
              target="_blank"
              rel="noopener noreferrer"
              >spine image</a
            >]
          </label>
          <p>
            A book's spine is the smallest part of the its cover, but often its
            most important part. The spine is the first thing that a reader sees
            when browsing a group of books lined up on a bookshelf or stacked up
            in a pile.<br />
            The spine shown in the image here, outlined in red, includes the
            basic identifying information about the book:
          </p>
          <ul>
            <li>the book's author</li>
            <li>the book's title</li>
            <li>the book's publishers</li>
          </ul>
          <p>
            Sometimes the spine will also include a small icon or trademark
            associated with the publisher.
          </p>
        </div>
        <div class="text5" *ngIf="currentTab === 4">
          <h1>Front and Back Flaps</h1>
          <label
            >[Enlarge
            <a
              href="assets\book-cover-guide\frontflap.jpg"
              target="_blank"
              rel="noopener noreferrer"
              >front</a
            > or
            <a
              href="assets\book-cover-guide\backflap.jpg"
              target="_blank"
              rel="noopener noreferrer"
              >back</a
            > flap]
          </label>
          <p>
            The front and back flaps on a book's dust jacket include additional
            details about the book, all meant to entice you to read the book.
            Generally speaking, the front flap discusses the contents of the book
            and the back flap focuses on the book's authors and illustrators.
            The front flap can include any of the following features.
          </p>
            <ul>
              <li>an excerpt from the book</li>
              <li>a general summary</li>
              <li>a description of the book's contents</li>
              <li>details on any awards the book has won</li>
            </ul>
            <p>
              The front flap is often like a very short book report: its purpose is to
              provide just enough details to interest someone in reading the book.
              The back flap of a book, on the other hand, focuses on telling you
              about the people behind the book. The back flap can include these
              details.
            </p>
            <ul>
              <li>a biographical note on the authors</li>
              <li>a biographical note on the illustrators</li>
              <li>identifying information on the publisher</li>
            </ul>
            <p>
              The list price of the book, the price suggested by the publisher, may
              be included on the either the front or back flap.
            </p>
        </div>
      </div>
    </div>
  </div>
</div>
