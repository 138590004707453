import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numeric-only]'
})
export class NumericOnlyDirective {

  constructor() { }
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if ( 
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||  
      (e.keyCode === 67 && e.ctrlKey === true) ||  
      (e.keyCode === 67 && e.metaKey === true) ||  
      (e.keyCode === 86 && e.metaKey === true) ||  
      (e.keyCode === 88 && e.metaKey === true) ||  
      (e.keyCode === 86 && e.ctrlKey === true) ||  
      (e.keyCode === 88 && e.ctrlKey === true) ||  
      (e.keyCode === 65 && e.metaKey === true) ||  
      (e.keyCode >= 35 && e.keyCode <= 39)      ) {
      return;   
    }
   
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    if(e && e.clipboardData && e.clipboardData.getData('text')){
      let pastedText: any = e.clipboardData.getData('text');
      if(isNaN(pastedText) || parseFloat(pastedText) < 0){
        e.preventDefault();
      }
    }
  }
}