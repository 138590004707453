<div *ngIf="step==-1" class="step--1">

    <div class="spacer-div"></div>

    <div class="sample-image"></div>

    <div class="sample-page-next-button" (click)="nextStep()"></div>
</div>

<div *ngIf="step==0" class="step-0">

    <div class="sound-toggle-button" *ngIf="soundEnabled == true" (click)="toggleSound()"></div>
    <div class="disabled-sound-toggle-button" *ngIf="soundEnabled == false" (click)="toggleSound()"></div>
    <div class="open-button" (click)='showOpen=true'></div>
    <div class='open-file' *ngIf='showOpen'>
        <h2>OPEN SAVED K-W-L</h2>
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openKwl($event)">
    </div>
    <div class="save-button" (click)='save()'></div>
    <div class="close-button"></div>
    <div class="step-0-text-1">
        <p>
            More About <br>
            K-W-L Charts
        </p>
    </div>
    <div class="step-0-text-2">
        <p>
            A <span class="step-0-highlighted-text">K-W-L chart</span> is a tool that helps you think about a topic <br>
            before and after you read about it. The letters stand for <br>
            "What I <span class="step-0-highlighted-text">k</span>now," "What I <span
                class="step-0-highlighted-text">w</span>ant to learn," and "what I <span
                class="step-0-highlighted-text">l</span>earned." <br> <br>
            Before reading, fill in the K column with what you <span class="step-0-highlighted-text">k</span>now <br>
            about the topic and the W column with what you <span class="step-0-highlighted-text">w</span>ant to <br>
            learn. After reading, fill in the L column with what you <br>
            <span class="step-0-highlighted-text">l</span>earned from the reading.
        </p>
    </div>
    <div class="step-0-block-thing-1"></div>

    <div class="step-0-block-thing-2"></div>

    <div class="step-0-example"></div>

    <div class="step-0-sample-button" (click)="prevStep()"></div>

    <div class="step-0-continue-button" (click)="nextStep()"></div>

</div>

<div *ngIf="step==1" class="step-1">
    <div class="sound-toggle-button" *ngIf="soundEnabled == true" (click)="toggleSound()"></div>
    <div class="disabled-sound-toggle-button" *ngIf="soundEnabled == false" (click)="toggleSound()"></div>
    <div class="open-button" (click)='showOpen=true'></div>
    <div class='open-file' *ngIf='showOpen'>
        <i class='fa fa-close' (click)='showOpen=false'></i>
        <h2>OPEN SAVED K-W-L</h2>
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openKwl($event)">
    </div>
    <div class="save-button"></div>
    <div class="close-button"></div>
    <div class="main-menu-welcome-text">
        <p>
            Welcome to the <br>
            K-W-L Creator
        </p>
    </div>
    <div class="main-menu-block-thing-1"></div>
    <div class="main-menu-welcome-text-2">
        <p>
            Please enter your name and <br>
            the topic of your K-W-L chart.
        </p>
    </div>
    <div class="main-menu-welcome-text-3">
        <p>
            Your Name:
        </p>
    </div>
    <div class="main-menu-welcome-text-4">
        <p>
            Topic:
        </p>
    </div>
    <div class="main-menu-text-input-1">
        <input type="text" [(ngModel)]='vars.name'>
        <div *ngIf="main_menu_input_valid_name==false" class="text-input-warning-1"></div>
    </div>
    <div class="main-menu-text-input-2">
        <input type="text" [(ngModel)]='vars.topic'>
        <div *ngIf="main_menu_input_valid_topic==false" class="text-input-warning-2"></div>
    </div>

    <div class="main-menu-block-thing-2">

    </div>
    <div class="what-is-kwl" (click)="prevStep()"></div>
    <div class="main-menu-continue-button" (click)="checkNextStep()"></div>
</div>

<div *ngIf="step==2 || step==3 || step==4" class="step-2">
    <div class="spacer-div"></div>
    <div class="sound-toggle-button" *ngIf="soundEnabled == true" (click)="toggleSound()"></div>
    <div class="disabled-sound-toggle-button" *ngIf="soundEnabled == false" (click)="toggleSound()"></div>
    <div class="open-button" (click)='showOpen=true'></div>
    <div class='open-file' *ngIf='showOpen'>
        <h2>OPEN SAVED K-W-L</h2>
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openKwl($event)">
    </div>
    <div class="save-button"></div>
    <div class="close-button"></div>

    <div class="block-thing-3"></div>
    <div *ngIf='step==2' class="step-2-main-text-1">
        <p>
            What I &nbsp;&nbsp; now
        </p>
        <div class="step-2-big-k"></div>
    </div>
    <div *ngIf='step==3' class="step-3-main-text-1">
        <p>
            What I &nbsp;&nbsp; ant to learn
        </p>
        <div class="step-3-big-w"></div>
    </div>
    <div *ngIf='step==4' class="step-4-main-text-1">
        <p>
            What I &nbsp;&nbsp; earned
        </p>
        <div class="step-4-big-l"></div>
    </div>


    <div class="step-2-main-text-2">
        <p>
            Use the area below to list the things you already know about <br>
            {{vars.topic}}.
        </p>
    </div>

    <div *ngIf='step!=2' class="k-button" (click)="step=2"></div>
    <div *ngIf='step==2' class="k-button-toggled"></div>
    <div *ngIf='step!=3' class="w-button" (click)="step=3"></div>
    <div *ngIf='step==3' class="w-button-toggled"></div>
    <div *ngIf='step!=4' class="l-button" (click)="step=4"></div>
    <div *ngIf='step==4' class="l-button-toggled"></div>
    <div class="layout-button" (click)="step=5"></div>
    <div class="final-button" (click)="step=6"></div>

    <div class="left-arrow-button" (click)='makeHead()'></div>
    <div class="right-arrow-button" (click)='makeSub()'></div>
    <div class="bold-button" (click)='setBold()'></div>
    <div class="italic-button" (click)='setItalic()'></div>
    <div class="hyperlink-button"></div>

    <div class="block-thing-4">
        <div class="scroll-div">
            <div *ngFor='let headingObject of getHeadings(); let i=index'>
                <span class='bullet' *ngIf='headingObject.isSub'><span>&bull;</span></span>
                <input [style.fontWeight]='(headingObject.bold ? "bold" : "normal")'
                    [style.fontStyle]='(headingObject.italic ? "italic" : "normal")'
                    [ngClass]="{'indent': headingObject.isSub}" class="know-screen-input"
                    [(ngModel)]='headingObject.heading' (focusin)='vars.focuscontrol=headingObject'>
                <div class="know-screen-add-sub-button" (click)='addSubHeading(headingObject, i)'></div>
            </div>
            <div (click)='addHeading()' class="add-heading-button"></div>
        </div>
    </div>
    <div class="trash-button"></div>

    <div class="back-button" (click)="prevStep()"></div>
    <div class="next-button" (click)="nextStep()"> </div>
</div>



<div *ngIf="step==5" class="step-5">
    <div class='spacer-div'></div>
    <div class="sound-toggle-button" *ngIf="soundEnabled == true" (click)="toggleSound()"></div>
    <div class="disabled-sound-toggle-button" *ngIf="soundEnabled == false" (click)="toggleSound()"></div>
    <div class="open-button" (click)='showOpen=true'></div>
    <div class='open-file' *ngIf='showOpen'>
        <h2>OPEN SAVED K-W-L</h2>
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openKwl($event)">
    </div>
    <div class="save-button"></div>
    <div class="close-button"></div>

    <div class="block-thing-3"></div>
    <div class="step-5-main-text-1">
        <p>
            Print Options
        </p>
    </div>
    <div class="step-5-main-text-2">
        <p>
            Choose the format for your printed K-W-L chart.
        </p>
    </div>

    <div class="k-button" (click)='step=2'></div>
    <div class="w-button" (click)='step=3'></div>
    <div class="l-button" (click)='step=4'></div>
    <div class="layout-button-toggled"></div>
    <div class="final-button" (click)='step=6'></div>
    <table class='page-table'>
        <tr>
            <td>
                <div class="checkbox-1">
                    <div [ngClass]='{"checkbox-blank": !vars.checkboxsamepage, "checkbox-filled": vars.onepage}'
                        (click)="vars.onepage=true"></div>
                    <div class="checkbox-text-1">
                        <p>
                            K, W, and L on the same page
                        </p>
                    </div>
                </div>
            </td>
            <td>
                <div class="checkbox-2">
                    <div [ngClass]='{"checkbox-blank": !vars.checkboxownpage, "checkbox-filled": !vars.onepage}'
                        (click)="vars.onepage=false"></div>
                    <div class="checkbox-text-2">
                        <p>
                            Each on its own page
                        </p>
                    </div>
                </div>

            </td>
        </tr>
        <tr>
            <td>
                <img src='/assets/kwl-creator/frames/kwl-layout1.png'>
            </td>
            <td>
                <img src='/assets/kwl-creator/frames/kwl-layout2.png'>
            </td>
        </tr>
    </table>

    <div class="back-button" (click)="prevStep()"></div>
    <div class="finish-button" (click)="nextStep()"> </div>

</div>

<div *ngIf="step==6" class="step-6">
    <div class='spacer-div'></div>
    <div class="sound-toggle-button" *ngIf="soundEnabled == true" (click)="toggleSound()"></div>
    <div class="disabled-sound-toggle-button" *ngIf="soundEnabled == false" (click)="toggleSound()"></div>
    <div class="open-button" (click)='showOpen=true'></div>
    <div class='open-file' *ngIf='showOpen'>
        <h2>OPEN SAVED K-W-L</h2>
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openKwl($event)">
    </div>
    <div class="save-button"></div>
    <div class="close-button"></div>

    <div class="block-thing-3"></div>
    <div class="step-6-main-text-1">
        <p>
            You've created your K-W-L Chart!
        </p>
    </div>
    <div class="step-6-main-text-2">
        <p>
            Review your chart. If you would like to make changes, you can use the Back button or click on the mini-map.
        </p>
    </div>

    <div class="k-button" (click)='step=2'></div>
    <div class="w-button" (click)='step=3'></div>
    <div class="l-button" (click)='step=4'></div>
    <div class="layout-button" (click)='step=5'></div>
    <div class="final-button-toggled"></div>

    <table class='controls'>
        <tr>
            <td>
                <div *ngIf='!vars.onepage' class='preview-info-k'>
                    <span class='name'>{{vars.name}}</span>
                    <span class='topic'>{{vars.topic}}</span>
                    <div class='k'>
                        <ng-container *ngFor='let h of vars.kheadings'>
                            <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                            [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                            <ul *ngIf='!!h.isSub'>
                                <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                                [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                            </ul>
                        </ng-container>
                    </div> 
                </div>

                <div *ngIf='!!vars.onepage' class='preview-info-all'>

                    <span class='name'>{{vars.name}}</span>
                    <span class='topic'>{{vars.topic}}</span>
                    <div class='k'>
                        <ng-container *ngFor='let h of vars.kheadings'>
                            <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                            [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                            <ul *ngIf='!!h.isSub'>
                                <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                                [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                            </ul>
                        </ng-container>
                    </div> 
                    <div class='w'>
                        <ng-container *ngFor='let h of vars.wheadings'>
                            <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                            [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                            <ul *ngIf='!!h.isSub'>
                                <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                                [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                            </ul>
                        </ng-container>
                    </div> 
                    <div class='l'>
                        <ng-container *ngFor='let h of vars.lheadings'>
                            <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                            [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                            <ul *ngIf='!!h.isSub'>
                                <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                                [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                            </ul>
                        </ng-container>
                    </div> 
                </div>

                <img *ngIf='vars.onepage' src='/assets/kwl-creator/print/preview-all.png' class='print-preview-all'>
                <img *ngIf='!vars.onepage' src='/assets/kwl-creator/print/preview-k.png' class='print-preview-k'>
            </td>
            <td align="right">
                <div class='print-instr'>
                    When your K-W-L chart is finished, you can ...
                </div>
                <div>
                    <div class="print-button" (click)="print()"></div>

                    <div class="save-file-button" (click)="save()"></div>
                    <div class='clear'></div>
                </div>
                <div>
                    <div class="new-button" (click)="createNew()"></div>
                    <div class="back-button" (click)="prevStep()"></div>
                    <div class='clear'></div>
                </div>
            </td>
        </tr>
    </table>


    <div *ngIf='vars.onepage' #printAllContainer class='print-container print-all'>

        <span class='name'>{{vars.name}}</span>
        <span class='topic'>{{vars.topic}}</span>
        <div class='k'>
            <ng-container *ngFor='let h of vars.kheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div>
        <div class='w'>
            <ng-container *ngFor='let h of vars.wheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div>
        <div class='l'>
            <ng-container *ngFor='let h of vars.lheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div>

    </div>

    <div *ngIf='!vars.onepage' #printKContainer class='print-container print-single print-k'>
        <span class='name'>{{vars.name}}</span>
        <span class='topic'>{{vars.topic}}</span>
        <div class='k'>
            <ng-container *ngFor='let h of vars.kheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div> 
    </div>

    <div *ngIf='!vars.onepage' #printWContainer class='print-container print-single print-w'>
        <span class='name'>{{vars.name}}</span>
        <span class='topic'>{{vars.topic}}</span>
        <div class='w'>
            <ng-container *ngFor='let h of vars.wheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div> 
    </div>

    <div *ngIf='!vars.onepage' #printLContainer class='print-container print-single print-l'>
        <span class='name'>{{vars.name}}</span>
        <span class='topic'>{{vars.topic}}</span>
        <div class='l'>
            <ng-container *ngFor='let h of vars.lheadings'>
                <span class='k-head' [style.fontWeight]='(h.bold ? "bold" : "normal")'
                [style.fontStyle]='(h.italic ? "italic" : "normal")' *ngIf='!h.isSub'>{{h.heading}}</span>
                <ul *ngIf='!!h.isSub'>
                    <li [style.fontWeight]='(h.bold ? "bold" : "normal")'
                    [style.fontStyle]='(h.italic ? "italic" : "normal")'>{{h.heading}}</li>
                </ul>
            </ng-container>
        </div> 
    </div>


</div>