<div *ngIf='step==0' class='step-0'>
  <table>
    <tr>
      <td style='width:50%'>
        <img src='/assets/multigenre-mapper/intro.png'>
      </td>
      <td valign='top'>
        <label>What is the title of your text?</label>
        <input [(ngModel)]='vars.title'>
        <label>Your name or your group members' names?</label>
        <input [(ngModel)]='vars.names'>
      </td>
    </tr>
  </table>
  <div class='printer-sel'>
    <label>What type of printer do you have?</label>
    <div>
      <button (click)='vars.printer="black"; step=1'>
        <i class='fa fa-print'></i> Black Ink Printer
      </button>
      <button (click)='vars.printer="color"; step=1'>
        <i class='fa fa-print'></i> Color Ink Printer
      </button>
    </div>
  </div>
</div>
<div class='information' *ngIf='printing'>
  <h2>Printing</h2>
  <p>
    Printing is the only way to keep a record of your work. After printing, you may go back and edit or begin a new
    project.
  </p>
  <p>
    Starting a new project erases all of your work.
  </p>
  <button (click)='initNew()'>New Project </button>&nbsp;
  <button (click)='printing=false'>Edit</button>
</div>
<div [hidden]='step!=1 || printing'>

  <table #printPreview>
    <tr>
      <td valign='top'></td>
      <td>{{vars.names}}</td>
      <td style='padding-left: 25px;'>
        <button id='printBtn' class='print-button' (click)='print()'><i class='fa fa-print'></i>Print</button>
        <div style='padding-top: 15px;'>
          <strong>{{vars.title}}</strong>
        </div>

      </td>
    </tr>
    <tr>
      <td valign='top'>
        <table id='toolsTbl' class="tools">
          <tr *ngIf='vars.printer!="black"'>
            <td valign='top'>
              <div (click)="hue_alter('red')" class="colorBtn" style="background: red;"></div>
              <div (click)="hue_alter('blue')" class="colorBtn" style="background: blue;"></div>
              <div (click)="hue_alter('purple')" class="colorBtn" style="background: purple;"></div>
              <div (click)="hue_alter('orange')" class="colorBtn" style="background: orange;"></div>
              <div (click)="hue_alter('black')" class="colorBtn" style="background: black;"></div>
             
            </td>
            <td valign='top'>
              <div (click)="hue_alter('green')" class="colorBtn" style="background: green;"></div>
              <div (click)="hue_alter('brown')" class="colorBtn" style="background: brown;"></div>
              <div (click)="hue_alter('yellow')" class="colorBtn" style="background: yellow;"></div>
              <div (click)="hue_alter('violet')" class="colorBtn" style="background: violet;"></div>
              <div (click)="hue_alter('white')" class="colorBtn" style="background: white; border: 1px solid black">
              </div>
             
            </td>
          </tr><tr *ngIf='vars.printer=="black"'>
            <td valign='top'>
              <div (click)="hue_alter('#D0CFD0')" class="colorBtn" style="background: #D0CFD0;"></div>
              <div (click)="hue_alter('#7B8280')" class="colorBtn" style="background: #7B8280;"></div>
              <div (click)="hue_alter('#424142')" class="colorBtn" style="background: #424142;"></div> 
              <div (click)="hue_alter('black')" class="colorBtn" style="background: black;"></div>
             
            </td>
            <td valign='top'>
              <div (click)="hue_alter('#BFBDBF')" class="colorBtn" style="background: #BFBDBF;"></div>
              <div (click)="hue_alter('#696669')" class="colorBtn" style="background: #696669;"></div>
              <div (click)="hue_alter('#262727')" class="colorBtn" style="background: #262727;"></div> 
              <div (click)="hue_alter('white')" class="colorBtn" style="background: white; border: 1px solid black">
              </div>
             
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              <div class='group'>
                <button (click)="action_undo()" id="undoButton" type="button" class="drawingbutton"><i
                  class='fa fa-undo'></i></button>
                  <button (click)="canvas_clear()" id="clearButton" type="button" class="drawingbutton"><i
                    class='fa fa-trash'></i></button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              <div class='group'>
                <div class='draw-size' (click)='draw_width=2'>
                  <div class='dot1'></div>
                </div>
                <div class='draw-size' (click)='draw_width=8'>
                  <div class='dot2'></div>
                </div>
                <div class='draw-size' (click)='draw_width=12'>
                  <div class='dot3'></div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </td>
      <td id='canvasTd'>
        <canvas class="cool-canvas" id="canvas" width="300" height="300" (touchstart)='start($event)'
          (touchmove)='draw($event)' (mousedown)='start($event)' (mousemove)='draw($event)' (touchend)='stop($event)'
          (mouseup)='stop($event)' (mouseout)='stop($event)'></canvas>



      </td>
      <td valign='top' style='padding-left:20px;'>
        <textarea style='width: 350px;
        height: 127px; '></textarea>
        <label>Explanation of Graffiti</label>
        <textarea style='width: 100%;
        height: 128px;'></textarea>
      </td>
    </tr>
    <tr>
    <tr>
      <td valign='top'></td>
      <td colspan='2' style='
      padding-top: 20px; '>
        <label>Significance of Graffiti to Text</label>
        <textarea style='width:100%; height:200px;'></textarea>
      </td>
    </tr>
  </table>

</div>