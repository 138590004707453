export class CubeCreatorFile {

    // step 1 inputs
    name?: string;
    type?: string;

    // bio cube inputs
    bioName?: string;
    bioTime?: string;
    bioPlace?: string;
    bioBackground?: string;
    bioPersonality?: string;
    bioSignificance?: string;
    bioObstacles?: string;
    bioQuote?: string;

    //mystery cube inputs
    mysterySetting?: string;
    mysteryDetective?: string;
    mysteryCrime?: string;
    mysteryVictim?: string;
    mysteryClues?: string;
    mysterySolution?: string;

    //story cube inputs
    storyCharacters?: string;
    storySetting?: string;
    storyConflict?: string;
    storyResolution?: string;
    storyTheme?: string;
    storyFavorite?: string;

    //create-your-own-cube inputs
    createQuestion?: string;
    createResponse?: string;
    createQuestion2?: string;
    createResponse2?: string;
    createQuestion3?: string;
    createResponse3?: string;
    createQuestion4?: string;
    createResponse4?: string;
    createQuestion5?: string;
    createResponse5?: string;
    createQuestion6?: string;
    createResponse6?: string;
}
