import { AbcMatchImageMatch } from "./AbcMatchImageMatch";

export class AbcMatchImageMatches {
    static matches: AbcMatchImageMatch[] = [];

    public static initMatches = (() => {
        let matchAlphabet = "abcdefghijklmnopqrstuvwyz".split("");

        for (let letter of matchAlphabet) {
            AbcMatchImageMatches.matches.push(
                new AbcMatchImageMatch(`../../../assets/abc-match/am-matching-${letter}-letter.png`, `../../../assets/abc-match/am-matching-${letter}-picture.png`)
            )
        }
    })();
}