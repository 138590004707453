import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { range } from 'rxjs';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { HaikuPoemInteractiveFile } from 'src/app/model/HaikuPoemInteractiveFile';
import { DragUtilService } from 'src/app/service/drag-util.service';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-haiku-poem-interactive',
  templateUrl: './haiku-poem-interactive.component.html',
  styleUrls: ['./haiku-poem-interactive.component.scss']
})
export class HaikuPoemInteractiveComponent implements OnInit {

  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  previewImage: any;

  step: number = 1;

  showInfo: boolean = false;

  showInspired: boolean = false;
  showSyllable: boolean = false;
  showAha: boolean = false;

  interactive: HaikuPoemInteractiveFile = new HaikuPoemInteractiveFile();

  nameError: boolean = false;

  startOverConfirm: boolean = false;

  background: string = 'tree';
  font: string = 'japanese-brush';

  constructor(private dragUtil: DragUtilService, private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
  }

  initInteractive() {
    if (this.interactive.name) {
      this.step++;
    }
    else {
      this.nameError = true;
    }
  }

  restart() {
    debugger;
    for (let wordSyllable of this.interactive.wordSyllables) {
      wordSyllable = {word: '', syllables: null};
    }

    this.interactive.title = '';
    this.interactive.poemLine1 = '';
    this.interactive.poemLine2 = '';
    this.interactive.poemLine3 = '';

    this.step = 1;
    this.startOverConfirm = false;
    console.log(this.interactive.wordSyllables)
  }

  finishPoem() {
    this.interactive.elements = [];
    let elem1 = this.dragUtil.createElement(this.interactive.elements);
    elem1.text = this.interactive.poemLine1;
    elem1.x = 92;
    elem1.y = 180;

    let elem2 = this.dragUtil.createElement(this.interactive.elements);
    elem2.text = this.interactive.poemLine2;
    elem2.x = 92;
    elem2.y = 260;


    let elem3 = this.dragUtil.createElement(this.interactive.elements);
    elem3.text = this.interactive.poemLine3;
    elem3.x = 92;
    elem3.y = 350;


    this.interactive.elements.push(elem1, elem2, elem3);

    this.step=4
  }

  cancelBuild() {
    this.interactive.elements = [];
    this.step--;
  }

  initPrint() {
    for (let elem of this.interactive.elements) {
      elem.selected = false;
    }

    this.step++;
    this.previewImage = null;
    let that = this;
    setTimeout(async () => {
      this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Landscape);
    }, 100);
  }

  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Landscape);
  }

  save() {
    this.fileUtil.save(this.interactive, 'HaikuPoem.json')
  }

  fileInput: any;
  openPoem(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.interactive = JSON.parse(json);
        this.initInteractive();
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }
}
