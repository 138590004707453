import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardFictionalPerson extends TradingCard implements ITradingCard {


    descriptionFields: TradingCardField[] = [
        new TradingCardField("Source", "Where do you read about or see __topic__"),
        new TradingCardField("Appearance", "What does __topic__ look like?"),
        new TradingCardField("Personality", "How does __topic__ act (e.g.m funny, shy, daring)")
    ];

    insightsFields: TradingCardField[] = [
        new TradingCardField("Thoughts", "What are __topic__'s most important thoughts"),
        new TradingCardField("Feelings", "What are __topic__'s most important feelings?")
    ];

    developmentFields: TradingCardField[] = [
        new TradingCardField("Problem", "What is __topic__'s problem or challenge at the beginning of the story?"),
        new TradingCardField("Goal", "What does __topic__ want to happen by the end of the story?"),
        new TradingCardField("Outcome", "Does __topic__ succeed? What happens as a result?")
    ];

    memorableInteractionsFields: TradingCardField[] = [
        new TradingCardField("Quote", "What memorable thing did __topic__ say?"),
        new TradingCardField("Action", "What was the one thing __topic__ did that really got your attention?"),
        new TradingCardField("Interactions", "How did __topic__ get along with other characters?")
    ];

    pesonalConnectionFields: TradingCardField[] = [
        new TradingCardField("Personal Connection", "Do you like __topic__? Why or why not? Who does __topic__ remind you of and why?")
    ];

    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Insights", this.insightsFields),
        new TradingCardSection("Development", this.developmentFields),
        new TradingCardSection("Memorable Interactions", this.memorableInteractionsFields),
        new TradingCardSection("Personal Connection", this.pesonalConnectionFields)

    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Fictional Person');
    }
}
