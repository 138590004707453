import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DragElement } from "../../model/DragElement";
import * as _ from "lodash";
import { DragUtilService } from "src/app/service/drag-util.service";
import { CirclePlotFile } from "src/app/model/CirclePlotFile";
import { FileUtilService } from "src/app/service/file-util.service";
import { PrintOrientation } from "src/app/enum/PrintOrientation";
import { PrintService } from "src/app/service/print.service";

@Component({
  selector: "app-circle-plot",
  templateUrl: "./circle-plot.component.html",
  styleUrls: ["./circle-plot.component.scss"],
})
export class CirclePlotComponent implements OnInit {
  @ViewChild("plotCanvas", null) plotCanvas: ElementRef;
  @ViewChild("printCanvas", null) printCanvas: ElementRef;
  @ViewChild("printPreview", null) printPreview: ElementRef;
  @ViewChild("printPage2Canvas", null) printPage2Canvas: ElementRef;

  // Selection
  step: number = 1;
  now: Date = new Date();
  vars: CirclePlotFile = new CirclePlotFile();
  nameRequiredError: boolean = false;
  titleRequiredError: boolean = false;
  previewImage = false;

  //errors
  emptyLabelError = false;

  constructor(
    private dragUtil: DragUtilService,
    private fileUtil: FileUtilService,
    private printer: PrintService
  ) {}

  ngOnInit() {}
  moveEvent($event: MouseEvent, elem: DragElement) {}
  addLabel() {
    let elem = this.dragUtil.createElement(this.vars.elements);
    elem.type = "text";
    this.vars.editId = elem.id;
    this.vars.elements.push(elem);
    this.editLabel(elem);
  }

  // Clear and start over
  reset() {
    this.vars.inputDiagrammedBy = "";
    this.vars.inputTitle = "";
    this.step = 1;
    this.vars.elements = [];
    this.clearSelection();
  }

  prnt: any;
  async print() {
    this.printer.print(
      [this.printCanvas, this.printPage2Canvas],
      PrintOrientation.Portrait
    );
  }
  async export() {
    this.printer.export(
      [this.printCanvas, this.printPage2Canvas],
      "CirclePlot.pdf",
      PrintOrientation.Portrait
    );
  }
  save() {
    this.fileUtil.save(this.vars, "CirclePlot.json");
  }

  fileInput: any;
  openPlot(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json);
        this.nextStep();
        this.fileInput = null;
      } catch (e) {
        alert("File cannot be opened");
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  // Navigate to previous step
  prevStep() {
    this.step--;
  }

  // navigate to next step
  async nextStep() {
    this.nameRequiredError = false;
    this.titleRequiredError = false;
    switch (this.step) {
      case 1:
        if (!this.vars.inputDiagrammedBy) {
          this.nameRequiredError = true;
          return;
        }
        if (!this.vars.inputTitle) {
          this.titleRequiredError = true;
          return;
        }
        break;
    }
    this.step++;
    if (this.step == 3) {
      this.previewImage =null;
      this.dragUtil
        .initPrint(
          this.vars.elements,
          "plotCanvas",
          "../../../assets/circle-plot/circle-plot-canvas.png",
          300,
          100,
          300,
          300,
          300
        )
        .then(async (x) => {
            this.previewImage = await this.printer.initPrintPreview(
              [this.printCanvas, this.printPage2Canvas],
              PrintOrientation.Portrait
            );

        });
    }
    this.vars.now = new Date();
  }

  // Save the event
  saveLabel() {
    // clear error
    this.emptyLabelError = false;

    // Ensure event name is entered.
    if (!this.vars.inputLabelText) {
      this.emptyLabelError = true;
      return;
    }
    // update event
    let elem = _.find(this.vars.elements, (x) => x.id == this.vars.editId);
    elem.text = this.vars.inputLabelText;
    elem.description = this.vars.inputLabelDescription;
    elem.selected = true;

    this.clearSelection();
    this.vars.editingLabel = false;
  }

  // clear the selected event
  clearSelection() {
    this.vars.inputLabelText = null;
    this.vars.inputLabelDescription = null;
    this.vars.editId = null;
  }

  // edit the event
  editLabel(elem: DragElement) {
    DragElement.select(elem);
    this.vars.editId = elem.id;
    this.vars.inputLabelDescription = elem.description;
    this.vars.inputLabelText = elem.text;
    this.vars.editingLabel = true;
  }
}
