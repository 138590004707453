import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { PictureMatchImages } from 'src/app/model/PictureMatch/PictureMatchImages';
import { PictureMatchImageArrayList } from 'src/app/model/PictureMatch/PictureMatchImageArrayList';
import { PictureMatchBox } from 'src/app/model/PictureMatch/PictureMatchBox';
import { PictureMatchBoxLetterImages } from 'src/app/model/PictureMatch/PictureMatchBoxletterImages';

import * as _ from 'lodash';
import { PrintService } from '../../service/print.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';

@Component({
  selector: 'app-picture-match',
  templateUrl: './picture-match.component.html',
  styleUrls: ['./picture-match.component.scss']
})
export class PictureMatchComponent implements OnInit {
  @ViewChild('beginning_letter_sounds_intro', {static:false}) public audio1: ElementRef;
  @ViewChild('enter_name_page_2', {static:false}) public audio2: ElementRef;

  audio_1: HTMLMediaElement;
  audio_2: HTMLMediaElement;
  printing = false;

  page: number = 0;
  previousPage: number;
  name: string;
  logo: number;
  
  alphabet: Array<string> = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  lastInput: string;
  letter1: string = "";
  letter2: string = "";
  letter3: string = "";
  letter4: string = "";
  letter5: string = "";

  matchingInputs: boolean = false;
  inputsFilled: boolean = true;
  nameFilled: boolean = true;
  rand: PictureMatchImages[] = [];
  gameImages: Array<PictureMatchImages> = [];
  gameImageIterator: number = 0;

  boxes: PictureMatchBox[] = [];
  rightOrWrong: boolean = false;

  randomWrongSound: string;
  wrongSounds: string[] = [
  '../../../assets/picture-match/reaction_bad_1.mp3', 
  '../../../assets/picture-match/reaction_bad_2.mp3', 
  '../../../assets/picture-match/reaction_bad_3.mp3',
  '../../../assets/picture-match/reaction_bad_4.mp3', 
  '../../../assets/picture-match/reaction_bad_5.mp3'];

  randomRightSound: string;
  rightSounds: string[] = [
    '../../../assets/picture-match/reaction_good_1.mp3',
    '../../../assets/picture-match/reaction_good_2.mp3',
    '../../../assets/picture-match/reaction_good_3.mp3',
    '../../../assets/picture-match/reaction_good_4.mp3',
    '../../../assets/picture-match/reaction_good_5.mp3',
    '../../../assets/picture-match/reaction_good_6.mp3'
  ];

  letterImages: Array<PictureMatchBoxLetterImages> = [
    new PictureMatchBoxLetterImages(),
    new PictureMatchBoxLetterImages(),
    new PictureMatchBoxLetterImages(),
    new PictureMatchBoxLetterImages(),
    new PictureMatchBoxLetterImages()
  ];



  constructor(private fileUtil: PrintService) { }

  ngOnInit() {
  }

  changePage(n: number) {
    this.previousPage = this.page;
    this.page = n;
  }

  mainButton() {
    this.letter1 = "";
    this.letter2 = "";
    this.letter3 = "";
    this.letter4 = "";
    this.letter5 = "";
    this.name = "";
    this.matchingInputs = false;
    this.nameFilled = true;
    this.inputsFilled = true;
    this.changePage(0);
  }

  playNextSound(n: any) {
    n.load();
    setTimeout(() => {
      n.play();
    }, 2000);
  }

  nameBeingFilled() {
    if(this.name.length > 0) {
      this.nameFilled = true;
    }
  }

  randomLetters() {
    let temp: string;
    let i: number =0;
    while(i<5) {
      temp = this.alphabet[Math.floor(Math.random() * (25 - 0 + 1)) + 0];
      if(this.arrayCompare(temp, 6) == false) {
        switch (i) {
          case 0:
            this.letter1 = temp;
            i++;
            break;
          case 1:
            this.letter2 = temp;
            i++;
            break;
          case 2:
            this.letter3 = temp;
            i++;
            break;
          case 3:
            this.letter4 = temp;
            i++;
            break;
          case 4:
            this.letter5 = temp;
            i++;
            break;
        }
      }
    }
    this.inputsFilled = true;
    this.matchingInputs = false;
    
  }

  get letters(): string[] {
    return [this.letter1, this.letter2, this.letter3, this.letter4, this.letter5];
  }

  changeFocus(input, value, next) {
    if(this.allInputsFull() == true) {
      this.inputsFilled = true;
    }
    this.lastInput = value;
    if(value.length == 0) {
      this.matchingInputs = false;
    }
    
    else if(!!next) {
      
      if(this.arrayCompare(value, input) == false) {
        this.matchingInputs = false;

        next.focus();
      }
      else {
        this.matchingInputs = true;
        
      }
    }
  }

  arrayCompare(value, input): boolean {
    let result: boolean = false;
    let count: number = 0;
    for(let i=0; i< this.letters.length; i++) {

      if(i!= input && value === this.letters[i])
      {
        count++;
      }
    }
    if(count > 0) {
      result = true;
    }
    return result;
  }

  allInputsFull(): boolean {
    return this.letters.every(x => x.length > 0);
  }

  get isNamePopulated(): boolean { return this.nameFilled == true && !!this.name && this.name.length > 0; }
  get allLettersAreValid(): boolean { return this.allInputsFull() && !this.matchingInputs; }

  inputValidation(): boolean {
    if(!this.name) {
      this.nameFilled = false;
    }

    if(this.allInputsFull() == false) {
      this.inputsFilled = false;
    }

    const allValid: boolean = this.isNamePopulated && this.allLettersAreValid;
    return allValid;
  }


  startBeginning() {
    if(this.inputValidation()) {
      this.beginningLetterGameSetup(this.letters);
      this.loadBoxes(this.letters);
      this.logo = 1;
      this.changePage(2);
      
    }
  }

  loadBoxes(letters: string[]) {
    for(let i =0; i<letters.length; i++) {
      this.boxes.push(new PictureMatchBox(letters[i], '../../../assets/picture-match/open_box.png'));
      this.letterImages[i].letter = letters[i];
    }
  }

  beginningLetterGameSetup(letters: string[]) {
    let filteredImages: PictureMatchImages[] = [];
    let unrandomizedImages: PictureMatchImages[] = [];
    let randNum: number;
    let n: number = 0;
    for(let i=0; i<letters.length; i++) {
      n=0;
      filteredImages = PictureMatchImageArrayList.images.filter(x=> x.beginningLetter === letters[i]); 
      if(filteredImages.length >= 3) {
        while(n<3) {
          randNum = Math.floor(Math.random() * ((filteredImages.length-1) - 0 + 1)) + 0;
          if(!_.find(unrandomizedImages, x=> x.imageName === filteredImages[randNum].imageName)) {
            unrandomizedImages.push(filteredImages[randNum]);
            n++;
          }

        }
      }
      else {
        unrandomizedImages.push(...filteredImages);
      }
    }
    
    this.gameImages = this.shuffle(unrandomizedImages);
  }
  
  shortVowelGameSetup(letters: string[]) {
    let filteredImages: PictureMatchImages[] = [];
    let unrandomizedImages: PictureMatchImages[] = [];
    let randNum: number;
    let n: number = 0;
    for(let i=0; i<letters.length; i++) {
      n=0;
      filteredImages = PictureMatchImageArrayList.images.filter(x=> x.shortVowel === letters[i]); 
      if(filteredImages.length >= 3) {
        while(n<3) {
          randNum = Math.floor(Math.random() * ((filteredImages.length-1) - 0 + 1)) + 0;
          if(!_.find(unrandomizedImages, x=> x.imageName === filteredImages[randNum].imageName)) {
            unrandomizedImages.push(filteredImages[randNum]);
            n++;
          }

        }
      }
      else {
        unrandomizedImages.push(...filteredImages);
      }
    }
    
    this.gameImages = this.shuffle(unrandomizedImages);
  }

  longVowelGameSetup(letters: string[]) {
    let filteredImages: PictureMatchImages[] = [];
    let unrandomizedImages: PictureMatchImages[] = [];
    let randNum: number;
    let n: number = 0;
    for(let i=0; i<letters.length; i++) {
      n=0;
      filteredImages = PictureMatchImageArrayList.images.filter(x=> x.longVowel === letters[i]); 
      if(filteredImages.length >= 3) {
        while(n<3) {
          randNum = Math.floor(Math.random() * ((filteredImages.length-1) - 0 + 1)) + 0;
          if(!_.find(unrandomizedImages, x=> x.imageName === filteredImages[randNum].imageName)) {
            unrandomizedImages.push(filteredImages[randNum]);
            n++;
          }

        }
      }
      else {
        unrandomizedImages.push(...filteredImages);
      }
    }
    
    this.gameImages = this.shuffle(unrandomizedImages);
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }
  
  resetBoxStates() {
    for(let i=0; i<this.boxes.length; i++) {
      this.boxes[i].displayImage = '../../../assets/picture-match/open_box.png'
    }
  }

  increaseGameImageIterator() {
    this.gameImageIterator++;
    if(this.gameImageIterator == this.gameImages.length) {
      this.changePage(8);
    }
  }


  changeRightOrWrong() {
    this.rightOrWrong = false;
    this.gameImageIterator++;
  }

  checkBeginningMatch(n: number, wrongSound: any, rightSound: any) {
    if(this.boxes[n].letter === this.gameImages[this.gameImageIterator].beginningLetter) {
      this.letterImages[n].images.push(this.gameImages[this.gameImageIterator].imageURL);
      this.rightOrWrong = true;
      this.resetBoxStates();
      this.randomRightSound = this.rightSounds[Math.floor(Math.random() * (this.rightSounds.length-1) - 0 + 1) + 0];
      rightSound.load();
      rightSound.play();    
      setTimeout(() => {
        this.rightOrWrong = false;
        this.increaseGameImageIterator();
      }, 3000)
      
    }
    else {
      this.boxes[n].displayImage = '../../../assets/picture-match/closed_box.png';
      this.randomWrongSound = this.wrongSounds[Math.floor(Math.random() * (this.wrongSounds.length-1) - 0 + 1) + 0];     
      wrongSound.load();
      wrongSound.play();
    }
  }

  checkShortVowelMatch(n: number, wrongSound: any, rightSound: any) {
    if(this.boxes[n].letter === this.gameImages[this.gameImageIterator].shortVowel) {
      this.letterImages[n].images.push(this.gameImages[this.gameImageIterator].imageURL);
      this.rightOrWrong = true;
      this.resetBoxStates();
      this.randomRightSound = this.rightSounds[Math.floor(Math.random() * (this.rightSounds.length-1) - 0 + 1) + 0];
      rightSound.load();
      rightSound.play();    
      setTimeout(() => {
        this.rightOrWrong = false;
        this.increaseGameImageIterator();
      }, 3000)
      
    }
    else {
      this.boxes[n].displayImage = '../../../assets/picture-match/closed_box.png';
      this.randomWrongSound = this.wrongSounds[Math.floor(Math.random() * (this.wrongSounds.length-1) - 0 + 1) + 0];     
      wrongSound.load();
      wrongSound.play();
    }
  }

  checkLongVowelMatch(n: number, wrongSound: any, rightSound: any) {
    if(this.boxes[n].letter === this.gameImages[this.gameImageIterator].longVowel) {
      this.letterImages[n].images.push(this.gameImages[this.gameImageIterator].imageURL); 
      this.rightOrWrong = true;
      this.resetBoxStates();
      this.randomRightSound = this.rightSounds[Math.floor(Math.random() * (this.rightSounds.length-1) - 0 + 1) + 0];
      rightSound.load();
      rightSound.play();    
      setTimeout(() => {
        this.rightOrWrong = false;
        this.increaseGameImageIterator();
      }, 3000)
      
    }
    else {
      this.boxes[n].displayImage = '../../../assets/picture-match/closed_box.png';
      this.randomWrongSound = this.wrongSounds[Math.floor(Math.random() * (this.wrongSounds.length-1) - 0 + 1) + 0];     
      wrongSound.load();
      wrongSound.play();
    }
  }

  selectShortVowel() {
    this.letter1 = "a";
    this.letter2 = "e";
    this.letter3 = "i";
    this.letter4 = "o";
    this.letter5 = "u";
    this.changePage(3);
  }

  selectLongVowel() {
    this.letter1 = "a";
    this.letter2 = "e";
    this.letter3 = "i";
    this.letter4 = "o";
    this.letter5 = "u";
    this.changePage(5);
  }

  startShortVowel() {
    if(this.inputValidation()) {
      this.shortVowelGameSetup(this.letters);
      this.loadBoxes(this.letters);
      this.logo = 2;
      this.changePage(4);
    }
    
  }

  startLongVowel() {
    if(this.inputValidation()) {
      this.longVowelGameSetup(this.letters);
      this.loadBoxes(this.letters);
      this.logo = 3;
      this.changePage(6);
    }
  }

  returnToGame() {
    this.changePage(this.previousPage);
  }

  startOver() {
    this.gameImageIterator = 0;
    this.rand = [];
    this.boxes = [];
    this.gameImages = [];
    this.mainButton();
  }

  goToPrint() { 
    this.changePage(9)
  }

  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  async print() {
    this.printing = true;
    await this.fileUtil.print([this.printCanvas]);
    this.printing = false;
  }

  fetchVideoAndPlay(url: string, audio: any) {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      audio.srcObject = blob;
      return audio.play();
    })
    .then(_ => {
      // Video playback started ;)
    })
    .catch(e => {
      // Video playback failed ;(
    })
  }


  initGame(step: number) {

  }
}
