import { PictureMatchImages } from "./PictureMatchImages";

export class PictureMatchImageArrayList {
    static images: PictureMatchImages[] = [
    new PictureMatchImages("Alligator", "a", "a", "a", "../../../assets/picture-match/alligator.mp3", "../../../assets/picture-match/alligator.png"), 
    new PictureMatchImages("Apple", "a", null, "a", "../../../assets/picture-match/apple.mp3", "../../../assets/picture-match/apple.png"),
    new PictureMatchImages("Astronaut", "a", null, "a", "../../../assets/picture-match/astronaut.mp3", "../../../assets/picture-match/astronaut.png"),
    
    new PictureMatchImages("Balloon", "b", "o", "a", "../../../assets/picture-match/balloon.mp3", "../../../assets/picture-match/balloon.png"),
    new PictureMatchImages("Bell", "b", null, "e", "../../../assets/picture-match/bell.mp3", "../../../assets/picture-match/bell.png"),
    new PictureMatchImages("Bike", "b", "i", null, "../../../assets/picture-match/bike.mp3", "../../../assets/picture-match/bike.png"),
    
    new PictureMatchImages("Bone", "b", "o", null, "../../../assets/picture-match/bone.mp3", "../../../assets/picture-match/bone.png"),
    new PictureMatchImages("Book", "b", null, null, "../../../assets/picture-match/book.mp3", "../../../assets/picture-match/book.png"),
    new PictureMatchImages("Bus", "b", null, "u", "../../../assets/picture-match/bus.mp3", "../../../assets/picture-match/bus.png"),
    
    new PictureMatchImages("Cake", "c", "a", null, "../../../assets/picture-match/cake.mp3", "../../../assets/picture-match/cake.png"),
    new PictureMatchImages("Car", "c", null, "a", "../../../assets/picture-match/car.mp3", "../../../assets/picture-match/car.png"),
    new PictureMatchImages("Cat", "c", null, "a", "../../../assets/picture-match/cat.mp3", "../../../assets/picture-match/cat.png"),
    
    new PictureMatchImages("Cookie", "c", null, null, "../../../assets/picture-match/cookie.mp3", "../../../assets/picture-match/cookie.png"),
    new PictureMatchImages("Cup", "c", null, "c", "../../../assets/picture-match/cup.mp3", "../../../assets/picture-match/cup.png"),
    new PictureMatchImages("Dog", "d", null, "o", "../../../assets/picture-match/dog.mp3", "../../../assets/picture-match/dog.png"),
    
    new PictureMatchImages("Door", "d", "o", null, "../../../assets/picture-match/door.mp3", "../../../assets/picture-match/door.png"),
    new PictureMatchImages("Duck", "d", null, "u", "../../../assets/picture-match/duck.mp3", "../../../assets/picture-match/duck.png"),
    new PictureMatchImages("Egg", "e", null, "e", "../../../assets/picture-match/egg.mp3", "../../../assets/picture-match/egg.png"),
    
    new PictureMatchImages("Elephant", "e", null, "e", "../../../assets/picture-match/elephant.mp3", "../../../assets/picture-match/elephant.png"),
    new PictureMatchImages("Elevator", "e", "a", "e", "../../../assets/picture-match/elevator.mp3", "../../../assets/picture-match/elevator.png"),
    new PictureMatchImages("Eraser", "e", "e", "a", "../../../assets/picture-match/eraser.mp3", "../../../assets/picture-match/eraser.png"),
    
    new PictureMatchImages("Fan", "f", null, "a", "../../../assets/picture-match/fan.mp3", "../../../assets/picture-match/fan.png"),
    new PictureMatchImages("Feather", "f", null, "e", "../../../assets/picture-match/feather.mp3", "../../../assets/picture-match/feather.png"),
    new PictureMatchImages("Fish", "f", null, "i", "../../../assets/picture-match/fish.mp3", "../../../assets/picture-match/fish.png"),
    
    new PictureMatchImages("Flag", "f", null, "a", "../../../assets/picture-match/flag.mp3", "../../../assets/picture-match/flag.png"),
    new PictureMatchImages("Fox", "f", null, "o", "../../../assets/picture-match/fox.mp3", "../../../assets/picture-match/fox.png"),
    new PictureMatchImages("Frog", "f", null, "o", "../../../assets/picture-match/frog.mp3", "../../../assets/picture-match/frog.png"),
    
    new PictureMatchImages("Fruit", "f", "u", null, "../../../assets/picture-match/fruit.mp3", "../../../assets/picture-match/fruit.png"),
    new PictureMatchImages("Glue", "g", "u", null, "../../../assets/picture-match/glue.mp3", "../../../assets/picture-match/glue.png"),
    new PictureMatchImages("Goat", "g", "o", null, "../../../assets/picture-match/goat.mp3", "../../../assets/picture-match/goat.png"),
    
    new PictureMatchImages("Grapes", "g", "a", null, "../../../assets/picture-match/grapes.mp3", "../../../assets/picture-match/grapes.png"),
    new PictureMatchImages("Guitar", "g", null, "a", "../../../assets/picture-match/guitar.mp3", "../../../assets/picture-match/guitar.png"),
    new PictureMatchImages("Gum", "g", null, "u", "../../../assets/picture-match/gum.mp3", "../../../assets/picture-match/gum.png"),
    
    new PictureMatchImages("Hammer", "h", null, "a", "../../../assets/picture-match/hammer.mp3", "../../../assets/picture-match/hammer.png"),
    new PictureMatchImages("Heart", "h", null, "a", "../../../assets/picture-match/heart.mp3", "../../../assets/picture-match/heart.png"),
    new PictureMatchImages("Horse", "h", "o", null, "../../../assets/picture-match/horse.mp3", "../../../assets/picture-match/horse.png"),
    
    new PictureMatchImages("Icecream", "i", "e", null, "../../../assets/picture-match/icecream.mp3", "../../../assets/picture-match/icecream.png"),
    new PictureMatchImages("Iceskate", "i", "i", null, "../../../assets/picture-match/iceskate.mp3", "../../../assets/picture-match/iceskate.png"),
    new PictureMatchImages("Icicle", "i", "i", "i", "../../../assets/picture-match/icicle.mp3", "../../../assets/picture-match/icicle.png"),
    
    new PictureMatchImages("Jar", "j", null, "a", "../../../assets/picture-match/jar.mp3", "../../../assets/picture-match/jar.png"),
    new PictureMatchImages("Jeep", "j", "e", null, "../../../assets/picture-match/jeep.mp3", "../../../assets/picture-match/jeep.png"),
    new PictureMatchImages("Jump", "j", null, "u", "../../../assets/picture-match/jump.mp3", "../../../assets/picture-match/jump.png"),
    
    new PictureMatchImages("Kangaroo", "k", "a", "a", "../../../assets/picture-match/kangaroo.mp3", "../../../assets/picture-match/kangaroo.png"),
    new PictureMatchImages("Key", "k", "e", null, "../../../assets/picture-match/key.mp3", "../../../assets/picture-match/key.png"),
    new PictureMatchImages("King", "k", null, "i", "../../../assets/picture-match/king.mp3", "../../../assets/picture-match/king.png"),
    
    new PictureMatchImages("Kite", "k", "i", null, "../../../assets/picture-match/kite.mp3", "../../../assets/picture-match/kite.png"),
    new PictureMatchImages("Ladder", "l", null, "a", "../../../assets/picture-match/ladder.mp3", "../../../assets/picture-match/ladder.png"),
    new PictureMatchImages("Leaf", "l", "e", null, "../../../assets/picture-match/leaf.mp3", "../../../assets/picture-match/leaf.png"),
    
    new PictureMatchImages("Lion", "l", "i", "o", "../../../assets/picture-match/lion.mp3", "../../../assets/picture-match/lion.png"),
    new PictureMatchImages("Lips", "l", null, "i", "../../../assets/picture-match/lips.mp3", "../../../assets/picture-match/lips.png"),
    new PictureMatchImages("Map", "m", null, "a", "../../../assets/picture-match/map.mp3", "../../../assets/picture-match/map.png"),
    
    new PictureMatchImages("Monkey", "m", "e", "o", "../../../assets/picture-match/monkey.mp3", "../../../assets/picture-match/monkey.png"),
    new PictureMatchImages("Moon", "m", null, null, "../../../assets/picture-match/moon.mp3", "../../../assets/picture-match/moon.png"),
    new PictureMatchImages("Mop", "m", null, "o", "../../../assets/picture-match/mop.mp3", "../../../assets/picture-match/mop.png"),
    
    new PictureMatchImages("Mouse", "m", null, null, "../../../assets/picture-match/mouse.mp3", "../../../assets/picture-match/mouse.png"),
    new PictureMatchImages("Nest", "n", null, "e", "../../../assets/picture-match/nest.mp3", "../../../assets/picture-match/nest.png"),
    new PictureMatchImages("Net", "n", null, "e", "../../../assets/picture-match/net.mp3", "../../../assets/picture-match/net.png"),
    
    new PictureMatchImages("Nine", "n", "i", null, "../../../assets/picture-match/nine.mp3", "../../../assets/picture-match/nine.png"),
    new PictureMatchImages("Nose", "n", "i", null, "../../../assets/picture-match/nose.mp3", "../../../assets/picture-match/nose.png"),
    new PictureMatchImages("Octopus", "o", "o", null, "../../../assets/picture-match/octopus.mp3", "../../../assets/picture-match/octopus.png"),
    
    new PictureMatchImages("Orange", "o", "o", "a", "../../../assets/picture-match/orange.mp3", "../../../assets/picture-match/orange.png"),
    new PictureMatchImages("Ostritch", "o", null, "o", "../../../assets/picture-match/ostrich.mp3", "../../../assets/picture-match/ostrich.png"),
    new PictureMatchImages("Pen", "p", null, "e", "../../../assets/picture-match/pen.mp3", "../../../assets/picture-match/pen.png"),
    
    new PictureMatchImages("Pig", "p", null, "i", "../../../assets/picture-match/pig.mp3", "../../../assets/picture-match/pig.png"),
    new PictureMatchImages("Plane", "p", "a", null, "../../../assets/picture-match/plane.mp3", "../../../assets/picture-match/plane.png"),
    new PictureMatchImages("Pot", "p", null, "o", "../../../assets/picture-match/pot.mp3", "../../../assets/picture-match/pot.png"),
    
    new PictureMatchImages("Quarter", "q", null, null, "../../../assets/picture-match/quarter.mp3", "../../../assets/picture-match/quarter.png"),
    new PictureMatchImages("Queen", "q", "e", null, "../../../assets/picture-match/queen.mp3", "../../../assets/picture-match/queen.png"),
    new PictureMatchImages("Question", "q", null, "i", "../../../assets/picture-match/question.mp3", "../../../assets/picture-match/question.png"),
    
    new PictureMatchImages("Rainbow", "r", "a", null, "../../../assets/picture-match/rainbow.mp3", "../../../assets/picture-match/rainbow.png"),
    new PictureMatchImages("Rake", "r", "a", null, "../../../assets/picture-match/rake.mp3", "../../../assets/picture-match/rake.png"),
    new PictureMatchImages("Ring", "r", null, "i", "../../../assets/picture-match/ring.mp3", "../../../assets/picture-match/ring.png"),
    
    new PictureMatchImages("Robot", "r", "o", "o", "../../../assets/picture-match/robot.mp3", "../../../assets/picture-match/robot.png"),
    new PictureMatchImages("Rock", "r", null, "o", "../../../assets/picture-match/rock.mp3", "../../../assets/picture-match/rock.png"),
    new PictureMatchImages("Rose", "r", "o", null, "../../../assets/picture-match/rose.mp3", "../../../assets/picture-match/rose.png"),
    
    new PictureMatchImages("Ruler", "r", "u", "e", "../../../assets/picture-match/ruler.mp3", "../../../assets/picture-match/ruler.png"),
    new PictureMatchImages("Saw", "s", null, "a", "../../../assets/picture-match/saw.mp3", "../../../assets/picture-match/saw.png"),
    new PictureMatchImages("Seal", "s", "e", null, "../../../assets/picture-match/seal.mp3", "../../../assets/picture-match/seal.png"),
    
    new PictureMatchImages("Sheep", "s", "e", null, "../../../assets/picture-match/sheep.mp3", "../../../assets/picture-match/sheep.png"),
    new PictureMatchImages("Sink", "s", null, "i", "../../../assets/picture-match/sink.mp3", "../../../assets/picture-match/sink.png"),
    new PictureMatchImages("Snake", "s", "a", null, "../../../assets/picture-match/snake.mp3", "../../../assets/picture-match/snake.png"),
    
    new PictureMatchImages("Sun", "s", null, "u", "../../../assets/picture-match/sun.mp3", "../../../assets/picture-match/sun.png"),
    new PictureMatchImages("Table", "t", "a", null, "../../../assets/picture-match/table.mp3", "../../../assets/picture-match/table.png"),
    new PictureMatchImages("Teeth", "t", "e", null, "../../../assets/picture-match/teeth.mp3", "../../../assets/picture-match/teeth.png"),
    
    new PictureMatchImages("Tent", "t", null, "e", "../../../assets/picture-match/tent.mp3", "../../../assets/picture-match/tent.png"),
    new PictureMatchImages("Tuba", "t", "u", "a", "../../../assets/picture-match/tuba.mp3", "../../../assets/picture-match/tuba.png"),
    new PictureMatchImages("Turtle", "t", null, "u", "../../../assets/picture-match/turtle.mp3", "../../../assets/picture-match/turtle.png"),
    
    new PictureMatchImages("Umbrella", "u", null, "u", "../../../assets/picture-match/umbrella.mp3", "../../../assets/picture-match/umbrella.png"),
    new PictureMatchImages("Umpire", "u", "i", "u", "../../../assets/picture-match/umpire.mp3", "../../../assets/picture-match/umpire.png"),
    new PictureMatchImages("Up", "u", null, "u", "../../../assets/picture-match/up.mp3", "../../../assets/picture-match/up.png"),
    
    new PictureMatchImages("Vacuum", "v", "u", "a", "../../../assets/picture-match/vacuum.mp3", "../../../assets/picture-match/vacuum.png"),
    new PictureMatchImages("Violin", "v", "i", "i", "../../../assets/picture-match/violin.mp3", "../../../assets/picture-match/violin.png"),
    new PictureMatchImages("Volcano", "v", "a", "o", "../../../assets/picture-match/volcano.mp3", "../../../assets/picture-match/volcano.png"),
    
    new PictureMatchImages("Wagon", "w", null, "a", "../../../assets/picture-match/wagon.mp3", "../../../assets/picture-match/wagon.png"),
    new PictureMatchImages("Watch", "w", null, "a", "../../../assets/picture-match/watch.mp3", "../../../assets/picture-match/watch.png"),
    new PictureMatchImages("Watermelon", "w", null, "a", "../../../assets/picture-match/watermelon.mp3", "../../../assets/picture-match/watermelon.png"),
    
    new PictureMatchImages("Xray", "x", "a", null, "../../../assets/picture-match/xray.mp3", "../../../assets/picture-match/xray.png"),
    new PictureMatchImages("Yarn", "y", null, "a", "../../../assets/picture-match/yarn.mp3", "../../../assets/picture-match/yarn.png"),
    new PictureMatchImages("Yawn", "y", null, "a", "../../../assets/picture-match/yawn.mp3", "../../../assets/picture-match/yawn.png"),
    
    new PictureMatchImages("Yoyo", "y", "o", null, "../../../assets/picture-match/yoyo.mp3", "../../../assets/picture-match/yoyo.png"),
    new PictureMatchImages("Zebra", "z", "e", "a", "../../../assets/picture-match/zebra.mp3", "../../../assets/picture-match/zebra.png"),
    new PictureMatchImages("Zero", "z", "e", null, "../../../assets/picture-match/zero.mp3", "../../../assets/picture-match/zero.png"),
    
    new PictureMatchImages("Zipper", "z", null, "i", "../../../assets/picture-match/zipper.mp3", "../../../assets/picture-match/zipper.png"),
    new PictureMatchImages("Zoo", "z", null, null, "../../../assets/picture-match/zoo.mp3", "../../../assets/picture-match/zoo.png")
    
    ];
    
    
    
    
    
    
    constructor() {

    }

}
