import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-postcard',
  templateUrl: './postcard.component.html',
  styleUrls: ['./postcard.component.css']
})
export class PostcardComponent implements OnInit {

  vars: any = {};
  step = 0;
  stamps: string[] = [];
  fileInput: any;
  showOpen = false;

  @ViewChild('printPage', null) printPage: ElementRef;
  constructor(private printer: PrintService, private fileUtil: FileUtilService) { 
    for(var i=1; i<12; i++){
      let s: string = i.toString();
      if(s.length == 1) s = '0' + i;
      this.stamps.push(s);
    }
  }

  ngOnInit() {
  }

  nextStep(){
    if(!!this.vars.body) this.vars.bodyFormatted = this.vars.body.replace(/\r\n|\r|\n/g,"<br />");
    if(!!this.vars.imageDescription) this.vars.imageDescriptionFormatted = this.vars.imageDescription.replace(/\r\n|\r|\n/g,"<br />"); 
    if(!!this.vars.mailingAddress) this.vars.mailingAddressFormatted = this.vars.mailingAddress.replace(/\r\n|\r|\n/g,"<br />"); 

    this.step++;
  }

  setStamp(stamp: string){
    this.vars.stamp = stamp;
    this.nextStep();
  }
  print(){
    this.printer.print([this.printPage], PrintOrientation.Portrait, true, false, false);
  }
  save(){
    this.fileUtil.save(this.vars, 'postcard.json');
  }
 
  openPostcard(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json); 
        this.fileInput = null;
        this.step = 4;
        this.showOpen = false;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

}
