import { TradingCardSection } from "./TradingCardSection";
import { SafeUrl } from "@angular/platform-browser";
import { ITradingCard } from "./ITradingCard";
import { v4 as uuidv4 } from 'uuid';

export class TradingCard implements ITradingCard {
   id: string = uuidv4();
   image: Blob;
   imageUrl: SafeUrl;
   isCreateYourOwn: boolean = false;
   activeSection: number = 1;
   sections: TradingCardSection[];

   constructor(public name: string, public topic: string, public readonly type: string) {
   }
   
  
   next() {
      this.activeSection = (this.activeSection == 5) ? 1 : this.activeSection + 1;
   }

   prev() {
      this.activeSection = (this.activeSection == 1) ? 5 : this.activeSection - 1;
   }

   flip() {
      this.activeSection = (this.activeSection < 3) ? 3 : 1;
   }
   
   get displayedSections(): TradingCardSection[] {
      return this.activeSection < 3 ? this.sections.slice(0, 2) : this.sections.slice(2);
   }
   get currentSection(): TradingCardSection {
      return this.sections[this.activeSection - 1];
   }

}
