
export class FlipAChipDropLocations {

    locations: number[][][] = [
        [
            [462, 284],
            [321, 327],
            [283, 371],
            [595, 410]
        ], 

        [
            [407, 284],
            [398, 330],
            [502, 410],
            [533, 455]
        ],

        [
            [500, 284],
            [317, 327],
            [456, 372],
            [581, 412]
        ],

        [
            [647, 284],
            [492, 327],
            [485, 372],
            [312, 452]
        ], 

        [
            [566, 285],
            [283, 411],
            [391, 454],
            [655, 454]
        ], 

        [
            [328, 285],
            [412, 327],
            [280, 369],
            [322, 414]
        ]
    ]

    text: string[][] = [
        [
            "?",
            "?",
            "?",
            "?"
        ],

        [
            "?",
            "?",
            "?",
            "?"
        ],

        [
            "?",
            "?",
            "?",
            "?"
        ],

        [
            "?",
            "?",
            "?",
            "?"
        ],

        [
            "?",
            "?",
            "?",
            "?"
        ],

        [
            "?",
            "?",
            "?",
            "?"
        ]
    ]
}