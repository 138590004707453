import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardFictionalPlace extends TradingCard implements ITradingCard{

    descriptionFields: TradingCardField[] = [
        new TradingCardField("Source", "From what fictional work did you learn of __topic__?"),
        new TradingCardField("Location", "Where is __topic__?"),
        new TradingCardField("Appearance", "What does __topic__ look like?")
    ];

    majorEventsFields: TradingCardField[] = [
        new TradingCardField("Events", "What are the major events that occur at __topic__?"),
        new TradingCardField("Effects", "How do these events influence the characters or the story?")
    ];

    importanceFields: TradingCardField[] = [
        new TradingCardField("Significance", "What problem or challenge was __topic__ trying to solve?"),
        new TradingCardField("To Whom", "To whom is __topic__ important?")
    ];

    interestingFactsFields: TradingCardField[] = [
        new TradingCardField("Additional Information", "What other information stands out to you about __topic__?")
    ];

    personalConnectionFields: TradingCardField[] = [
        new TradingCardField("Personal Connection", "What are your impressions of__topic__? Where does __topic__ remind you of, and why? Would you like to visit? Why or why not?")  
    ];


    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Major Events", this.majorEventsFields),
        new TradingCardSection("Importance", this.importanceFields),
        new TradingCardSection("Interesting Facts", this.interestingFactsFields),
        new TradingCardSection("Personal Connection", this.personalConnectionFields)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Fictional Place');
    }
}
 