<div class='app-border'>
  <div class='title-bar'>
    <span>Cube Creator</span>
  </div>



  <!-------------------------------------------- Select Cube ---------------------------------------------->
  <div *ngIf="step==1" class='step'>
    <h1>Cube Creator</h1>
    <div class='cube-info'>
      <label>Enter your name:</label>
      <input type='text' (change)='nameRequiredError=false' [ngClass]="{'has-error':nameRequiredError}"
        [(ngModel)]='cubes.name'>
      <p>Then select which type of cube you would like to make by clicking one of the cubes below.</p>
    </div>
    <div>
      <div class='cube-type' (click)="selectCube('bio')">
        <div class="cube-label">
          <label>BIO CUBE</label>
        </div>

        <div class="cube-image">
          <img [src]='"assets/cube-creator/bio-cube.png"' class='bio-cube'>
        </div>

        <div class="cube-caption">Use after reading or before writing a biography or autobiography</div>

        <a class="planning-sheet" (click)='clickPlanningSheet($event)' target="_blank"
          href="assets/cube-creator/planning-bio.pdf">
          Planning Sheet
        </a>
      </div>

      <div class='cube-type' (click)="initCube('mystery')">
        <div class="cube-label">
          <label>MYSTERY CUBE</label>
        </div>

        <div class='cube-image'>
          <img [src]='"assets/cube-creator/mystery-cube.png"' class='mystery-cube'>
        </div>

        <div class="cube-caption">Use after reading or before writing a mystery story</div>

        <a class="planning-sheet" (click)='clickPlanningSheet($event)' target="_blank"
          href="assets/cube-creator/planning-mystery.pdf">
          Planning Sheet
        </a>
      </div>

      <div class='cube-type' (click)="initCube('story')">
        <div class="cube-label">
          <label>STORY CUBE</label>
        </div>

        <div class="cube-image">
          <img [src]='"assets/cube-creator/story-cube.png"' class='story-cube'>
        </div>

        <div class="cube-caption">Use to map out the key elements of a story</div>

        <a class="planning-sheet" (click)='clickPlanningSheet($event)' target="_blank"
          href="assets/cube-creator/planning-story.pdf">
          Planning Sheet
        </a>
      </div>

      <div class='cube-type' (click)="initCube('custom')">
        <div class="cube-label">
          <label>CREATE-YOUR-OWN CUBE</label>
        </div>

        <div class="cube-image">
          <img [src]='"assets/cube-creator/create-your-own-cube.png"' class='create-your-own-cube'>
        </div>

        <div class="cube-caption">Fill in your own questions and responses to a topic</div>

        <a class="planning-sheet" (click)='clickPlanningSheet($event)' target="_blank"
          href="assets/cube-creator/planning-create.pdf">
          Planning Sheet
        </a>
      </div>
      <div class='clear'></div>
    </div>

    <hr>
    <h2>OPEN SAVED CUBE</h2>

    <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openCube($event)">

  </div>

  <!-------------------------------------------- End Select Cube ---------------------------------------------->




  <!-------------------------------------------- Build Cube ---------------------------------------------->

  <div *ngIf='step==2'>
    <div class='toolbar'>
      <button (click)='step=1'>
        Start Over
      </button>
      <button *ngIf='side>1' (click)='prevSide()'>
        <i class='fa fa-chevron-left'></i> &nbsp; Back
      </button>
      <button *ngIf='side<6' (click)='nextSide()'>
        Next &nbsp;<i class='fa fa-chevron-right'></i>
      </button>
      <button *ngIf='side==6' (click)='initPrint()'>
        Finish &nbsp;<i class='fa fa-chevron-right'></i>
      </button>
    </div>

    <!-- cubes -->
    <div class='cube-builder'>
      <div class='cube-header'>
        <img [src]='layout.cubeImage' class='cube-header-img'>
        <h1 style='line-height:70px;'>{{layout.name}}</h1>
        <div class='clear'></div>
      </div>


      <div class='cube-select'>
        <h3 class='cube-select-header'>Fill in the necessary information in the cube.</h3>

        <div class='cube-select-images'>
          <span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(1)'></i>
            <img (click)="side=1" [src]='layout.side1image'>
          </span>
          <span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(2)'></i>
            <img (click)="side=2" [src]='layout.side2image'>
          </span><span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(3)'></i>
            <img (click)="side=3" [src]='layout.side3image'>
          </span>
          <div class='clear'></div>
        </div>

        <div class='cube-select-images'>
          <span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(4)'></i>
            <img (click)="side=4" [src]='layout.side4image'>
          </span>
          <span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(5)'></i>
            <img (click)="side=5" [src]='layout.side5image'>
          </span>
          <span>
            <i class='fa fa-check side-check' *ngIf='sideCompleted(6)'></i>
            <img (click)="side=6" [src]='layout.side6image'>
          </span>
          <div class='clear'></div>
        </div>

        <h3 class='cube-select-header'>(Click on a number above to rotate the cube.)</h3>
      </div>

      <div [class]='cubeBackground()'>

        <!-------------------------------------------- Bio Cube ---------------------------------------------->
        <div *ngIf="cubes.type=='bio' && side==1">
          <label class='bio-label'>Name:</label>
          <label class='bio-label-2'>Who are you writing about?</label>
          <input [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioName)}" class='bio-name-input' type='text' size='35' [(ngModel)]='cubes.bioName'>
          <label class='bio-label-2'>Time Period:</label>
          <label class='bio-label-2'>When did this person live?</label>
          <input [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioTime)}" class='bio-second-inputs' type='text' size='35' [(ngModel)]='cubes.bioTime'>
          <label class='bio-label-2'>Place:</label>
          <label class='bio-label-2'>Where did this person live?</label>
          <input [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioPlace)}" class='bio-second-inputs' type='text' size='35' [(ngModel)]='cubes.bioPlace'>
        </div>
        <div *ngIf="cubes.type=='bio' && side==2">
          <label class='bio-label'>Personal Background:</label>
          <label class='bio-label-2'>What were the major events in this person's life?</label>
          <textarea [ngClass]="{'has-error': (cubeCompleteError && !cubes.bioBackground)}" class='bio-textarea-input' [(ngModel)]='cubes.bioBackground'></textarea>
        </div>
        <div *ngIf="cubes.type=='bio' && side==3">
          <label class='bio-label'>Personality Traits:</label>
          <label class='bio-label-2'>What was this person like?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioPersonality)}" class='bio-textarea-input' [(ngModel)]='cubes.bioPersonality'></textarea>
        </div>
        <div *ngIf="cubes.type=='bio' && side==4">
          <label class='bio-label'>Significance:</label>
          <label class='bio-label-2'>Why was this person important?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioSignificance)}" class='bio-textarea-input' [(ngModel)]='cubes.bioSignificance'></textarea>
        </div>
        <div *ngIf="cubes.type=='bio' && side==5">
          <label class='bio-label'>Obstacles:</label>
          <label class='bio-label-2'>What challenges did this person overcome?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioObstacles)}" class='bio-textarea-input' [(ngModel)]='cubes.bioObstacles'></textarea>
        </div>
        <div *ngIf="cubes.type=='bio' && side==6">
          <label class='bio-label'>Important Quote:</label>
          <label class='bio-label-2'>Provide a quote from this person.</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.bioQuote)}" class='bio-textarea-input' [(ngModel)]='cubes.bioQuote'></textarea>
        </div>
        <!-------------------------------------------- End Bio Cube ---------------------------------------------->



        <!-------------------------------------------- Mystery Cube ---------------------------------------------->
        <div *ngIf="cubes.type=='mystery' && side==1">
          <label class='mystery-label'>Setting:</label>
          <label class='mystery-label-2'>Where does the mystery take place?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysterySetting)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysterySetting'></textarea>
        </div>
        <div *ngIf="cubes.type=='mystery' && side==2">
          <label class='mystery-label'>Detective:</label>
          <label class='mystery-label-2'>Who is trying to solve the mystery?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysteryDetective)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysteryDetective'></textarea>
        </div>
        <div *ngIf="cubes.type=='mystery' && side==3">
          <label class='mystery-label'>Crime or Mystery:</label>
          <label class='mystery-label-2'>What is the problem in the story?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysteryCrime)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysteryCrime'></textarea>
        </div>
        <div *ngIf="cubes.type=='mystery' && side==4">
          <label class='mystery-label'>Victim:</label>
          <label class='mystery-label-2'>Who is the target of the crime or mystery?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysteryVictim)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysteryVictim'></textarea>
        </div>
        <div *ngIf="cubes.type=='mystery' && side==5">
          <label class='mystery-label'>Clues:</label>
          <label class='mystery-label-2'>What clues lead the detective to solve the mystery?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysteryClues)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysteryClues'></textarea>
        </div>
        <div *ngIf="cubes.type=='mystery' && side==6">
          <label class='mystery-label'>Solution:</label>
          <label class='mystery-label-2'>What is the solution to the mystery?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.mysterySolution)}" class='mystery-textarea-input' [(ngModel)]='cubes.mysterySolution'></textarea>
        </div>

        <!-------------------------------------------- End Mystery Cube ---------------------------------------------->


        <!-------------------------------------------- Story Cube ---------------------------------------------->
        <div *ngIf="cubes.type=='story' && side==1">
          <label class='story-label'>Characters:</label>
          <label class='story-label-2'>Who are the main characters in the story?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storyCharacters)}" class='story-textarea-input' [(ngModel)]='cubes.storyCharacters'></textarea>
        </div>
        <div *ngIf="cubes.type=='story' && side==2">
          <label class='story-label'>Setting:</label>
          <label class='story-label-2'>Where does the story take place?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storySetting)}" class='story-textarea-input' [(ngModel)]='cubes.storySetting'></textarea>
        </div>
        <div *ngIf="cubes.type=='story' && side==3">
          <label class='story-label'>Conflict:</label>
          <label class='story-label-2'>What is the main problem in the story?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storyConflict)}" class='story-textarea-input' [(ngModel)]='cubes.storyConflict'></textarea>
        </div>
        <div *ngIf="cubes.type=='story' && side==4">
          <label class='story-label'>Resolution:</label>
          <label class='story-label-2'>How is the problem resolved?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storyResolution)}" class='story-textarea-input' [(ngModel)]='cubes.storyResolution'></textarea>
        </div>
        <div *ngIf="cubes.type=='story' && side==5">
          <label class='story-label'>Theme:</label>
          <label class='story-label-2'>What is the topic or subject of the story?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storyTheme)}" class='story-textarea-input' [(ngModel)]='cubes.storyTheme'></textarea>
        </div>
        <div *ngIf="cubes.type=='story' && side==6">
          <label class='story-label'>Favorite:</label>
          <label class='story-label-2'>What part of the story did you like best?</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.storyFavorite)}" class='story-textarea-input' [(ngModel)]='cubes.storyFavorite'></textarea>
        </div>
        <!-------------------------------------------- End Story Cube ---------------------------------------------->


        <!-------------------------------------------- Custom Cube ---------------------------------------------->
        <div *ngIf="cubes.type=='custom' && side==1">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse'></textarea>
        </div>
        <div *ngIf="cubes.type=='custom' && side==2">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion2)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion2'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse2)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse2'></textarea>
        </div>
        <div *ngIf="cubes.type=='custom' && side==3">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion3)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion3'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse3)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse3'></textarea>
        </div>
        <div *ngIf="cubes.type=='custom' && side==4">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion4)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion4'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse4)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse4'></textarea>

        </div>
        <div *ngIf="cubes.type=='custom' && side==5">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion5)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion5'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse5)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse5'></textarea>
        </div>
        <div *ngIf="cubes.type=='custom' && side==6">
          <label class='create-label'>Question/Topic:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createQuestion6)}" class='create-textarea-input' [(ngModel)]='cubes.createQuestion6'></textarea>
          <label class='create-label-2'>Response:</label>
          <textarea [ngClass]="{'has-error':(cubeCompleteError && !cubes.createResponse6)}" class='create-textarea-input' [(ngModel)]='cubes.createResponse6'></textarea>
        </div>

        <!-------------------------------------------- End Custom Cube ---------------------------------------------->


        <div class='clear'></div>

      </div>

      <div class='clear'> </div>
    </div>



  </div>

  <!----   print  -->

  <div *ngIf="step==3">
    <div class='toolbar'>
      <button (click)="step=2"><i class='fa fa-chevron-left'></i>&nbsp;Back</button>
      <button (click)='print()'><i>&nbsp;</i>&nbsp;Print</button>
    </div>
    <div class='pad'>
    <h1>Congrats!</h1>
    <p>
      To assemble your cube, print it out and follow the directions.
      If you want to make changes to your cube, use the Back button.
    </p>
    <div>
      <button (click)='print()'><i class='fa fa-print'></i> Print</button>
      <button (click)='export()'><i class='fa fa-download'></i> Export</button>
      <button (click)='save()'><i class='fa fa-save'></i> Save</button>
      <button (click)='reset()'>Start New Project</button>
    </div>
    <hr>


    <div class='print-preview-landscape'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas class='print-page print-canvas'>
      <table class='print-cube' cellpadding='0' cellspacing='0'>
        <tr>
          <td class='all-hide col-70'></td>

          <td class='l-hide t-hide r-solid'>
            <img class='fold-top' src='assets/cube-creator/fold-top.png'>
          </td>

          <td class='t-solid'>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube5top'></div>
              <div *ngIf='cube5middle' class='cube-contents-row' [innerHTML]='cube5middle'></div>
            </div>
          </td>

          <td class='all-hide'>
            <img class='fold-right' src='assets/cube-creator/fold-right.png'>
          </td>

          <td class='all-hide'>

          </td>
        </tr>
        <tr>
          <td class='all-hide col-70'>
            <img class='fold-left' src='assets/cube-creator/fold-left.png'>
          </td>
          <td>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube1top'></div>
              <div class='cube-contents-row' [innerHTML]='cube1middle' *ngIf='cube1middle'></div>
              <div class='cube-contents-row' [innerHTML]='cube1bottom' *ngIf='cube1bottom'></div>
            </div>
          </td>
          <td class='l-hide t-hide '>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube2top'></div>
              <div *ngIf='cube2middle' class='cube-contents-row' [innerHTML]='cube2middle'></div>
            </div>
          </td>
          <td class='t-solid l-hide b-solid'>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube3top'></div>
              <div *ngIf='cube3middle' class='cube-contents-row' [innerHTML]='cube3middle'></div>
            </div>
          </td>
          <td class='t-solid r-solid b-solid'>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube4top'></div>
              <div *ngIf='cube4middle' class='cube-contents-row' [innerHTML]='cube4middle'></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class='all-hide col-70'></td>
          <td class='l-hide t-hide b-hide r-solid'>
            <img class='fold-bottom' src='assets/cube-creator/fold-bottom.png'>
          </td>
          <td class='b-solid'>
            <div class='cube-contents'>
              <div class='cube-contents-row' [innerHTML]='cube6top'></div>
              <div *ngIf='cube6middle' class='cube-contents-row' [innerHTML]='cube6middle'></div>
            </div>
          </td>

          <td class='all-hide'>
            <img class='fold-right' src='assets/cube-creator/fold-right.png'>
          </td>

          <td class='all-hide'></td>
        </tr>
      </table>
    </div>
  </div>
</div>
</div>
