import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardPhysicalObject extends TradingCard implements ITradingCard{

    descriptionFields: TradingCardField[] = [
        new TradingCardField("Definition", "What is __topic__? Explain what it is."),
        new TradingCardField("Senses", "How does __topic__ feel, smell, taste? What does it look like? Does it make a sound?")
        
    ];

    purposeFields: TradingCardField[] = [
        new TradingCardField("Location", "Where is __topic__ typically used?"),
        new TradingCardField("Function", "What does __topic__ do or how does it work?")
    ];

    importanceFields: TradingCardField[] = [
        new TradingCardField("Use", "How and why is __topic__ used?"),
        new TradingCardField("Effects", "What result does __topic__ have on other things or its surroundings? Why is it important?"),
        new TradingCardField("To Whom", "To whom is __topic__ important?")
    ];
    
    interestingFactsFields: TradingCardField[] = [
        new TradingCardField("Additional Information", "What interesting facts or details about __topic__ stand out to you?")
    ];

    personalConnectionFields: TradingCardField[] = [
        new TradingCardField("Personal Connection", "What do you think about __topic__. Have you ever seen or used it? Is it valuable? Why or why not?")
    ];
    
    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Purpose", this.purposeFields),
        new TradingCardSection("Importance", this.importanceFields),
        new TradingCardSection("Interesting Facts", this.interestingFactsFields),
        new TradingCardSection("Personal Connection", this.personalConnectionFields)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Physical Object');
    }

    
}
