<div>
    <div  *ngIf="step==0" class="firstPage">
        <div class="what-is">
            What is a
        </div>

        <div class="what-is-pt2">
            fractured fairy tale
        </div>
        
        <div class="text">
            It is a story that uses fairy tales you know and changes the characters, setting, points of view, or plots. Ever heard of <i>The True Story of the Three Little Pigs</i> or <i>The Wolf Who Cried Boy</i>?
        </div>

        <div>
            <img src='assets/fractured-fairytale/read_a_sample_fractured_fairytale.png' class='read-sample' (click)="changeStep(1)">

            <img src='assets/fractured-fairytale/write_your_own_fractured_fairytale.png' class='write-own' (click)="changeStep(2)">
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/exit.png' class='exitButton' (click)='exitMenu()'>
        </div>

    </div>

    <div *ngIf="step==1" [class.frog-page-2]="page == 1" [class.frog-page-3]="page == 2" [class.frog-page-3]="page == 3" [class.frog-page-1]="page == 4" [class.frog-page-4]="page == 5" [class.frog-page-1]="page == 0">
        <div class='example-text'>
            <p>{{example.ex[page]}}</p>
            <img src='../../../assets/fractured-fairytale/continue_fairy_tale.png' (click)="changePage()" *ngIf="page!=5" class="cont-button-1">

            <div *ngIf="page==5">
                <p>{{example.ex[6]}} </p>
                <img src='../../../assets/fractured-fairytale/get_started.png' (click)="endExample()" class='get-started'>
            </div>
        </div>

    </div>

    <div *ngIf="step==2" class="thirdPage">
        <div class='choose-one-of-these'> 
            Choose one of these
        </div>

        <div class='three-tales'>
            three fairy tales
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/riding_hood.png' class='ridingBook' (click)="initStory('Riding Hood')">
            <img src='../../../assets/fractured-fairytale/princess_and_the_pea.png' class='princessBook' (click)="initStory('Princess')">
            <img src='../../../assets/fractured-fairytale/jack_and_the_beanstalk.png' class='jackBook' (click)="initStory('Jack')">
            <img src='../../../assets/fractured-fairytale/table.png' class='table'>
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/exit.png' class='exitButton' (click)='exitMenu()'>
        </div>

    </div>

    <div *ngIf="step==3" [class.jack-intro]="tale.storyName == jack.storyName" [class.ridingHood-intro]="tale.storyName == ridingHood.storyName" [class.princess-intro]="tale.storyName == princess.storyName">
        
        <div class="intro-page-info">
            <p *ngFor="let paragraph of tale.intro">{{paragraph}}</p>
        </div>
            
        
        <div>
            <img src='../../../assets/fractured-fairytale/write_a_fractured_version_of_this_tale.png' class='write-a-fractured-version-of-this-tale' (click)='changeStep(4)'>

            <img src='../../../assets/fractured-fairytale/choose_a_different_fairy_tale.png' class="choose-a-different-tale" (click)="changeStep(2)">
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/exit.png' class='exit-button-intro-page' (click)="exitMenu()">
        </div>

    </div>

    <div *ngIf="step==4" class='fourthPage'>
        <div class='are-you-ready'>
            Are you ready to write
        </div>
        
        <div class='a-fractured-version'>
            a fractured version of
        </div>

        <div class='story-name'>
            {{tale.storyName}}
        </div>

        
            <div class='before-you-do'>
                <div>Before you do, remember, there are lots of ways </div>
                <div>you can change a fairy tale:</div>
            </div>
            <ul class='list-of-changes'>
                <li class='small-line'>Change the main character</li>
                <li class='small-line'>Have the story take place somewhere else</li>
                <li class='small-line'>Have the story take place in another time</li>
                <li class='small-line'>Tell the story from a different character's point of view</li>
                <li class='small-line'>Make the problem of the story different</li>
                <li class='small-line'>Change an important item in the story</li>
                <li class='small-line'>You can even change the end of the story. Maybe they don't live "happily ever after" after all!</li>
            </ul>
        

        
        <div>
            <img src='../../../assets/fractured-fairytale/choose_my_changes.png' class='choose-my-changes' (click)='changeStep(5)'>
            <img src='../../../assets/fractured-fairytale/choose_a_different_fairy_tale.png' class="choose-a-different-fairy-tale" (click)='chooseADifferentStory()'>
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/exit.png' class='exit-button-intro-page' (click)='exitMenu()'>
        </div>

    </div>

    <div *ngIf="step==5" class='fifthPage'>
        <div class='fifth-page-inputs'>    
            <label>My name is:</label>
            <input type='text' [(ngModel)]='tale.name' maxlength="22" class='fifth-page-input-boxes'>
            
            <label class='story-info'>{{tale.storyCharacters}}</label>
            <label>In my story the main characters are: </label>
            <input type='text' [(ngModel)]='tale.inputCharacters' maxlength="65" class='fifth-page-input-boxes'>

            <label class='story-info'>{{tale.storySetting}}</label>
            <label>In my story the setting is: </label>
            <input type='text' [(ngModel)]='tale.inputSetting' maxlength="65" class='fifth-page-input-boxes'>

            <label class='story-info'>{{tale.storyTime}}</label>
            <label>My story takes place: </label>
            <input type='text' [(ngModel)]='tale.inputTime' maxlength="65" class='fifth-page-input-boxes'>
        </div>
        <div>
            <img src='../../../assets/fractured-fairytale/next_button.png' class='next-button-1' (click)='changeStep(6)'>
        </div>

        <img src='../../../assets/fractured-fairytale/exit.png' class='exit-button-intro-page' (click)='exitMenu()'>
    </div>

    <div *ngIf="step==6" class='sixthPage'>
        <div class='sixth-page-inputs'>
            <label class=story-info>{{tale.storyPOV}}</label>
            <label>My story is told from the point of view of: </label>
            <input type='text' [(ngModel)]='tale.inputPOV' maxlength="65" class='sixth-page-input-boxes'> 

            <label class='story-info'>{{tale.storyProblem}}</label>
            <label>The problem in my story is: </label>
            <input type='text' [(ngModel)]='tale.inputProblem' maxlength="65" class='sixth-page-input-boxes'>

            <label class='story-info'>{{tale.storyEnding}}</label>
            <label>My story ends when: </label>
            <input type='text' [(ngModel)]='tale.inputEnding' maxlength="65" class='sixth-page-input-boxes'>
        </div>
        
        
        <div>
            <img src='../../../assets/fractured-fairytale/back_button.png' class='back-button' (click)='changeStep(5)'>
            <img src='../../../assets/fractured-fairytale/next_button.png' class='next-button-2' (click)='changeStep(7)'>
        </div>

        <img src='../../../assets/fractured-fairytale/exit.png' class='eighth-page-exit-button' (click)='exitMenu()'>
    </div>

    <div *ngIf="step==7" class='seventhPage'>
        <div class='i-would-also-like-to-change'>
            <label>I'd also like to change: </label>
            <textarea columns="60" rows="6" [(ngModel)]='tale.inputMisc' maxlength="350" class='seventh-page-input'></textarea>
        </div>
        <div>
            <div class='print-my-changes-button'>
                <img src='../../../assets/fractured-fairytale/print_my_changes.png' (click)='print()'>
            </div>
            <div>
                <img src='../../../assets/fractured-fairytale/write_my_fractured_fairytale.png' class='write-my-fractured-fairytale-button' (click)='changeStep(8)'>
            </div>
            <div>
                <img src='../../../assets/fractured-fairytale/back_button.png' class='back-button-2' (click)='changeStep(6)'>
            </div>
        </div>

        <img src='../../../assets/fractured-fairytale/exit.png' class='eighth-page-exit-button' (click)='exitMenu()'>
    </div>

    <div *ngIf="step==8" class='eighthPage'>
        <div class='title-of-story'>
            <label>The title of my story is:</label>        
            <input type='text' [(ngModel)]='tale.inputTitle' maxlength="65" class='title-of-story-input'>

        </div>
            
        <div class='write-story-below'>
            <label>Write your story in the space below: </label>
            <textarea columns="49" rows="45" [(ngModel)]='tale.storyRewrite' maxlength="2205" class='rewrite-story-input'></textarea>
        </div>

        <div>
            <img src='../../../assets/fractured-fairytale/review_my_changes_for_this_fairy_tale.png' class='review-changes-for-this-tale' (click)='changeStep(5)'>
        </div>
        
        <div>
            <img src='../../../assets/fractured-fairytale/print_my_fairy_tale.png' class='print-my-fairy-tale' (click)='print()'>
        </div>

        <img src='../../../assets/fractured-fairytale/finish.png' class='finish-button' (click)='exitMenu()'>
        <img src='../../../assets/fractured-fairytale/exit.png' class='eighth-page-exit-button' (click)='exitMenu()'>
    </div>

    <div *ngIf="step==9">
        <!-- here for testing the print is working, until actual printing is implemented -->
        {{tale.tester}}
    </div>

    <div *ngIf="step==10" class='exitPage'>
        <p class='we-hope-you-enjoyed'>
           We hope you enjoyed writing your fractured<br/> fairy tale. Remember to print your work<br/> before choosing a different story. 
        </p>
        <div>
            <img src='../../../assets/fractured-fairytale/print_my_fairy_tale.png' class='exit-menu-print' (click)='print()'>
        </div>
        
        <div>
            <img src='../../../assets/fractured-fairytale/choose_a_different_fairy_tale.png' class='exit-menu-choose-a-different' (click)="chooseADifferentStory()">
        </div>
        
        <div>
            <span class='this-will-erase'>(This will erase your work so far)</span>
        </div>
        
        <div>
            <img src='../../../assets/fractured-fairytale/back_button.png' class='exit-menu-back' (click)='changeStep(prevStep)'>
        </div>
    </div>
</div>

