import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader-service.service';
declare var ncte_core: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  gaTrackingId: string = 'UA-31675-33';
  isAuthorizedSubscription: Subscription;
  userDataSubscription: Subscription;e
  user: any;
  currentUrl: null;
  private subscriptions: Subscription[] = [];


  constructor(
    private router: Router,
    private dynamicScriptLoader: DynamicScriptLoaderService) {

  }
  async ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event['url']) {
        this.currentUrl = event['url'];
      }
    });
    // load NCTE core JS
    this.dynamicScriptLoader.load('https://apiv2.ncte.org', 'nctejs').then(data => {
      var ncte = new ncte_core();
      ncte.init( 'https://apiv2.ncte.org/api/');

      // Apply the theme from the server
      ncte.loadTheme();

    }).catch(error => console.log(error));

    this.router.events.subscribe(x=>{
      if(x instanceof NavigationEnd){
        window.scrollTo(0, 0);
      }
    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}

