
<canvas class="cool-canvas" id="canvas" width="750" height="400"
(touchstart)='start($event)'
(touchmove)='draw($event)'
(mousedown)='start($event)'
(mousemove)='draw($event)'
(touchend)='stop($event)'
(mouseup)='stop($event)'
(mouseout)='stop($event)'
></canvas>


<div class="tools">

  <button (click)="action_undo()" id="undoButton" type="button" class="drawingbutton">Undo</button>
  <button (click)="canvas_clear()" id="clearButton"  type="button" class="drawingbutton">Clear</button>

  <!-- Get color attribute, attach to hue_change() -->
  <div (click)="hue_alter('red')" class="colorBtn" style="background: red;"></div>
  <div (click)="hue_alter('blue')" class="colorBtn" style="background: blue;"></div>
  <div (click)="hue_alter('purple')" class="colorBtn" style="background: purple;"></div>
  <div (click)="hue_alter('orange')" class="colorBtn" style="background: orange;"></div>
  <div (click)="hue_alter('green')" class="colorBtn" style="background: green;"></div>
  <div (click)="hue_alter('brown')" class="colorBtn" style="background: brown;"></div>
  <div (click)="hue_alter('yellow')" class="colorBtn" style="background: yellow;"></div>
  <div (click)="hue_alter('violet')" class="colorBtn" style="background: violet;"></div>

  <!-- color picker element returns an input value instead -->
  <!-- <input (change)="draw_color=this.value" type="color" class="color-picker">
  <input (change)="draw_width=this.value" type="range" min="1" max="100" class="pen-range"> -->


</div>
