<div class="app-border">
    <div class="title-bar">
        <span>Essay Map</span>
    </div>

    <div class="blank-map" #blankMap></div>

    <div *ngIf="interactive.step==1" class="start-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button *ngIf="interactive.unGraySave" class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="print-blank-map-button" (click)="printBlankMap()"></button>
        <button class="get-started-button" (click)="checkIncomplete()"></button>
        <input class="name-input" [(ngModel)]="interactive.name" (change)="checkForSaving()" [ngClass]="{'has-error': !interactive.nameValid && interactive.hasBeenChecked}" maxlength="25">
        <input class="topic-input" [(ngModel)]="interactive.topic" (change)="checkForSaving()" [ngClass]="{'has-error': !interactive.topicValid && interactive.hasBeenChecked}" maxlength="25">
    </div>

    <div *ngIf="interactive.step==2" class="introduction-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-2-button" (click)="interactive.step=3"></button>
        <button class="nav-3-1-button" (click)="interactive.step=4"></button>
        <button class="nav-3-2-button" (click)="interactive.step=5"></button>
        <button class="nav-3-3-button" (click)="interactive.step=6"></button>
        <button class="nav-4-button" (click)="interactive.step=7"></button>
        <button class="down-button" (click)="interactive.step=3"></button>
        <button class="back-button" (click)="interactive.step=1"></button>
        <textarea class="introduction-input" [(ngModel)]="interactive.introduction" maxlength="350"></textarea>
    </div>

    <div *ngIf="interactive.step==3" class="main-ideas-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-1-button" (click)="interactive.step=2"></button>
        <button class="nav-3-1-button" (click)="interactive.step=4"></button>
        <button class="nav-3-2-button" (click)="interactive.step=5"></button>
        <button class="nav-3-3-button" (click)="interactive.step=6"></button>
        <button class="nav-4-button" (click)="interactive.step=7"></button>
        <button class="up-button" (click)="interactive.step=2"></button>
        <button class="down-button" (click)="interactive.step=4"></button>
        <button class="down-button-2 down-button" (click)="interactive.step=6"></button>
        <button class="down-button-3 down-button" (click)="interactive.step=5"></button>
        <textarea class="idea-1-input" [(ngModel)]="interactive.ideas[0].idea" maxlength="130"></textarea>
        <textarea class="idea-2-input" [(ngModel)]="interactive.ideas[1].idea" maxlength="130"></textarea>
        <textarea class="idea-3-input" [(ngModel)]="interactive.ideas[2].idea" maxlength="130"></textarea>
    </div>

    <div *ngIf="interactive.step==4" class="idea-1-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-1-button" (click)="interactive.step=2"></button>
        <button class="nav-2-button" (click)="interactive.step=3"></button>
        <button class="nav-3-2-button" (click)="interactive.step=5"></button>
        <button class="nav-3-3-button" (click)="interactive.step=6"></button>
        <button class="nav-4-button" (click)="interactive.step=7"></button>
        <button class="up-button" (click)="interactive.step=3"></button>
        <button class="down-button" (click)="interactive.step=7"></button>
        <textarea class="detail-1-input" [(ngModel)]="interactive.ideas[0].details[0]" maxlength="100"></textarea>
        <textarea class="detail-2-input" [(ngModel)]="interactive.ideas[0].details[1]" maxlength="100"></textarea>
        <textarea class="detail-3-input" [(ngModel)]="interactive.ideas[0].details[2]" maxlength="100"></textarea>
    </div>

    <div *ngIf="interactive.step==5" class="idea-2-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-1-button" (click)="interactive.step=2"></button>
        <button class="nav-2-button" (click)="interactive.step=3"></button>
        <button class="nav-3-1-button" (click)="interactive.step=4"></button>
        <button class="nav-3-3-button" (click)="interactive.step=6"></button>
        <button class="nav-4-button" (click)="interactive.step=7"></button>
        <button class="up-button" (click)="interactive.step=3"></button>
        <button class="down-button" (click)="interactive.step=7"></button>
        <textarea class="detail-1-input" [(ngModel)]="interactive.ideas[1].details[0]" maxlength="100"></textarea>
        <textarea class="detail-2-input" [(ngModel)]="interactive.ideas[1].details[1]" maxlength="100"></textarea>
        <textarea class="detail-3-input" [(ngModel)]="interactive.ideas[1].details[2]" maxlength="100"></textarea>
    </div>

    <div *ngIf="interactive.step==6" class="idea-3-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-1-button" (click)="interactive.step=2"></button>
        <button class="nav-2-button" (click)="interactive.step=3"></button>
        <button class="nav-3-1-button" (click)="interactive.step=4"></button>
        <button class="nav-3-2-button" (click)="interactive.step=5"></button>
        <button class="nav-4-button" (click)="interactive.step=7"></button>
        <button class="up-button" (click)="interactive.step=3"></button>
        <button class="down-button" (click)="interactive.step=7"></button>
        <textarea class="detail-1-input" [(ngModel)]="interactive.ideas[2].details[0]" maxlength="100"></textarea>
        <textarea class="detail-2-input" [(ngModel)]="interactive.ideas[2].details[1]" maxlength="100"></textarea>
        <textarea class="detail-3-input" [(ngModel)]="interactive.ideas[2].details[2]" maxlength="100"></textarea>
    </div>

    <div *ngIf="interactive.step==7" class="conclusion-background frame" [style.opacity]="currentOpacity">
        <button class="open-button" (click)="handleDialog('open', true)"></button>
        <button class="save-button" (click)="handleDialog('save', true)"></button>
        <button class="nav-1-button" (click)="interactive.step=2"></button>
        <button class="nav-2-button" (click)="interactive.step=3"></button>
        <button class="nav-3-1-button" (click)="interactive.step=4"></button>
        <button class="nav-3-2-button" (click)="interactive.step=5"></button>
        <button class="nav-3-3-button" (click)="interactive.step=6"></button>
        <button class="up-button" (click)="interactive.step=6"></button>
        <button class="up-button-2 up-button" (click)="interactive.step=5"></button>
        <button class="up-button-3 up-button" (click)="interactive.step=4"></button>
        <button class="finish-button" (click)="interactive.step=8"></button>
        <textarea class="conclusion-input" [(ngModel)]="interactive.conclusion" maxlength="350"></textarea>
    </div>

    <div *ngIf="interactive.step==8" class="finished-background">
        <div class='toolbar'>
            <button (click)="interactive.step=7">
                <i class='fa fa-chevron-left'></i> &nbsp; Back
            </button>
        </div>
        <h1>Congrats! You have finished your Essay Map.</h1>
        <p>
            Review your map. If you want to make changes, use the Back button.
        </p>
        <p>
            When it is finished, you can ...
        </p>
        <div class="handler-buttons">
            <button (click)='printFinal()'>
                <i class='fa fa-print'></i>&nbsp;Print
            </button>
            <button (click)='exportFinal()'>
                <i class='fa fa-download'></i>&nbsp;Export
            </button>
            <button (click)='saveInProgress()'>
                <i class='fa fa-save'></i>&nbsp;Save
            </button>
            <button (click)="handleDialog('startNew', true)">Start New Project</button>
        </div>
        <br>
        <div class="final-name-container">
            <label for="final-name-input">Enter a name for your final Essay Map: 
                <input class="final-name-input" id="final-name-input" [(ngModel)]="finalName">
            </label>
        </div>
        <div class="essay-map-background">
            <p class="name"> {{ interactive.name }} </p>
            <div class="topic-container">
                <p class="topic"> {{ interactive.topic }} </p>
            </div>
            <p class="introduction"> {{ interactive.introduction }} </p>
            <div class="main-ideas">
                <p class="idea-1"> {{  interactive.ideas[0].idea }} </p>
                <p class="idea-2"> {{  interactive.ideas[1].idea }} </p>
                <p class="idea-3"> {{  interactive.ideas[2].idea }} </p>
            </div>
            <div class="detail-area">
                <div *ngFor="let idea of interactive.ideas; let i = index" class="detail-set-{{ i + 1 }}">
                    <div class="detail-container" *ngFor="let detail of idea.details; let j = index">
                        <div class="detail">
                            <img class="detail-image" src="../../../assets/essay-map/em-detail-{{ j + 1 }}.png"/>
                            <p class="detail-text"> {{ detail }} </p>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <p class="conclusion"> {{ interactive.conclusion }} </p>
        </div>
        
        <div class="essay-map-background" id="map-copy" #printCanvas>
            <p class="name"> {{ interactive.name }} </p>
            <div class="topic-container">
                <p class="topic"> {{ interactive.topic }} </p>
            </div>
            <p class="introduction"> {{ interactive.introduction }} </p>
            <div class="main-ideas">
                <p class="idea-1"> {{  interactive.ideas[0].idea }} </p>
                <p class="idea-2"> {{  interactive.ideas[1].idea }} </p>
                <p class="idea-3"> {{  interactive.ideas[2].idea }} </p>
            </div>
            <div class="detail-area">
                <div *ngFor="let idea of interactive.ideas; let i = index" class="detail-set-{{ i + 1 }}">
                    <div class="detail-container" *ngFor="let detail of idea.details; let j = index">
                        <div class="detail">
                            <img class="detail-image" src="../../../assets/essay-map/em-detail-{{ j + 1 }}.png"/>
                            <p class="detail-text"> {{ detail }} </p>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <p class="conclusion"> {{ interactive.conclusion }} </p>
        </div>

    </div>

    <div *ngIf="showOpenDialog" class="open-background">
        <input class="open-input" [(ngModel)]="openName">
        <button class="cancel-button" (click)="handleDialog('open', false)"></button>
        <button class="find-my-file-button" (click)="fileInput.click()"></button>
        <input type="file" class="file-input" (change)="openFile($event)" #fileInput>
    </div>

    <div *ngIf="showSaveDialog" class="save-background">
        <input class="save-input" [(ngModel)]="saveInProgressName">
        <button class="cancel-button" (click)="handleDialog('save', false)"></button>
        <button class="save-file-button" (click)="saveInProgress()"></button>
    </div>

    <div *ngIf="showStartNewDialog" class="start-new-background">
        <button class="cancel-button" (click)="handleDialog('startNew', false);"></button>
        <button class="start-new-button" (click)="startNew()"></button>
    </div>
</div>