import { HaikuPoemWordSyllable } from 'src/app/model/HaikuPoemWordSyllable';
import { DragElement } from "./DragElement";

export class HaikuPoemInteractiveFile {
    name: string;

    wordSyllables: HaikuPoemWordSyllable[] = [
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null},
        {word: '', syllables: null}
    ]

    title: string;

    poemLine1: string;
    poemSyllables1: number;

    poemLine2: string;
    poemSyllables2: number;

    poemLine3: string;
    poemSyllables3: number;

    elements?: DragElement[] = [];
}