
export class ProfilePublisherPersonalProfile {
    step: number = 0;

    name: string = "";
    profileName: string = "";
    quote: string = "";
    song: string = "";
    aboutMe: string = "";
    latestBlog: string = "";
    interests: string = "";

}