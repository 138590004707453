import { ITradingCard } from "./ITradingCard";
import { TradingCardSection, TradingCardField } from "./TradingCardSection";
import { TradingCard } from "./TradingCard";


export class TradingCardAbstractConcept extends TradingCard implements ITradingCard {
    
    descriptionFields: TradingCardField[] = [
        new TradingCardField("Definition", " What is __topic__? Explain what it is."),
        new TradingCardField("Feeling", "Describe the feelings most commonly associated with __topic__.")
    ];

    causeAndOutcomeFields: TradingCardField[] = [
        new TradingCardField("Cause", "What happens to cause __topic__?"),
        new TradingCardField("Outcome", "What usually happens after experiencing __topic__?")
    ];

    importanceFields: TradingCardField[] = [
        new TradingCardField("Significance", "Why is __topic__ important? Is it typically a positive or a negative concept? Why?"),
        new TradingCardField("To Whom", "Who considers __topic__ to be important")
    ];

    interestingFactsFields: TradingCardField[] = [
        new TradingCardField("Additional Information", "What __topic__ stands out to you? What did you find most interesting about it?")
    ];

    personalConnectionFields: TradingCardField[] = [
        new TradingCardField("Personal Connection", "Have you ever experienced __topic__? If so, what was it like? What caused the experience? If not, would you like to experience it? Why or why not?")
    ];

    
    sections: TradingCardSection[] = [
        new TradingCardSection("Description", this.descriptionFields),
        new TradingCardSection("Cause and Outcome", this.causeAndOutcomeFields),
        new TradingCardSection("Importance", this.importanceFields),
        new TradingCardSection("Interesting Facts", this.interestingFactsFields),
        new TradingCardSection("Personal Connection", this.personalConnectionFields)
    ];

    constructor(public name: string, public topic: string) {
        super(name, topic, 'Abstract Concept');
    }
}
