import { SafeUrl } from "@angular/platform-browser";

export class FracturedFairytale {


   inputCharacters: string;
   inputSetting: string;
   inputTime: string;
   inputPOV: string;
   inputProblem: string;
   inputEnding: string;
   name: string;
   miscChanges: string;
   inputTitle: string;
   storyRewrite: string;
   intro: string[];
   tester: string;
   introPagePath: string;

   constructor(public storyName: string, public storyCharacters: string, public storySetting: string, public storyTime: string, public storyPOV: string, public storyProblem: string, public storyEnding: string) {

   }
   
  

}
