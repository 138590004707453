import { Directive, HostListener, EventEmitter, Output, Input, OnInit, HostBinding, ElementRef, Renderer2 } from '@angular/core';
import { DragElement } from '../model/DragElement';
import { DragUtilService } from '../service/drag-util.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { _ParseAST } from '@angular/compiler';
import * as _ from 'lodash';

@Directive({
  selector: '[drag-object]'
})
export class DragObjectDirective implements OnInit {
  @Input() dragElement: DragElement;
  @Input() dragElements: DragElement[];
  @Input() allowSelect?: boolean = false;
  @Output() onSelected = new EventEmitter();
  @Output() onMove = new EventEmitter();
  @Input() initialFocus?: boolean = false; 

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, "drag-object");
  }

  ngOnInit() {
    if (this.initialFocus) {
      this.hostElement.nativeElement.className += ' dragging';
      this.hostElement.nativeElement.className += ' selected';
      this.dragElement.selected = true;
      this.onSelected.emit(true);
      _.join(_.uniq(this.hostElement.nativeElement.className.split(' ')), ' ');
    }

  }

  @HostBinding('style.left.px')
  get left() {
    return this.dragElement.x;
  }

  @HostBinding('style.top.px')
  get top() {
    return this.dragElement.y;
  }

  @HostBinding('style.width.px')
  get width() {
    return this.dragElement.width;
  }

  @HostBinding('style.height.px')
  get height() {
    return this.dragElement.height;
  }

  @HostBinding('style.background')
  get background() {
    return this.dragElement.imageSrc ? "url(" + this.dragElement.imageSrc + ")" : null;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {
    this.dragElement.startX = e.clientX;
    this.dragElement.startY = e.clientY;
    this.dragElement.nativeElement = this.hostElement.nativeElement;
    this.dragElements.forEach(x => { DragElement.deselect(x); });
    this.dragElement.mouseDown = true;
    this.hostElement.nativeElement.className += ' dragging';
    if (this.allowSelect) {
      this.hostElement.nativeElement.className += ' selected';
      this.dragElement.selected = true;
      this.onSelected.emit(true);
    }
    _.join(_.uniq(this.hostElement.nativeElement.className.split(' ')), ' ');
    event.cancelBubble = true;

  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(e: MouseEvent) {
    this.dragElement.mouseDown = false;
    DragElement.removeDrag(this.dragElement);
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    if (this.dragElement.mouseDown) {
      this.onMove.emit(this.dragElement);
    }

  }

}
