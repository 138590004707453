import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-cover-guide',
  templateUrl: './book-cover-guide.component.html',
  styleUrls: ['./book-cover-guide.component.scss']
})
export class BookCoverGuideComponent implements OnInit {
  bulletMargin: string = "3px";
  currentTab: number = 0;

  constructor() { }

  ngOnInit() {
  }

    changeCurrentTab(tab) {
      this.currentTab = tab;
      switch(tab) {
        case 0:
          this.bulletMargin = "3px"
          break;

        case 1:
          this.bulletMargin = "158px"
          break;

        case 2:
          this.bulletMargin = "335px"
          break;

        case 3:
          this.bulletMargin = "508px"
          break;

        case 4:
          this.bulletMargin = "637px"
          break;

      }
    }
}
