import { forEach } from "lodash";
import { DragUtilService } from "../service/drag-util.service";
import { DragElement } from "./DragElement";
import { FlipAChipDropLocations } from "./FlipAChipDropLocations";
import { FlipAChipTextChoices } from "./FlipAChipTextChoices";

export class FlipAChipInteractiveFile {
    step: number = 1;
    name: string = "";

    textChoices: FlipAChipTextChoices = new FlipAChipTextChoices();
    placements: FlipAChipDropLocations = new FlipAChipDropLocations();
    
    dragElements: DragElement[] = [];

    constructor() {
        let dragUtil = new DragUtilService();
        
        for (let i = 0; i < 6; i++) {
            let dragElement = dragUtil.createElement(this.dragElements);
            dragElement.y = 166;
            dragElement.x = 653;
            this.dragElements.push(dragElement);
        }
    }

    coinStates = [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0]
    ]

    showDragWord = [
        false,
        false,
        false,
        false,
        false,
        false
    ]
}