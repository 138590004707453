<div class='app-border'>
  <div class='title-bar'>
    <span>Venn Diagram</span>
  </div>

  <!-- Step 1 -->
  <div *ngIf='step==1' class='step step1'>
    <h1>What is a Venn Diagram</h1>
    <em class='plot-info'>A venn diagram is an orgizational tool made up of two or more overlapping circles, which are
      used
      to visually compare and contrast information and to examine relationships
    </em>

    <label>Your Name:</label>
    <input type='text' [(ngModel)]='vars.inputDiagrammedBy' [ngClass]="{'has-error':nameRequiredError}">


    <label>Title</label>
    <input type='text' [(ngModel)]='vars.inputTitle' [ngClass]="{'has-error':titleRequiredError}">

    <div class='buttons'>
      <button (click)='nextStep()'>Next <i class='fa fa-chevron-right'></i></button>
    </div>


    <hr>
    <h2>OPEN SAVED VENN DIAGRAM</h2>

    <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openVenn($event)">


  </div>
  <!-- End Step 1 -->

  <!-- Step 2 -->
  <div class='toolbar' *ngIf='step>1'>
    <button *ngIf='step>1' (click)='prevStep()'><i class='fa fa-chevron-left'></i> Back</button>
    <button *ngIf='step<3' (click)='nextStep()'>Next <i class='fa fa-chevron-right'></i></button>
    <button *ngIf='step==3' (click)='print()'><i class='fa fa-print'></i> Print</button>
    <div class='tool-button'>
      <button *ngIf='step==2' (click)='addingCircle=true'>Add Circle</button>
      <div class='tools' *ngIf='addingCircle'>
        <img [src]='"../../../assets/venn-diagram/circle-black.gif"' style='width:30px' (click)='addCircle("black")'>
        <img [src]='"../../../assets/venn-diagram/circle-blue.gif"' style='width:30px' (click)='addCircle("blue")'>
        <img [src]='"../../../assets/venn-diagram/circle-green.gif"' style='width:30px' (click)='addCircle("green")'>
        <img [src]='"../../../assets/venn-diagram/circle-orange.gif"' style='width:30px' (click)='addCircle("orange")'>
        <img [src]='"../../../assets/venn-diagram/circle-red.gif"' style='width:30px' (click)='addCircle("red")'>
      </div>
      <button *ngIf='step==2' (click)='addLabel()'>Add Label</button>
    </div>
  </div>

  <div *ngIf='step==2' class='step2'>
    <div class='drag-canvas' drag-canvas [dragElements]='vars.elements'>
      <div drag-object *ngFor='let elem of vars.elements' [dragElement]='elem' [dragElements]='vars.elements'
        [allowSelect]='true'>
        <drag-toolbar [dragElement]='elem' [dragElements]='vars.elements' [showEdit]='elem.type=="text"'
          (onEdit)='editLabel($event)'></drag-toolbar>
        <label *ngIf='elem.type=="text"'>{{elem.text || 'New Label'}}</label>
        <drag-status [dragElement]='elem' [allowSize]='elem.type=="image"'></drag-status>
      </div>

      <div *ngIf='vars.editingLabel' class='drag-object-attribute'>
        <div class='attribute-title'>Add Label</div>
        <label>Label Text</label>
        <input type='text' [(ngModel)]='vars.inputLabelText' [ngClass]="{'has-error':emptyLabelError}">
        <label>Description</label>
        <textarea [(ngModel)]='vars.inputLabelDescription'></textarea>
        <button (click)='saveLabel()'>Save</button>
        <button (click)='editingLabel=false'>Cancel</button>
      </div>
    </div>
  </div>

  <!-- End Step 2 -->


  <!-- Step 3 -->



  <div *ngIf='step==3' class='step step3'>
    <div class='noprint'>
      <h1>Finished!</h1>
      <p>
        Congratulations, your plot diagram is now complete. Your printout
        will show you where you placed items on the diagram and it will
        also show your description text.
      </p>
      <div>
        <button (click)='print()'><i class='fa fa-print'></i> Print</button>
        <button (click)='export()'><i class='fa fa-download'></i> Export</button>
        <button (click)='save()'><i class='fa fa-save'></i> Save</button>
        <button (click)='reset()'>Start New Project</button>
      </div>
      <hr>
    </div>

    <div class='print-preview-landscape'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas class='print-canvas' style='min-width:1000px'>
      <canvas id='vennCanvas'></canvas>
      <h1 *ngIf='vars.inputTitle'>{{vars.inputTitle}}</h1>
      <h3 *ngIf='vars.inputDiagrammedBy'>Diagrammed by {{vars.inputDiagrammedBy}}</h3>

    </div>

    <div #printPage2Canvas class='print-canvas' style='min-width:1000px; display:block !important'>
      <h3>Plot Events and Descriptions</h3>
      <p *ngFor='let elem of getLabels();let idx=index;'>
        <strong>{{idx+1}}</strong>. {{elem.text}}<br>
        {{elem.description}}
        <br>
      </p>
    </div>
  </div>


  <!-- End Step 3 -->
</div>