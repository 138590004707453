import { Injectable, ElementRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { _ParseAST } from '@angular/compiler';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

(window as any).html2canvas = html2canvas;
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  dataKey: string;
  showHeader: boolean = false;
  showFooter: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.dataKey = 'data.' + document.location.pathname.substring(1);
  }



  async initPrintPreview(printElements: ElementRef[], orientation: PrintOrientation = PrintOrientation.Portrait) {
    return this.getBody(printElements[0].nativeElement, true);
  }
  async print(printElements: ElementRef[], orientation: PrintOrientation = PrintOrientation.Landscape, centerBody = false, centerBodyVertical = false, hideAfterPrint = true, showHeader = true, showFooter = true) {
    try {
      this.showHeader = showHeader;
      this.showFooter = showFooter;
      document.getElementById('printContainer').style.display = 'block';
      document.getElementById('printDialog').style.display = 'block';
      document.getElementById('printDialogTitle').innerHTML = 'Printing ...';

      let pdf = await this.createPdf(printElements, orientation, centerBody, centerBodyVertical, hideAfterPrint);
      pdf.autoPrint();
      pdf.output('dataurlnewwindow');
    }
    catch (e) { debugger; alert('Error printing.') }

    document.getElementById('printContainer').style.display = 'none';
    document.getElementById('printDialog').style.display = 'none';
  }

  async export(printElements: ElementRef[], filename, orientation: PrintOrientation = PrintOrientation.Portrait, centerBody = false, centerBodyVertical = false, hideAfterPrint = true, noheader = false, nofooter = false) {
    try {
      this.showHeader = this.showHeader;
      this.showFooter = this.showFooter;
      document.getElementById('printContainer').style.display = 'block';
      document.getElementById('printDialog').style.display = 'block';
      document.getElementById('printDialogTitle').innerHTML = 'Exporting ...';

      let pdf = await this.createPdf(printElements, orientation, centerBody, centerBodyVertical, hideAfterPrint);
      pdf.save(filename);
      pdf.output('dataurlnewwindow');
    }
    catch (e) { alert('Error exporting.') }

    document.getElementById('printContainer').style.display = 'none';
    document.getElementById('printDialog').style.display = 'none';
  }



  async getPrintImage(printElement: ElementRef, orientation: PrintOrientation, showFooter: boolean = false) {
    let tempDisplay = printElement.nativeElement.style.display;
    let tempTop = printElement.nativeElement.style.marginTop;
    if (printElement.nativeElement.style.display == 'none') {
      printElement.nativeElement.style.margin.top = '10000px';
    }
    printElement.nativeElement.style.display = 'block';
    let printCanvas = await html2canvas(printElement.nativeElement, { scrollY: -window.scrollY });
    let img = (printCanvas as any).toDataURL("image/jpeg", 1.0);

    printElement.nativeElement.style.marginTop = tempTop;
    printElement.nativeElement.style.display = tempDisplay;
    return img;

  }

  async getHeader() {
    const page: HTMLElement = document.createElement('div');
    page.innerHTML = window.document.title + "<br><em style='font-size:11px'>" + window.document.location.href + "<em>";
    page.style.position = 'absolute';
    page.style.top = '0px';
    page.style.left = '0px';
    page.style.width = '100%';
    page.style.fontSize = '14px;';
    page.style.lineHeight = '14px';
    document.getElementById('app-print-canvas').style.display = 'block';
    document.getElementById('app-print-canvas').appendChild(page);

    let printCanvas = await html2canvas(page, { scrollY: -window.scrollY });
    var img = (printCanvas as any).toDataURL("image/jpeg", 1.0);
    document.getElementById('app-print-canvas').style.display = 'none';
    document.getElementById('app-print-canvas').innerHTML = '';
    return img;
  }

  async getFooter() {
    return new Promise(resolve => {
      const footer: HTMLImageElement = document.createElement('img');
      footer.src = '/assets/common/footer-logo.png';
      footer.style.width = '100%';
      footer.onload = async function () {
        document.getElementById('app-print-canvas').style.display = 'block';
        document.getElementById('app-print-canvas').appendChild(footer);

        let printCanvas = await html2canvas(footer, { scrollY: -window.scrollY });
        var img = (printCanvas as any).toDataURL("image/jpeg", 1.0);
        document.getElementById('app-print-canvas').style.display = 'none';
        document.getElementById('app-print-canvas').innerHTML = '';
        resolve(img);
      }
    });

  }

  async getLine() {
    const page: HTMLElement = document.createElement('div');
    page.innerHTML = '&nbsp;';
    page.style.position = 'absolute';
    page.style.top = '0px';
    page.style.left = '0px';
    page.style.width = '100%';
    page.style.fontSize = '14px;';
    page.style.lineHeight = '14px';
    page.style.backgroundColor = 'black';
    document.getElementById('app-print-canvas').style.display = 'block';
    document.getElementById('app-print-canvas').appendChild(page);

    let printCanvas = await html2canvas(page, { scrollY: -window.scrollY });
    var img = (printCanvas as any).toDataURL("image/jpeg", 1.0);
    document.getElementById('app-print-canvas').style.display = 'none';
    document.getElementById('app-print-canvas').innerHTML = '';
    return img;
  }

  async getBody(elem, hideAfterPrint) {
    elem.style.display = 'block';
    let bodyCanvas = await html2canvas(elem,
      {
        scrollY: -window.scrollY,
        width: elem.offsetWidth,
        height: elem.offsetHeight
      });
    let body = (bodyCanvas as any).toDataURL("image/jpeg", 1.0);
    if (hideAfterPrint) { elem.style.display = 'none'; }
    return body;
  }


  async createPdf(printElements: ElementRef[], orientation: PrintOrientation = PrintOrientation.Portrait, centerBody: boolean = false, centerBodyVertical = false, hideAfterPrint = false): Promise<any> {
    let pdf: any = null;

    // constants
    const margin = 10.0;
    let headerHeight = 20.0;
    let footerHeight = 20.0;
    const lineHeight = .8;
    const pad = 3.0;
    let pdfHeight = 0.0;
    let pdfWidth = 0.0;

    if (!this.showFooter) footerHeight = 0;
    if (!this.showHeader) headerHeight = 0;


    // set orientation
    if (orientation == PrintOrientation.Portrait) {
      pdf = new jsPDF('p', 'pt', 'letter');
      pdf.canvas.height = 72 * 11;
      pdf.canvas.width = 72 * 8.5;
      pdfHeight = 275.0;
    }
    else {
      pdf = new jsPDF('l', 'pt', 'letter');
      pdf.canvas.width = 72 * 11;
      pdf.canvas.height = 72 * 8.5;
    }
    pdfHeight = pdf.internal.pageSize.getHeight() - (margin * 2.0);
    pdfWidth = pdf.internal.pageSize.getWidth() - (margin * 2.0);

    // create pages for each print element
    for (var ctr = 0; ctr < printElements.length; ctr++) {

      let top = margin;
      let printElement = printElements[ctr];

      // set status 
      // let line: any = null;
      document.getElementById('printDialogStatus').innerHTML = 'Page ' + (ctr + 1) + ' of ' + printElements.length;

      // add header 
      let line: any = null;
      if (this.showHeader) {
        let header = await this.getHeader();
        const headerProps = pdf.getImageProperties(header);
        let scale = (headerHeight / headerProps.height);
        pdf.addImage(header, 'PNG', margin, margin, (parseFloat(headerProps.width) * scale), headerHeight);
        top += headerHeight + pad;

        // add header line
        let line = await this.getLine();
        pdf.addImage(line, 'PNG', margin, top, pdfWidth, lineHeight);
        top += lineHeight + pad;
      }
      let bodyHeight = pdfHeight - margin - headerHeight - pad - lineHeight - pad - pad - lineHeight - footerHeight - margin;


      // get body canvas and image 

      // add body 
      let body = await this.getBody(printElement.nativeElement, hideAfterPrint);
      const bodyProps = pdf.getImageProperties(body);

      // get dimensions available for body 
      let left = margin;
      let width = pdfWidth;
      let height = (pdfWidth * bodyProps.height) / bodyProps.width;
      if (height > bodyHeight) {
        height = bodyHeight;
        width = (bodyHeight * bodyProps.width) / bodyProps.height;
      }

      let bodyTop = top;
      if (centerBody) {
        left = margin + ((pdfWidth - width) / 2);
      }
      if (centerBodyVertical) {
        bodyTop = margin + ((pdfHeight - height) / 2);
      }

      pdf.addImage(body, 'PNG', left, bodyTop, width, height);

      // bottom line
      if (this.showFooter) {
        if(!line) line = await this.getLine();
        top += bodyHeight + pad;
        pdf.addImage(line, 'PNG', margin, top, pdfWidth, lineHeight);
        top += lineHeight + pad;

        // add footer
        let footer = await this.getFooter();
        const footerProps = pdf.getImageProperties(footer);
        let scale = (footerHeight / footerProps.height);
        pdf.addImage(footer, 'PNG', margin, top, (parseFloat(footerProps.width) * scale), footerHeight);
      }

      if (ctr < (printElements.length - 1)) {
        pdf.addPage();
      }
    }
    return pdf;

  }
}
