import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { LitElements } from "src/app/model/LitElements";
import { FileUtilService } from "src/app/service/file-util.service";
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from "src/app/service/print.service";

@Component({
  selector: "app-lit-elements",
  templateUrl: "./lit-elements.component.html",
  styleUrls: ["./lit-elements.component.scss"],
})
export class LitElementsComponent implements OnInit {
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  @ViewChild('printPreview', null) printPreview: ElementRef;

  previewImage: any;
  step: number = 0;

  projectData = new LitElements();

  showWarning: boolean = false;

  graphicMargin: string = "30px";

  constructor(private fileUtil: FileUtilService, private printer: PrintService) {}

  ngOnInit() {
    this.projectData.graphicOrganizer = "graphicOrganizers";
  }

  prevStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }

  initGraphicOrganizer(graphic_organizer) {
    if (this.projectData.author && this.projectData.title){
      this.projectData.graphicOrganizer = graphic_organizer;
      this.nextStep();
    }
  }

  enterInitGraphOrg() {
    if (!this.projectData.author || !this.projectData.title) {
      this.graphicMargin = "20px";
      this.showWarning = true;
    }
  }

  leaveInitGraphOrg() {
    this.graphicMargin = "30px";
    this.showWarning = false;
  }

  onEnter(stepNumber) {
    if (this.step < stepNumber) {
      this.nextStep()
    }
  }

  handleSelection() {
    if (this.projectData.graphicOrganizer === "graphicOrganizers") {
      this.step = 0;
    } else {
      this.step = 1;
    }
  }

  async print() { 
    await this.printer.print([this.printCanvas], PrintOrientation.Landscape, true)
  }

  initPrint() {
    this.step = 6;
    this.previewImage = null;
    setTimeout(async () => {
      this.previewImage = await this.printer.initPrintPreview([this.printCanvas], PrintOrientation.Landscape);
    }, 100);
  }
}
