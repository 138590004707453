import { Injectable } from '@angular/core'; 
import html2canvas from 'html2canvas' 
import { _ParseAST } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';

(window as any).html2canvas = html2canvas;
@Injectable({
  providedIn: 'root'
})
export class FileUtilService {
  dataKey: string;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.dataKey = 'data.' + document.location.pathname.substring(1);
  }
 

  setStep(step: any, vars: any = null) {
    if(!!vars){
      localStorage.setItem(this.dataKey, JSON.stringify(vars));
    }
    this.router.navigateByUrl(document.location.pathname + '?step=' + step);

  }
  getStep(): any {
    let step: any = (new URLSearchParams(document.location.search)).get('step');
    return step;
  }
  getVars(): any {
    if (!this.getStep()) return null;
    let vars: any = null;
    let v = localStorage.getItem(this.dataKey);
    if (!!v) vars = JSON.parse(v);
    return vars;
  } 

  public save(obj: any, filename: string) {
    const element = document.createElement('a');
    const fileType = 'text/json';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(JSON.stringify(obj))}`);
    element.setAttribute('download', filename);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
}
