<div class="app-border">
    <div class="title-bar">
        <span>Construct a Word</span>
    </div>

    <div *ngIf="interactive.step==1" class="intro-background frame">
        <div class="robot-text-container">
            <p class="robot-text"> Let's Make Words! </p>
        </div>

        <button class="begin-button" (click)="interactive.step=2"></button>
        <input class="name-input" [(ngModel)]="interactive.name">
    </div>

    <div *ngIf="interactive.step==2" class="choose-background frame" drag-canvas [dragElements]="interactive.chooseDragElements">
        <div class="robot-text-container">
            <p class="robot-text"> Choose an ending. </p>
        </div>

        <button *ngFor="let i of getOrderedArray(9)"
            [style.z-index]="interactive.chooseDragElements[i].dragging ? 1 : 0" 
            (mousedown)='handleDragging("down", "choose", i, true)' 
            (mouseup)='handleDragging("up", "choose", i, false)' 
            [ngClass]='{"choose-ending-button": !interactive.chooseDragElements[i].dragging, "choose-ending-selected-button": interactive.chooseDragElements[i].dragging, "hidden-button": interactive.usedChooseButtons[i]}' 
            drag-object [dragElements]="interactive.chooseDragElements" 
            [dragElement]="interactive.chooseDragElements[i]"> {{ buttonInformation.chooseButtons[i] }} 
        </button>

        <img *ngIf='currentlyDragging("choose")' class="placement-flash" src="../../../assets/construct-a-word/cw-placement-flash.gif">
        <button class="word-bank-button" (click)="interactive.step=4"></button>
        <button class="exit-button" (click)="exit()"></button>
    </div>  

    <div *ngIf="interactive.step==3" class="game-background frame" drag-canvas [dragElements]="interactive.gameDragElements">
        <div class="robot-text-container">
            <p class="robot-text"> {{ interactive.robotText }} </p>
        </div>

        <button *ngFor="let i of getOrderedArray(32)" class="game-letter-button"
            [ngClass]='{"game-letter-button": !interactive.gameDragElements[i].dragging, "game-letter-selected-button": interactive.gameDragElements[i].dragging, "hidden-button": interactive.gameDragElements[i].chosen || interactive.usedGameButtons.get(interactive.gameChosenEnding)[i]}' 
            [style.z-index]="getGameZIndex(i)"
            (mousedown)='handleDragging("down", "game", i, true)' 
            (mouseup)='handleDragging("up", "game", i, false)' 
            drag-object [dragElements]="interactive.gameDragElements" 
            [dragElement]="interactive.gameDragElements[i]"> {{ buttonInformation.gameButtons[i] }} 
        </button>

        <button *ngIf="!interactive.showFinalWord" class="selected-ending-placeholder"> {{ interactive.gameChosenEnding }} </button>
        <button *ngIf="interactive.showFinalWord" class="final-word-holder"> {{ interactive.fullWord }} </button>
        <img *ngIf='currentlyDragging("game")' class="placement-flash" src="../../../assets/construct-a-word/cw-placement-flash.gif">
        <button class="back-arrow-button" (click)="goToChoose()"></button>
        <button class="word-bank-button" (click)="interactive.step=4"></button>
        <button class="exit-button" (click)="exit()"></button>
    </div>

    <div *ngIf="interactive.step==4" class="word-bank-background frame">
        <img class="ending-row-background" src="../../../assets/construct-a-word/cw-row-{{ this.interactive.gameChosenEnding }}.png"/>
        <button *ngFor="let button of getWordBankButtons()" class="word-bank-{{ button }}-button" (click)="this.interactive.gameChosenEnding=button"></button>
        <button [ngClass]='{"add-more-words-button": true, "hidden-button": interactive.allEndingsUsed}' (click)="goToGame()"></button>
        <div class="full-words-container">
            <p *ngFor="let word of interactive.foundWords.get(interactive.gameChosenEnding)" class="full-word"> {{ word }} </p>
        </div>
        <button class="print-button" (click)="printWords()"></button>
        <button class="exit-button" (click)="exit()"></button>
    </div>

    <div *ngIf="interactive.step==5" class="exit-background frame">
        <button class="start-over-button" (click)="startOver()"></button>
        <button class="back-button" (click)="back()"></button>
        <button class="word-bank-button" (click)="interactive.step=4"></button>
        <button class="exit-button" (click)="exit()"></button>
    </div>

    <div class="print-screen" #printScreen>
        <p class="name"> {{ interactive.name }} </p>
        <div class="print-area">
            <div *ngFor="let endings of interactive.foundWords | keyvalue" class="full-words-container">
                <p *ngFor="let word of endings.value" class="full-word"> {{ word }}</p>
            </div>
        </div>
    </div>
    
</div>