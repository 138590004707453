import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbcMatchInteractiveFile } from 'src/app/model/AbcMatchInteractiveFile';
import { AbcMatchGameController } from 'src/app/model/AbcMatchGameController'; 
import { PrintService } from 'src/app/service/print.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';

@Component({
  selector: 'app-abc-match',
  templateUrl: './abc-match.component.html',
  styleUrls: ['./abc-match.component.scss']
})
export class AbcMatchComponent implements OnInit {

  interactive: AbcMatchInteractiveFile = new AbcMatchInteractiveFile();
  gameController: AbcMatchGameController;
  

  constructor(private printer: PrintService) { }

  getGeneralStep() {
    if (!this.interactive.learnStep && !this.interactive.playStep) {
      return this.interactive.step;
    }

    return 0;
  }

  getLearnStep() {
    if (this.interactive.learnStep) {
      return this.interactive.learnStep;
    }

    return 0;
  }

  getPlayStep() {
    if (this.interactive.playStep) {
      return this.interactive.playStep;
    }
    
    return 0;
  }

  beginLearnGame() {
    this.interactive.learnStep = 1; 
    this.gameController = new AbcMatchGameController(this.interactive);
    this.gameController.gameType = 'learn';
    this.gameController.initTiles();
  }

  beginPlayGame() {
    this.interactive.playStep = 1; 
    this.gameController = new AbcMatchGameController(this.interactive);
    this.gameController.gameType = 'play';
    this.gameController.initTiles();
  }

  getOrderedArray(size) {
    let array = Array(size).fill(1).map((x,i) => i);
    return array;
  }

  @ViewChild('printout1', null) printout1: ElementRef;
  @ViewChild('printout2', null) printout2: ElementRef;
  @ViewChild('printout3', null) printout3: ElementRef;
  printGameCards() {
    this.printer.print([this.printout1, this.printout2, this.printout3], PrintOrientation.Portrait);
  }

  ngOnInit() {

  }

}
