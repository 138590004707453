import { ElementRef, OnInit } from "@angular/core";
import * as _ from 'lodash';

export class DragElement {
  id: any;
  x: number;
  y: number;
  type?: any;
  width?: number;
  height?: number;
  text?: string;
  description?: string;
  hide?: boolean;
  color?: string;
  imageSrc?: string;

  // mouse events
  mouseDown?: boolean = false; 
  startX?: number;
  startY?: number;
  nativeElement?: any;
  selected?: boolean = false;
 
  static select(de: DragElement){
    de.selected = true;
    de.mouseDown = false;
    if (de.nativeElement) {
      de.nativeElement.className = de.nativeElement.className += ' selected';
      _.join(_.uniq(de.nativeElement.className.split(' ')), ' ');
    }
  }
  static deselect(de: DragElement) {
    de.selected = false;
    de.mouseDown = false;
    if (de.nativeElement) {
      de.nativeElement.className = de.nativeElement.className.replace(' selected', '');
      de.nativeElement.className = de.nativeElement.className.replace(' dragging', '');
      _.join(_.uniq(de.nativeElement.className.split(' ')), ' ');
    }
  }
  static removeDrag(de: DragElement) {
    de.mouseDown = false;

    if (de.nativeElement) {
      de.nativeElement.className = de.nativeElement.className.replace(' dragging', '');
      _.join(_.uniq(de.nativeElement.className.split(' ')), ' ');
    }
  }
}

