<!-- Showing the subsections -->

<div class="row">
    <div class="card-frame" [ngClass]='{"print-version": printVersion}'>

        <div class='card-frame-header'>
            <ul>
                <li class='card-frame-topic'>
                    {{card.topic}}
                </li>
                <li class='card-frame-type'>
                    {{card.type}}
                </li>
            </ul>
        </div>

        <div class="section-list">
            <ul>
                <li *ngIf="card.activeSection < 3" class="image-selector">
                    <div (click)='showInsertImageModal(true)' class="image-selector">
                        <span *ngIf='!card.imageUrl'>Click here to insert an image</span>
                        <img [src]='card.imageUrl' *ngIf='card.imageUrl' class='img'>
                    </div>
                </li>
                <li *ngFor="let s of card.displayedSections" class='card-section'>
                    <span class="section-header">{{s.sectionName}}</span>
                    <ul>
                        <li class="section-field" *ngFor="let field of s.fields">
                            <span>{{field.fieldName ? field.fieldName + ':' : null}}</span>
                            <span class="section-field-value">{{field.value}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <span class="flip" *ngIf='!disableButtons' (click)='card.flip()'>Flip<i class='fa fa-lg fa-repeat'></i></span>
    </div>
    <ng-container *ngIf='!disableButtons'>
        <div class='card-entry-container'>
            <div *ngIf="card.currentSection">
                <ng-container *ngIf="!card.isCreateYourOwn">
                    <h2 >{{card.activeSection}}. {{card.currentSection.sectionName}}</h2>
                    <div *ngFor="let field of card.currentSection.fields">
                        <label>{{field.label | replace:'__topic__':card.topic}}</label>
                        <input type="text" [(ngModel)]="field.value" maxlength="78">
                    </div>
                </ng-container>
                <ng-container *ngIf="card.isCreateYourOwn">
                    <input type="text" [(ngModel)]="card.currentSection.sectionName" placeholder="Section Name:" maxlength="31">
                    <div *ngFor="let field of card.currentSection.fields" class='columns'>
                        <input type="text" [(ngModel)]="field.fieldName" placeholder="Label:" maxlength="38">
                        <input type="text" [(ngModel)]="field.value" placeholder="Answer" maxlength="78">
                    </div>
                </ng-container>
            </div>

            <div class='card-toolbar'>
                <button (click) = 'showEditTopicModal(true)' class="card-toolbar-button">
                    Edit Topic
                </button>

                <button (click)='card.prev()' class='next-prev-button'>
                    <i class="fa fa-lg fa-angle-left"></i>
                </button>
                <button (click)='card.next()' class='next-prev-button'>
                    <i class="fa fa-lg fa-angle-right"></i>
                </button>
            </div>
        </div>
    </ng-container>

</div>


<!-- The Modal -->
<div id="myModal" class="modal" [class.show]='editingTopic'>

    <!-- Modal content -->
    <div class="modal-content">
        <h3>Edit your topic.</h3>

        <label>Topic: </label>
        <input type="text" [(ngModel)]="tempTopic">

        <div class="row center">
            <button (click)="showEditTopicModal(false)">
                Cancel
            </button>

            <button (click) = 'editTopic(tempTopic)'>
                Ok
            </button>
        </div>
    </div>

</div>

<div id="imgModal" class="modal" [class.show]='insertingPicture'>

    <div class="modal-content">
        <input type="file" (change)='selectImage($event)' accept="image/*" placeholder="Select Image">

        <img class="img" [src]='tempImageUrl' *ngIf='tempImageUrl' class='img'>

        <div class="row center">
            <button (click)="showInsertImageModal(false)">
                Cancel
            </button>
            <button (click)="setPicture()">
                Ok
            </button>
        </div>
    </div>
</div>
