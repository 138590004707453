<div class="app-border">
    <div class="title-bar">
        <span>Resume Generator</span>
    </div>

    <div class="app">
        <h1 class="title">RESUME GENERATOR</h1>
        <div *ngIf="deleteWarning === true || startOver === true" class="shaded"></div>

        <div *ngIf="startOver === true" class="start-over-container">
            <div class="start-over-warning">
                <h1>Are you sure you want to start over?</h1>
                <p>Be sure to click Cancel if you would like to save or print your work before beginning again.</p>

                <div class="warning-box">
                    <img src="/assets/resume-generator/resume-restart-exclamation.png">
                    <p>Warning! Clicking Start New will erase all of your work.</p>
                </div>

                <div class="buttons-container">
                    <button (click)="startOver = false" class="cancel-button">Cancel</button>
                    <button (click)="restart()" class="start-new">Start New</button>
                </div>
            </div>
        </div>

        <div class="white-background">
            <div class="inner-app1" *ngIf="step >= 0 && step <= 2">

                <!--------------------- Welcome Screen -------------------->
                <div *ngIf="step === 0">
                    <h1 class="welcome-header">Welcome to<br />ReadWriteThink's<br />Resume Generator!</h1>
                    <p class="get-started-label">Ready to create a resume?<br />Click Get Started to begin.</p>
                    <button (click)="nextStep()" class="resume-example-button">
                        <span>❮</span>
                        <p>What is a resume?</p>
                    </button>
                    <button (click)="changeStep(2)" class="get-started">
                        <p>Get Started</p>
                        <span>❯</span>
                    </button>
                </div>

                <!--------------------- What is a resume? -------------------->
                <div *ngIf="step === 1">
                    <h1 class="header-backdrop">WHAT IS A RESUME?</h1>
                    <p class="paragraph-backdrop">
                        A resume is an advertisement of your skills. Be sure to highlight
                        accomplishments over tasks, but don't embellish those accomplishments beyond the truth.
                    </p>

                    <button (click)="changeStep(2)" class="get-started2">
                        <p>Get Started</p>
                        <span>❯</span>
                    </button>

                    <div class="resume-example">
                        <div class="personal-info">
                            <h1>ANITA JOB</h1>

                            <div class="line"></div>

                            <p>123 Main Street</p>
                            <p>Anytown, State 11111</p>

                            <div class="line"></div>

                            <p>(555) 555-5555</p>
                            <p>anita.job@email.com</p>
                        </div>

                        <h1>OBJECTIVE</h1>
                        <p>To find a position that connects my publishing experience and my passion for sports and
                            fitness to inspire a healthy lifestyle.</p>
                        <h1 class="caps-word">PROFESSIONAL EXPERIENCE</h1>

                        <table>
                            <tr>
                                <td class="left-column">
                                    <p>8/11 -</p>
                                </td>
                                <td>
                                    <p><strong>Anytown Press</strong>, Anytown, State</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Present</p>
                                </td>
                                <td>
                                    <p><strong>Sports Reporter</strong></p>
                                </td>
                            </tr>
                        </table>

                        <ul>
                            <li>Develop, write, and edit news stories about local athletes and sport events</li>
                            <li>Consult with lead sports editor on creation of story concepts</li>
                            <li>Standardize writing and editing style for all sports reporters</li>
                        </ul>

                        <table>
                            <tr>
                                <td class="left-column">
                                    <p>11/10 -</p>
                                </td>
                                <td>
                                    <p><strong>Anytown Press</strong>, Anytown, State</p>
                                </td>
                            </tr>

                            <tr>
                                <td class="left-column">
                                    <p>8/11</p>
                                </td>
                                <td>
                                    <p><strong>Sports Intern</strong></p>
                                </td>
                            </tr>
                        </table>

                        <ul>
                            <li>Copy edited sports columns</li>
                            <li>Assisted on-the-scene sports reporters by collecting sports stats and interviews</li>
                            <li>Investigated new ways to use social media to report sports news and updates</li>
                        </ul>

                        <h1 class="caps-word">EDUCATION</h1>

                        <table>
                            <tr>
                                <td class="left-column">
                                    <p>6/12</p>
                                </td>
                                <td>
                                    <p><strong>Anytown High School</strong>, Anytown, State</p>
                                </td>
                            </tr>
                        </table>

                        <h1 class="caps-word">OTHER INTERESTS</h1>

                        <p>Get Fit Health Club</p>
                        <p>Anytown, State</p>
                        <p>Volunteer Youth Sports Officer</p>


                        <ul>
                            <li>Coordinate afterschool sports programs for kids</li>
                            <li>Designated healthy eating initiative that works in conjuction with sports programs</li>
                            <li>Compose monthly newsletters for current and potential participants</li>
                        </ul>
                    </div>

                    <div class="curved-lines">
                        <div class="info-line"></div>
                        <div class="objective-line"></div>
                        <div class="experience-line"></div>
                        <div class="education-line"></div>
                        <div class="other-line"></div>
                    </div>


                    <div class="explanation-label explanation-label1">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Personal Info</p>
                        </div>
                    </div>

                    <div class="explanation-label explanation-label2">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Objective</p>
                        </div>
                    </div>

                    <div class="explanation-label explanation-label3">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Work Experience</p>
                        </div>
                    </div>

                    <div class="explanation-label explanation-label4">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Skills</p>
                        </div>
                    </div>

                    <div class="explanation-label explanation-label5">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Education</p>
                        </div>
                    </div>

                    <div class="explanation-label explanation-label6">
                        <div class="triangle"></div>
                        <div class="explanation-text">
                            <p>Other</p>
                        </div>
                    </div>
                </div>

                <!--------------------- Resume types -------------------->
                <div *ngIf="step === 2">
                    <h1 class="header-backdrop resume-types-header">RESUME TYPES</h1>
                    <p class="paragraph-backdrop">There are two common types of resumes. Which style of resume would you
                        like to create?</p>

                    <div class="resume-options">
                        <div class="chronological-choice">
                            <h1 class="header-backdrop">Chronological</h1>
                            <div (click)="chooseStyle('Chronological')" class="hover-styles">
                                <div class="chronological-page">
                                    <ul>
                                        <li>Traditional style</li>
                                        <li>Displays work experience in a reverse chronological order, with most recent
                                            at
                                            the top</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="functional-choice">
                            <h1 class="header-backdrop">Functional</h1>

                            <div (click)="chooseStyle('Functional')" class="hover-styles">
                                <div class="functional-page">
                                    <ul>
                                        <li>Nontraditional style</li>
                                        <li>Highlights accomplishments through a list of targeted skills</li>
                                        <li>Perfect for those with little work experience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="paragraph-backdrop click-styles">Click one of the styles to begin.</p>

                    <button (click)="changeStep(0)" class="back-button">
                        <span>❮</span>
                        <p>Back</p>
                    </button>
                </div>
            </div>

            <!---------------------------------- Main app --------------------------------------->
            <div class="inner-app2" *ngIf="step >= 3">
                <table>
                    <td>
                        <div class="left-side">
                            <!------------------------- Labels for each input selection --------------------------->
                            <div class="top-bar">

                                <div *ngIf="selectedInput == 'Personal Info'">
                                    <h1 class="header-backdrop">PERSONAL INFORMATION</h1>
                                    <p class="paragraph-backdrop">Every resume should include personal information so
                                        that an employer can contact you.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Skills'">
                                    <h1 class="header-backdrop">SKILL SETS</h1>
                                    <p class="paragraph-backdrop">Identify 3-5 skill categories and list accomplishments
                                        pertaining to this skill set.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Experience'">
                                    <h1 class="header-backdrop">WORK EXPERIENCE</h1>
                                    <p class="paragraph-backdrop">Previous work experience allows employers to see how
                                        your skills align with their needs. Be sure to enter your most recent experience
                                        first.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Education'">
                                    <h1 class="header-backdrop">EDUCATION</h1>
                                    <p class="paragraph-backdrop">Education is important to many employers. Include your
                                        education information below, with your most recent school listed first.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Other'">
                                    <h1 class="header-backdrop">OTHER</h1>
                                    <p class="paragraph-backdrop">Skills related to extracurricular activities may be
                                        valuable to an employer.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Template'">
                                    <h1 class="header-backdrop">TEMPLATE</h1>
                                    <p class="paragraph-backdrop">Choose a template for your printed resume.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Summary'">
                                    <h1 class="header-backdrop">SUMMARY AND EDIT</h1>
                                    <p class="paragraph-backdrop">Now that you've compiled your experience and
                                        accomplishments, edit your entries to make sure oyur resume matches the job
                                        you're applying for.</p>
                                </div>
                                <div *ngIf="selectedInput === 'Final'">
                                    <h1 class="header-backdrop">REVIEW</h1>
                                    <p class="paragraph-backdrop">Review your resume. If you want to make changes, use
                                        the Back button.</p>
                                </div>
                            </div>
                            <div class="inner-content" [ngClass]="{
                                'inner-content-padding': selectedInput !== 'Template',
                                'inner-content-nopadding': selectedInput === 'Template' 
                              }">
                                <div *ngIf="deleteWarning === true" class="delete-warning-container">
                                    <div class="delete-warning">
                                        <div>
                                            <img src="/assets/resume-generator/resume-exclamation.png">
                                            <h1>Are you sure?</h1>
                                        </div>
                                        <p>Clicking Delete will erase your work from this entry.</p>
                                        <button (click)="deleteExperience()">Delete</button>
                                        <button (click)="deleteWarning = false">Cancel</button>
                                    </div>
                                </div>

                                <!------------------------- Personal info input ----------------------------------------------->
                                <div class="info-inputs" *ngIf="selectedInput === 'Personal Info'">
                                    <h1>Personal Information*</h1>
                                    <label>Full Name</label>
                                    <input type="text" autocomplete="name" [(ngModel)]="projectData.fullName" />
                                    <label>Address 1</label>
                                    <input type="text" autocomplete="address-line1"
                                        [(ngModel)]="projectData.address1" />
                                    <label>Address 2</label>
                                    <input type="text" [(ngModel)]="projectData.address2" />
                                    <label>City, state, zip</label>
                                    <input type="text" [(ngModel)]="projectData.cityStateZip" />
                                    <label>Phone Number</label>
                                    <input type="text" autocomplete="tel" [(ngModel)]="projectData.phoneNumber" />
                                    <label>E-mail</label>
                                    <input type="text" autocomplete="email" [(ngModel)]="projectData.email" />
                                    <p>*ReadWriteThink does not store any personal information.</p>
                                </div>
                                <!------------------------- Skills input ----------------------------------------------------->

                                <div class="experience-inputs skills-inputs" *ngIf="selectedInput === 'Skills'">
                                    <button *ngFor="let skill of projectData.skills; let i = index" [ngClass]="{
                                        'select-experience': skill != projectData.skills[projectData.skills.length - currentSelection - 1],
                                        'selected-experience': skill == projectData.skills[projectData.skills.length - currentSelection - 1] 
                                      }" (click)="currentSelection = projectData.skills.length - i - 1">
                                        <p>{{projectData.skills.length - i}}</p>
                                    </button>

                                    <h1>Skill Set #{{currentSelection + 1}}</h1>
                                    <label>Skill Category</label>
                                    <input [(ngModel)]="projectData.skills[currentSelection].category" />
                                    <label>Skills/Accomplishments</label>
                                    <textarea
                                        [(ngModel)]="projectData.skills[currentSelection].accomplishments"></textarea>

                                    <button (click)="addSkill()" class="add-another-button">Add another
                                        <img src="/assets/resume-generator/resume-green-plus.png" />
                                    </button>
                                    <button (click)="showDeleteWarning()" class="delete-experience-button">Delete
                                        <img src="/assets/resume-generator/resume-red-minus.png" />
                                    </button>
                                </div>

                                <!------------------------- Experience input -------------------------------------------------->

                                <div class="experience-inputs" *ngIf="selectedInput === 'Experience'">
                                    <button *ngFor="let experience of projectData.experiences; let i = index" [ngClass]="{
                                        'select-experience': experience != projectData.experiences[projectData.experiences.length - currentSelection - 1],
                                        'selected-experience': experience == projectData.experiences[projectData.experiences.length - currentSelection - 1] 
                                      }" (click)="currentSelection = projectData.experiences.length - i - 1">
                                        <p>{{projectData.experiences.length - i}}</p>
                                    </button>

                                    <h1>Work Experience #{{currentSelection + 1}}</h1>
                                    <label>Company/Organization Name</label>
                                    <input [(ngModel)]="projectData.experiences[currentSelection].companyName" />
                                    <label>Company Address (City, State)</label>
                                    <input [(ngModel)]="projectData.experiences[currentSelection].companyAddress" />
                                    <label>Job Title</label>
                                    <input [(ngModel)]="projectData.experiences[currentSelection].jobTitle" />

                                    <table>
                                        <td class="start-date">
                                            <label>Start Date <span>(MM/YYYY)</span></label>
                                        </td>
                                        <td class="end-date" [ngClass]="{
                                            'label-disabled': projectData.stillEmployed == true
                                          }">
                                            <label>End Date <span>(MM/YYYY)</span></label>
                                        </td>
                                    </table>

                                    <table>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.experiences[currentSelection].startMonth"
                                                (keydown)="checkNumber($event, 'startMonth')" readonly="readonly" />
                                        </td>
                                        <td>
                                            <label class="slash">/</label>
                                        </td>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.experiences[currentSelection].startYear"
                                                (keydown)="checkNumber($event, 'startYear')" readonly="readonly" />
                                        </td>
                                        <td>
                                            <input [(ngModel)]="projectData.experiences[currentSelection].stillEmployed"
                                                class="still-employed" type="checkbox" />
                                        </td>
                                        <td>
                                            <label class="still-employed-label">Still Employed
                                                Here?</label>
                                        </td>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.experiences[currentSelection].endMonth"
                                                (keydown)="checkNumber($event, 'endMonth')" readonly="readonly"
                                                [disabled]="stillEmployed()" />
                                        </td>
                                        <td>
                                            <label [ngClass]="{
                                                'label-disabled': projectData.experiences[currentSelection].stillEmployed == true
                                              }" class="slash">/</label>
                                        </td>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.experiences[currentSelection].endYear"
                                                (keydown)="checkNumber($event, 'endYear')" readonly="readonly"
                                                [disabled]="stillEmployed()" />
                                        </td>
                                    </table>

                                    <label
                                        *ngIf="projectData.resumeStyle === 'Chronological'">Skills/Accomplishments</label>
                                    <textarea *ngIf="projectData.resumeStyle === 'Chronological'"
                                        [(ngModel)]="projectData.experiences[currentSelection].skills"
                                        class="skills"></textarea>

                                    <button [ngClass]="{
                                        'chronological-add-button': projectData.resumeStyle === 'Chronological',
                                        'functional-add-button': projectData.resumeStyle === 'Functional'
                                      }" (click)="addExperience()" class="add-another-button">Add another
                                        <img src="/assets/resume-generator/resume-green-plus.png" />
                                    </button>
                                    <button (click)="showDeleteWarning()" class="delete-experience-button">Delete
                                        <img src="/assets/resume-generator/resume-red-minus.png" />
                                    </button>
                                </div>
                                <!------------------------- Education input -------------------------------------------------------->

                                <div class="experience-inputs education-inputs" *ngIf="selectedInput === 'Education'">
                                    <button *ngFor="let education of projectData.educations; let i = index" [ngClass]="{
                                        'select-experience': education != projectData.educations[projectData.educations.length - currentSelection - 1],
                                        'selected-experience': education == projectData.educations[projectData.educations.length - currentSelection - 1] 
                                      }" (click)="currentSelection = projectData.educations.length - i - 1">
                                        <p>{{projectData.educations.length - i}}</p>
                                    </button>

                                    <h1>Education #{{currentSelection + 1}}</h1>
                                    <label>School/University</label>
                                    <input [(ngModel)]="projectData.educations[currentSelection].school" />
                                    <label>City, State of School</label>
                                    <input [(ngModel)]="projectData.educations[currentSelection].cityState" />
                                    <label>Degree/Area of Study</label>
                                    <input [(ngModel)]="projectData.educations[currentSelection].degree" />
                                    <label class="graduation-date">Graduation Date <span>(MM/YYYY)</span></label>
                                    <table>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.educations[currentSelection].graduationMonth"
                                                (keydown)="checkNumber($event, 'graduationMonth')"
                                                readonly="readonly" />
                                        </td>
                                        <td>
                                            <label class="slash">/</label>
                                        </td>
                                        <td>
                                            <input type="text"
                                                [ngModel]="projectData.educations[currentSelection].graduationYear"
                                                (keydown)="checkNumber($event, 'graduationYear')" readonly="readonly" />
                                        </td>
                                    </table>
                                    <button (click)="addEducation()" class="add-another-button">Add another
                                        <img src="/assets/resume-generator/resume-green-plus.png" />
                                    </button>
                                    <button (click)="showDeleteWarning()" class="delete-experience-button">Delete
                                        <img src="/assets/resume-generator/resume-red-minus.png" />
                                    </button>
                                </div>

                                <!------------------------- Other input --------------------------------------------------------------->

                                <div class="experience-inputs other-inputs" *ngIf="selectedInput === 'Other'">
                                    <h1>Other</h1>
                                    <label>Other Interests</label>
                                    <textarea [(ngModel)]="projectData.otherInterests"></textarea>
                                    <label>Special Accomplishments</label>
                                    <textarea [(ngModel)]="projectData.accomplishments"></textarea>
                                </div>
                                <div class="template-inputs" *ngIf="selectedInput === 'Template'">
                                    <div class="select-template">
                                        <img (click)="prevTemplate()"
                                            src="/assets/resume-generator/resume-left-arrow.png" />
                                        <p>{{projectData.templateSelection + 1}} of 3</p>
                                        <img (click)="nextTemplate()"
                                            src="/assets/resume-generator/resume-right-arrow.png" />
                                    </div>

                                    <div class="angled-template">
                                        <div class="template-1" *ngIf="projectData.templateSelection === 0">
                                            <div class="personal-info">
                                                <h1>ANITA JOB</h1>

                                                <div class="line"></div>

                                                <p><i>123 Main Street</i></p>
                                                <p><i>Anytown, State 11111</i></p>

                                                <div class="line"></div>

                                                <p><i>(555) 555-5555</i></p>
                                                <p><i>anita.job@email.com</i></p>
                                            </div>

                                            <h1>OBJECTIVE</h1>
                                            <p>To find a position that connects my publishing experience and my passion
                                                for sports and
                                                fitness to inspire a healthy lifestyle.</p>
                                            <h1 class="caps-word">PROFESSIONAL EXPERIENCE</h1>

                                            <table>
                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>8/11 -</i></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Anytown Press</strong>, Anytown, State</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><i>Present</i></p>
                                                    </td>
                                                    <td>
                                                        <p><strong><i>Sports Reporter</i></strong></p>
                                                    </td>
                                                </tr>
                                            </table>

                                            <ul>
                                                <li>Develop, write, and edit news stories about local athletes and sport
                                                    events</li>
                                                <li>Consult with lead sports editor on creation of story concepts</li>
                                                <li>Standardize writing and editing style for all sports reporters</li>
                                            </ul>

                                            <table>
                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>11/10 -</i></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Anytown Press</strong>, Anytown, State</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>8/11</i></p>
                                                    </td>
                                                    <td>
                                                        <p><strong><i>Sports Intern</i></strong></p>
                                                    </td>
                                                </tr>
                                            </table>

                                            <ul>
                                                <li>Copy edited sports columns</li>
                                                <li>Assisted on-the-scene sports reporters by collecting sports stats
                                                    and interviews</li>
                                                <li>Investigated new ways to use social media to report sports news and
                                                    updates</li>
                                            </ul>

                                            <h1 class="caps-word">EDUCATION</h1>

                                            <table>
                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>6/12</i></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Anytown High School</strong>, Anytown, State</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="template-2" *ngIf="projectData.templateSelection === 1">
                                            <div class="personal-info">
                                                <h1>ANITA JOB</h1>

                                                <p>123 Main Street</p>
                                                <p>Anytown, State 11111</p>
                                                <p>(555) 555-5555</p>
                                                <p>anita.job@email.com</p>
                                            </div>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word">OBJECTIVE</h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>

                                            <p>To find a position that connects my publishing experience and my passion
                                                for sports and
                                                fitness to inspire a healthy lifestyle.</p>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word">PROFESSIONAL EXPERIENCE</h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>


                                            <p class="right-date"><strong><i>8/11 - Present</i></strong></p>

                                            <p><strong>Anytown Press</strong>, Anytown, State</p>
                                            <p><strong><i>Sports Reporter</i></strong></p>


                                            <ul>
                                                <li>Develop, write, and edit news stories about local athletes and sport
                                                    events</li>
                                                <li>Consult with lead sports editor on creation of story concepts</li>
                                                <li>Standardize writing and editing style for all sports reporters</li>
                                            </ul>

                                            <p class="right-date"><strong><i>11/10 - 8/11</i></strong></p>
                                            <p><strong>Anytown Press</strong>, Anytown, State</p>
                                            <p><strong><i>Sports Intern</i></strong></p>

                                            <ul>
                                                <li>Copy edited sports columns</li>
                                                <li>Assisted on-the-scene sports reporters by collecting sports stats
                                                    and interviews</li>
                                                <li>Investigated new ways to use social media to report sports news and
                                                    updates</li>
                                            </ul>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word">EDUCATION</h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>

                                            <p class="right-date">6/12</p>
                                            <p><strong>Anytown High School</strong>, Anytown, State</p>
                                        </div>

                                        <div class="template-3" *ngIf="projectData.templateSelection === 2">
                                            <div class="personal-info">
                                                <h1>ANITA JOB</h1>

                                                <p><strong><i>123 Main Street</i></strong></p>
                                                <p><strong><i>Anytown, State 11111</i></strong></p>
                                                <p><strong><i>(555) 555-5555</i></strong></p>
                                                <p><strong><i>anita.job@email.com</i></strong></p>
                                            </div>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word"><i>OBJECTIVE</i></h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>

                                            <p>To find a position that connects my publishing experience and my passion
                                                for sports and
                                                fitness to inspire a healthy lifestyle.</p>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word"><i>PROFESSIONAL EXPERIENCE</i></h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>

                                            <table>
                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>August 2011 to</i></p>
                                                    </td>
                                                    <td>
                                                        <p>Anytown Press, Anytown, State</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><i>Present</i></p>
                                                    </td>
                                                    <td>
                                                        <p>Sports Reporter</p>
                                                    </td>
                                                </tr>
                                            </table>

                                            <ul>
                                                <li>Develop, write, and edit news stories about local athletes and sport
                                                    events</li>
                                                <li>Consult with lead sports editor on creation of story concepts</li>
                                                <li>Standardize writing and editing style for all sports reporters</li>
                                            </ul>

                                            <table>
                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>November 2010 to</i></p>
                                                    </td>
                                                    <td>
                                                        <p>Anytown Press, Anytown, State</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="left-column">
                                                        <p><i>August 2011</i></p>
                                                    </td>
                                                    <td>
                                                        <p>Sports Intern</p>
                                                    </td>
                                                </tr>
                                            </table>

                                            <ul>
                                                <li>Copy edited sports columns</li>
                                                <li>Assisted on-the-scene sports reporters by collecting sports stats
                                                    and interviews</li>
                                                <li>Investigated new ways to use social media to report sports news and
                                                    updates</li>
                                            </ul>

                                            <table>
                                                <td>
                                                    <h1 class="caps-word">EDUCATION</h1>
                                                </td>
                                                <td class="line-container">
                                                    <div class="line"></div>
                                                </td>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!------------------------- Summary input --------------------------------------------------------------->

                                <div class="functional-summary-inputs" *ngIf="selectedInput === 'Summary'">
                                    <p>Decide what to include in your final resume by selecting items below.</p>

                                    <table class="summary-table">
                                        <tr class="summary-headers">
                                            <td *ngIf="projectData.resumeStyle === 'Functional'">
                                                <p>Skills:</p>
                                            </td>
                                            <td>
                                                <p>Experience:</p>
                                            </td>
                                            <td>
                                                <p>Education:</p>
                                            </td>
                                            <td *ngIf="projectData.resumeStyle === 'Chronological'"></td>
                                        </tr>
                                        <tr *ngFor="let i of getSummaryRows()">
                                            <td *ngIf="projectData.resumeStyle === 'Functional'">
                                                <div class="summary-rows" *ngIf="projectData.skills.length >= i + 1">
                                                    <input id="skill-checkbox-{{i}}"
                                                        [(ngModel)]="projectData.skills[i].include"
                                                        [disabled]="isSkillComplete(projectData.skills[i]) === 'Incomplete'"
                                                        type="checkbox" />
                                                    <label for="skill-checkbox-{{i}}" [ngClass]="{
                                                        'disabled-label': isSkillComplete(projectData.skills[i]) === 'Incomplete'
                                                      }">{{isSkillComplete(projectData.skills[i])}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="summary-rows"
                                                    *ngIf="projectData.experiences.length >= i + 1">
                                                    <input id="experience-checkbox-{{i}}"
                                                        [(ngModel)]="projectData.experiences[i].include"
                                                        [disabled]="isExperienceComplete(projectData.experiences[i]) === 'Incomplete'"
                                                        type="checkbox" />
                                                    <label for="experience-checkbox-{{i}}" [ngClass]="{
                                                        'disabled-label': isExperienceComplete(projectData.experiences[i]) === 'Incomplete'
                                                      }">{{isExperienceComplete(projectData.experiences[i])}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="summary-rows"
                                                    *ngIf="projectData.educations.length >= i + 1">
                                                    <input id="education-checkbox-{{i}}"
                                                        [(ngModel)]="projectData.educations[i].include"
                                                        [disabled]="isEducationComplete(projectData.educations[i]) === 'Incomplete'  "
                                                        type="checkbox" />
                                                    <label for="education-checkbox-{{i}}" [ngClass]="{
                                                        'disabled-label': isEducationComplete(projectData.educations[i]) === 'Incomplete'
                                                      }">{{isEducationComplete(projectData.educations[i])}}</label>
                                                </div>
                                            </td>
                                            <td *ngIf="projectData.resumeStyle === 'Chronological'"></td>
                                        </tr>
                                    </table>

                                    <div class="include-objective">
                                        <input id="objective-checkbox" [(ngModel)]="projectData.includeObjective"
                                            type="checkbox" />
                                        <label for="objective-checkbox">Include an Objective statement?</label>
                                    </div>
                                    <textarea [disabled]="projectData.includeObjective === false"
                                        class="objective-input" [(ngModel)]="projectData.objectiveStatement"></textarea>
                                </div>

                                <!----------------------- Final (print page) --------------------------------------------->
                                <div *ngIf="selectedInput === 'Final'">
                                    <table>
                                        <td class="preview-cell">
                                            <div class="preview-container">
                                                <div class="preview-middle">
                                                    <div class='print-preview'>
                                                        <img *ngIf='!previewImage'
                                                            src="/assets/common/loading-small.gif"
                                                            class='loading-small'>
                                                        <img *ngIf='previewImage' [src]='previewImage'>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="final-buttons">
                                                <h1>Final</h1>
                                                <p>When your resume is final, you can...</p>
                                                <button (click)="print()">
                                                    <img src="/assets/resume-generator/resume-print-icon.png" />
                                                    <p>Print Your Resume</p>
                                                </button>
                                                <button (click)="save()">
                                                    <img src="/assets/resume-generator/resume-save-icon.png" />
                                                    <p>Save Draft</p>
                                                </button>
                                                <button (click)="export()">
                                                    <img src="/assets/resume-generator/resume-save-icon.png" />
                                                    <p>Save Final</p>
                                                </button>
                                                <!-- <button>Send Final</button> -->
                                                <div class="divider-line"></div>
                                                <p class="letter-gen-label">Want to write your cover letter?</p>
                                                <a href="/letter-generator/">
                                                    <button>
                                                        <p>Launch Letter Generator</p>
                                                    </button>
                                                </a>
                                            </div>
                                        </td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class=" right-side">
                            <div class="choose-step">
                                <div class="top-container">
                                    <div class="top-styles">
                                        <h1 class="style-header">{{projectData.resumeStyle}}</h1>
                                        <button (click)="switchStyles()" class="switch-style">
                                            <p>⇄</p>
                                        </button>
                                    </div>
                                </div>

                                <!------------------------- Change to a different input --------------------------------------------------------------->
                                <button *ngFor="let selection of inputSelections" (mouseover)="selection.hovered = true"
                                    (mouseout)="selection.hovered = false" (click)="inputSelect(selection)"
                                    class="info-button-container">
                                    <div class="border-triangle"></div>
                                    <div class="border-info"></div>

                                    <div class="triangle" [ngClass]="{
                                        'triangle-hovered': selection.hovered === true,
                                        'triangle-unhovered': selection.hovered === false,
                                        'triangle-selected': selection.selected === true
                                      }"></div>

                                    <div class="info-button" [ngClass]="{
                                        'info-button-hovered': selection.hovered === true,
                                        'info-button-unhovered': selection.hovered === false,
                                        'info-button-selected': selection.selected === true
                                      }">
                                        <p [ngClass]="{
                                            'selection-title-unselected': selection.selected === false,
                                            'selection-title-selected': selection.selected === true
                                          }">{{selection.title}}</p>
                                    </div>
                                </button>

                            </div>

                            <!------------------------- Tips --------------------------------------------------------------->
                            <div class="tips" [ngClass]="{
                                'tips-action-words': (selectedInput === 'Experience' && projectData.resumeStyle === 'Chronological') || selectedInput === 'Skills'
                              }">
                                <h1>tips</h1>
                                <ul *ngIf="selectedInput === 'Personal Info'">
                                    <li>Spellcheck your personal information.</li>
                                    <li>Use a personal e-mail address, not one that is provided through a
                                        current
                                        employer.</li>
                                    <li>Your personal e-mail address should not sound unprofessional.</li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Skills'">
                                    <li>Highlight skill sets, like "Marketing", "Project Management", or "Customer
                                        Service".</li>
                                    <li>Use action words to describe your skills.</li>
                                </ul>
                                <ul
                                    *ngIf="selectedInput === 'Experience' && projectData.resumeStyle === 'Chronological'">
                                    <li>Use action words to describe your skills.</li>
                                    <li>Highlight accomplishments, not just the tasks you performed.</li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Experience' && projectData.resumeStyle === 'Functional'">
                                    <li>If you don't have an official job title, create a title that encompasses your
                                        role.</li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Education'">
                                    <li>If you don't have an area of study, leave that section blank.</li>
                                    <li>Haven't graduated yet? Just include your expected graduation date.</li>
                                </ul>

                                <ul *ngIf="selectedInput === 'Other'">
                                    <li>Add out-of-school experiences when they've provided valuable experience related
                                        to the job to which you're applying.</li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Template'">
                                    <li>Your resume is the first impression an employer has of you.</li>
                                    <li>Choose a template that fits the job.</li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Summary'">
                                    <li>Keep your resume to one or two pages.</li>
                                    <li>Include the items that are most relevant for the position you are applying for.
                                    </li>
                                </ul>
                                <ul *ngIf="selectedInput === 'Final'">
                                    <li>Don't send your resume out to employers without a cover letter!</li>
                                    <li>Your cover letter should explain in narrative format the job you're applying for
                                        and why you are perfect for the job.</li>
                                </ul>
                                <div *ngIf="(selectedInput === 'Experience' && projectData.resumeStyle === 'Chronological') || selectedInput === 'Skills'"
                                    class="see-tips-container action-words">
                                    <a href="/assets/resume-generator/resume-action-words.pdf" target="_blank"
                                        rel="noopener noreferrer">
                                        <button class="see-tips">
                                            See Action Words
                                        </button>
                                    </a>
                                </div>
                                <div class="see-tips-container">
                                    <a href="/assets/resume-generator/resume-tips.pdf" target="_blank"
                                        rel="noopener noreferrer">
                                        <button class="see-tips">
                                            See All Tips
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div class="buttons">
                                <button (click)="changeInputSelection(-1)" class="back-button2">
                                    <span>❮</span>
                                    <p>Back</p>
                                </button>

                                <button *ngIf="selectedInput != 'Final'" (click)="changeInputSelection(1)"
                                    class="next-button">
                                    <p>Next</p>
                                    <span>❯</span>
                                </button>

                                <button (click)="startOver = true" *ngIf="selectedInput == 'Final'" class="next-button">
                                    <p>New</p>
                                    <span>❯</span>
                                </button>
                            </div>
                        </div>
                    </td>
                </table>
            </div>
        </div>
        <div class="open-resume" *ngIf="step === 0">
            <h1>Open Saved Resume</h1>
            <input [(ngModel)]="fileInput" id="preview" type="file" (change)="openResume($event)" />
        </div>
        <div class="print-canvas print-content" #printCanvas *ngIf="selectedInput === 'Final'">
            <div class="template-1" *ngIf="projectData.templateSelection === 0">
                <div class="personal-info">
                    <h1>{{projectData.fullName}}</h1>

                    <div class="line"></div>

                    <p><i>{{projectData.address1}}</i></p>
                    <p><i>{{projectData.cityStateZip}}</i></p>

                    <div class="line"></div>

                    <p><i>{{projectData.phoneNumber}}</i></p>
                    <p><i>{{projectData.email}}</i></p>
                </div>

                <h1 *ngIf="projectData.includeObjective && projectData.objectiveStatement">OBJECTIVE</h1>
                <p *ngIf="projectData.includeObjective && projectData.objectiveStatement" class="objective-statement">
                    {{projectData.objectiveStatement}}</p>

                <div *ngIf="projectData.resumeStyle === 'Functional'">
                    <h1 *ngIf="getIncluded(projectData.skills).length > 0">AREAS OF MAJOR EXPERIENCE</h1>
                    <div *ngFor="let skill of getIncluded(projectData.skills)" [ngClass]="{
                        'space-between-tables': isLastIncluded(skill, projectData.skills) === false
                      }">
                        <table>
                            <tr>
                                <td class="left-column"></td>
                                <td>
                                    <p><strong>{{skill.category}}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td class="left-column"></td>
                                <td>
                                    <p>{{skill.accomplishments}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>


                <h1 *ngIf="getIncluded(projectData.experiences).length > 0" class="caps-word">PROFESSIONAL EXPERIENCE
                </h1>

                <div *ngFor="let experience of getIncluded(projectData.experiences)" [ngClass]="{
                    'space-between-tables': isLastIncluded(experience, projectData.experiences) === false
                  }">
                    <table>
                        <tr>
                            <td class="left-column">
                                <p><i>{{experience.startMonth}}/{{getExperienceStartYear(experience)}} -</i></p>
                            </td>
                            <td>
                                <p><strong>{{experience.companyName}}</strong>, {{experience.companyAddress}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="left-column">
                                <p *ngIf="experience.stillEmployed === true"><i>Present</i></p>
                                <p *ngIf="experience.stillEmployed === false">
                                    <i>{{experience.endMonth}}/{{getExperienceEndYear(1, experience)}}</i></p>
                            </td>
                            <td>
                                <p><strong><i>{{experience.jobTitle}}</i></strong></p>
                            </td>
                        </tr>
                        <tr *ngIf="projectData.resumeStyle === 'Chronological'">
                            <td class="left-column">
                            </td>
                            <td>
                                <p>{{experience.skills}}</p>
                            </td>
                        </tr>
                    </table>
                </div>


                <h1 *ngIf="getIncluded(projectData.educations).length > 0" class="caps-word">EDUCATION</h1>

                <div *ngFor="let education of getIncluded(projectData.educations)" [ngClass]="{
                    'space-between-tables': isLastIncluded(education, projectData.educations) === false
                  }">
                    <table>
                        <tr>
                            <td class="left-column">
                                <p><i>{{education.graduationMonth}}/{{getEducationYear(education)}}</i></p>
                            </td>
                            <td>
                                <p><strong>{{education.school}}</strong>, {{education.cityState}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="left-column"></td>
                            <td>
                                <p><strong><i>{{education.degree}}</i></strong></p>
                            </td>
                        </tr>
                    </table>
                </div>

                <h1 *ngIf="projectData.otherInterests" class="caps-word">OTHER INTERESTS</h1>
                <p>{{projectData.otherInterests}}</p>

                <h1 *ngIf="projectData.accomplishments" class="caps-word">SPECIAL ACCOMPLISHMENTS</h1>
                <p>{{projectData.accomplishments}}</p>
            </div>

            <div class="template-2" *ngIf="projectData.templateSelection === 1">
                <div class="personal-info">
                    <h1>{{projectData.fullName}}</h1>

                    <p>{{projectData.address1}}</p>
                    <p>{{projectData.address2}}</p>
                    <p>{{projectData.cityStateZip}}</p>
                    <p>{{projectData.phoneNumber}}</p>
                    <p>{{projectData.email}}</p>
                </div>

                <table *ngIf="projectData.includeObjective && projectData.objectiveStatement">
                    <td>
                        <h1 class="caps-word">OBJECTIVE</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <p *ngIf="projectData.includeObjective && projectData.objectiveStatement">
                    {{projectData.objectiveStatement}}
                </p>

                <div *ngIf="projectData.resumeStyle === 'Functional'">
                    <table *ngIf="getIncluded(projectData.skills).length > 0">
                        <td>
                            <h1 class="caps-word">AREAS OF MAJOR EXPERIENCE</h1>
                        </td>
                        <td class="line-container">
                            <div class="line"></div>
                        </td>
                    </table>
                    <div *ngFor="let skill of getIncluded(projectData.skills)" [ngClass]="{
                        'space-between-tables': isLastIncluded(skill, projectData.skills) === false
                        }">
                        <p class="skill-category"><strong>{{skill.category}}</strong></p>
                        <p>{{skill.accomplishments}}</p>
                    </div>
                </div>

                <table *ngIf="getIncluded(projectData.experiences).length > 0">
                    <td>
                        <h1 class="caps-word">PROFESSIONAL EXPERIENCE</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <div *ngFor="let experience of getIncluded(projectData.experiences)" [ngClass]="{
                    'space-between-tables': isLastIncluded(experience, projectData.experiences) === false
                    }">
                    <p class="right-date"><strong><i>{{experience.startMonth}}/{{getExperienceStartYear(experience)}} -
                                {{getExperienceEndYear(2, experience)}}</i></strong></p>
                    <p><strong>{{experience.companyName}}</strong>, {{experience.companyAddress}}</p>
                    <p><strong><i>{{experience.jobTitle}}</i></strong></p>
                    <p *ngIf="projectData.resumeStyle === 'Chronological'">{{experience.skills}}</p>
                </div>

                <table *ngIf="getIncluded(projectData.educations).length > 0">
                    <td>
                        <h1 class="caps-word">EDUCATION</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <div *ngFor="let education of getIncluded(projectData.educations)" [ngClass]="{
                    'space-between-tables': isLastIncluded(education, projectData.educations) === false
                    }">
                    <p class="right-date"><strong><i>{{education.graduationYear}}</i></strong></p>
                    <p><strong>{{education.school}}</strong>, {{education.cityState}}</p>
                    <p><strong><i>{{education.degree}}</i></strong></p>
                </div>

                <table *ngIf="projectData.otherInterests">
                    <td>
                        <h1 class="caps-word">OTHER INTERESTS</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>
                <p>{{projectData.otherInterests}}</p>

                <table *ngIf="projectData.accomplishments">
                    <td>
                        <h1 class="caps-word">SPECIAL ACCOMPLISHMENTS</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>
                <p>{{projectData.accomplishments}}</p>
            </div>

            <div class="template-3" *ngIf="projectData.templateSelection === 2">
                <div class="personal-info">
                    <h1>{{projectData.fullName}}</h1>

                    <p><strong><i>{{projectData.address1}}</i></strong></p>
                    <p><strong><i>{{projectData.address2}}</i></strong></p>
                    <p><strong><i>{{projectData.address2}}</i></strong></p>
                    <p><strong><i>{{projectData.cityStateZip}}</i></strong></p>
                    <p><strong><i>{{projectData.email}}</i></strong></p>
                </div>

                <table *ngIf="projectData.includeObjective && projectData.objectiveStatement">
                    <td>
                        <h1 class="caps-word"><i>OBJECTIVE</i></h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <p *ngIf="projectData.includeObjective && projectData.objectiveStatement">
                    {{projectData.objectiveStatement}}
                </p>

                <div *ngIf="projectData.resumeStyle === 'Functional'">
                    <table *ngIf="getIncluded(projectData.skills).length > 0">
                        <td>
                            <h1 class="caps-word"><i>AREAS OF MAJOR EXPERIENCE</i></h1>
                        </td>
                        <td class="line-container">
                            <div class="line"></div>
                        </td>
                    </table>
                    <div *ngFor="let skill of getIncluded(projectData.skills)" [ngClass]="{
                        'space-between-tables': isLastIncluded(skill, projectData.skills) === false
                        }">
                        <table>
                            <tr>
                                <td class="left-column"></td>
                                <td>
                                    <p>{{skill.category}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="left-column"></td>
                                <td>
                                    <p>{{skill.accomplishments}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <table *ngIf="getIncluded(projectData.experiences).length > 0">
                    <td>
                        <h1 class="caps-word"><i>PROFESSIONAL EXPERIENCE</i></h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>



                <div *ngFor="let experience of getIncluded(projectData.experiences)" [ngClass]="{
                    'space-between-tables': isLastIncluded(experience, projectData.experiences) === false
                    }">
                    <table>
                        <tr>
                            <td class="left-column">
                                <p><i>{{getMonth(experience.startMonth)}} to</i></p>
                            </td>
                            <td>
                                <p>{{experience.companyName}}, {{experience.companyAddress}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="left-column">
                                <p><i>{{getExperienceEndYear(3, experience)}}</i></p>
                            </td>
                            <td>
                                <p>{{experience.jobTitle}}</p>
                            </td>
                        </tr>
                        <tr *ngIf="projectData.resumeStyle === 'Chronological'">
                            <td class="left-column"></td>
                            <td>
                                <p>{{experience.skills}}</p>
                            </td>
                        </tr>
                    </table>
                </div>

                <table *ngIf="getIncluded(projectData.educations).length > 0">
                    <td>
                        <h1 class="caps-word">EDUCATION</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <div *ngFor="let education of getIncluded(projectData.educations)" [ngClass]="{
                    'space-between-tables': isLastIncluded(education, projectData.educations) === false
                    }">
                    <table>
                        <tr>
                            <td class="left-column">
                                <p><i>{{getEducationMonth(education)}}</i></p>
                            </td>
                            <td>
                                <p>{{education.school}}, {{education.cityState}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="left-column"></td>

                            <td>
                                <p>{{education.degree}}</p>
                            </td>
                        </tr>
                    </table>
                </div>

                <table *ngIf="projectData.otherInterests">
                    <td>
                        <h1 class="caps-word">OTHER INTERESTS</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <table *ngIf="projectData.otherInterests">
                    <tr>
                        <td class="left-column"></td>
                        <td>
                            <p>{{projectData.otherInterests}}</p>
                        </td>
                    </tr>
                </table>

                <table *ngIf="projectData.accomplishments">
                    <td>
                        <h1 class="caps-word">SPECIAL ACCOMPLISHMENTS</h1>
                    </td>
                    <td class="line-container">
                        <div class="line"></div>
                    </td>
                </table>

                <table *ngIf="projectData.accomplishments">
                    <tr>
                        <td class="left-column"></td>
                        <td>
                            <p>{{projectData.accomplishments}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>