
export class LiteraryGuideBeowulfInteractiveFile {
    step: number = 1;

    overviewBackgroundIndex: number = 1;
    languageBackgroundIndex: number = 1;
    poeticsBackgroundIndex: number = 1;
    translationsBackgroundIndex: number = 1;
    
    showMenu: boolean = false;
    showIllustration: boolean = false;
}
