import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graffiti',
  templateUrl: './graffiti.component.html',
  styleUrls: ['./graffiti.component.scss']
})
export class GraffitiComponent implements OnInit {

  canvas: any;
  draw_color = "";
  draw_width = 2;
  is_drawing = false;

  action_array = [];
  action_index = -1;
  context: any;
  default_background_color = "white";

  appContext: GraffitiComponent;

  constructor() { }

  ngOnInit() {
    this.appContext = this;

    let that = this;
    setTimeout(() => {
      console.log(document.getElementsByClassName('cool-this.canvas'));
      this.canvas = document.getElementById('canvas');
      this.context = this.canvas.getContext('2d');

      this.context.fillStyle = "white";

      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

      console.log("yo");

      //mobile initialize and draw
    //   this.canvas.addEventListener("touchstart", this.start, false);
    //   this.canvas.addEventListener("touchmove", this.draw, false);
    //   //PC initialize and draw
    //   this.canvas.addEventListener("mousedown", this.start, false);
    //   this.canvas.addEventListener("mousemove", this.draw, false);

    //   //When draw ends or leaves this.canvas
    //   this.canvas.addEventListener("touchend", stop, false);
    //   this.canvas.addEventListener("mouseup", stop, false);
    //   this.canvas.addEventListener("mouseout", stop, false);
    }, 100);



  }


hue_alter(color) {
  this.draw_color = color;
}

//Create array of all paths created
action_undo() {
  debugger;
  console.info('here');
  if (this.action_index <= 0) {
    this.canvas_clear();
  } else {
    this.action_index -= 1;
    this.action_array.pop();
    this.context.putImageData(this.action_array[this.action_index], 0, 0);
  }
}



//Intialize drawing definition
start(event) {
  this.is_drawing = true;
  this.context.beginPath();
  this.context.moveTo(event.clientX - this.canvas.offsetLeft ,
    event.clientY - this.canvas.offsetTop + window.scrollY);
  event.preventDefault();
}

draw(event) {
  //if this.is_drawing, create line, set line properties
  if (this.is_drawing) {

    this.context.lineTo(event.clientX - this.canvas.offsetLeft,
      event.clientY - this.canvas.offsetTop + window.scrollY);
    //set the Path() to the draw color
    this.context.strokeStyle = this.draw_color;
    this.context.lineWidth = this.draw_width;
    this.context.lineCap = "round";
    this.context.lineJoin = "round";
    this.context.stroke();

  }
  event.preventDefault();
}

stop(event) {

  if (this.is_drawing) {

    this.context.stroke();
    this.context.closePath();
    this.is_drawing = false;

  }

  event.preventDefault();
  if (event.type != 'mouseout') {
    this.action_array.push(this.context.getImageData(0, 0, this.canvas.width, this.canvas.height));
    this.action_index += 1;
    console.log(this.action_array);
  }

}



canvas_clear() {
  this.context.fillStyle = this.default_background_color;
  this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

  this.action_array = [];
  this.action_index = -1;
}

}
