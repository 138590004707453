import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { ProfilePublisherInteractiveFile } from 'src/app/model/ProfilePublisherInteractiveFile';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-profile-publisher',
  templateUrl: './profile-publisher.component.html',
  styleUrls: ['./profile-publisher.component.scss']
})
export class ProfilePublisherComponent implements OnInit {

  personalFileName: string = "";
  characterFileName: string = "";

  interactive: ProfilePublisherInteractiveFile = new ProfilePublisherInteractiveFile();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  //Return intro step if user is not on a Personal or Character Profile
  getIntroStep() {
    if (!this.interactive.personalInteractive.step && !this.interactive.characterInteractive.step) {
      return this.interactive.step;
    }

    return 0;
  }

  //Return personal step if user is on a Personal Profile
  getPersonalStep() {
    if (this.interactive.personalInteractive.step) {
      return this.interactive.personalInteractive.step;
    }

    return 0;
  }


  //Return character step if user is on Character Profile
  getCharacterStep() {
    if (this.interactive.characterInteractive.step) {
      return this.interactive.characterInteractive.step;
    }

    return 0;
  }

  //Print the full version of user's Profile
  @ViewChild('personalPrintCanvas', null) personalPrintCanvas: ElementRef;
  @ViewChild('characterPrintCanvas', null) characterPrintCanvas: ElementRef;
  printFinal() {
    if (this.interactive.personalInteractive.step) {
      this.printer.print([this.personalPrintCanvas], PrintOrientation.Portrait);
    }
    else {
      this.printer.print([this.characterPrintCanvas], PrintOrientation.Portrait);
    }
  }

  //Allows users to save Profile as pdf
  exportFinal() {
    if (this.interactive.personalInteractive.step) {
      let name = this.personalFileName ? this.personalFileName:"Personal-Profile"
      this.printer.export([this.personalPrintCanvas], name + '.pdf');
    }
    else {
      let name = this.characterFileName ? this.characterFileName:"Character-Profile"
      this.printer.export([this.characterPrintCanvas], name + '.pdf');
    }
  }

  //Allows user to save the project later as an rwt file
  saveInProgress() {
    if (this.interactive.personalInteractive.step) {
      let name = this.personalFileName ? this.personalFileName:"Personal-Profile"
      this.fileUtil.save(this.interactive, name + ".rwt");
    }
    else {
      let name = this.characterFileName ? this.characterFileName:"Character-Profile"
      this.fileUtil.save(this.interactive, name + ".rwt")
    }
  }

  startNew() {
    if (confirm("Are you sure you want to start a new profile? All unsaved work will be lost.")) {
      this.interactive = new ProfilePublisherInteractiveFile();
    }
  }

  fileInput: any;
  //Attempts to open file supplied by user
  openProfile(event: any) {
  const filereader = new FileReader();
  filereader.onload = (e: any) =>
  {
    try {
      this.interactive = JSON.parse(e.target.result);
    }
    catch(e) {
      alert("File cannot be opened");
      return;
    }
  }

  let file = event.target.files[0];
  filereader.readAsText(file);
  this.fileInput = null;
}


  ngOnInit() {
  }

}
