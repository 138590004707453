import { CloneVisitor } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstructAWordDragElement } from 'src/app/model/ConstructAWordDragElement';
import { ConstructAWordInteractiveFile } from 'src/app/model/ConstructAWordInteractiveFile';
import { ConstructAWordButtonInformation } from 'src/app/model/ConstructAWordLetterInformation';
import { ConstructAWordFullWords } from 'src/app/model/ConstructAWordFullWords';
import { DragElement } from 'src/app/model/DragElement';
import { PrintService } from 'src/app/service/print.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';

@Component({
  selector: 'app-construct-a-word',
  templateUrl: './construct-a-word.component.html',
  styleUrls: ['./construct-a-word.component.scss']
})
export class ConstructAWordComponent implements OnInit {

  interactive: ConstructAWordInteractiveFile = new ConstructAWordInteractiveFile();
  buttonInformation: ConstructAWordButtonInformation = new ConstructAWordButtonInformation();
  fullWords: ConstructAWordFullWords = new ConstructAWordFullWords();

  wordBankArray: string[] = [
    "an",
    "at",
    "ed",
    "et",
    "ig",
    "in",
    "op",
    "ot",
    "un"
  ];

  chooseWordArray: string[] = [
    "an",
    "ed",
    "ig",
    "at",
    "et",
    "in",
    "op",
    "ot",
    "un"
  ];

  constructor(private printer: PrintService) { }

  getOrderedArray(size: number) {
    let array = Array(size).fill(1).map((x,i) => i);
    return array;
  }

  @ViewChild("printScreen", null) printScreen: ElementRef;
  printWords() {
    this.printer.print([this.printScreen], PrintOrientation.Portrait);
  }

  goToChoose() {
    this.interactive.goBackToChoose = false;
    this.interactive.step = 2;
    this.interactive.robotText = "Choose an ending."
    this.interactive.generateChooseButtons();
    this.interactive.step = 2;
  }

  goToGame() {
    let usedButtonIndex = this.chooseWordArray.indexOf(this.interactive.gameChosenEnding);
    console.log(this.interactive.usedChooseButtons)
    console.log(this.interactive.goBackToChoose)
    console.log(this.interactive.usedChooseButtons[usedButtonIndex])
    console.log(usedButtonIndex)
    console.log(this.interactive.gameChosenEnding)
    if (this.interactive.goBackToChoose || this.interactive.usedChooseButtons[usedButtonIndex]) {
      this.goToChoose();
    } else {
      this.interactive.generateGameButtons();
      this.interactive.robotText = "Choose a beginning."
      this.interactive.step = 3;
    }
  }

  exit() {
    this.interactive.step = 5;
  }

  startOver() {
    this.interactive = new ConstructAWordInteractiveFile();
  }

  back() {
    if (this.interactive.allEndingsUsed) {
      this.interactive.step = 4;
    } else {
      this.goToChoose();
    }
  }

  getWordBankButtons() {

    let wordBankArray = Array.from(this.wordBankArray);

    let index = wordBankArray.indexOf(this.interactive.gameChosenEnding);
    wordBankArray.splice(index, 1);

    return wordBankArray;
  }

  handleDragging(mouse: string, ctx: string, index: number, state: boolean) {
    if (this.interactive.showFinalWord) return;

    switch (ctx) {
      case "choose": {
        var dragElement = this.interactive.chooseDragElements[index];

        break;
      }

      case "game": {
        var dragElement = this.interactive.gameDragElements[index];
        break;
      }
    }

    this.toggleDragging(dragElement, state);

    if (mouse == "up") {
      if (ctx == "choose" && this.closeTo(dragElement.x, dragElement.y, 400, 390)) {
        dragElement.x = 400;
        dragElement.y = 390;
        this.interactive.gameChosenEnding = this.buttonInformation.chooseButtons[index];

        this.goToGame();
      } 

      else if (ctx == "game" && this.closeTo(dragElement.x, dragElement.y, 274, 390)) {
        dragElement.x = 274;
        dragElement.y = 390;

        dragElement.chosen = true;
        this.interactive.showFinalWord = true;

        this.interactive.fullWord = this.buttonInformation.gameButtons[index] + this.interactive.gameChosenEnding;
        
        if (this.fullWords.wordMap.get(this.interactive.gameChosenEnding).includes(this.interactive.fullWord)) {
          this.interactive.foundWords.get(this.interactive.gameChosenEnding).push(this.interactive.fullWord);
          this.decWordsLeft();
          this.interactive.robotText = "CORRECT!";
          this.interactive.usedGameButtons.get(this.interactive.gameChosenEnding)[index] = true;
        } else {
          this.interactive.robotText = "That's not on my list.";
        }

        setTimeout(() => {
          if (this.getWordsLeft() == 0) {
            this.interactive.robotText = "You found them all!";
            console.log(this.interactive.usedChooseButtons)
            console.log(this.chooseWordArray.indexOf(this.interactive.gameChosenEnding))
            console.log(this.interactive.gameChosenEnding)
            console.log(this.chooseWordArray.indexOf(this.interactive.gameChosenEnding))
            this.interactive.usedChooseButtons[this.chooseWordArray.indexOf(this.interactive.gameChosenEnding)] = true;
            
            //All endings complete
            if (this.interactive.usedChooseButtons.every(x => x)) {
              this.interactive.allEndingsUsed = true;
              this.interactive.robotText = "Hurray!";
            }

            setTimeout(() => {
              this.interactive.step = 4;
              this.interactive.robotText = "Here are your words.";
              this.interactive.showFinalWord = false;
              this.interactive.goBackToChoose = true;
            }, 2000);
          

          } else {
            this.interactive.robotText = `${this.getWordsLeft()} more on my list!`;
            this.interactive.showFinalWord = false;
            this.interactive.generateGameButtons();
          }
        }, 1500);
      } else {
        this.returnDragElement(dragElement);
      }
    }
  }

  getWordsLeft() {
    return this.interactive.wordsLeft.get(this.interactive.gameChosenEnding);
  }

  decWordsLeft() {
    this.interactive.wordsLeft.set(this.interactive.gameChosenEnding, this.getWordsLeft() - 1);
  }

  closeTo(currentX: number, currentY: number, targetX: number, targetY: number) {
    return this.distanceCalc(currentX, currentY, targetX, targetY) < 70;
  }

  distanceCalc(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  returnDragElement(dragElement: ConstructAWordDragElement) {
    dragElement.x = dragElement.originalX;
    dragElement.y = dragElement.originalY;
  }

  toggleDragging(dragElement: ConstructAWordDragElement, state: boolean) {
    dragElement.dragging = state;
  }

  currentlyDragging(ctx: string) {
    switch (ctx) {
      case "choose": {
        return this.interactive.chooseDragElements.some((elem) => elem.dragging);
      }
      case "game": {
        return this.interactive.gameDragElements.some((elem) => elem.dragging);
      }
    }
  }

  getGameZIndex(index: number) {
    if (!this.currentlyDragging("game")) return 33 - index;

    if (this.interactive.gameDragElements[index].dragging) return 99;

    return 0;
  }

  ngOnInit() {
  }

}
