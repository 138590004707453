<div class='app-border'>
  <div class='title-bar'>
    <span>Compare & Contrast Map</span>
  </div>

  <!-- Open Map-->
  <div *ngIf='step==1' style='height: 40px'>
    <h2 class='open-header'>OPEN SAVED MAP:</h2>

    <input class='file-input' [(ngModel)]='fileInput' id="preview" type="file" (change)="openMap($event)">
  </div>

  <div *ngIf='step!=4' class='page-background'>
    <!-------------------------------------------------- Step 1 -------------------------------------------------->
    <div *ngIf="step==1 && !showAnswer" class='step'>
      <div class='step-1-question-block'>
        <img class='question' src='assets/comp-contrast/question.png'>
        <img class='see-answer-button' src='assets/comp-contrast/see-answer-button.png' (click)="showAnswer=true">
      </div>
      <div>
        <img class='start-button' src='assets/comp-contrast/start-button.png' (click)="showAnswer=false; step=step+1">
      </div>
    </div>

    <div *ngIf="showAnswer" class='step'>
      <div class='answer-img-block'>
        <img class='answer' src='assets/comp-contrast/answer.png'>
      </div>

      <div>
        <img class='start-button' src='assets/comp-contrast/start-button.png' (click)="showAnswer=false; step=step+1">

        <a [routerLink]="['/compare-contrast-guide']">
          <img class='learn-button' src='assets/comp-contrast/learn-button.png'>
        </a>
      </div>
    </div>

    <!-------------------------------------------------- Step 2 -------------------------------------------------->
    <div *ngIf='step==2' class='step'>
      <div class='step-2-header'>
        <div class='step-2-header-box'>
          <p class='step-2-header-text'></p>
        </div>
      </div>

      <div class='step-2-form'>
        <div class='step-2-form-box'>
          <div class='name-input-box'>
            <input class='name-input' [ngClass]="{'has-error':nameError}" maxlength='25' (change)='nameError=false'
              [(ngModel)]='map.name'>
          </div>

          <div class='items-input-box'>
            <input class='item-2-input' [ngClass]="{'has-error':item2Error}" maxlength='25' (change)='item2Error=false'
              [(ngModel)]='map.item2'>
            <input class='item-1-input' [ngClass]="{'has-error':item1Error}" maxlength='25' (change)='item1Error=false'
              [(ngModel)]='map.item1'>
          </div>

          <div class='type-input-box'>
            <div class='type-input' (click)='chooseType("wholeToWhole")'>
              <i *ngIf='wholeToWhole' class='cr-icon fa fa-check map-to-whole'></i>
            </div>

            <div class='type-input' (click)='chooseType("similaritiesToDifferences")'>
              <i *ngIf='similaritiesToDifferences' class='cr-icon fa fa-check map-to-whole'></i>
            </div>
            <div class='type-input' (click)='chooseType("pointToPoint")'>
              <i *ngIf='pointToPoint' class='cr-icon fa fa-check map-to-whole'></i>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img class='step-2-continue-button' src='assets/comp-contrast/continue-button.png' (click)='initMap()'>
      </div>
    </div>

    <!-------------------------------------------------- Step 3 -------------------------------------------------->
    <div *ngIf='step==3' class='step'>
      <img *ngIf='selection != 1' class='back-button' src='assets/comp-contrast/back-button.png'
        (click)='selection=selection-1'>
      <!-- Input Selector -->
      <div class='selector-box'>
        <div class='selector-header-box'>
          <p class='selector-header'>{{ map.type }}</p>
        </div>
        <div class='selector' (click)='selection=1' [ngClass]="{'selector-chosen':selection==1}"></div>
        <div class='selector' (click)='selection=2' [ngClass]="{'selector-chosen':selection==2}"></div>
        <div class='selector' (click)='selection=3' [ngClass]="{'selector-chosen':selection==3}"></div>
        <div class='selector' (click)='selection=4' [ngClass]="{'selector-chosen':selection==4}"></div>

      </div>

      <!-- Introduction Header-->
      <div *ngIf='selection==1' class='step-3-header'>
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Introduction</p>
          <p class='step-3-header-text'>Write a brief description of the two items ({{ map.item1 }} and {{ map.item2 }})
            and introduce your comparison.</p>
        </div>
      </div>

      <!-- Whole-to-Whole Headers -->
      <div *ngIf="selection==2 && map.type=='Whole-to-Whole'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Description of {{ map.item1 }}</p>
          <p class='step-3-header-text'>List the main ideas about {{ map.item1 }}. Include important facts or
            definitions. Remember that you will need to list the same about {{ map.item2 }}.</p>
        </div>
      </div>

      <div *ngIf="selection==3 && map.type=='Whole-to-Whole'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Description of {{ map.item2 }}</p>
          <p class='step-3-header-text'>List the main ideas about {{ map.item2 }}. Include important facts or
            definitions. List the same things you have included for {{ map.item1 }}.</p>
        </div>
      </div>

      <!-- Similarities-to-Differences Headers-->
      <div *ngIf="selection==2 && map.type=='Similarities-to-Differences'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>How Are They Similar?</p>
          <p class='step-3-header-text'>List the similarities between {{ map.item1 }} and {{ map.item2 }}. Include
            important facts or definitions.</p>
        </div>
      </div>

      <div *ngIf="selection==3 && map.type=='Similarities-to-Differences'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>How Are They Different?</p>
          <p class='step-3-header-text'>List the differences between {{ map.item1 }} and {{ map.item2 }}. Include
            important facts or definitions.</p>
        </div>
      </div>

      <!-- Point-to-Point Headers-->
      <div *ngIf="selection==2 && map.type=='Point-to-Point'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Point #1</p>
          <p class='step-3-header-text'>List the main ideas about the first point you have chosen in relation to {{
            map.item1 }} and then {{ map.item2 }}.</p>
        </div>
      </div>

      <div *ngIf="selection==3 && map.type=='Point-to-Point'">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Point #2</p>
          <p class='step-3-header-text'>List the main ideas about the second point you have chosen in relation to {{
            map.item1 }} and then {{ map.item2 }}</p>
        </div>
      </div>

      <!-- Conclusion Header -->
      <div *ngIf="selection==4">
        <div class='step-3-header-box'>
          <p class='step-3-header-large'>Conclusion</p>
          <p class='step-3-header-text'>Conclude your essay with one or two sentences. Summarize the main idea, describe
            what is important about the comparison, or explain what the reader should learn from the essay.</p>
        </div>
      </div>

      <!-- Introduction Input -->
      <div class='step-3-form-1' *ngIf='selection==1'>
        <div class='form-1-label-2-box'>
          <label class='form-1-label-2'>{{ map.item2 }}</label>
        </div>
        <div class='form-1-label-1-box'>
          <label class='form-1-label-1'>{{ map.item1 }}</label>
        </div>

        <textarea class='introduction-input' maxlength='300' [(ngModel)]='map.introduction'></textarea>
      </div>

      <div class='step-3-form-2' *ngIf='selection>1'>
        <!-- Whole-to-Whole Inputs -->
        <div *ngIf="map.type=='Whole-to-Whole'">
          <textarea *ngIf='selection==2' class='step-3-input-large' maxlength='300'
            [(ngModel)]='map.item1Description'></textarea>
          <textarea *ngIf='selection==3' class='step-3-input-large' maxlength='300'
            [(ngModel)]='map.item2Description'></textarea>
        </div>
        <!-- Similarities-to-Differences Inputs -->
        <div *ngIf="map.type=='Similarities-to-Differences'">
          <textarea *ngIf='selection==2' class='step-3-input-large' maxlength='300'
            [(ngModel)]='map.similarities'></textarea>
          <textarea *ngIf='selection==3' class='step-3-input-large' maxlength='300'
            [(ngModel)]='map.differences'></textarea>
        </div>

        <!-- Point-to-Point Point Inputs -->
        <div *ngIf="map.type=='Point-to-Point'">
          <textarea *ngIf='selection==2' class='step-3-input-large' maxlength='300' [(ngModel)]='map.point1'></textarea>
          <textarea *ngIf='selection==3' class='step-3-input-large' maxlength='300' [(ngModel)]='map.point2'></textarea>
        </div>

        <!-- Conclusion Input -->
        <div *ngIf='selection==4'>
          <textarea class='step-3-input-large' maxlength='300' [(ngModel)]='map.conclusion'></textarea>
        </div>
      </div>

      <img *ngIf='selection != 4' class='next-button' src='assets/comp-contrast/next-button.png'
        (click)='selection=selection+1'>
      <img *ngIf='selection == 4' class='finish-button' src='assets/comp-contrast/finish-button.png'
        (click)='initPrint()'>
      <img class='revise-button' src='assets/comp-contrast/revise-button.png' (click)="step=2; selection=1">
    </div>
  </div>

  <!-------------------------------------------------- Print -------------------------------------------------->
  <div *ngIf='step==4' class='step'>
    <div class="toolbar">
      <button (click)="step=step-1">
        <i class='fa fa-chevron-left'></i> &nbsp; Back
      </button>

      <button (click)="print()"><i>&nbsp;</i>&nbsp;Print</button>

      <button (click)='save()'><i class='fa fa-save'></i> Save</button>
    </div>


    <div class='print-preview'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas style="width: 800px" class='print-canvas print-content'>
      <div class='print-header'>
        <div class='step-4-img-block'>
          <img class='print-logo' src='assets/comp-contrast/logo.png'>
        </div>

        <div class='name-block'>
          <p class='name-text'>By: {{ map.name }}</p>
        </div>
      </div>

      <div class='print-items-box'>
        <div class='item-1'>
          <p class='item-1-header'>Item 1</p>
          <p class='item-1-text'>{{ map.item1 }}</p>
        </div>
        <div class='item-2'>
          <p class='item-2-header'>Item 2</p>
          <p class='item-2-text'>{{ map.item2 }}</p>
        </div>
      </div>

      <div class='print-box-top'>
        <p class='print-box-header'>Introduction</p>
        <p class='top-box-text'>{{ map.introduction }}</p>
      </div>

      <div class='print-box-bottom'>
        <p *ngIf="map.type=='Whole-to-Whole'" class='print-box-header'>Description of {{ map.item1 }}</p>
        <p *ngIf="map.type=='Similarities-to-Differences'" class='print-box-header'>How are they similar?</p>
        <p *ngIf="map.type=='Point-to-Point'" class='print-box-header'>Point #1</p>

        <p *ngIf="map.type=='Whole-to-Whole'" class='bottom-box-text'>{{ map.item1Description }}</p>
        <p *ngIf="map.type=='Similarities-to-Differences'" class='bottom-box-text'>{{ map.similarities }}</p>
        <p *ngIf="map.type=='Point-to-Point'" class='bottom-box-text'>{{ map.point1 }}</p>
      </div>

      <div class='print-box-top'>
        <p *ngIf="map.type=='Whole-to-Whole'" class='print-box-header'>Description of {{ map.item1 }}</p>
        <p *ngIf="map.type=='Similarities-to-Differences'" class='print-box-header'>How are they different?</p>
        <p *ngIf="map.type=='Point-to-Point'" class='print-box-header'>Point #2</p>

        <p *ngIf="map.type=='Whole-to-Whole'" class='top-box-text'>{{ map.item2Description }}</p>
        <p *ngIf="map.type=='Similarities-to-Differences'" class='top-box-text'>{{ map.differences }}</p>
        <p *ngIf="map.type=='Point-to-Point'" class='bottom-box-text'>{{ map.point2 }}</p>
      </div>

      <div class='print-box-bottom'>
        <p class='print-box-header'>Conclusion</p>

        <p class='bottom-box-text'>{{ map.conclusion }}</p>
      </div>
    </div>
  </div>
