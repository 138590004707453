import { AbcMatchImageMatch } from './AbcMatchImageMatch';
import { AbcMatchImageMatches } from './AbcMatchImageMatches';
import { AbcMatchInteractiveFile } from './AbcMatchInteractiveFile';

export class AbcMatchGameController {
    public levelMap: Map<number, number> = new Map([
        [1, 5],
        [2, 7],
        [3, 10]
    ])

    public pictureTileArray: AbcMatchImageMatch[] = [];
    public letterTileArray: AbcMatchImageMatch[] = [];

    public level: number = 1;
    public gameType: string;
    public score: number = 0;
    public incorrectCooldown = false;
    public matchesFound = 0;
    public hideCards = "block";
    public timeLeft = 60;
    public timeInterval;
    public interactive: AbcMatchInteractiveFile;
    
    public selected: boolean = false;
    public selectedType: string;
    public selectedId: number;

    getNumTiles() {
        return this.levelMap.get(this.level);
    }

    initTiles() {
        let used = [];
        let match;
        let matches = [];
        //Deep clone the matches array so the same objects are not used between levels
        AbcMatchImageMatches.matches.forEach(val => matches.push(Object.assign({}, val)));

        this.pictureTileArray = [];
        this.letterTileArray = [];

        for (let i = 0; i < this.getNumTiles(); i++) {  
          while (true) {
            match = matches[Math.floor(Math.random() * AbcMatchImageMatches.matches.length)];
            if (!used.includes(match)) {
              break;
            }
          }

          this.pictureTileArray.push(match);
          used.push(match);
        }
        this.letterTileArray = this.shuffle(this.pictureTileArray);

        if (this.gameType == "play") {
            this.timeInterval = setInterval((() => {
                this.timeLeft -= 1;
                if (this.timeLeft == 0) {
                    this.interactive.playStep = 0;
                    this.interactive.step = 3;
                    clearInterval(this.timeInterval);
                }
            }).bind(this), 1000);
        }
    }
    
    tilesMatch(currentTile: AbcMatchImageMatch, selectedTile: AbcMatchImageMatch) {
        return currentTile.pictureImage == selectedTile.pictureImage;
    }

    nextLevel() {
        this.hideCards = "block";
        this.level += 1;
        this.matchesFound = 0;
        this.timeLeft = 60;
        
        this.initTiles();
    }

    prepareLevelTransition() {
        this.hideCards = "none";

        setTimeout(this.nextLevel.bind(this), 1000);
    }

    tileClicked(type, id) {
        let selectedArray = null;
        let otherArray = null;

        if (type == "picture") {
            selectedArray = this.pictureTileArray;
            otherArray = this.letterTileArray;
        } else {
            selectedArray = this.letterTileArray;
            otherArray = this.pictureTileArray;
        }


        //Return if selection is on cooldown or clicked card has already been paired
        if (this.incorrectCooldown || selectedArray[id].used) return;

        if (this.selected) {
            if (type == this.selectedType) return;
            
            selectedArray[id].selected = true;

            if (this.tilesMatch(selectedArray[id], otherArray[this.selectedId])) {
                    this.matchesFound += 1;

                if (this.gameType == "play") {
                    this.score += 100;
                }

                selectedArray[id].selected = true;
                
                selectedArray[id].used = true;
                otherArray[this.selectedId].used = true;
                
                if (this.matchesFound == this.getNumTiles()) {
                    if (this.gameType == "play") {
                        this.score += this.timeLeft * 10;
                        clearInterval(this.timeInterval);
                    }

                    if (this.level == 3 && this.gameType == "play") {
                        setTimeout((() => {
                            this.interactive.playStep = 2;
                        }).bind(this), 500);
                    } else if (this.level == 3 && this.gameType == "learn") {
                        setTimeout((() => {
                            this.interactive.learnStep = 2;
                        }).bind(this), 500);
                    } else {
                        setTimeout(this.prepareLevelTransition.bind(this), 1000);
                    }
                }

            } else {
                //Answer was wrong. Flip back over after half a second
                this.incorrectCooldown = true;
                setTimeout(() => {
                    selectedArray[id].selected = false;
                    otherArray[this.selectedId].selected = false;
                    this.selected = false;
                    this.incorrectCooldown = false;
                }, 500);
            }

            this.selected = false;

        } else {
            this.selected = true;
            this.selectedId = id;
            this.selectedType = type;
            selectedArray[id].selected = true;
        }
    }

    shuffle(originalArray) {
        let array = [];
        originalArray.forEach(val => array.push(Object.assign({}, val)));
        var currentIndex = array.length,  randomIndex;
      
        while (0 !== currentIndex) {
      
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    constructor(interactive: AbcMatchInteractiveFile) { 
        this.interactive = interactive;
    }
    
}