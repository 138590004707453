export class DramaMapFile {
    //First page input values
    title: string;
    titleError: boolean = false;
    
    name: string;
    nameError: boolean = false;
  
    type: string;
  
    //Character input values
    characterName: string;
    characterNameEntered: boolean = false;
    characterNameError: boolean = false;
  
    appearance: string;
    appearanceEntered: boolean = false;
    appearanceError: boolean = false;
  
    behavior: string;
    behaviorEntered: boolean = false;
    behaviorError: boolean = false;

    reaction: string;
    reactionEntered: boolean = false;
    reactionError: boolean = false;

    //Conflict input values
    conflict: string;
    conflictEntered: boolean = false;
    conflictError: boolean = false;

    reason: string;
    reasonEntered: boolean = false;
    reasonError: boolean = false;

    possibleResolution: string;
    possibleResolutionEntered: boolean = false;
    possibleResolutionError: boolean = false;

    //Resolution input values
    resolution: string;
    resolutionEntered: boolean = false;
    resolutionError: boolean = false;

    afterResolution: string;
    afterResolutionEntered: boolean = false;
    afterResolutionError: boolean = false;

    effect: string;
    effectEntered: boolean = false;
    effectError: boolean = false;

    //Setting input values
    place: string;
    placeEntered: boolean = false;
    placeError: boolean = false;

    time: string;
    timeEntered: boolean = false;
    timeError: boolean = false;

    description: string;
    descriptionEntered: boolean = false;
    descriptionError: boolean = false;
}