<div class="app-border">
  <div class="title-bar">
    <span>Animal Inquiry</span>
    <select
      class="graphic-organizer-menu"
      [(ngModel)]="projectData.graphicOrganizer"
      (ngModelChange)="handleSelection()"
    >
      <option value="graphicOrganizers">Graphic Organizers</option>
      <option
        *ngIf="projectData.author && projectData.title"
        value="animalFacts"
      >
        Animal Facts
      </option>
      <option
        *ngIf="projectData.author && projectData.title"
        value="animalBabies"
      >
        Animal Babies
      </option>
      <option
        *ngIf="projectData.author && projectData.title"
        value="animalInteractions"
      >
        Animal Interactions
      </option>
      <option
        *ngIf="projectData.author && projectData.title"
        value="animalHabitats"
      >
        Animal Habitats
      </option>
    </select>
    <br />
    <span *ngIf="step >= 1"
      >{{ projectData.title }} by {{ projectData.author }}</span
    >
  </div>
  <div class="header">
    <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'animalFacts'">
      <label class="header-label"> Animal Facts </label>
      <p *ngIf="step === 1" class="header-instruction">
        Enter the animal's name.
      </p>
      <p *ngIf="step === 2" class="header-instruction">
        What does the animal look like?
      </p>
      <p *ngIf="step === 3" class="header-instruction">
        How does the animal move? What sounds does it make?
      </p>
      <p *ngIf="step === 4" class="header-instruction">
        What does the animal eat?
      </p>
      <button *ngIf="step === 5" class="goto-print" (click)="initPrint()"> 
        Next
      </button>
    </div>
    <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'animalBabies'">
      <label class="header-label"> Animal Babies </label>
      <p *ngIf="step === 1" class="header-instruction">
        How is the animal born? (hatches from an egg, born alive)
      </p>
      <p *ngIf="step === 2" class="header-instruction">
        What do the animal babies look like?
      </p>
      <p *ngIf="step === 3" class="header-instruction">
        How does the baby change as it grows into an adult?
      </p>
      <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
        <i class="fa fa-print"></i>
        Print
      </button>
    </div>
    <div
      *ngIf="step >= 1 && projectData.graphicOrganizer === 'animalInteractions'"
    >
      <label class="header-label"> Animal Interactions </label>
      <p *ngIf="step === 1" class="header-instruction">
        Who are the animal's enemies?
      </p>
      <p *ngIf="step === 2" class="header-instruction">
        How does the animal protect itself?
      </p>
      <p *ngIf="step === 3" class="header-instruction">
        How does the animal communicate?
      </p>
      <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
        <i class="fa fa-print"></i>
        Print
      </button>
    </div>
    <div *ngIf="step >= 1 && projectData.graphicOrganizer === 'animalHabitats'">
      <label class="header-label"> Animal Habitats </label>
      <p *ngIf="step === 1" class="header-instruction">
        Where does the animal live? (rain forest, desert, ponds)
      </p>
      <p *ngIf="step === 2" class="header-instruction">
        How has the animal adapted to its habitat? (coloring, hibernating, heavy
        fur in winter)
      </p>
      <p *ngIf="step === 3" class="header-instruction">
        Write a detailed description of the habitat. (weather, noise, colors)
      </p>
      <button *ngIf="step === 4" class="goto-print" (click)="initPrint()">
        <i class="fa fa-print"></i>
        Print
      </button>
    </div>
  </div>

  <!------------------------------------------  Intro Screen  -------------------------------------------------------->
  <div *ngIf="step === 0" class="step-0">
    <h1>Project Labels</h1>
    <p>Enter a title for your project and your name.</p>

    <div class="block">
      <label>Title:</label>
      <input
        type="text"
        id="title"
        name="title"
        [(ngModel)]="projectData.title"
      />
    </div>

    <div class="block">
      <label>By:</label>
      <input
        type="text"
        id="author"
        name="author"
        [(ngModel)]="projectData.author"
      />
    </div>

    <div class="choose-graphic">
      <h1>Choose a Graphic Organizer</h1>
      <p *ngIf="showWarning" class="warning">
        You must first enter a title for your project and your name.
      </p>

      <div
        [ngStyle]="{ 'margin-top': graphicMargin }"
        (mouseenter)="enterInitGraphOrg()"
        (mouseleave)="leaveInitGraphOrg()"
        (click)="initGraphicOrganizer('animalFacts')"
        class="choose-graphic-button1"
      >
        <img src="assets/animal-inquiry/animals.png" />
        <label class="choose-graphic-label"
          >Animal <br />
          Facts</label
        >
      </div>
      <div
        (mouseenter)="enterInitGraphOrg()"
        (mouseleave)="leaveInitGraphOrg()"
        (click)="initGraphicOrganizer('animalBabies')"
        class="choose-graphic-button2"
      >
        <img src="assets/animal-inquiry/babies.png" />
        <label class="choose-graphic-label"
          >Animal <br />
          Babies</label
        >
      </div>
      <div
        (mouseenter)="enterInitGraphOrg()"
        (mouseleave)="leaveInitGraphOrg()"
        (click)="initGraphicOrganizer('animalInteractions')"
        class="choose-graphic-button3"
      >
        <img src="assets/animal-inquiry/interaction.png" />
        <label class="choose-graphic-label"
          >Animal <br />
          Interactions</label
        >
      </div>
      <div
        (mouseenter)="enterInitGraphOrg()"
        (mouseleave)="leaveInitGraphOrg()"
        (click)="initGraphicOrganizer('animalHabitats')"
        class="choose-graphic-button4"
      >
        <img src="assets/animal-inquiry/habitat.png" />
        <label class="choose-graphic-label"
          >Animal <br />
          Habitats</label
        >
      </div>
    </div>
  </div>
  <!------------------------------------------- Animal Facts ------------------------------------->
  <div
    class="animal-facts"
    *ngIf="
      1 <= step && step <= 5 && projectData.graphicOrganizer === 'animalFacts'
    "
  >
    <img class="facts-img" src="assets/animal-inquiry/animals.png" />
    <div class="name-input-div">
      <input
        id="name-input"
        (keyup.enter)="onEnter(2)"
        [(ngModel)]="projectData.animalName"
      />
      <label>Animal Name</label>
    </div>
    <p *ngIf="step === 5" class="choose-go-prompt">
      Select another graphic <br />organizer or choose print.
    </p>
    <br />

    <div class="animal-facts-looks" *ngIf="step >= 2">
      <div class="diagonal1"></div>
      <textarea
        class="animal-description looks-input"
        (keyup.enter)="onEnter(3)"
        [(ngModel)]="projectData.animalLooks"
      ></textarea>
    </div>

    <div class="animal-facts-move" *ngIf="step >= 3">
      <div class="line1"></div>
      <textarea
        class="animal-description move-input"
        (keyup.enter)="onEnter(4)"
        [(ngModel)]="projectData.animalMove"
      ></textarea>
    </div>

    <div class="animal-facts-eat" *ngIf="step >= 4">
      <div class="diagonal2"></div>
      <textarea
        class="animal-description eat-input"
        (keyup.enter)="onEnter(5)"
        [(ngModel)]="projectData.animalEat"
      ></textarea>
    </div>

    <p *ngIf="1 <= step && step <= 4" class="press-enter">
      Press the enter key <br />
      to see the next prompt.
    </p>
  </div>

  <!----------------------------------  Animal Babies  ------------------------------------>
  <div
    class="animal-babies"
    *ngIf="
      1 <= step && step <= 4 && projectData.graphicOrganizer == 'animalBabies'
    "
  >
    <img class="facts-img" src="assets/animal-inquiry/babies.png" />
    <p *ngIf="step === 4" class="choose-go-prompt2">
      Select another graphic <br />organizer or choose print.
    </p>
    <br />
    <div class="animal-facts-looks" *ngIf="step >= 1">
      <div class="diagonal1"></div>
      <textarea
        class="animal-description looks-input"
        (keyup.enter)="onEnter(2)"
        [(ngModel)]="projectData.animalBorn"
      ></textarea>

      <div class="animal-babies-move" *ngIf="step >= 2">
        <div class="line1"></div>
        <textarea
          class="animal-description move-input"
          (keyup.enter)="onEnter(3)"
          [(ngModel)]="projectData.animalBabiesLooks"
        ></textarea>
      </div>

      <div class="animal-facts-eat" *ngIf="step >= 3">
        <div class="diagonal2"></div>
        <textarea
          class="animal-description eat-input"
          (keyup.enter)="onEnter(4)"
          [(ngModel)]="projectData.animalChange"
        ></textarea>
      </div>

      <p *ngIf="1 <= step && step <= 3" class="press-enter">
        Press the enter key <br />
        to see the next prompt.
      </p>
    </div>
  </div>
  <!---------------------------------  Animal Interactions  --------------------------------->
  <div
    class="animal-babies"
    *ngIf="
      1 <= step &&
      step <= 4 &&
      projectData.graphicOrganizer == 'animalInteractions'
    "
  >
    <img class="facts-img" src="assets/animal-inquiry/interaction.png" />
    <p *ngIf="step === 4" class="choose-go-prompt2">
      Select another graphic <br />organizer or choose print.
    </p>
    <br />
    <div class="animal-facts-looks" *ngIf="step >= 1">
      <div class="diagonal1"></div>
      <textarea
        class="animal-description looks-input"
        (keyup.enter)="onEnter(2)"
        [(ngModel)]="projectData.animalEnemies"
      ></textarea>

      <div class="animal-babies-move" *ngIf="step >= 2">
        <div class="line1"></div>
        <textarea
          class="animal-description move-input"
          (keyup.enter)="onEnter(3)"
          [(ngModel)]="projectData.animalProtection"
        ></textarea>
      </div>

      <div class="animal-facts-eat" *ngIf="step >= 3">
        <div class="diagonal2"></div>
        <textarea
          class="animal-description eat-input"
          (keyup.enter)="onEnter(4)"
          [(ngModel)]="projectData.animalCommunicate"
        ></textarea>
      </div>

      <p *ngIf="1 <= step && step <= 3" class="press-enter">
        Press the enter key <br />
        to see the next prompt.
      </p>
    </div>
  </div>

  <!----------------------------------  Animal Habitat  ---------------------------------->
  <div
    class="animal-babies"
    *ngIf="
      1 <= step && step <= 4 && projectData.graphicOrganizer == 'animalHabitats'
    "
  >
    <img class="facts-img" src="assets/animal-inquiry/habitat.png" />
    <p *ngIf="step === 4" class="choose-go-prompt2">
      Select another graphic <br />organizer or choose print.
    </p>
    <br />
    <div class="animal-facts-looks" *ngIf="step >= 1">
      <div class="diagonal1"></div>
      <textarea
        class="animal-description looks-input"
        (keyup.enter)="onEnter(2)"
        [(ngModel)]="projectData.animalLocation"
      ></textarea>

      <div class="animal-babies-move" *ngIf="step >= 2">
        <div class="line1"></div>
        <textarea
          class="animal-description move-input"
          (keyup.enter)="onEnter(3)"
          [(ngModel)]="projectData.animalAdapt"
        ></textarea>
      </div>

      <div class="animal-facts-eat" *ngIf="step >= 3">
        <div class="diagonal2"></div>
        <textarea
          class="animal-description eat-input"
          (keyup.enter)="onEnter(4)"
          [(ngModel)]="projectData.animalHabitat"
        ></textarea>
      </div>

      <p *ngIf="1 <= step && step <= 3" class="press-enter">
        Press the enter key <br />
        to see the next prompt.
      </p>
    </div>
  </div>
  <!-------------------------------------------  Printing   ----------------------------------------------->
  <div *ngIf="step === 6">
    <button class="print-button" (click)='print()'>
      <i class="fa fa-print"></i>
      Print
    </button>
    <br />
    <br />

    <div class='print-preview-landscape'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas class='print-box print-canvas'>
      <div >
        <!---------------------------  Animal Facts Printing  ------------------------------------->
        <div *ngIf="projectData.graphicOrganizer === 'animalFacts'">
          <p class="print-name-title">
            {{ projectData.title }} by {{ projectData.author }}
          </p>
          <img class="print-image" src="assets/animal-inquiry/animals.png" />
          <p class="print-character-name">{{ projectData.animalName }}</p>
          <div class="inputs">
            <div class="input-box1">
              <h3 class="input-label">What does the animal look like?</h3>
              <div class="print-diagonal1"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalLooks }}</p>
              </div>
            </div>

            <div class="input-box2">
              <h3 class="input-label">How does the animal move?</h3>
              <div class="print-line1"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalMove }}</p>
              </div>
            </div>

            <div class="input-box3">
              <h3 class="input-label">What does the animal eat?</h3>
              <div class="print-diagonal2"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalEat }}</p>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------------------  Animal Babies Printing  ----------------------------------------->
        <div *ngIf="projectData.graphicOrganizer === 'animalBabies'">
          <p class="print-name-title">
            {{ projectData.title }} by {{ projectData.author }}
          </p>
          <img class="print-image2" src="assets/animal-inquiry/babies.png" />
          <div class="inputs">
            <div class="input-box1">
              <h3 class="input-label3">
                How is the animal born?
              </h3>
              <div class="print-diagonal1"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalBorn }}</p>
              </div>
            </div>
            <div class="input-box2">
              <h3 class="input-label">What do the animal babies look like?</h3>
              <div class="print-line1"></div>
              <div class="print-output-box">
                <p class="print-output-text">
                  {{ projectData.animalBabiesLooks }}
                </p>
              </div>
            </div>

            <div class="input-box3">
              <h3 class="input-label2">
                How does the baby change as it grows into an adult?
              </h3>
              <div class="print-diagonal2"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalChange }}</p>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------------------  Animal Interactions Printing  ----------------------------------------->
        <div *ngIf="projectData.graphicOrganizer === 'animalInteractions'">
          <p class="print-name-title">
            {{ projectData.title }} by {{ projectData.author }}
          </p>
          <img
            class="print-image"
            src="assets/animal-inquiry/interaction.png"
          />
          <div class="inputs">
            <div class="input-box1">
              <h3 class="input-label">Who are the animal's enemies?</h3>
              <div class="print-diagonal1"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalEnemies }}</p>
              </div>
            </div>
            <div class="input-box2">
              <h3 class="input-label">How does the animal protect itself?</h3>
              <div class="print-line1"></div>
              <div class="print-output-box">
                <p class="print-output-text">
                  {{ projectData.animalProtection }}
                </p>
              </div>
            </div>

            <div class="input-box3">
              <h3 class="input-label">How does the animal communicate?</h3>
              <div class="print-diagonal2"></div>
              <div class="print-output-box">
                <p class="print-output-text">
                  {{ projectData.animalCommunicate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------------------  Animal Habitats Printing  ----------------------------------------->
        <div *ngIf="projectData.graphicOrganizer === 'animalHabitats'">
          <p class="print-name-title">
            {{ projectData.title }} by {{ projectData.author }}
          </p>
          <img
            class="print-image"
            src="assets/animal-inquiry/habitat.png"
          />
          <div class="inputs">
            <div class="input-box1">
              <h3 class="input-label">Where does the animal live?</h3>
              <div class="print-diagonal1"></div>
              <div class="print-output-box">
                <p class="print-output-text">{{ projectData.animalLocation }}</p>
              </div>
            </div>
            <div class="input-box2">
              <h3 class="input-label">How has the animal adapted to its habitat? </h3>
              <div class="print-line1"></div>
              <div class="print-output-box">
                <p class="print-output-text">
                  {{ projectData.animalAdapt }}
                </p>
              </div>
            </div>

            <div class="input-box3">
              <h3 class="input-label">Write a detailed description of the habitat.</h3>
              <div class="print-diagonal2"></div>
              <div class="print-output-box">
                <p class="print-output-text">
                  {{ projectData.animalHabitat }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
