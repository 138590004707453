<div class="app-border">
    <div class="title-bar">
        <span>Persuasion Map</span>
    </div>
    
    <div class="blank-map" #blankMap></div>

    <div *ngIf="interactive.step==1" class="step">
        <div class="intro-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <img *ngIf="!interactive.unGraySave" class="save-button-grayed" src="../../../assets/persuasion-map/pm-save-button-grayed.png">
            <button *ngIf="interactive.unGraySave" class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="intro-input-area">
                <input class="name-input-box" (change)="checkForSaving()" [(ngModel)]="interactive.name" [ngClass]="{'has-error': !interactive.nameValid && interactive.hasBeenChecked}" maxlength="25">
                <br>
                <input class="title-input-box" (change)="checkForSaving()" [(ngModel)]="interactive.title" [ngClass]="{'has-error': !interactive.titleValid && interactive.hasBeenChecked}" maxlength="25">
            </div>
            <button class="print-blank-map-button" (click)="printBlankMap()"></button>
            <button class="get-started-button" (click)="checkIncomplete()"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==2" class="step">
        <div class="thesis-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <img class="nav-1-active" src="../../../assets/persuasion-map/pm-nav-1-hover.png"/>
                <button class="nav-2" (click)="interactive.step=3"></button>
                <div class="nav-3">
                    <button class="nav-3-1" (click)="interactive.step=4"></button>
                    <button class="nav-3-2" (click)="interactive.step=5"></button>
                    <button class="nav-3-3" (click)="interactive.step=6"></button>
                </div>
                <button class="nav-4" (click)="interactive.step=7"></button>
            </div>
            <textarea class="thesis-input" [(ngModel)]="interactive.thesis" maxlength="350"></textarea>
            <button class="back-button" (click)="interactive.step=1"></button>
            <button class="down-button" (click)="interactive.step=3"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==3" class="step">
        <div class="main-reasons-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <button class="nav-1" (click)="interactive.step=2"></button>
                <img class="nav-2-active" src="../../../assets/persuasion-map/pm-nav-2-hover.png">
                <div class="nav-3">
                    <button class="nav-3-1" (click)="interactive.step=4"></button>
                    <button class="nav-3-2" (click)="interactive.step=5"></button>
                    <button class="nav-3-3" (click)="interactive.step=6"></button>
                </div>
                <button class="nav-4" (click)="interactive.step=7"></button>
            </div>
            <button class="up-button" (click)="interactive.step=2"></button>
            <div class="main-reasons-input">
                <textarea class="reason-1-input reason-input" [(ngModel)]="interactive.reasons[0].reason" maxlength="130"></textarea>
                <textarea class="reason-2-input reason-input" [(ngModel)]="interactive.reasons[1].reason" maxlength="130"></textarea>
                <textarea class="reason-3-input reason-input" [(ngModel)]="interactive.reasons[2].reason" maxlength="130"></textarea>
            </div>
            <div class="down-buttons">
                <button class="down-button-1 down-button" (click)="interactive.step=4"></button>
                <button class="down-button-2 down-button" (click)="interactive.step=5"></button>
                <button class="down-button-3 down-button" (click)="interactive.step=6"></button>
            </div>
        </div>
    </div>

    <div *ngIf="interactive.step==4" class="step">
        <div class="reason-1-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <button class="nav-1" (click)="interactive.step=2"></button>
                <button class="nav-2" (click)="interactive.step=3"></button>
                <div class="nav-3">
                    <img class="nav-3-1-active" src="../../../assets/persuasion-map/pm-nav-3-1-hover.png">
                    <button class="nav-3-2" (click)="interactive.step=5"></button>
                    <button class="nav-3-3" (click)="interactive.step=6"></button>
                </div>
                <button class="nav-4" (click)="interactive.step=7"></button>
            </div>
            <button class="up-button" (click)="interactive.step=3"></button>
            <div class="examples-input">
                <textarea class="example-1-input example-input" [(ngModel)]="interactive.reasons[0].examples[0]" maxlength="100"></textarea>
                <br>
                <textarea class="example-2-input example-input" [(ngModel)]="interactive.reasons[0].examples[1]" maxlength="100"></textarea>
                <br>
                <textarea class="example-3-input example-input" [(ngModel)]="interactive.reasons[0].examples[2]" maxlength="100"></textarea>
            </div>
            <button class="down-button" (click)="interactive.step=7"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==5" class="step">
        <div class="reason-2-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <button class="nav-1" (click)="interactive.step=2"></button>
                <button class="nav-2" (click)="interactive.step=3"></button>
                <div class="nav-3">
                    <button class="nav-3-1" (click)="interactive.step=4"></button>
                    <img class="nav-3-2-active" src="../../../assets/persuasion-map/pm-nav-3-2-hover.png">
                    <button class="nav-3-3" (click)="interactive.step=6"></button>
                </div>
                <button class="nav-4" (click)="interactive.step=7"></button>
            </div>
            <button class="up-button" (click)="interactive.step=3"></button>
            <div class="examples-input">
                <textarea class="example-1-input example-input" [(ngModel)]="interactive.reasons[1].examples[0]" maxlength="100"></textarea>
                <br>
                <textarea class="example-2-input example-input" [(ngModel)]="interactive.reasons[1].examples[1]" maxlength="100"></textarea>
                <br>
                <textarea class="example-3-input example-input" [(ngModel)]="interactive.reasons[1].examples[2]" maxlength="100"></textarea>
            </div>
            <button class="down-button" (click)="interactive.step=7"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==6" class="step">
        <div class="reason-3-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <button class="nav-1" (click)="interactive.step=2"></button>
                <button class="nav-2" (click)="interactive.step=3"></button>
                <div class="nav-3">
                    <button class="nav-3-1" (click)="interactive.step=4"></button>
                    <button class="nav-3-2" (click)="interactive.step=5"></button>
                    <img class="nav-3-3-active" src="../../../assets/persuasion-map/pm-nav-3-3-hover.png">
                </div>
                <button class="nav-4" (click)="interactive.step=7"></button>
            </div>
            <button class="up-button" (click)="interactive.step=3"></button>
            <div class="examples-input">
                <textarea class="example-1-input example-input" [(ngModel)]="interactive.reasons[2].examples[0]" maxlength="100"></textarea>
                <br>
                <textarea class="example-2-input example-input" [(ngModel)]="interactive.reasons[2].examples[1]" maxlength="100"></textarea>
                <br>
                <textarea class="example-3-input example-input" [(ngModel)]="interactive.reasons[2].examples[2]" maxlength="100"></textarea>
            </div>
            <button class="down-button" (click)="interactive.step=7"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==7" class="step">
        <div class="conclusion-background frame" [style.opacity]="currentOpacity">
            <button class="open-button" (click)="handleDialog('open', true)"></button>
            <button class="save-button" (click)="handleDialog('save', true)"></button>
            <div class="nav-background">
                <button class="nav-1" (click)="interactive.step=2"></button>
                <button class="nav-2" (click)="interactive.step=3"></button>
                <div class="nav-3">
                    <button class="nav-3-1" (click)="interactive.step=4"></button>
                    <button class="nav-3-2" (click)="interactive.step=5"></button>
                    <button class="nav-3-3" (click)="interactive.step=6"></button>
                </div>
                <img class="nav-4-active" src="../../../assets/persuasion-map/pm-nav-4-hover.png">
            </div>
            <div class="up-buttons">
                <button class="up-button-1 up-button" (click)="interactive.step=4"></button>
                <button class="up-button-2 up-button" (click)="interactive.step=5"></button>
                <button class="up-button-3 up-button" (click)="interactive.step=6"></button>
            </div>
            <textarea class="conclusion-input" [(ngModel)]="interactive.conclusion" maxlength="350"></textarea>
            <br>
            <button class="finish-button" (click)="interactive.step=8"></button>
        </div>
    </div>

    <div *ngIf="interactive.step==8" class="step">
        <div class="finished-background">
            <div class='toolbar'>
                <button (click)="interactive.step=7">
                  <i class='fa fa-chevron-left'></i> &nbsp; Back
                </button>
            </div>
            <h1>Congrats! You have finished your Persuasion Map.</h1>
            <p>
                Review your map. If you want to make changes, use the Back button.
            </p>
            <p>
                When it is finished, you can ...
            </p>
            <div class="handler-buttons">
                <button (click)='printFinal()'>
                  <i class='fa fa-print'></i>&nbsp;Print
                </button>
                <button (click)='exportFinal()'>
                  <i class='fa fa-download'></i>&nbsp;Export
                </button>
                <button (click)='saveInProgress()'>
                  <i class='fa fa-save'></i>&nbsp;Save
                </button>
                <button (click)="handleDialog('startNew', true)">Start New Project</button>
            </div>
            <br>
            <div class="final-name-container">
                <label for="final-name-input">Enter a name for your final Persuasion Map: 
                    <input class="final-name-input" id="final-name-input" [(ngModel)]="finalName">
                </label>
            </div>
            <div class="persuasion-map-background">
                <p class="name"> {{ interactive.name }} </p>
                <div class="title-container">
                    <p class="title"> {{ interactive.title }} </p>
                </div>
                <p class="introduction"> {{ interactive.thesis }} </p>
                <div class="main-reasons">
                    <p class="reason-1"> {{  interactive.reasons[0].reason }} </p>
                    <p class="reason-2"> {{  interactive.reasons[1].reason }} </p>
                    <p class="reason-3"> {{  interactive.reasons[2].reason }} </p>
                </div>
                <div class="example-area">
                    <div *ngFor="let reason of interactive.reasons; let i = index" class="example-set-{{ i + 1 }}">
                        <div class="example-container" *ngFor="let example of reason.examples; let j = index">
                            <div class="example">
                                <img class="example-image" src="../../../assets/persuasion-map/pm-example-{{ j + 1 }}.png"/>
                                <p class="example-text"> {{ example }} </p>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <p class="conclusion"> {{ interactive.conclusion }} </p>
            </div>
            <div class="persuasion-map-background" id="map-copy" #printCanvas>
                <p class="name"> {{ interactive.name }} </p>
                <div class="title-container">
                    <p class="title"> {{ interactive.title }} </p>
                </div>
                <p class="introduction"> {{ interactive.thesis }} </p>
                <div class="main-reasons">
                    <p class="reason-1"> {{  interactive.reasons[0].reason }} </p>
                    <p class="reason-2"> {{  interactive.reasons[1].reason }} </p>
                    <p class="reason-3"> {{  interactive.reasons[2].reason }} </p>
                </div>
                <div class="example-area">
                    <div *ngFor="let reason of interactive.reasons; let i = index" class="example-set-{{ i + 1 }}">
                        <div class="example-container" *ngFor="let example of reason.examples; let j = index">
                            <div class="example">
                                <img class="example-image" src="../../../assets/persuasion-map/pm-example-{{ j + 1 }}.png"/>
                                <p class="example-text"> {{ example }} </p>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <p class="conclusion"> {{ interactive.conclusion }} </p>
            </div>

        </div>
    </div>

    <div *ngIf="showOpenDialog" class="open-background">
        <input class="open-input" [(ngModel)]="openName">
        <button class="cancel-button" (click)="handleDialog('open', false)"></button>
        <button class="find-my-file-button" (click)="fileInput.click()"></button>
        <input type="file" class="file-input" (change)="openFile($event)" #fileInput>
    </div>

    <div *ngIf="showSaveDialog" class="save-background">
        <input class="save-input" [(ngModel)]="saveInProgressName">
        <button class="cancel-button" (click)="handleDialog('save', false)"></button>
        <button class="save-file-button" (click)="saveInProgress()"></button>
    </div>

    <div *ngIf="showStartNewDialog" class="start-new-background">
        <button class="cancel-button" (click)="handleDialog('startNew', false);"></button>
        <button class="start-new-button" (click)="startNew()"></button>
    </div>

</div>
