import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WordMatrix } from 'src/app/model/WordMatrix';
import { DragUtilService } from "src/app/service/drag-util.service";
import { DragElement } from "../../model/DragElement";
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';
import * as _ from "lodash";



@Component({
    selector: 'app-word-matrix',
    templateUrl: './word-matrix.component.html',
    styleUrls: ['./word-matrix.component.scss']
})
export class WordMatrixComponent implements OnInit {

    step: number = 0;
    lastStep: number;

    projectData: WordMatrix = new WordMatrix;

    nameWarning: boolean = false;
    hoveredSelection: number;
    learnMoreHovered: boolean = false;

    directionsOpen: boolean = true;

    conceptsWords: object = this.defaultConceptsWords();
    currentWords: string[];
    conceptSelected: boolean = false;
    currentConcept: string;
    printWarning: boolean = false;

    newConceptTitle: string;
    newConceptOpened: boolean;

    newWord: string;
    newWordOpened: boolean;

    newWordPlaceholder: string = "";
    emptyLabelError = false;
    printing = false;



    @ViewChild('printPreview', null) printPreview: ElementRef;
    @ViewChild('printCanvas', null) printCanvas: ElementRef;
    @ViewChild('printCanvas2', null) printCanvas2: ElementRef;

    constructor(
        private dragUtil: DragUtilService,
        private printer: PrintService,
    ) { }

    ngOnInit() {
    }

    moveEvent($event: MouseEvent, elem: DragElement) { }

    addLabel(text) {
        let elem = this.dragUtil.createElement(this.projectData.elements);
        elem.type = "text";
        elem.text = text;
        this.projectData.editId = elem.id;
        this.projectData.elements.push(elem);
        this.currentWords.splice(this.currentWords.indexOf(text), 1);
        this.editLabel(elem);
    }

    editLabel(elem: DragElement) {
        DragElement.select(elem);
        this.projectData.editId = elem.id;
        this.projectData.inputLabelDescription = elem.description;
        this.projectData.inputLabelText = elem.text;
        this.projectData.editingLabel = true;
    }

    saveLabel() {
        // clear error
        this.emptyLabelError = false;

        // Ensure event name is entered.
        if (!this.projectData.inputLabelText) {
            this.emptyLabelError = true;
            return;
        }
        // update event
        let elem = _.find(this.projectData.elements, (x) => x.id == this.projectData.editId);
        elem.text = this.projectData.inputLabelText;
        elem.description = this.projectData.inputLabelDescription;
        elem.selected = true;

        this.clearSelection();
        this.projectData.editingLabel = false;
    }

    clearSelection() {
        this.projectData.inputLabelText = null;
        this.projectData.inputLabelDescription = null;
        this.projectData.editId = null;
    }

    async print() {
        this.printing = true;

        this.projectData.elements.forEach(x => { 
            x.selected = false;
            // deselect drag element
            DragElement.deselect(x);
         });

        //set a timeout to wait for elements to clear selected
        setTimeout(async () => { 
            await this.printer.print([this.printCanvas, this.printCanvas2], PrintOrientation.Landscape, true, true, false);
            this.printing = false;
        }, 100);
    }

    new() {
        this.step = 0;
        (this.projectData as any) = {};
    }

    async export() {
        this.printing = true;

        this.projectData.elements.forEach(x => { x.selected = false; });

        await this.printer.export([this.printCanvas, this.printCanvas2], 'word-matrix', PrintOrientation.Landscape, true, false, false);
        this.printing = false;
    }

    defaultConceptsWords() {
        return {
            "Verbal expression": ['say', 'interject', 'assert', 'nag', 'state', 'protest', 'chide', 'bark', 'comment', 'express', 'impart', 'mention', 'grumble'],
            "Sad": ['unhappy', 'sorrowful', 'dismal', 'in the dumps', 'sulky', 'compassionate', 'depressed', 'heavyhearted'],
            "Happy": ['buoyant', 'cheerful', 'comfortable', 'ecstatic', 'glad', 'jolly', 'elated', 'optimistic'],
            "Good": ['excellent', 'fine', 'satisfactory', 'proper', 'valid', 'worthy', 'benign'],
            "Bad": ['poor', 'terrible', 'awful', 'inept', 'heinous', 'wicked', 'evil'],
            "Angry": ['resentful', 'irritated', 'furious', 'offended', 'wrathful', 'bitter', 'cross', 'fuming'],
            "Fear": ['fear', 'dread', 'terror', 'panic', 'phobia', 'anxiety', 'cowardice'],
            "Selection": ['choose', 'decide on', 'elect', 'opt', 'pick', 'prefer', 'select', 'settle on', 'single out']
        }
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    submitName() {
        if (this.projectData.author) this.step++;
        else this.nameWarning = true;
    }

    hoverSelect(selection) {
        this.hoveredSelection = selection;
    }

    unhoverSelect() {
        this.hoveredSelection = null;
    }

    hoverLearnMore() {
        this.learnMoreHovered = true;
    }

    unhoverLearnMore() {
        this.learnMoreHovered = false;
    }

    learnMore() {
        delete this.newWordOpened;
        this.lastStep = this.step;
        this.step = 2;
    }

    goLastStep() {
        this.step = this.lastStep;
    }

    changeSelection(selection: string) {
        this.projectData.organizeSelection = selection;
    }

    chooseSelection(selection: string) {
        this.projectData.organizeSelection = selection;
        this.step = 3;
    }

    hideDirections() {
        this.directionsOpen = false;
    }

    chooseConcept(concept: string) {
        this.currentWords = this.conceptsWords[concept];
        this.conceptSelected = true;
        this.currentConcept = concept;
        delete this.newConceptOpened;
    }

    newConcept() {
        delete this.newWordOpened;
        this.conceptsWords = this.defaultConceptsWords();
        this.currentWords = [];
        this.conceptSelected = false;
        this.currentConcept = "";
        this.printWarning = false;
    }

    getConcepts() {
        let concepts = [];
        for (let concept in this.conceptsWords) {
            concepts.push(concept);
        }
        return concepts;
    }

    getCurrentWords() {
        let currentWordsSeparated = [[], []];
        this.currentWords.forEach((element, index) => {
            if ((index + 1) % 2 == 0) currentWordsSeparated[1].push(element);
            else currentWordsSeparated[0].push(element);
        });
        return currentWordsSeparated;
    }

    addNewConcept() {
        this.conceptsWords[this.newConceptTitle] = [];
        this.chooseConcept(this.newConceptTitle);
        this.newConceptTitle = "";
    }

    addNewWord() {
        if (this.conceptsWords[this.currentConcept].includes(this.newWord)) {
            this.newWordPlaceholder = "No duplicate words.";
            this.newWord = "";
        }
        else {
            this.conceptsWords[this.currentConcept].push(this.newWord);
            this.newWord = "";
            this.newWordOpened = false;
            this.newWordPlaceholder = "";
        }
    }
}
