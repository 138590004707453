<h1>Interactive Apps</h1>
<ul>
  <li><a [routerLink]="['/abc-match']">ABC Match</a></li>
  <li><a [routerLink]="['/acrostic-poems']">Acrostic Poems</a></li>
  <li><a [routerLink]="['/alphabet-organizer']">Alphabet Organizer</a></li>
  <li><a [routerLink]="['/animal-inquiry']">Animal Inquiry</a></li>
  <li><a [routerLink]="['/book-cover-guide']">Book Cover Guide</a></li>
  <li><a [routerLink]="['/circle-plot']">Circle Plot</a></li>
  <li><a [routerLink]="['/comp-contrast-map']">Compare and Contrast Map</a></li>
  <li><a [routerLink]="['/compare-contrast-guide']">Compare Constrast Guide</a></li>
  <li><a [routerLink]="['/construct-a-word']">Construct a Word</a></li>
  <li><a [routerLink]="['/cube-creator']">Cube Creator</a></li>
  <li><a [routerLink]="['/diamante-poems']">Diamante Poems</a></li>
  <li><a [routerLink]="['/drama-map']">Drama Map</a></li>
  <li><a [routerLink]="['/essay-map']">Essay Map</a></li>
  <li><a [routerLink]="['/flip-a-chip']">Flip a Chip</a></li>
  <li><a [routerLink]="['/fractured-fairytale']">Fractured Fairytale</a></li>
  <li><a [routerLink]="['/graphic-map']">Graphic Map</a></li>
  <li><a [routerLink]="['/haiku-poem-interactive']">Haiku Poem Interactive</a></li>
  <li><a [routerLink]="['/kwl-creator']">K-W-L Creator</a></li>
  <li><a [routerLink]="['/letter-generator']">Letter Generator</a></li>
  <li><a [routerLink]="['/lit-elements']">Literary Elements Mapping</a></li>
  <li><a [routerLink]="['/graffiti']">Literary Graffiti</a></li>
  <li><a [routerLink]="['/literary-guide-beowulf']">Literary Guide: Beowulf</a></li>
  <li><a [routerLink]="['/multigenre-mapper']">Multi-Genre Mapper</a></li>
  <li><a [routerLink]="['/persuasion-map']">Persuasion Map</a></li>
  <li><a [routerLink]="['/picture-match']">Picture Match</a></li> 
  <li><a [routerLink]="['/riddle']">Riddle</a></li>
  <li><a [routerLink]="['/plot-diagram']">Plot Diagram</a></li>
  <li><a [routerLink]="['/postcard']">Postcard Creator</a></li>
  <li><a [routerLink]="['/profile-publisher']">Profile Publisher</a></li>
  <li><a [routerLink]="['/resume-generator']">Resume Generator</a></li> 
  <li><a [routerLink]="['/riddle']">Riddle</a></li>
  <li><a [routerLink]="['/stapleless']">Stapleless Book</a></li>
  <li><a [routerLink]="['/story-map']">Story Map</a></li>
  <li><a [routerLink]="['/theme-poems']">Theme Poems</a></li>
  <li><a [routerLink]="['/timeline']">Timeline</a></li>
  <li><a [routerLink]="['/trading-card-creator']">Trading Card Creator</a></li>
  <li><a [routerLink]="['/venn-diagram']">Venn Diagram</a></li>
  <li><a [routerLink]="['/word-family']">Word Family Sort</a></li>
  <li><a [routerLink]="['/word-matrix']">Word Matrix</a></li>
  <li><a [routerLink]="['/word-mover']">Word Mover</a></li>
</ul>
