    <div class='toolbar' *ngIf='step>1'>
    <button *ngIf='step>1' (click)='prevStep()'><i class='fa fa-chevron-left'></i> Back</button>
    <button *ngIf='step<2' (click)='nextStep()'>Next <i class='fa fa-chevron-right'></i></button>
    <button *ngIf='step==2'  (click)='save()'><i class='fa fa-print'></i> Save</button> 
    <button *ngIf='step==2'  (click)='print()'><i class='fa fa-print'></i> Print</button> 
    <button *ngIf='step==2'  (click)='export()'><i class='fa fa-print'></i> Export</button> 
  </div>

<div *ngIf='step==1'>
    <h1>What Is A Timeline?</h1>
    <p>
        A timeline is a graphical representation of related items or events in sequential order and displayed along a
        line.
    </p>
    <p>
        You can organize your timeline by date, time, or event. <em>(Click to view examples)</em>
    </p>

    <div class='entry'>
        <table>
            <tr>
                <td>Your Name</td>
                <td><input [ngClass]="{'has-error':nameRequiredError}" [(ngModel)]='vars.name'> </td>
            </tr>
            <tr>
                <td>Project Title:</td>
                <td><input [ngClass]="{'has-error':titleRequiredError}" [(ngModel)]='vars.title'> </td>
            </tr>
        </table>
    </div>
    <div class='nav-buttons'>
        <button (click)='nextStep()'>Start</button>
        <button (click)='showOpen=true'>Open</button>
    </div>
    <div *ngIf='showOpen'>
        <h2>OPEN SAVED TIMELINE</h2>

        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="open($event)">
    
    </div>
</div>

<div *ngIf='step==2' [style.zoom]='zoom'>


    <div #timelineCanvas class='step2'>
        <h1>{{vars?.title}}</h1>
        <div>
            {{vars.name}}
        </div>

        <div class='timeline-instr' *ngIf='!hasSelectedNodes()' >
            Click on the timeline to add an event.
        </div>

        <div *ngIf='vars' class='drag-canvas' drag-canvas  [dragElements]='vars.elements'>  
            <div class='t-timeline'>
                <div class='t-left'></div>
                <ng-container *ngFor='let node of vars.nodes'>
                    <div draggable="true" (dragstart)='startDragNode(node)'  class='t-node t-node-{{node.index}}' [ngClass]='{"t-node-circle" : node.selected}'
                        (click)='addElement(node)'>
                        <div *ngIf='!node.selected' class='t-node-selected' (drop)='dropNode(node)' (dragover)="allowDrop($event)"></div>

                        <div *ngIf='!node.selected' class='t-node-selected-bottom' (drop)='dropNode(node)' (dragover)="allowDrop($event)"></div>
                    </div>
                </ng-container>
                <div class='t-right'></div>
            </div> 

            <ng-container *ngFor='let node of vars.nodes'>
                <div *ngFor='let elem of vars.elements' class='drag-line drag-line-{{node.index}}'></div>
            </ng-container>

            <ng-container *ngIf='!!vars?.elements?.length'>
                <div drag-object *ngFor='let elem of vars.elements; let idx=index' [attr.data-elem-id]='elem.id'  [dragElement]='elem' [dragElements]='vars.elements' [initialFocus]='true'
                    (onMove)='moveLine(elem)' [allowSelect]='true'>
                    <drag-toolbar *ngIf='!readonly' [dragElement]='elem' [dragElements]='vars.elements' [showEdit]='true' (onClose)='closeEdit(elem)'
                      (onDelete)='deleteElement(elem)'  (onEdit)='showEdit(elem)'></drag-toolbar>
                    <label *ngIf='elem.type=="text"'>{{elem.text || 'New Label'}}</label>
                    <div class='edit-block' *ngIf='!readonly && elem.edit==true'>
                        <input placeholder="Enter label" [(ngModel)]='elem.text'>
                        <textarea placeholder="Enter description" [(ngModel)]='elem.description'></textarea>
                        <div filedrop class='img-drop' (files)='drop(elem, $event)'   [ngClass]='{"elem.over": img-over}'>
                            <em *ngIf='!elem.imagePreview'>Drag and drop image here</em>
                            <img *ngIf='!!elem.imagePreview' [src]='elem.imagePreview' class='image-preview'>
                        </div> 
                        <!--droped image preview-->
                        <div *ngFor="let file of files">
                          <img *ngIf="file" [src]="file.url" width="100px" height="100px">
                        </div>
 
                        <button (click)='closeEdit(elem)'>Done</button>
                    </div>
                    <div *ngIf='(readonly || !elem.edit) && elem.imagePreview'>
                        <div>
                            <img [src]='elem.imagePreview' class='image-preview'>
                            <div class='clear'></div>
                        </div> 
                    </div>

                    <div class='clear'></div>
                    <drag-status *ngIf='!readonly' [dragElement]='elem' [allowSize]='elem.type=="image"'></drag-status>
                </div>
            </ng-container>
        </div>
    </div> 

    <div #itemCanvas *ngIf='!!readonly && !!vars?.elements' >
        <h1>Items</h1>
        <table *ngFor='let elem of vars.elements'>
            <tr>
                <td>
                    <div class='t-node'></div>
                </td>
                <td>
                    <strong>{{elem.text}}</strong>
                    <p [innerHTML]='elem.description'>
                        
                    </p>
                </td>
            </tr>
        </table>
    </div>
</div>


  