import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { StaplelessBook, StaplelessTemplate, StaplelessMenuItem } from 'src/app/model/StaplelessBook';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from "src/app/service/print.service";
import { FileUtilService } from 'src/app/service/file-util.service';

@Component({
    selector: 'app-stapleless',
    templateUrl: './stapleless.component.html',
    styleUrls: ['./stapleless.component.scss']
})
export class StaplelessComponent implements OnInit {
    @ViewChild('printCanvas', null) printCanvas: ElementRef;
    @ViewChild('printPreview', null) printPreview: ElementRef;
    year = 2021;
    previewImage: any;

    staplelessData: StaplelessBook = new StaplelessBook();

    pages: StaplelessTemplate[] = [];
    step: number = 0;

    templatesText: string = "Mouse over an icon for a description and click the icon to choose it.";
    templatesBoxOpen: boolean = true;
    templatesBoxAnimating: boolean = false;

    templatesTexts: object = {
        1: "Headline at top with bullet points below.",
        2: "Bullet points at top with large text at bottom.",
        3: "Headline at top with space for a picture below.",
        4: "Picture at top with large text at bottom.",
        5: "Headline at top with smaller text below.",
        6: "Smaller text at top with large text at bottom.",
        7: "This page allows you to type in the most text.",
        "default": "Mouse over an icon for a description and click the icon to choose it."
    }

    templates: object = {
        1: "headlineTopBulleted",
        2: "headlineBottomBulleted",
        3: "headlineTopPicture",
        4: "headlineBottomPicture",
        5: "smallTextBottom",
        6: "smallTextTop",
        7: "mostText"
    }

    editDiv: StaplelessMenuItem = new StaplelessMenuItem();
    helpDiv: StaplelessMenuItem = new StaplelessMenuItem();
    printDiv: StaplelessMenuItem = new StaplelessMenuItem();

    constructor(private printer: PrintService, private fileUtil: FileUtilService) { }

    ngOnInit() {
        for (let page = 0; page < 6; page++) {
            this.pages.push(new StaplelessTemplate());
        }
        this.year = ((new Date())).getFullYear();
    }

    changeStep(step) {
        this.step = step;
    }

    nextStep() {
        if (this.step === 0) {
            if (this.staplelessData.author && this.staplelessData.title) {
                this.step++;
            }
        } else {
            this.step++;
        }
    }

    handleTemplateHover(temp) {
        this.templatesText = this.templatesTexts[temp];
    }

    handleTemplateClick(temp) {
        this.pages[this.step - 1].templateName = this.templates[temp];
    }

    toggleTemplatesBox() {
        if (!this.templatesBoxAnimating) {
            this.templatesBoxOpen = !this.templatesBoxOpen;
            this.templatesBoxAnimating = true;

            setTimeout(() => {
                this.templatesBoxAnimating = false;
            }, 500);
        }
    }

    async print() {
        await this.printer.print([this.printCanvas], PrintOrientation.Portrait, false, false, false, false, false);
    }
    async exportPdf() {
        await this.printer.export([this.printCanvas], 'stapleless.pdf', PrintOrientation.Portrait, false, false, false, false, false)
    }
    save() {
        this.fileUtil.save({
            pages: this.pages,
            data: this.staplelessData
        }, 'stapleless.json')
    }

    fileInput: any;
    openBook(event: any) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            try {
                let json = e.target.result;
                let data = JSON.parse(json);
                this.pages = data.pages;
                this.staplelessData = data.data;
                this.step = 0;
                this.fileInput = null;
            }
            catch (e) {
                alert('File cannot be opened');
                this.fileInput = null;
            }
        };
        reader.readAsText(event.target.files[0]);
    }


    initPrint() {
        this.step = 7;
    }
}
