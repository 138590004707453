import { DragElement } from "./DragElement";

export class WordMatrix {
    author?: string;
    organizeSelection?: string;

    elements?: DragElement[] = [];

    editId?: string;
    now?: Date;
    addingCircle?: boolean = false;
    editingLabel?: boolean = false;

    inputDiagrammedBy?: string;
    inputTitle?: string;
    inputLabelText?: string;
    inputLabelDescription?: string;

}