
export class TradingCardSection {
   constructor(public sectionName: string, public fields: TradingCardField[]){

   }
}

export class TradingCardField {
   public value: string;
   constructor(public fieldName: string, public label: string) {

   }
}
