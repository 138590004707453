<div style="min-width: 800px; min-height: 800px;">
    
    <div class="menu">
        <img src="../../../assets/riddle/menu/menu_closed.png" *ngIf="menuOpenOrClosed==false" (click)="changeMenu()">
        <img src="../../../assets/riddle/menu/menu_down.png" *ngIf="menuOpenOrClosed==true">            
        
        <ul *ngIf="menuOpenOrClosed==true" class="menu-sections">
            <li style="margin-top: -241px;">
                <img src="../../../assets/riddle/menu/getting_started_button.png" (click)="changePage(0)">    
            </li>

            <li style="margin-top: -4px;">
                <img src="../../../assets/riddle/menu/writing_a_draft_button.png"  (click)="changePage(2)">
            </li>

            <li style="margin-top: -3px;">
                <img src="../../../assets/riddle/menu/read_a_sample_button.png"   (click)="changePage(3)">
            </li>

            <li style="margin-top: -2px;">
                <img src="../../../assets/riddle/menu/write_your_own_button.png"   (click)="changePage(4)">
            </li>
                
            <li style="margin-top: -3px;">
                <img src="../../../assets/riddle/menu/learn_more_button.png"   (click)="changePage(5)">
            </li>

            <li style="margin-left: 93px; margin-top: 32px;">
                <img src="../../../assets/riddle/menu/exit_button.png"   (click)="changeMenu()">
            </li>


        </ul>
        
    </div>

    <div class="page-one" *ngIf="page==0">
  

       <div class="page-one-buttons">
            <div (click)="changeSection(1)">
                <img src="../../../assets/riddle/choose_an_answer_button.png" >
            </div>

            <div style="margin-left: 130px;" (click)="changeSection(3)">
                <img src="../../../assets/riddle/brainstorm_button.png">
            </div>
            
            <div style="margin-left: 33px;" (click)="changeSection(5)">
                <img src="../../../assets/riddle/use_a_thesaurus_button.png">
            </div>

            <div style="margin-left: -32px; margin-top: -1px;" (click)="changeSection(7)">
                <img src="../../../assets/riddle/think_like_the_object_button.png">    
            </div> 

            <div style="margin-left: -101px; margin-top: -1px;" (click)="changeSection(8)">
                <img src="../../../assets/riddle/use_figurative_language_button.png">
            </div>

            
       </div>

       <div class="getting-started-continue-button">
            <img src="../../../assets/riddle/continue_button.png" (click)="startGettingStarted()">
       </div>


   </div> 


   <div class="page-two" *ngIf="page==1">
        
        <div>
            <video width="642px" height="402px" #gettingStartedWithARiddlePoemVid autoplay *ngIf="playingVid==1" style="z-index: 1;position: absolute; ">
                <source src="../../../assets/riddle/getting-started-riddle-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #thinkAboutHowDifficultVid autoplay *ngIf="playingVid==2" style="margin-top: 2px; z-index: 1; position: absolute;">
                <source src="../../../assets/riddle/choose-the-answer-vid.mp4" type="video/mp4">
            </video>

            <video width = "642px" height="402px" #brainstormVid autoplay *ngIf="playingVid==3" style="position: absolute; z-index: 1; margin-top: 2px">
                <source src="../../../assets/riddle/start-brainstorm-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #oppositesBrainstorm autoplay *ngIf="playingVid==4" style="position: absolute; z-index: 1;">
                <source src="../../../assets/riddle/opposites-brainstorm-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #synonymThesaurus autoplay *ngIf="playingVid==5" style="position: absolute; z-index: 1; margin-top: 2px;">
                <source src="../../../assets/riddle/synonym-thesaurus-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #lookUpAnswer autoplay *ngIf="playingVid==6" style="position: absolute; z-index: 1;">
                <source src="../../../assets/riddle/look-up-answer-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #thinkLikeObject autoplay *ngIf="playingVid==7" style="position: absolute; z-index: 1; margin-top: 1px;">
                <source src="../../../assets/riddle/think-like-the-object-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #figurativeLanguage autoplay *ngIf="playingVid==8" style="position: absolute; z-index: 1;">
                <source src="../../../assets/riddle/figurative-language-vid.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" #writingDraft autoplay *ngIf="playingVid==9" style="position: absolute; z-index: 1;">
                <source src="../../../assets/riddle/writing-draft-vid.mp4" type="video/mp4">
            </video>
        </div>

        <div class="choose-the-answer-button" *ngIf="showContButtonOne==true">
            <img src="../../../assets/riddle/continue_button.png" (click)="increasePlayingVid()">
        </div>
        
   </div>

   <div class="page-three" *ngIf="page==2">
        
        <div>
            <video width="642px" height="402px" autoplay #writingDraftStart *ngIf="playingVid==1" style="z-index: 1; position: absolute;">
                <source src="../../../assets/riddle/writing-draft-beginning.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" autoplay #writingDraftCHangeWords *ngIf="playingVid==2" style="z-index: 1; position: absolute; margin-top: 5px; margin-left: 1px;">
                <source src="../../../assets/riddle/writing-a-draft-change-words.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" autoplay #writingDraftTrickWords *ngIf="playingVid==3" style="z-index: 1; position: absolute; margin-top: 5px; margin-left: 1px;">
                <source src="../../../assets/riddle/writing-a-draft-trick-words.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" autoplay #writingDraftOpposites *ngIf="playingVid==4" style="z-index: 1; position: absolute; margin-top: 3px; margin-left: 2px;">
                <source src="../../../assets/riddle/writing-a-draft-opposites.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" autoplay #writingDraftFinal *ngIf="playingVid==5" style="z-index: 1; position: absolute; margin-top: 3px; margin-left: 2px;" (loadeddata)="hideContButton()">
                <source src="../../../assets/riddle/writing-a-draft-final.mp4" type="video/mp4">
            </video>
        </div>

        <div class="continue-button" *ngIf="showContButtonOne==true">
            <img src="../../../assets/riddle/continue_button.png" (click)="increasePlayingVid()">
        </div>
   </div>

   <div class="page-four" *ngIf="page==3">
        <audio #samplePoemAudio>
            <source src="../../../assets/riddle/sample_poem_read_aloud.mp3">
        </audio>

       <div>
            <video width="642px" height="402px" autoplay #readSample *ngIf="playingVid==1" style="z-index: 1; position: absolute;" (loadeddata)="hideSMP()" (ended)="revealSMP()">
                <source src="../../../assets/riddle/read-a-sample.mp4" type="video/mp4">
            </video>

            <video width="642px" autoplay height="402px" #readSampleAgain *ngIf="replayVid==true" style="z-index: 1; position: absolute;" (loadeddata)="hideSMP()" (ended)="revealSMPAndChangeReplayVid()">
                <source src="../../../assets/riddle/read-a-sample.mp4" type="video/mp4">
            </video>

            <video width="642px" height="402px" autoplay *ngIf="playingVid==2" style="z-index: 1; position: absolute;">
                <source src="../../../assets/riddle/water.mp4" type="video/mp4">
            </video>
       </div>
       
       <div class="sample-poem-things" *ngIf="showSMP==true">
            <img src="../../../assets/riddle/hear_poem_button.png" (click)="playNextMedia(samplePoemAudio)" style="margin-right: 27px;">
            <img src="../../../assets/riddle/play_again_button.png" (click)="changeReplayVid()">
       </div>
       
       <div *ngIf="showSMP==true" style="position: absolute; margin-top: 369px; margin-left: 388px; z-index: 3; cursor: pointer;">
            <img src="../../../assets/riddle/show_me_the_answer_button.png" (click)="increasePlayingVidAndHideSMP()">
       </div>
   </div>

   <div class="page-five" *ngIf="page==4">
    <embed src="../../../assets/riddle/WriteYourOwnRiddle.pdf" width="1080px" height="2100px" />
   </div>

   <div class="page-six" *ngIf="page==5">
       <div>
            <video width="642px" height="402px" autoplay style="position: absolute; z-index: 1;">
                <source src="../../../assets/riddle/learn-more-video.mp4" type="video/mp4">
            </video>
       </div>
   </div>
</div>