import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ITradingCard } from 'src/app/model/TradingCard/ITradingCard';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() card: ITradingCard;
  @Input() disableButtons: boolean = false;
  @Input() printVersion?: boolean;
  editingTopic: boolean = false;
  insertingPicture: boolean = false;

  tempTopic: string;
  tempImageUrl: SafeUrl;

  section: number = 1;

  constructor() { }

  ngOnInit() {
    console.log("CardComponent.ngOnInit - card: ", this.card);
  }

  showEditTopicModal(value: boolean) {
    this.editingTopic = value;
    this.tempTopic = this.card.topic;
  }

  editTopic(newTopic: string) {
    this.card.topic = newTopic;
    this.showEditTopicModal(false);
  }

  showInsertImageModal(value: boolean) {
    this.insertingPicture = value;
  }


  selectImage(event) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onloadend = () => {
          this.tempImageUrl = reader.result;
        }
      }
  }

  setPicture() {
    this.card.imageUrl = this.tempImageUrl;
    this.tempImageUrl = null;
    this.insertingPicture = false;
  }

}
