export class CubeCreatorLayout {
  side1image: string;
  side2image: string;
  side3image: string;
  side4image: string;
  side5image: string;
  side6image: string;
  cubeBackgroundClass: string;
  name: string;
  cubeImage: string;

}
