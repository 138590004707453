<div class="app-border">
    <div class="title-bar">
        <span *ngIf="step >= 1" class="title">{{ staplelessData.title }}</span> <br />
        <span *ngIf="step >= 1" class="author">by {{ staplelessData.author }}</span>
    </div>
    <div class="header">
        <label class="header-label"> Stapleless Book </label>
        <div class="page-label-div">
            <div *ngIf="step >= 1">
                <label class="page-label">Page</label>
                <!-- Page numbers -->

                <button *ngFor="let page of pages; index as i" class="page-button" [disabled]="step === i + 1" [ngClass]="{'selected-page': step == (i+1)}"
                    (click)="changeStep(i + 1)">{{ i + 1 }}</button>
            </div>
        </div>
        <div class="white-space1">
            <button class="next-page" *ngIf="step < 6" (click)="nextStep()">Next Page ▶</button>
        </div>
        <button (click)="initPrint()" class="finished" *ngIf="step >= 1">Finished &#9873;</button>
    </div>

    <div class="page" *ngIf="step === 0">
        <h1>Title Page</h1>
        <p>Add a book title and your name for the title page. Click the "Next Page" button to begin working on your
            stapleless book.</p>

        <div class="title-inputs">
            <div>
                <label for="title-input">Title:</label>
                <input [(ngModel)]="staplelessData.title" id="title-input" type="text" />
            </div>
            <div>
                <label for="author-input">By:</label>
                <input [(ngModel)]="staplelessData.author" id="author-input" type="text" />
            </div>
        </div>
        <hr>
        <h2>PLANNING SHEET</h2>
        <P>
            Click <a href='https://www.readwritethink.org/sites/default/files/2021-08/StaplelessBookPlanningSheet.pdf' target='_blank'>HERE</a> to view the planning sheet.
        </P>

        <hr>
        <h2>OPEN SAVED BOOK</h2>

        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openBook($event)">

    </div>

    <div class="page" *ngIf="step >= 1 && step <= 6">
        <table class="page-table">
            <td>
                <div class="templates-box-container">
                    <div [ngClass]="{'templates-box-open' : templatesBoxOpen,
                    'templates-box-closed' : !templatesBoxOpen}" class="templates-box">
                        <h1 class="templates-label">Page Templates</h1>
                        <button [ngClass]="{'templates-arrow-open' : templatesBoxOpen,
                           'templates-arrow-closed' : !templatesBoxOpen}" (click)="toggleTemplatesBox()"
                            class="templates-arrow">▲</button>
                        <br />
                        <div class="templates-description">
                            <p class="templates-description">{{templatesText}}</p>
                        </div>
                        <table>
                            <tr>
                                <td>
                                    <button (click)="handleTemplateClick(1)" (mouseover)="handleTemplateHover(1)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template1.png">
                                    </button>
                                </td>
                                <td>
                                    <button (click)="handleTemplateClick(2)" (mouseover)="handleTemplateHover(2)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template2.png">
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button (click)="handleTemplateClick(3)" (mouseover)="handleTemplateHover(3)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template3.png">
                                    </button>
                                </td>
                                <td>
                                    <button (click)="handleTemplateClick(4)" (mouseover)="handleTemplateHover(4)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template4.png">
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button (click)="handleTemplateClick(5)" (mouseover)="handleTemplateHover(5)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template5.png">
                                    </button>
                                </td>
                                <td>
                                    <button (click)="handleTemplateClick(6)" (mouseover)="handleTemplateHover(6)"
                                        (mouseout)="handleTemplateHover('default')">
                                        <img src="/assets/stapleless/templates/template6.png">
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <button (click)="handleTemplateClick(7)" (mouseover)="handleTemplateHover(7)"
                            (mouseout)="handleTemplateHover('default')">
                            <img src="/assets/stapleless/templates/template7.png">
                        </button>
                    </div>
                </div>

            </td>
            <td>
                <stapleless-page *ngIf='!!pages[step-1]' [(currentPage)]='pages[step-1]'></stapleless-page>

            </td>
        </table>
    </div>

    <div class="page" *ngIf="step == 7">
        <p>Congratulations!  Your book is complete. Click 'Print' to print your book.</p>

        <div class='action-bar'>
            <button class='btn btn-primary' class="print-button" (click)='print()'>
                <i class="fa fa-print"></i>
                Print
            </button>
            <button class='btn btn-primary' (click)='exportPdf()'>
                Export to PDF
            </button>
            <button class='btn btn-primary' (click)='save()'>
                Save Book
            </button>
        </div>
        <br />
        <br />
        <div class='zoom-preview'>
            <div #printCanvas>
                <table class='print-table'>
                    <tr>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[0]'
                                        [(currentPage)]='pages[0]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                        <td id='ptitle'>
                            <div class='rotate-container'>
                                <div class='rotate-90'>
                                    <span class='title' [innerHTML]='staplelessData.title'></span>
                                    <span class='book'>A Stapleless Book</span>
                                    <em>by</em>
                                    <span class="author">{{ staplelessData.author }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[1]'
                                        [(currentPage)]='pages[1]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                        <td id='pback'>
                            <div class='rotate-container'>
                                <div class='rotate-90'>

                                    <table style='width:100%; height:100%;'>
                                        <tr>
                                            <td class='noborder' style='vertical-align: middle; text-align: center;'>
                                                Go online to https://readwritethink.org
                                            </td>
                                        </tr>

                                        <tr style='height: 1px'>
                                            <td class='noborder' style='vertical-align: middle; text-align: center;'>
                                                Copyright {{year}} IRA/NCTE<br>
                                                All Rights Reserved.
                                            </td>
                                        </tr>
                                        <tr style='height: 1px'>
                                            <td class='noborder' style='vertical-align: middle; text-align: center;'>
                                                <img src='/assets/common/footer-logo.png' style='width:80%'>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[2]'
                                        [(currentPage)]='pages[2]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate-90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[5]'
                                        [(currentPage)]='pages[5]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[3]'
                                        [(currentPage)]='pages[3]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class='rotate-container'>
                                <div class='rotate-90'>
                                    <stapleless-page [printVersion]='true' *ngIf='!!pages[4]'
                                        [(currentPage)]='pages[4]'>
                                    </stapleless-page>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>