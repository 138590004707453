import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AlphabetOrganizerFile } from "src/app/model/AlphabetOrganizerFile";
import { AlphabetOrganizerAlphabet } from "src/app/model/AlphabetOrganizerAlphabet";
import { AlphabetOrganizerInput } from "src/app/model/AlphabetOrganizerInput";
import { AlphabetOrganizerLetter } from "src/app/model/AlphabetOrganizerLetter";
import { AlphabetOrganizerWordImg } from "src/app/model/AlphabetOrganizerWordImg";
import * as _ from 'lodash';
import { PrintOrientation } from "src/app/enum/PrintOrientation";
import { FileUtilService } from "src/app/service/file-util.service";
import { SafeUrl } from "@angular/platform-browser";
import { TitleCasePipe } from "@angular/common";
import { PrintService } from "src/app/service/print.service";

@Component({
  selector: "app-alphabet-organizer",
  templateUrl: "./alphabet-organizer.component.html",
  styleUrls: ["./alphabet-organizer.component.scss"],
})
export class AlphabetOrganizerComponent implements OnInit {
  step: number = 1;
  organizerType: string;
  letter: string;
  previewImage: any;

  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvasWordPages', null) printCanvasWordPages: ElementRef;
  @ViewChild('printCanvasChartsNotes', null) printCanvasChartsNotes: ElementRef;

  dim: boolean = false;
  alphabet: string[] = [];

  nameEmpty: boolean = false;
  titleEmpty: boolean = false;

  wordError: boolean = false;
  startOverConfirm: boolean = false;

  wordsOnlyLetters: AlphabetOrganizerLetter[] = [];
  inputtedWordsOnlyLetters: AlphabetOrganizerLetter[] = [];

  wordAndImgLetters: AlphabetOrganizerWordImg[] = [];
  inputtedWordImgLetters: AlphabetOrganizerWordImg[] = [];

  inputtedLettersGenerated: boolean = false;


  wrongLetterError: boolean = false;

  letterRows: AlphabetOrganizerAlphabet[] = [];

  printType: string;

  printViewNum: number;

  vars: AlphabetOrganizerFile = new AlphabetOrganizerFile();

  tempImageUrl: SafeUrl;

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
    for (let i = 0; i < 26; i++) {
      this.alphabet.push(String.fromCharCode(97 + i));
    }

    for (let i = 0; i < 4; i++) {
      let rowLetters: string[] = [];
      for (let f = 0; f < 7; f++) {
        if (this.alphabet[i * 7 + f]) {
          rowLetters.push(this.alphabet[i * 7 + f]);
        }
      }

      let lr: AlphabetOrganizerAlphabet = {
        letterRow: i,
        letters: rowLetters,
      };
      this.letterRows.push(lr);
    }


    // this.inputBoxes.push(firstInputBox);
    for (let char of this.alphabet) {
      let firstInputBox: AlphabetOrganizerInput = { num: 1, inputValue: "", entered: false, applied: false };
      let letterElement = { letter: char, inputs: [firstInputBox], entered: false };
      this.wordsOnlyLetters.push(letterElement);

      this.wordAndImgLetters.push({ word: "", description: "", imgUrl: null, entered: false });
    }
  }

  applyNameAndTitle() {
    this.nameEmpty = !this.vars.name;
    this.titleEmpty = !this.vars.title;

    if (!this.nameEmpty && !this.titleEmpty) {
      let inputBox: AlphabetOrganizerInput = { num: 1, inputValue: "", entered: false, applied: false };
      let wordsOnlyEntered = false;
      let wordImgEntered = false;
      for (let item of this.wordsOnlyLetters) {
        if (item.entered != false && item.inputs != [inputBox]) {
          wordsOnlyEntered = true;
        }
      }

      for (let letter of this.wordAndImgLetters) {
        if (letter.word != "" && letter.description != "" && letter.imgUrl != null && letter.entered != false) {
          wordImgEntered = true;
        }
      }

      if (!wordsOnlyEntered && !wordImgEntered) {
        this.step++;
      }
      else {
        this.step = 4;
      }
    }

  }

  initOrganizer(type: string) {
    this.step = 3;
    switch (type) {
      case "wordsOnly":
        this.organizerType = "wordsOnly";
        break;
      case "wordAndPicture":
        this.organizerType = "wordAndPicture";
    }
  }

  clearInput(char: string) {
    this.dim = false;
    this.letter = '';

    if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length == 1 && this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[0].applied == false) {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs = [];
    }

    for (let input of this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs) {
      if (input.applied == false && (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length != 1)) {
        let index = this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.indexOf({ num: input.num, inputValue: input.inputValue, entered: input.entered, applied: input.applied });
        this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.splice(index, 1);
      }
    }

    console.log(this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs);
    if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length == 0) {
      let firstInputBox: AlphabetOrganizerInput = { num: 1, inputValue: "", entered: false, applied: false };
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.push(firstInputBox);
    }

  }

  applyInput(char: string) {
    if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length == 1 && this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[0].inputValue == '') {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].entered = false;
    }


    else if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length == 1 && !this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[0].inputValue.toLowerCase().startsWith(char.toLowerCase())) {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].entered = false;
      this.wrongLetterError = true;
    }
    else {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].entered = true;
    }


    this.dim = false;

    for (let input of this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs) {
      input.applied = true;
    }

    if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[0].inputValue.toLowerCase().startsWith(char.toLowerCase())) {
      this.letter = '';
    }
  }

  newInput(char: string, num: number) {
    if (!this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[num - 1].inputValue.toLowerCase().startsWith(char.toLowerCase())) {
      this.wrongLetterError = true;
    }

    if (this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[num - 1].inputValue && this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[num - 1].inputValue.startsWith(char.toLowerCase())) {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[num - 1].entered = true;

      let newInputBox: AlphabetOrganizerInput = { num: num + 1, inputValue: "", entered: false, applied: false };
      console.log(newInputBox);
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.push(newInputBox);
      console.log(this.wordsOnlyLetters);
    }
  }

  deleteInput(char: string, num: number) {
    this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.splice(num - 1, 1);
    console.log(this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs);
    for (let i = num - 1; i < this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length; i++) {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[i].num--;
    }

    this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs[this.wordsOnlyLetters[char.charCodeAt(0) - 97].inputs.length - 1].entered = false;
  }

  restart() {
    for (let char of this.alphabet) {
      this.wordsOnlyLetters[char.charCodeAt(0) - 97] = { entered: false, letter: char, inputs: [{ num: 1, inputValue: "", entered: false, applied: false }] };
      this.wordAndImgLetters[char.charCodeAt(0) - 97] = { word: "", description: "", imgUrl: null, entered: false };
    }

    this.startOverConfirm = false;

    this.step = 1;
  }

  finish() {
    if (this.organizerType == 'wordsOnly') {
      for (let letter of this.wordsOnlyLetters) {
        if (letter.entered) {
          this.step = 4;
        }
      }
    }
    else {
      for (let letter of this.wordAndImgLetters) {
        if (letter.entered) {
          this.step = 4;
        }
      }
    }

  }

  get printCanvas() {
    if (this.printType == 'word pages') {
      return this.printCanvasWordPages;
    }
    else {
      return this.printCanvasChartsNotes;
    }
  }

  initPrint(type, num) {

    this.printViewNum = num;

    if (type == 'word pages') {
      if (this.organizerType == 'wordsOnly') {
        if (!this.inputtedLettersGenerated) {
          for (let letter of this.wordsOnlyLetters) {
            if (letter.entered) {
              this.inputtedWordsOnlyLetters.push(letter);
            }
          }
          this.inputtedLettersGenerated = true;
        }

        this.letter = this.inputtedWordsOnlyLetters[this.printViewNum].letter;
        this.printType = 'word pages';
      }
      else {
        if (!this.inputtedLettersGenerated) {
          for (let letter of this.wordAndImgLetters) {
            if (letter.entered && !this.printViewNum[this.printViewNum - 1] || letter.entered && this.printViewNum == 0) {
              this.inputtedWordImgLetters.push(letter);
            }
          }
          this.inputtedLettersGenerated = true;
        }

        this.letter = this.inputtedWordImgLetters[this.printViewNum].word[0];
        this.printType = 'word pages'
      }

    }
    else {
      this.printType = 'charts notes';
    }
    this.step = 5;


    let that = this;
    if (type == 'word pages') {
      setTimeout(async () => {
        this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Portrait);
      }, 100);
    }
    else {
      setTimeout(async () => {
        this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Landscape);
      }, 100);
    }

  }

  decPrintPage() {
    if (this.printViewNum != 0) {
      this.printViewNum--;

      this.initPrint(this.printType, this.printViewNum);
    }
  }

  incPrintPage() {
    if (this.organizerType == 'wordsOnly') {
      if (this.printViewNum != this.inputtedWordsOnlyLetters.length - 1) {
        this.printViewNum++;

        this.initPrint(this.printType, this.printViewNum);
      }
    }
    else {
      if (this.printViewNum != this.inputtedWordImgLetters.length - 1) {
        this.printViewNum++;

        this.initPrint(this.printType, this.printViewNum);
      }
    }

  }

  cancelPrint() {
    this.printViewNum = 0;
    this.inputtedWordsOnlyLetters = [];
    this.inputtedWordImgLetters = [];
    this.inputtedLettersGenerated = false;
    this.letter = '';
    this.step = 4;
  }

  async print() {
    if (this.printType == 'word pages') {
      this.printer.print([this.printCanvas], PrintOrientation.Portrait, true);
    }
    else {
      this.printer.print([this.printCanvas], PrintOrientation.Landscape, true);
    }
  }

  selectImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onloadend = () => {
        this.tempImageUrl = reader.result;
      }
    }
  }

  applyWordImgInput(char) {
    if (!this.wordAndImgLetters[char.charCodeAt(0) - 97].word || !this.wordAndImgLetters[char.charCodeAt(0) - 97].word.startsWith(char)) {
      this.wordError = true;
      console.log('error');
    }

    if (this.wordAndImgLetters[char.charCodeAt(0) - 97].word && this.wordError == false) {
      this.wordAndImgLetters[char.charCodeAt(0) - 97] = { word: this.wordAndImgLetters[char.charCodeAt(0) - 97].word, description: this.wordAndImgLetters[char.charCodeAt(0) - 97].description, imgUrl: this.tempImageUrl, entered: true };
      this.tempImageUrl = null;
      this.letter = '';
      this.dim = false;
      this.wordError = false;
      console.log('obama');
    }
  }

  clearWordImgInput(char) {

    if (!this.wordAndImgLetters[char.charCodeAt(0) - 97].entered) {
      this.wordAndImgLetters[char.charCodeAt(0) - 97].word = '';
      this.wordAndImgLetters[char.charCodeAt(0) - 97].description = '';
      this.wordAndImgLetters[char.charCodeAt(0) - 97].imgUrl = null;
    }

    this.dim = false;
    this.letter = '';
  }

  save() {
    if (this.organizerType == 'wordsOnly') {
      this.fileUtil.save(this.wordsOnlyLetters, 'Organizer.json');
    }
    else {
      this.fileUtil.save(this.wordAndImgLetters, 'Organizer.json');
    }
  }

  fileInput: any;
  open(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {

        let json = e.target.result;
        this.wordsOnlyLetters = JSON.parse(json);
        this.wordAndImgLetters = JSON.parse(json);

        let wordsOnly = false;
        for (let letter of this.wordsOnlyLetters) {
          if ('applied' in letter) {
            wordsOnly = true;
          }
        }
        if (wordsOnly) {
          this.organizerType = 'wordsOnly';
        }
        else {
          this.organizerType = 'wordAndPicture';
        }

        // this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }
}
