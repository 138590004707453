<div class="app-border">
    <div class="title-bar">
        <span>Word Family Sort</span>
    </div>
    <div class="app">
        <!---------------------------------------- Name Input -------------------------------------------------------->
        <div class="name-input" *ngIf="step === 0">
            <h1>Enter your name and click <br> "Begin" when ready:</h1>
            <input type="text" autocomplete="name" [(ngModel)]="projectData.name" />

            <button (click)="checkName()">Begin ></button>

            <p>This activity has been modeled after the word family sort referenced in <i>Small-Group Reading
                    Instruction: A Differentiated Teaching Model for Beginning and Struggling Readers</i> by Beverly
                Tyner (International Reading Association, 2004)</p>
        </div>

        <!---------------------------------------- Vowel Choice -------------------------------------------------------->

        <div class="vowel-choice" *ngIf="step === 1">
            <h1>Short vowel word families</h1>

            <p class="vowel-description">Click on a vowel to begin a word sort with words in that vowel family.</p>

            <table>
                <tr>
                    <td>
                        <button (click)="chooseVowel('a')" class="choose-vowel vowel-a">
                            <p>a</p>
                        </button>
                    </td>
                    <td>
                        <button (click)="chooseVowel('e')" class="choose-vowel vowel-e">
                            <p>e</p>
                        </button>
                    </td>
                </tr>

                <tr>
                    <td>
                        <button (click)="chooseVowel('i')" class="choose-vowel vowel-i">
                            <p>i</p>
                        </button>
                    </td>
                    <td>
                        <button (click)="chooseVowel('o')" class="choose-vowel vowel-o">
                            <p>o</p>
                        </button>
                    </td>
                </tr>
            </table>

            <button (click)="chooseVowel('u')" class="choose-vowel vowel-u">
                <p>u</p>
            </button>
        </div>

        <!---------------------------------------- Sorting -------------------------------------------------------->
        <div class="center-container" *ngIf="exitWarning === true || printBox === true">
            <div class="exit-warning" *ngIf="exitWarning === true">
                <button (click)="exitWarning = false" class="close-box">X</button>
                <p class="remember-print">Remember to print your word family chart before closing. Your work will not be saved.</p>
                
                <table class="print-buttons">
                    <td class="print-button">
                        <table (click)="exitWarning = false">
                            <td class="print-icon">🖶</td>
                            <td>Take me back <br> to print.</td>
                        </table>
                    </td>
                    <td class="print-button">
                        <table (click)="start()">
                            <td class="x-icon">x</td>
                            <td>I'm Finished</td>
                        </table>
                    </td>
                </table>
            </div>

            <div class="print-box" *ngIf="printBox === true">
                <button (click)="printBox = false" class="close-box">X</button>

                <table class="print-buttons">
                    <td class="one-chart">
                        <table class="print-chart1" (click)="print(printAll=false)">
                            <td class="button-img">
                                <img src="/assets/word-family/wordfamily-chart1.png" />
                            </td>
                            <td>
                                Print this <br> chart only
                            </td>
                        </table>
                    </td>
                    
                    <td>
                        <table (click)="print(printAll=true)">
                            <td class="button-img">
                                <img src="/assets/word-family/wordfamily-chartall.png" />
                            </td>
                            <td>
                                Print all <br> charts
                            </td>
                        </table>
                    </td>
                </table>
            </div>
        </div>

        <div class="word-sort" *ngIf="step === 2">
            <h1 class="vowel-label" *ngIf="projectData.availableWords[currentVowel].length !== 0">Short {{currentVowel}}</h1>

            <p class="sort-description" *ngIf="projectData.availableWords[currentVowel].length !== 0">Click the correct word family column for the blue word. Click a word in a word family column to remove it.</p>

            <p class="sort-description all-sorted" *ngIf="projectData.availableWords[currentVowel].length === 0">
                You have sorted all of your words. <br/> Remember to print your word family chart.
            </p>

            <p class="word-of">Word {{getVowelWords().length + currentWordExists()}} of {{getVowelWords().length + projectData.availableWords[currentVowel].length}}</p>

            <div class="inputted-words current-word" *ngIf="currentWord">
                <p>{{ currentWord }}</p>
            </div>

            <table class="word-table">
                <tr>
                    <td class="vowel-header" *ngFor="let header of headers[currentVowel]" (click)="chooseColumn(header)">
                        <p>{{header}}</p>
                    </td>
                </tr>

                <tr *ngFor="let i of range(9)">
                    <td *ngFor="let header of headers[currentVowel]">
                        <div class="inputted-words" *ngIf="projectData.currentWords[currentVowel][header][i]" (click)="removeWord(header, projectData.currentWords[currentVowel][header][i])">
                            <p>{{ projectData.currentWords[currentVowel][header][i] }}</p>
                        </div>

                        <div class="empty-cell" *ngIf="!projectData.currentWords[currentVowel][header][i]"
                             (click)="chooseColumn(header)">
                        </div>
                    </td>
                </tr>
            </table>

            <table class="buttons-table">
                <td class="button1">
                    <table class="pointer" (click)="printBox = true">
                        <td class="vert-middle">🖶</td>
                        <td>PRINT MY WORD <br /> FAMILY CHART</td>
                    </table>
                </td>
                <td class="button2" >
                    <table class="pointer" (click)="differentVowel()">
                        <td class="vert-middle"><</td>
                        <td>CHOOSE A <br /> DIFFERENT VOWEL</td>
                    </table>
                </td>
                <td class="button3">
                    <table class="pointer" (click)="exitWarning = true">
                        <td class="exit-x">x</td>
                        <td>EXIT</td>
                    </table>
                </td>
            </table>
        </div>
    </div>
</div>

<div #printCanvas class="print-canvas" *ngIf="printing === true && printAll === false">
    <p class="print-name">Name: {{ projectData.name }}</p>
    <h1 class="print-label">Word Family Sort</h1>
    <h1 class="vowel-label">Short {{ currentVowel }}</h1>

    <table class="word-table">
        <tr>
            <td class="vowel-header" *ngFor="let header of headers[currentVowel]">
                <p>{{header}}</p>
            </td>
        </tr>
    
        <tr *ngFor="let i of range(9)">
            <td *ngFor="let header of headers[currentVowel]">
                <div class="inputted-words" *ngIf="projectData.currentWords[currentVowel][header][i]">
                    <p>{{ projectData.currentWords[currentVowel][header][i] }}</p>
                </div>
    
                <div class="empty-cell" *ngIf="!projectData.currentWords[currentVowel][header][i]">
                </div>
            </td>
        </tr>
    </table>
</div>

<div *ngIf="printing === true && printAll === true">
    <div #printCanvas *ngFor="let currentVowel of ['a', 'e', 'i', 'o', 'u']" class="print-canvas">
        <p class="print-name">Name: {{ projectData.name }}</p>
        <h1 class="print-label">Word Family Sort</h1>
        <h1 class="vowel-label">Short {{ currentVowel }}</h1>
    
        <table class="word-table">
            <tr>
                <td class="vowel-header" *ngFor="let header of headers[currentVowel]">
                    <p>{{header}}</p>
                </td>
            </tr>
        
            <tr *ngFor="let i of range(9)">
                <td *ngFor="let header of headers[currentVowel]">
                    <div class="inputted-words" *ngIf="projectData.currentWords[currentVowel][header][i]">
                        <p>{{ projectData.currentWords[currentVowel][header][i] }}</p>
                    </div>
        
                    <div class="empty-cell" *ngIf="!projectData.currentWords[currentVowel][header][i]">
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>