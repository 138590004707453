<div class="app-border">
    <div class="title-bar">
        <span>Plot Diagram</span>
        <button *ngIf="step !== 2" class="next-button" (click)="nextStep()">Next &#9658;</button>
    </div>
    <!------------------- Diagram Example --------------------->
    <div *ngIf="step == 0" class="diagram-example">
        <div class="between-lines">
            <h1>middle</h1>
            <h1>climax</h1>
        </div>
        <div class="line1">
            <h1>beginning</h1>
            <div></div>
            <h1>exposition</h1>
        </div>
        <div class="line2">
            <h1>rising action</h1>
            <div></div>
        </div>
        <div class="line3">
            <h1>falling action</h1>
            <div></div>
        </div>
        <div class="line4">
            <h1>end</h1>
            <div></div>
            <h1>resolution</h1>
        </div>

        <hr>
        <h2>OPEN SAVED DIAGRAM</h2>
    
        <input [(ngModel)]='fileInput' id="preview" type="file" (change)="open($event)">


    </div>
    <!------------------------- Diagram type, title, and author  ----------------------->
    <div *ngIf="step == 1" class="diagram-choices">
        <h1>Project Labels</h1>
        <p>Add project labels in the spaces provided. Click the "Next" button to begin working on your Plot Diagram.</p>

        <div class="inputs">
            <div class="text-inputs">
                <label>Project Title:</label>
                <input type="text" id="projectTitle" name="projectTitle" [(ngModel)]="plotDiagram.projectTitle"
                    autocomplete="off">

                <br />

                <label>By:</label>
                <input type="text" id="author" name="author" [(ngModel)]="plotDiagram.author" autocomplete="off">
            </div>
            <div class='error' *ngIf='labelError'>
                Title and Author is required.
            </div>
        </div>
        <h1>Triangle Labels</h1>
        <div class="triangle-choice">
            <input [(ngModel)]="plotDiagram.triangleChoice" type="radio" value="middle" id="triangle-choice1"
                name="triangle-choice" />
            <label for="triangle-choice1">Beginning, Middle, End</label>
            <br />
            <input [(ngModel)]="plotDiagram.triangleChoice" type="radio" value="climax" id="triangle-choice2"
                name="triangle-choice" />
            <label for="triangle-choice2">Exposition, Climax, Resolution</label>
        </div>
    </div>
    <!------------------------- Plot Diagrammer  ----------------------->

    <div *ngIf="step == 2" class="toolbar">
        <button (click)="print()"><i class='fa fa-print'></i>&nbsp;Print</button>
        <button (click)="export()"><i class='fa fa-download'></i>&nbsp;Export</button>
        <button (click)="save()"><i class='fa fa-download'></i>&nbsp;Save</button>
        <button (click)="new()"><i class='fa fa-file-o'></i>&nbsp;New</button>
    </div>

    <div *ngIf="step == 2" class="plot-diagrammer" #printCanvas>
        <h2 *ngIf='printing'>{{plotDiagram.projectTitle}}</h2>
        <em *ngIf='printing'>By: {{plotDiagram.author}}</em>
            <table class="plot-table">
                <td>
                    <div class="left-panel">
                        <div class="diagram">
                            <div *ngIf="plotDiagram.triangleChoice === 'middle'">
                                <label id="triangle-label"
                                    [ngStyle]="{'top': triangleMiddle[1], 'left': triangleMiddle[0]}">middle</label>
                            </div>

                            <div *ngIf="plotDiagram.triangleChoice === 'climax'">
                                <label id="triangle-label"
                                    [ngStyle]="{'top': triangleMiddle[1], 'left': triangleMiddle[0]}">climax</label>

                                <label id="rising-label"
                                    [ngStyle]="{'top': triangleRising[1], 'left': triangleRising[0], 'transform': triangleRising[2]}">
                                    rising action
                                </label>

                                <label id="falling-label"
                                    [ngStyle]="{'top': triangleFalling[1], 'left': triangleFalling[0], 'transform': triangleFalling[2]}">
                                    falling action
                                </label>
                            </div>


                            <canvas id="triangle-canvas" (load)="makeTriangle()"></canvas>
                        </div>
                        <div class='drag-canvas' drag-canvas [dragElements]='plotDiagram.elements'>
                            <div drag-object *ngFor='let elem of plotDiagram.elements' [dragElement]='elem'
                                [dragElements]='plotDiagram.elements' [allowSelect]='true'>
                                <drag-toolbar *ngIf='!printing' [dragElement]='elem'
                                    [dragElements]='plotDiagram.elements' [showEdit]='elem.type=="text"'
                                    (onEdit)='editLabel(elem, $event)'></drag-toolbar>
                                <label *ngIf='elem.type=="text"'>{{elem.text || 'New Label'}}</label>
                                <drag-status *ngIf='!printing' [dragElement]='elem' [allowSize]='elem.type=="image"'>
                                </drag-status>
                            </div>
                        </div>
                        <div class="timeline"></div>
                        <div class="timeline-labels-middle">
                            <div *ngIf="plotDiagram.triangleChoice === 'middle'">
                                <div class="timeline-beginning">beginning</div>
                                <div class="timeline-end">end</div>
                            </div>
                            <div *ngIf="plotDiagram.triangleChoice === 'climax'">
                                <div class="timeline-beginning">exposition</div>
                                <div class="timeline-end">resolution</div>
                            </div>

                        </div>

                        <div *ngIf='!printing' class="slider-margin">
                            <div class="slidercontainer">
                                <input id="triangle-slider" type="range" min="0" max="100" value="50" class="slider"
                                    (input)="makeTriangle()" [(ngModel)]="sliderValue" *ngIf="makeTriangle()">
                            </div>
                            <em>Drag the slider to adjust the diagram</em>
                        </div>
                    </div>

                </td>
                <td class="right-panel" *ngIf='!printing'>
                    <div class="event-chooser">

                        <button *ngIf='!plotDiagram.editingLabel' (click)='addLabel()' class='add-event-button'>Add
                            Event</button>
                        <div *ngIf='plotDiagram.editingLabel' class='drag-object-attribute'>
                            <div class='attribute-title'>Add Event</div>
                            <label>Event Text</label>
                            <input type='text' [(ngModel)]='plotDiagram.inputLabelText'
                                [ngClass]="{'has-error':emptyLabelError}">
                            <label>Description</label>
                            <textarea [(ngModel)]='plotDiagram.inputLabelDescription'></textarea>
                            <button (click)='saveLabel()'>Save</button>
                            <button class="cancel-button" (click)='plotDiagram.editingLabel=false'>Cancel</button>
                        </div>


                        <div *ngIf='!plotDiagram.editingLabel && step==2' class="input-instruction">
                            <p>Add plot event to the diagram, <br> then enter name and description.</p>
                        </div>
                    </div>
                </td>
            </table>
    </div>
    <div *ngIf='step==3' class='step step3'>
        <div class='noprint'>
            <h1>Finished!</h1>
            <p>
                Congratulations, your plot diagram is now complete. Your printout will show you where you placed items
                on the
                diagram and it will also show your description text.
            </p>
            <div>
                <button (click)='print()'><i class='fa fa-print'></i> Print</button>
                <button (click)='reset()'>Start New Project</button>
            </div>
            <hr>
        </div>



        <div class='print-preview-landscape'>
            <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
            <img *ngIf='previewImage' [src]='previewImage'>
        </div>

        <!-- <div #printCanvas class='print-canvas'>
            <h1>{{plotDiagram.projectTitle}}</h1>
            <h3>Diagrammed by {{plotDiagram.author}}</h3>
            <em>Printed on {{ now }}</em>


            <div class="diagram">
                <div *ngIf="plotDiagram.triangleChoice === 'middle'">
                    <label id="triangle-label"
                        [ngStyle]="{'top': triangleMiddle[1], 'left': triangleMiddle[0]}">middle</label>
                </div>

                <div *ngIf="plotDiagram.triangleChoice === 'climax'">
                    <label id="triangle-label"
                        [ngStyle]="{'top': triangleMiddle[1], 'left': triangleMiddle[0]}">climax</label>

                    <label id="rising-label"
                        [ngStyle]="{'top': triangleRising[1], 'left': triangleRising[0], 'transform': triangleRising[2]}">
                        rising action
                    </label>

                    <label id="falling-label"
                        [ngStyle]="{'top': triangleFalling[1], 'left': triangleFalling[0], 'transform': triangleFalling[2]}">
                        falling action
                    </label>
                </div>


                <canvas id="triangle-canvas" (load)="makeTriangle()"></canvas>
            </div>
            <div class='drag-canvas' drag-canvas [dragElements]='plotDiagram.elements'>
                <div drag-object *ngFor='let elem of plotDiagram.elements' [dragElement]='elem'
                    [dragElements]='plotDiagram.elements' [allowSelect]='true'>
                    <drag-toolbar [dragElement]='elem' [dragElements]='plotDiagram.elements'
                        [showEdit]='elem.type=="text"' (onEdit)='editLabel(elem, $event)'></drag-toolbar>
                    <label *ngIf='elem.type=="text"'>{{elem.text || 'New Label'}}</label>
                    <drag-status [dragElement]='elem' [allowSize]='elem.type=="image"'></drag-status>
                </div>
            </div>
            <div class="timeline"></div>
            <div class="timeline-labels-middle">
                <div *ngIf="plotDiagram.triangleChoice === 'middle'">
                    <div class="timeline-beginning">beginning</div>
                    <div class="timeline-end">end</div>
                </div>
                <div *ngIf="plotDiagram.triangleChoice === 'climax'">
                    <div class="timeline-beginning">exposition</div>
                    <div class="timeline-end">resolution</div>
                </div>
            </div>


            <div #printPage2Canvas class='print-canvas print-horizontal-page'>
                <h3>Plot Events and Descriptions</h3>
                <p *ngFor='let elem of plotDiagram.elements;let idx=index;'>
                    <strong>{{idx+1}}</strong>. {{elem.text}}<br>
                    {{elem.description}}
                    <br>
                </p>
            </div>

        </div> -->
    </div>