import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { DramaMapFile } from 'src/app/model/DramaMapFile';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-drama-map',
  templateUrl: './drama-map.component.html',
  styleUrls: ['./drama-map.component.scss']
})
export class DramaMapComponent implements OnInit {

  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  @ViewChild('printPreview', null) printPreview: ElementRef;

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }
  previewImage: any;

  ngOnInit() {
  }

  step: number = 1;

  map: DramaMapFile = new DramaMapFile();

  initMap(type: string) {
    if (this.map.title && this.map.name) {
      this.map.type = type;
      this.step++;
    }
    else{
      if (!this.map.title) {
        this.map.titleError = true;
      }
      if (!this.map.name) {
        this.map.nameError = true;
      }
    }
  }

  enterCharacterName(){
    if (this.map.characterName) {
      this.map.characterNameEntered = true;
    }
    else {
      this.map.characterNameError = true;
    }
  }

  enterInput(type: string){
    switch(type){
      //Character inputs
      case 'appearance':
        if (this.map.appearance) {
          this.map.appearanceEntered = true;
        }
        else {
          this.map.appearanceError = true;
        }
        break;

      case 'behavior':
        if (this.map.behavior) {
          this.map.behaviorEntered = true;
        }
        else {
          this.map.behaviorError = true;
        }
        break;

      case 'reaction':
        if (this.map.reaction) {
          this.map.reactionEntered = true;
        }
        else {
          this.map.reactionError = true;
        }
        break;

      //Conflict inputs
      case 'conflict':
        if (this.map.conflict) {
          this.map.conflictEntered = true;
        }
        else {
          this.map.conflictError = true;
        }
        break;

      case 'reason':
        if (this.map.reason) {
          this.map.reasonEntered = true;
        }
        else {
          this.map.reasonError = true;
        }
        break;

      case 'possibleResolution':
        if (this.map.possibleResolution) {
          this.map.possibleResolutionEntered = true;
        }
        else {
          this.map.possibleResolutionError = true;
        }
        break;

      //Resolution inputs
      case 'resolution':
        if (this.map.resolution) {
          this.map.resolutionEntered = true;
        }
        else {
          this.map.resolutionError = true;
        }
        break;

        case 'afterResolution':
          if (this.map.afterResolution) {
            this.map.afterResolutionEntered = true;
          }
          else {
            this.map.afterResolutionError = true;
          }
          break;

        case 'effect':
          if (this.map.effect) {
            this.map.effectEntered = true;
          }
          else {
            this.map.effectError = true;
          }
          break;

        //Setting inputs
        case 'place':
          if (this.map.place) {
            this.map.placeEntered = true;
          }
          else {
            this.map.placeError = true;
          }
          break;

        case 'time':
          if (this.map.time) {
            this.map.timeEntered = true;
          }
          else {
            this.map.timeError = true;
          }
          break;

        case 'description':
          if (this.map.description) {
            this.map.descriptionEntered = true;
          }
          else {
            this.map.descriptionError = true;
          }
          break;
    }
  }

  initPrint() {
    this.previewImage = null;
    if ((this.map.type == 'character' && this.map.reactionEntered) || (this.map.type == 'conflict' && this.map.possibleResolutionEntered) || (this.map.type == 'resolution' && this.map.effectEntered) || (this.map.type == 'setting' && this.map.descriptionEntered)) {
      this.step = 3;
      setTimeout(async () => {
          this.previewImage = await this.printer.initPrintPreview([this.printCanvas], PrintOrientation.Landscape);
      }, 100);
    }
    else {
      alert('Please answer each equestion.')
    }
  }

  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Landscape, true, true);
  }
}
