<div class='app-border'>
  <div class='title-bar'>
    <span>Haiku Poem Interactive</span>
  </div>

  <div *ngIf='step==1' class='step'>
    <div class='page-1-background'>
      <div *ngIf='showInfo || showInspired || showSyllable || showAha' class='dim'></div>
      <img class='info-button' src='assets/haiku-poem-interactive/info-button.png' (click)='showInfo = true'>
      <div *ngIf='showInfo' class='info-background'>
        <img src='assets/haiku-poem-interactive/producer.png'>
        <img src='assets/haiku-poem-interactive/partnership.png'>
        <img src='assets/haiku-poem-interactive/support.png'>
        <img class='ok-button' src='assets/haiku-poem-interactive/ok-button.png' (click)='showInfo = false'>
      </div>

      <img class='be-inspired-button' src='assets/haiku-poem-interactive/be-inspired-button.png'
        (click)='showInspired = true'>
      <img class='syllable-count-button' src='assets/haiku-poem-interactive/syllable-count-button.png'
        (click)='showSyllable = true'>
      <img class='aha-moment-button' src='assets/haiku-poem-interactive/aha-moment-button.png' (click)='showAha = true'>

      <div *ngIf='showInspired' class='inspired-message'>
        <img class='close-button' src='assets/haiku-poem-interactive/close-button.png' (click)='showInspired = false'>
      </div>
      <div *ngIf='showSyllable' class='syllable-message'>
        <img class='close-button' src='assets/haiku-poem-interactive/close-button.png' (click)='showSyllable = false'>
      </div>
      <div *ngIf='showAha' class='aha-message'>
        <img class='close-button' src='assets/haiku-poem-interactive/close-button.png' (click)='showAha = false'>
      </div>

      <label class='name-input-label'>Your Name:</label>
      <input class='name-input' [ngClass]="{'input-error': nameError}" maxlength='30' [(ngModel)]='interactive.name'
        (change)='nameError = false'>

      <img class='start-button' [ngClass]="{'start-dim': !interactive.name}"
        src='assets/haiku-poem-interactive/start-button.png' (click)='initInteractive()'>
    </div>
    <h2>OPEN SAVED CUBE</h2>

    <input [(ngModel)]='fileInput' id="preview" type="file" (change)="openPoem($event)">

  </div>

  <div *ngIf='step==2' class='step'>
    <div class='page-2-background'>
      <div class='input-row-1'>
        <input class='page-2-input-1' maxlength='30' [(ngModel)]='interactive.wordSyllables[0].word'>
        <input class='page-2-syllables-1' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[0].syllables'>

        <input class='page-2-input-2' maxlength='30' [(ngModel)]='interactive.wordSyllables[1].word'>
        <input class='page-2-syllables-2' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[1].syllables'>
      </div>

      <div class='input-row'>
        <input class='page-2-input-1' maxlength='30' [(ngModel)]='interactive.wordSyllables[2].word'>
        <input class='page-2-syllables-1' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[2].syllables'>

        <input class='page-2-input-2' maxlength='30' [(ngModel)]='interactive.wordSyllables[3].word'>
        <input class='page-2-syllables-2' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[3].syllables'>
      </div>

      <div class='input-row'>
        <input class='page-2-input-1' maxlength='30' [(ngModel)]='interactive.wordSyllables[4].word'>
        <input class='page-2-syllables-1' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[4].syllables'>

        <input class='page-2-input-2' maxlength='30' [(ngModel)]='interactive.wordSyllables[5].word'>
        <input class='page-2-syllables-2' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[5].syllables'>
      </div>

      <div class='input-row'>
        <input class='page-2-input-1' maxlength='30' [(ngModel)]='interactive.wordSyllables[6].word'>
        <input class='page-2-syllables-1' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[6].syllables'>

        <input class='page-2-input-2' maxlength='30' [(ngModel)]='interactive.wordSyllables[7].word'>
        <input class='page-2-syllables-2' numeric-only type='text' [(ngModel)]='interactive.wordSyllables[7].syllables'>
      </div>

      <div class='page-2-buttons'>
        <img class='page-2-restart-button' src='assets/haiku-poem-interactive/restart-button.png'
          (click)='startOverConfirm = true'>
        <img class='next-button' src='assets/haiku-poem-interactive/next-button.png' (click)='step=3'>
      </div>

      <div class='start-over' *ngIf='startOverConfirm'>
        <img class='confirm-button' src='assets/alphabet-organizer/confirm-button.png' (click)='restart()'>
        <img class='restart-cancel-button' src='assets/alphabet-organizer/restart-cancel-button.png'
          (click)='startOverConfirm = false'>
      </div>
    </div>
  </div>

  <div *ngIf='step==3' class='step'>
    <div class='page-3-background'>
      <div class='page-3-inputs'>
        <input class='title-input' maxlength='40' [(ngModel)]='interactive.title'>

        <div class='poem-inputs'>
          <div class='poem-input-line'>
            <input class='poem-input' maxlength='40' [(ngModel)]='interactive.poemLine1'>
            <input type='text' class='poem-syllable-input' numeric-only [(ngModel)]='interactive.poemSyllables1'>
          </div>

          <div class='poem-input-line'>
            <input class='poem-input' maxlength='40' [(ngModel)]='interactive.poemLine2'>
            <input type='text' class='poem-syllable-input' numeric-only [(ngModel)]='interactive.poemSyllables2'>
          </div>

          <div class='poem-input-line'>
            <input class='poem-input' maxlength='40' [(ngModel)]='interactive.poemLine3'>
            <input type='text' class='poem-syllable-input' numeric-only [(ngModel)]='interactive.poemSyllables3'>
          </div>
        </div>
      </div>
      <div class='brainstorm-words'>
        <div *ngFor='let w of interactive.wordSyllables'>
          <div *ngIf='!!w.word' class='brainstorm-word'>
            <p class='brainstorm-word-text'>{{ w.word }}</p>
          </div>
        </div>
      </div>

      <div class='page-3-buttons'>
        <img class='back-button' src='assets/haiku-poem-interactive/back-button.png' (click)='step=2'>
        <img class='next-button' src='assets/haiku-poem-interactive/next-button.png' (click)='finishPoem()'>
      </div>
    </div>
  </div>

  <div *ngIf='step==4' class='step'>
    <div class='toolbar'>
      <button (click)='cancelBuild()'>
        <i class='fa fa-chevron-left'></i> &nbsp; Cancel
      </button>
      <button (click)='initPrint()'>
        Next &nbsp;<i class='fa fa-chevron-right'></i>
      </button>

      <label class='toolbar-label'>Background:</label>
      <img class='toolbar-button' src='assets/haiku-poem-interactive/tree-button.png' (click)="background='tree'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/branches-button.png'
        (click)="background='branches'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/flowers-button.png' (click)="background='flowers'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/mountains-button.png'
        (click)="background='mountains'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/fish-button.png' (click)="background='fish'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/blank-button.png' (click)="background='blank'">

      <div class='toolbar-label'>Text Style:</div>
      <img class='toolbar-button' src='assets/haiku-poem-interactive/japanese-brush.png'
        (click)="font='japanese-brush'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/kristen-ITC.png' (click)="font='kristen-ITC'">
      <img class='toolbar-button' src='assets/haiku-poem-interactive/lucida-handwriting.png'
        (click)="font='lucida-handwriting'">
    </div>

    <h1 class='click-drag-instruction'><em>(Click and drag to position poem lines)</em></h1>

    <div class='poem-box'>
      <img class='poem-background' src='assets/haiku-poem-interactive/tree-background.png' *ngIf="background=='tree'">
      <img class='poem-background' src='assets/haiku-poem-interactive/branches-background.png'
        *ngIf="background=='branches'">
      <img class='poem-background' src='assets/haiku-poem-interactive/flowers-background.png'
        *ngIf="background=='flowers'">
      <img class='poem-background' src='assets/haiku-poem-interactive/mountains-background.png'
        *ngIf="background=='mountains'">
      <img class='poem-background' src='assets/haiku-poem-interactive/fish-background.png' *ngIf="background=='fish'">

      <p class='title-label'>{{ interactive.title }}</p>
      <p class='name-label'>By: {{ interactive.name }}</p>
      <div class='drag-canvas' drag-canvas numeric-onlynvas [dragElements]='interactive.elements'>
        <div class='drag-object' drag-object *ngFor='let elem of interactive.elements' [dragElement]='elem'
          [dragElements]='interactive.elements' [allowSelect]='true'>
          <drag-toolbar [dragElement]='elem' [dragElements]='interactive.elements' [showEdit]='elem.type=="text"'>
          </drag-toolbar>
          <p [ngClass]="{'japanese-brush': font=='japanese-brush', 'kristen-itc': font=='kristen-ITC', 'lucida-handwriting': font=='lucida-handwriting'}"
            class='drag-text'>{{ elem.text }}</p>
          <drag-status [dragElement]='elem' [allowSize]='elem.type=="image"'></drag-status>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='step==5'>
    <div class='toolbar'>
      <button (click)="step=4">
        <i class='fa fa-chevron-left'></i> &nbsp; Back
      </button>

      <button (click)='save()'><i class='fa fa-save'></i> Save</button>

      <button (click)="print()"><i class='fa fa-print'></i> Print</button>
    </div>

    <div class='print-preview-landscape'>
      <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
      <img *ngIf='previewImage' [src]='previewImage'>
    </div>

    <div #printCanvas style="width: 950px" class='print-canvas print-content'>
      <div class='print-poem-box'>
        <img class='poem-background' src='assets/haiku-poem-interactive/tree-background.png' *ngIf="background=='tree'">
        <img class='poem-background' src='assets/haiku-poem-interactive/branches-background.png'
          *ngIf="background=='branches'">
        <img class='poem-background' src='assets/haiku-poem-interactive/flowers-background.png'
          *ngIf="background=='flowers'">
        <img class='poem-background' src='assets/haiku-poem-interactive/mountains-background.png'
          *ngIf="background=='mountains'">
        <img class='poem-background' src='assets/haiku-poem-interactive/fish-background.png' *ngIf="background=='fish'">

        <p class='title-label'>{{ interactive.title }}</p>
        <p class='name-label'>By: {{ interactive.name }}</p>
        <div class='drag-canvas' drag-canvas numeric-onlynvas [dragElements]='interactive.elements'>
          <div class='drag-object' drag-object *ngFor='let elem of interactive.elements' [dragElement]='elem'
            [dragElements]='interactive.elements' [allowSelect]='true'>
            <drag-toolbar [dragElement]='elem' [dragElements]='interactive.elements' [showEdit]='elem.type=="text"'>
            </drag-toolbar>
            <p [ngClass]="{'japanese-brush': font=='japanese-brush', 'kristen-itc': font=='kristen-ITC', 'lucida-handwriting': font=='lucida-handwriting'}"
              class='drag-text'>{{ elem.text }}</p>
            <drag-status [dragElement]='elem' [allowSize]='elem.type=="image"'></drag-status>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
