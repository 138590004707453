import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DiamantePoemsFile } from '../../model/DiamantePoemsFile';
import { FileUtilService } from "src/app/service/file-util.service";
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-diamante-poems',
  templateUrl: './diamante-poems.component.html',
  styleUrls: ['./diamante-poems.component.scss']
})
export class DiamantePoemsComponent implements OnInit {

  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  previewImage: any;
  step: number = 1;

  showingExamples: boolean = false;

  nameError: boolean = false;
  titleError: boolean = false;

  showingAntonymInfo: boolean = false;
  showingSynonymInfo: boolean = false;
  showingAdjectivesInfo: boolean = false;
  showingIngInfo: boolean = false;
  showingNounInfo: boolean = false;
  showingShortPhraseInfo: boolean = false;
  showingIng2Info: boolean = false;
  showingAdjectives2Info: boolean = false;

  restartConfirm: boolean = false;

  poem: DiamantePoemsFile = new DiamantePoemsFile();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
  }

  clickPrintExample($event) {
    $event.stopPropagation();
  }

  initPoem() {
    if (!this.poem.name) {
      this.nameError = true;
    }
    if (!this.poem.title) {
      this.titleError = true;
    }

    if (this.poem.name && this.poem.title) {
      this.step = 2;
    }
  }

  restart() {
    this.poem.name = '';
    this.poem.title = '';

    this.poem.beginningTopic = '';
    this.poem.endingTopic = '';

    this.poem.adjective1 = '';
    this.poem.adjective2 = '';
    this.poem.ing1 = '';
    this.poem.ing2 = '';
    this.poem.ing3 = '';
    this.poem.nouns = '';
    this.poem.ing4 = '';
    this.poem.ing5 = '';
    this.poem.ing6 = '';
    this.poem.adjective3 = '';
    this.poem.adjective4 = '';

    this.restartConfirm = false;

    this.step--;
  }

  initPrint() {
    let that = this;
    this.previewImage = null;
    setTimeout(async () => {
      this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Landscape);
    }, 100);

    this.step++;
  }

  async print() {
    this.printer.print([this.printCanvas], PrintOrientation.Landscape);
  }

  save() {
    this.fileUtil.save(this.poem, 'DiamantePoem.json');
  }

  fileInput: any;
  openPoem(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.poem = JSON.parse(json);
        this.initPoem();
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

}
