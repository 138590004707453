import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ResumeGenerator, ResumeSelection, ExperienceData, EducationData, SkillsData } from 'src/app/model/ResumeGenerator';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
    selector: 'app-resume-generator',
    templateUrl: './resume-generator.component.html',
    styleUrls: ['./resume-generator.component.scss']
})
export class ResumeGeneratorComponent implements OnInit {
    previewImage: any;

    @ViewChild('printPreview', null) printPreview: ElementRef;
    @ViewChild('printCanvas', null) printCanvas: ElementRef;

    step: number = 0;
    projectData: ResumeGenerator = new ResumeGenerator();

    defaultInputSelections: object = {
        "Chronological": [
            "Personal Info", "Experience", "Education", "Other", "Template", "Summary", "Final"
        ],

        "Functional": [
            "Personal Info", "Skills", "Experience", "Education", "Other", "Template", "Summary", "Final"
        ]
    };

    numberToMonth: object = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
    }

    inputSelections: ResumeSelection[];
    selectedInput: string;

    deleteWarning: boolean = false;
    startOver: boolean = false;

    currentSelection: number = 0;


    constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

    ngOnInit() {
    }

    changeStep(step: number) {
        this.step = step;
    }

    nextStep() {
        this.step++;
    }

    chooseStyle(style: string) {
        this.projectData.resumeStyle = style;
        this.inputSelections = this.getSelections(style);
        this.nextStep();
    }

    getSelections(style: string) {
        let selections = [...this.defaultInputSelections[style]];
        let currentSelections = [];
        let selected = new ResumeSelection(selections[0]);
        selected.selected = true;
        this.selectedInput = selected.title;
        selections.splice(0, 1)
        currentSelections.push(selected)

        for (let selection of selections) {
            currentSelections.push(new ResumeSelection(selection));
        }

        return currentSelections;

    }

    inputSelect(selection: ResumeSelection) {
        if (this.selectedInput != selection.title) {
            for (let selection of this.inputSelections) {
                selection.selected = false;
            }

            selection.selected = true;
            this.selectedInput = selection.title;
            this.currentSelection = 0;

            if (selection.title === 'Final') {
                this.initPrint();
            }
        }
    }

    switchStyles() {
        this.changeStep(2);
    }

    changeInputSelection(amount: number) {
        let selections = this.defaultInputSelections[this.projectData.resumeStyle];
        let index = selections.indexOf(this.selectedInput) + amount;
        if (index < 0) this.switchStyles()
        else {
            this.selectedInput = selections[index];
            this.currentSelection = 0;
        }

        for (let selection of this.inputSelections) {
            selection.selected = false;
            if (selection.title === this.selectedInput) {
                selection.selected = true;

                if (selection.title === 'Final') {
                    this.initPrint();
                }
            }
        }
    }

    checkNumber(event: KeyboardEvent, output: string) {
        let numbers = "0123456789";
        if (event.key == "Backspace") this.removeNumber(output);
        if (numbers.split('').includes(event.key)) {
            switch (output) {
                case "startMonth":
                    if (this.projectData.experiences[this.currentSelection].startMonth.length < 2) this.projectData.experiences[this.currentSelection].startMonth += event.key;
                    break;
                case "startYear":
                    if (this.projectData.experiences[this.currentSelection].startYear.length < 4) this.projectData.experiences[this.currentSelection].startYear += event.key;
                    break;
                case "endMonth":
                    if (this.projectData.experiences[this.currentSelection].endMonth.length < 2) this.projectData.experiences[this.currentSelection].endMonth += event.key;
                    break;
                case "endYear":
                    if (this.projectData.experiences[this.currentSelection].endYear.length < 4) this.projectData.experiences[this.currentSelection].endYear += event.key;
                    break;
                case "graduationMonth":
                    if (this.projectData.educations[this.currentSelection].graduationMonth.length < 2) this.projectData.educations[this.currentSelection].graduationMonth += event.key;
                    break;
                case "graduationYear":
                    if (this.projectData.educations[this.currentSelection].graduationYear.length < 4) this.projectData.educations[this.currentSelection].graduationYear += event.key;
                    break;
            }
        }
    }

    removeNumber(output: string) {
        if (output === "startMonth" || output === "startYear" || output === "endMonth" || output === "endYear") {
            let startMonth = this.projectData.experiences[this.currentSelection].startMonth;
            let startYear = this.projectData.experiences[this.currentSelection].startYear;
            let endMonth = this.projectData.experiences[this.currentSelection].endMonth;
            let endYear = this.projectData.experiences[this.currentSelection].endYear;

            switch (output) {
                case "startMonth":
                    if (startMonth.length >= 1) this.projectData.experiences[this.currentSelection].startMonth = startMonth.substring(0, startMonth.length - 1);
                    break;
                case "startYear":
                    if (startYear.length >= 1) this.projectData.experiences[this.currentSelection].startYear = startYear.substring(0, startYear.length - 1);
                    break;
                case "endMonth":
                    if (endMonth.length >= 1) this.projectData.experiences[this.currentSelection].endMonth = endMonth.substring(0, endMonth.length - 1);
                    break;
                case "endYear":
                    if (endYear.length >= 1) this.projectData.experiences[this.currentSelection].endYear = endYear.substring(0, endYear.length - 1);
                    break;
            }
        }

        if (output === "graduationMonth" || output === "graduationYear") {
            let graduationMonth = this.projectData.educations[this.currentSelection].graduationMonth;
            let graduationYear = this.projectData.educations[this.currentSelection].graduationYear;
            switch (output) {
                case "graduationMonth":
                    if (graduationMonth.length >= 1) this.projectData.educations[this.currentSelection].graduationMonth = graduationMonth.substring(0, graduationMonth.length - 1);
                    break;
                case "graduationYear":
                    if (graduationYear.length >= 1) this.projectData.educations[this.currentSelection].graduationYear = graduationYear.substring(0, graduationYear.length - 1);
                    break;
            }
        }
    }


    stillEmployed() {
        if (this.projectData.experiences[this.currentSelection].stillEmployed) {
            return "disabled";
        }
    }

    addExperience() {
        if (this.projectData.experiences.length < 10) this.projectData.experiences.push(new ExperienceData());
        this.currentSelection = this.projectData.experiences.length - 1;
    }

    addEducation() {
        if (this.projectData.educations.length < 10) this.projectData.educations.push(new EducationData());
        this.currentSelection = this.projectData.educations.length - 1;
    }

    addSkill() {
        if (this.projectData.skills.length < 10) this.projectData.skills.push(new SkillsData());
        this.currentSelection = this.projectData.skills.length - 1;
    }

    deleteExperience() {
        if (this.selectedInput === "Experience") {
            if (this.projectData.experiences.length > 1) {
                this.projectData.experiences.splice(this.currentSelection, 1);
                this.currentSelection = this.projectData.experiences.length - 1;
                this.deleteWarning = false;
            }
        }
        else if (this.selectedInput === "Education") {
            if (this.projectData.educations.length > 1) {
                this.projectData.educations.splice(this.currentSelection, 1);
                this.currentSelection = this.projectData.educations.length - 1;
                this.deleteWarning = false;
            }
        }
        else if (this.selectedInput === "Skills") {
            if (this.projectData.skills.length > 1) {
                this.projectData.skills.splice(this.currentSelection, 1);
                this.currentSelection = this.projectData.skills.length - 1;
                this.deleteWarning = false;
            }
        }
    }

    showDeleteWarning() {
        if (this.selectedInput === "Experience") {
            if (this.projectData.experiences.length > 1) {
                this.deleteWarning = true;
            }
        }
        else if (this.selectedInput === "Education") {
            if (this.projectData.educations.length > 1) {
                this.deleteWarning = true;
            }
        }
        else if (this.selectedInput === "Skills") {
            if (this.projectData.skills.length > 1) {
                this.deleteWarning = true;
            }
        }
    }

    nextTemplate() {
        this.projectData.templateSelection++;
        if (this.projectData.templateSelection > 2) this.projectData.templateSelection = 0;
    }

    prevTemplate() {
        this.projectData.templateSelection--;
        if (this.projectData.templateSelection < 0) this.projectData.templateSelection = 2;
    }

    getSummaryRows() {
        return [...Array(10).keys()];
    }

    isSkillComplete(skill) {
        if (skill.category) return skill.category;
        return "Incomplete"
    }

    isExperienceComplete(experience) {
        if (experience.companyName) return experience.companyName;
        return "Incomplete"
    }

    isEducationComplete(education) {
        if (education.school) return education.school;
        return "Incomplete"
    }

    getIncluded(objects) {
        let included = [];
        for (let i of objects) {
            if (i.include === true) included.push(i);
        }
        return included;
    }

    isLastIncluded(elem, array) {
        let included = this.getIncluded(array);
        if (elem === included[included.length - 1]) return true;
        return false;
    }

    getExperienceStartYear(experience: ExperienceData) {
        return experience.startYear.substring(2, 4);
    }

    getExperienceEndYear(template: number, experience: ExperienceData) {
        switch (template) {
            case 1:
                return experience.endYear.substring(2, 4);
            case 2:
                if (experience.stillEmployed) return "Present";
                else return `${experience.endMonth}/${experience.endYear.substring(2, 4)}`;
            case 3:
                if (experience.stillEmployed) return "Present";
                else return `${this.getMonth(experience.startMonth)} ${experience.endYear}`

        }
    }


    getEducationMonth(education: EducationData) {
        return `${this.getMonth(education.graduationMonth)} ${education.graduationYear}`;
    }

    getEducationYear(education: EducationData) {
        return education.graduationYear.substring(2, 4);
    }

    getMonth(monthNumber: string) {
        let monthInt = parseInt(monthNumber, 10);
        if (monthInt >= 1 && monthInt <= 12) return this.numberToMonth[monthInt];
        else return monthNumber;
    }


    async initPrint() {
        let that = this;
        this.previewImage = null;
        setTimeout(async () => {
            this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Portrait);
        }, 999999999999999);
    }

    async print() {
        this.printer.print([this.printCanvas], PrintOrientation.Portrait);
    }
    async export() {
        this.printer.export([this.printCanvas], 'Resume.pdf', PrintOrientation.Portrait);
    }
    save() {
        this.fileUtil.save(this.projectData, 'Resume.json')
    }

    fileInput: any;
    openResume(event: any) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            try {
                let json = e.target.result;
                this.projectData = JSON.parse(json);
                this.inputSelections = this.getSelections(this.projectData.resumeStyle);
                this.step = 3;
            }
            catch (e) {
                alert('File cannot be opened');
                this.fileInput = null;
            }
        };
        reader.readAsText(event.target.files[0]);
    }

    restart() {
        this.projectData = new ResumeGenerator();
        this.startOver = false;
        this.step = 2;
    }
}
