<div class="app-border">
  <div class="title-bar">
    <span>Word Mover</span>
  </div>
  <div class="app positioned">
    <div class="dimmed" *ngIf="showingInstructions"></div>
    <!----------------------------------- Instructions pop-up -------------------------------->
    <div class="instructions" *ngIf="showingInstructions === true">
      <button class="exit-instructions" (click)="showingInstructions = false">
        <img src="assets\word-mover\x-button.jpg" />
      </button>
      <h2 class="instructions-header">Creating Your Poem</h2>
      <table class="instructions-table">
        <tbody>
          <tr>
            <td>
              <img src="assets\word-mover\example-poem.jpg" class="instructions-img" />
            </td>
            <td class="instructions-paragraphs1">
              <p>Enter your name and a title for your poem.</p>
              <br />
              <p>
                Click on the Backgrounds tab to change the background of your
                poem.
              </p>
              <br />
              <p>
                If you don't like the words in your Word Bank, click on the "New
                Word Bank" button to get a new set of words, or click "Shuffle"
                to shuffle your current word bank.
              </p>
            </td>
          </tr>
          <tr>
            <td class="instructions-paragraphs2">
              <p>
                Click words in your Word Bank to add them to your poem, or
                create your own words using the "Add Label" button. Drag the
                word to your preferred position after adding it to your poem.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--------------------------------------  Intro Screen  --------------------------------------->
    <div class="intro-screen" *ngIf="step === 0">
      <button class="menu-buttons instructions-button" (click)="showingInstructions = true">
        Instructions
      </button>

      <button class="start-poem" (click)="nextStep()">
        <img class="start-button-imgs" src="assets\word-mover\start-poem-button.png" />
        <label class="intro-labels">Start a New Poem</label>
      </button>

      <button class="open-poem">
        <img class="start-button-imgs" src="assets\word-mover\open-existing-button.png" />
        <label class="intro-labels">Open a Poem in Progress</label>
      </button>
    </div>
    <!-------------------------------------  Choose Poem  ------------------------------------------>
    <div class="choose-poem-screen" *ngIf="step === 1">
      <button class="menu-buttons exit-button" (click)="reset()">Exit</button>
      <button class="menu-buttons instructions-button" (click)="showingInstructions = true">
        Instructions
      </button>

      <div class="custom-words">
        <button class="word-bank" (click)="initPoem('wordBank')">
          <img class="choose-poem-imgs" src="assets\word-mover\word-bank-button.png" />
          <label class="choose-poem-labels">Word Bank</label>
        </button>

        <button class="own-words" (click)="initPoem('ownWords')">
          <img class="choose-poem-imgs" src="assets\word-mover\my-words-button.png" />
          <label class="choose-poem-labels">My Own Words</label>
        </button>
      </div>

      <div class="famous-works">
        <button class="america-button" (click)="initPoem('americaPoem')">
          <img class="choose-poem-imgs" src="assets\word-mover\america-button.png" />
          <label class="choose-poem-labels">America the Beautiful</label>
        </button>

        <button class="famous-works-buttons" (click)="initPoem('gettysburgPoem')">
          <img class="choose-poem-imgs" src="assets\word-mover\gettysburg-button.png" />
          <label class="choose-poem-labels">Gettysburg Address</label>
        </button>

        <button class="famous-works-buttons" (click)="initPoem('dreamPoem')">
          <img class="choose-poem-imgs" src="assets\word-mover\dream-button.png" />
          <label class="choose-poem-labels">I Have a Dream</label>
        </button>

        <button class="famous-works-buttons" (click)="initPoem('shakespearePoem')">
          <img class="choose-poem-imgs" src="assets\word-mover\shakespeare-button.png" />
          <label class="choose-poem-labels">Shakespeare's Sonnet 18</label>
        </button>
      </div>
    </div>
    <!-----------------------------------  Poem Creators  --------------------------------------->
    <div class="word-bank-screen" *ngIf="step === 2" [ngStyle]="{
        'background-image':
          'url(./assets/word-mover/backgrounds/background' +
          poemBackground +
          '.jpg)'
      }">
      <div class="overlay">
        <button class="menu-buttons exit-button" (click)="reset()">Exit</button>
        <button class="menu-buttons instructions-button" (click)="nextStep()">
          Next
        </button>
        <button class="menu-buttons instructions-button" (click)="showingInstructions = true">
          Instructions
        </button>

        <!--  Inputs for the title of the poem and the author  -->
        <div class="title-name">
          <input class="title-input" [(ngModel)]="poemData.title" placeholder="Untitled Work" />
          <label class="title-name-label">by</label>
          <input class="name-input" [(ngModel)]="poemData.name" placeholder="Your Name" />
        </div>

        <!-- The background menu -->
        <div [ngClass]="{
            'backgrounds-menu': backgroundsMenuStart === true,
            'backgrounds-menu-open':
              backgroundsMenuOpen === true && backgroundsMenuStart === false,
            'backgrounds-menu-closed':
              backgroundsMenuOpen === false && backgroundsMenuStart === false
          }">
          <button class="backgrounds-menu-button" (click)="backgroundsMenuState()">
            <label class="backgrounds-label">Backgrounds</label>
            <img src="assets\word-mover\up-arrow.png" class="backgrounds-arrow" [ngStyle]="{
                transform: 'rotate(' + backgroundMenuArrow + 'deg)'
              }" />
          </button>

          <div class="backgrounds-container" *ngFor="let element of backgroundQuantity">
            <img (click)="poemBackground = element + 1" class="background-thumbnail{{ element + 1 }}" src="assets\word-mover\background-thumbnails\background{{
                element + 1
              }}.jpg" />
          </div>
        </div>

        <!-- Drag divs -->
        <div class="drag-canvas" drag-canvas [dragElements]="poemData.elements" id="drag-canvas">
          <div drag-object *ngFor="let elem of poemData.elements" [dragElement]="elem"
            [dragElements]="poemData.elements" [allowSelect]="true">
            <drag-toolbar [dragElement]="elem" [dragElements]="poemData.elements" [showEdit]="elem.type == 'text'"
              (onEdit)="editLabel(elem, $event)"></drag-toolbar>
            <label *ngIf="elem.type == 'text'">{{
              elem.text || "New Label"
              }}</label>
            <drag-status [dragElement]="elem" [allowSize]="elem.type == 'image'"></drag-status>
          </div>

          <div *ngIf="poemData.editingLabel" class="drag-object-attribute">
            <div class="attribute-title">Add Label</div>
            <label>Label Text</label>
            <input type="text" [(ngModel)]="poemData.inputLabelText" [ngClass]="{ 'has-error': emptyLabelError }" />
            <label>Description</label>
            <textarea [(ngModel)]="poemData.inputLabelDescription"></textarea>
            <button class="original-button" (click)="saveLabel()">Save</button>
            <button class="original-button" (click)="poemData.editingLabel = false">
              Cancel
            </button>
          </div>
        </div>

        <button (click)="addNewLabel()" class="add-label-button">
          Add Label
        </button>

        <div *ngIf="poemType !== 'ownWords'" id="wordbank-menu" [ngClass]="{
            wordbank: wordBankStart === true,
            'wordbank-open': wordBankOpen === true && wordBankStart === false,
            'wordbank-closed': wordBankOpen === false && wordBankStart === false
          }">
          <div>
            <button class="wordbank-button" (click)="wordBankState()">
              <img src="assets\word-mover\up-arrow.png" class="wordbank-arrow"
                [ngStyle]="{ transform: 'rotate(' + wordBankArrow + 'deg)' }" />
              <label class="wordbank-label">Word Bank</label>
              <button *ngIf="wordBankOpen === true" class="new-wordbank" (click)="initPoem(poemType); wordBankState()">
                <img src="assets\word-mover\new-wordbank-img.jpg" class="new-wordbank-img" />
                <label class="new-wordbank-label">New Word Bank</label>
              </button>
              <button *ngIf="wordBankOpen === true" class="wordbank-shuffle" (click)="shuffleWords(); wordBankState()">
                <img src="assets\word-mover\shuffle.jpg" class="wordbank-shuffle-img" />
                <label class="wordbank-shuffle-label">Shuffle</label>
              </button>
            </button>
            <div class="wordbank-container">
              <!-- get words from wordbank  -->
              <ul class="word-container">
                <li *ngFor="let word of words; let i = index" (click)="addLabel(word, i)">
                  {{ word }}
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----------------------------------------- Printing ----------------------------------------->
    <div *ngIf="step === 3" class="step step3">
      <div class="noprint">
        <h1>Finished!</h1>
        <p>
          Congratulations, your plot diagram is now complete. Your printout will
          show you where you placed items on the diagram and it will also show
          your description text.
        </p>
        <div>
          <button (click)="print()"><i class="fa fa-print"></i> Print</button>
          <button (click)="export()">
            <i class="fa fa-download"></i> Export
          </button>
          <button (click)="save()"><i class="fa fa-save"></i> Save</button>
          <button (click)="reset()">Start New Project</button>
        </div>
        <hr />
      </div>


      <div class='print-preview-landscape'>
        <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
        <img *ngIf='previewImage' [src]='previewImage'>
      </div>

      <div #printCanvas class="print-canvas">
 
          <h1 *ngIf="poemData.title">{{ poemData.title }}</h1>
          <h3 *ngIf="poemData.name">Created by {{ poemData.name }}</h3>
          <em>Printed on {{ now }}</em>
          <div>
          <canvas id="plotCanvas"></canvas>
        </div>
 
      </div>
    </div>
  </div>
</div>