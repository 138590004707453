export class DiamantePoemsFile {
    name: string;
    title: string;

    beginningTopic: string;
    endingTopic: string;

    adjective1: string;
    adjective2: string;

    ing1: string;
    ing2: string;
    ing3: string;

    nouns: string;

    ing4: string;
    ing5: string;
    ing6: string;

    adjective3: string;
    adjective4: string;
}