import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-riddle',
  templateUrl: './riddle.component.html',
  styleUrls: ['./riddle.component.scss']
})
export class RiddleComponent implements OnInit {

  page: number = 0;
  menuOpenOrClosed: boolean = false;
  gettingStartedSection: number;
  playingVid: number =0;
  showContButtonOne: boolean = true;
  showSMP: boolean = false;
  replayVid: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  changeMenu() {
    this.menuOpenOrClosed = !(this.menuOpenOrClosed);
  }

  changeGettingStartedSection(num: number) {
    this.page = num;
  }

  playNextMedia(n: any) {
    n.stop();
    n.load();
    n.play();
  }

  changeSection(a: number) {
    this.page = 1;
    this.playingVid = a;
  }

  changePage(a: number) {
    this.page = a;
    this.playingVid = 1;
    this.showContButtonOne = true;
  }

  startGettingStarted() {
    this.page = 1;
    this.playingVid = 1;
  }

  hideContButton() {
    this.showContButtonOne = false;
  }

  hideSMP() {
    this.showSMP = false;
  }

  revealSMP() {
    this.showSMP = true;
  }

  stupidResetBug() {
    this.page = 2;
    this.page = 3;
    this.playingVid = 1;
  }

  changeReplayVid() {
    this.replayVid = !(this.replayVid);
  }

  revealSMPAndChangeReplayVid() {
    this.replayVid = !(this.replayVid);
    this.showSMP = true;
  }

  increasePlayingVid() {
    this.playingVid++;
    if(this.playingVid == 9) {
      this.showContButtonOne = !(this.showContButtonOne);
    }
  }

  increasePlayingVidAndHideSMP() {
    this.playingVid++;
    this.showSMP = false;
  }

}
