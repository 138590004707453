import { Component, OnInit } from '@angular/core';
import { FracturedFairytale } from 'src/app/model/FracturedFairytale/FracturedFairytale';
import { ExampleTale } from 'src/app/model/FracturedFairytale/ExampleTale';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
  selector: 'app-fractured-fairytale',
  templateUrl: './fractured-fairytale.component.html',
  styleUrls: ['./fractured-fairytale.component.scss']
})
export class FracturedFairytaleComponent implements OnInit {
  step: number = 0;
  page: number = 0;
  prevStep: number = 0;
  tale: FracturedFairytale;
  jack: FracturedFairytale = new FracturedFairytale("Jack and the Beanstalk", "In Jack and the Beanstalk, the main characters are Jack and the Giant.", "The setting is a poor farm in a land far away.", "The story takes place long ago", "The story is told from the point of view of Jack.", "The problem of the story is that Jack and his mother are poor and Jack trades the cow for beans.", "The story ends when Jack and his mother buy a grand house and have plenty to eat.");
  ridingHood: FracturedFairytale = new FracturedFairytale("Little Red Riding Hood", "In Little Red Riding Hood, the main characters are Little Red Riding Hood, the wolf, and the woodsman.", "The setting is a big forest.", "The story takes place once upon a time.", "The story is told from the point of view of Little Red Riding Hood.", "The problem of the story is that the wolf eats the grandmother.", "The story ends when the woodcutter hits the wolf and the grandmother comes out of his belly.");
  princess: FracturedFairytale = new FracturedFairytale("The Princess and the Pea", "In The Princess and the Pea, the two main characters are the prince and the princess.", "The setting is a splendid castle in a beautiful kingdom.", "The story takes place once upon a time.", "The story is told from the point of view of the prince.", "The problem of the story is that the prince can't find a 'real' princess to marry.", "The story ends when the prince and princess get married and live happily ever after.");
  example: ExampleTale = new ExampleTale();
  //Testing purposes, remove afterwords
  

  constructor() { }

  ngOnInit() {
  }

  initStory(story: string) {
    switch (story) {
      case "Jack":
        this.tale = new FracturedFairytale(this.jack.storyName, this.jack.storyCharacters, this.jack.storySetting, this.jack.storyTime, this.jack.storyPOV, this.jack.storyProblem, this.jack.storyEnding);
        this.tale.intro = ["Long ago, in a land far away, there lived a boy named Jack. He and his mother had a small farm, but they were very poor and all they had was one cow.",
                          "Since they needed food to eat, Jack's mother sent him to the market to sell the cow.", 
                          "On the way to the market, Jack met an odd man dressed all in green. The man offered him five magic beans for his cow. Jack decided to make the trade.", 
                          "When Jack got home his mother was so angry that she threw the beans out the window and sent Jack to bed without supper.", 
                          "The next day, outside his window, there was a huge beanstalk stretching all the way to the sky. Jack climbed up it and found a huge castle at the top.", 
                          "Inside the castle, there was a small goose sitting on a nest. \"Take me with you,\" the goose said to Jack. \"I lay golden eggs.\"", 
                          "Jack grabbed the goose and headed for the beanstalk, but behind him he heard a loud rumbling. \"Fee, Fie, Fo, Fum!\" a giant called as he started to chase Jack.", 
                          "Jack climbed down the beanstalk as fast as he could, with the giant climbing behind him.", 
                          "When he got to the bottom, Jack grabbed an axe and chopped down the beanstalk. The giant had to quickly climb back to his castle or he would have fallen down.", 
                          "Jack and his mother used the golden eggs to buy a grand house and they were never hungry again."];
        this.tale.introPagePath = "../../../assets/fractured-fairytale/jack_intro_page.png";
        break;
      
      case "Riding Hood":
        this.tale = new FracturedFairytale(this.ridingHood.storyName, this.ridingHood.storyCharacters, this.ridingHood.storySetting, this.ridingHood.storyTime, this.ridingHood.storyPOV, this.ridingHood.storyProblem, this.ridingHood.storyEnding);
        this.tale.intro = ["Once upon a time, at the edge of a big forest, a little girl lived with her mother. She always wore a red hat and cape and was called Little Red Riding Hood by everyone who knew her.", 
                          "One day, Little Red Riding Hood’s mother gave her a basket with some cake inside and told her to walk through the forest to the other side where her grandmother lay sick in bed.", 
                          "\“Remember not to talk to strangers!\” Little Red Riding Hood\’s mother said.", 
                          "On the way to her grandmother’s house, Little Red Riding Hood met the Big Bad Wolf.", 
                          "\“Where are you going little girl?\” he asked with his biggest smile.", 
                          "\“To my grandmother’s house on the other side of the forest,\” said Little Red Riding Hood who had forgotten what her mother told her.", 
                          "The wolf took a shortcut and ran ahead to Grandmother’s house. When he got there, he went inside and swallowed Grandmother whole!", 
                          "Then he put on her cap and nightgown and climbed into her bed.", 
                          "When Little Red Riding Hood got there, she walked right up to the bed. \“Grandmother! What big ears you have,\” she said.", 
                          "\“All the better to hear you with my dear,\” said the wolf.", 
                          "\“And what big eyes you have,\” she said.", 
                          "\“All the better to see you with my dear,\” said the wolf.", 
                          "\“And what big teeth you have,\” said Little Red Riding Hood.", 
                          "\“All the better to eat you with!\” said the wolf and he jumped out of bed and started chasing Little Red Riding Hood.", 
                          "A man who was chopping wood in the forest heard Little Red Riding Hood. He came inside and hit the wolf over the head with his axe. The wolf fell to the ground, and Grandmother popped out of his mouth.", 
                          "Then the woodsman, Little Red Riding Hood, and Grandmother all had cake and tea."];
        break;

      case "Princess":
        this.tale = new FracturedFairytale(this.princess.storyName, this.princess.storyCharacters, this.princess.storySetting, this.princess.storyTime, this.princess.storyPOV, this.princess.storyProblem, this.princess.storyEnding);
        this.tale.intro = ["Once upon a time, in a beautiful kingdom, in a splendid castle, there lived a prince who wanted to marry a real princess.", 
                          "He traveled the whole world over meeting princesses, but could never find one who he was sure was real.", 
                          "One day, it poured rain. The prince heard a knock at his door.", 
                          "When he opened it, a princess stood there, water dripping from her hair and her clothes. Even her crown was wet.", 
                          "\“I know what to do!\” said the prince’s mother. She took a small pea and put it at the bottom of twenty mattresses.", 
                          "The princess slept on top of the mattresses all night.", 
                          "In the morning, the prince asked the princess how she slept. \“Oh very badly!\” she said. \“I was lying on something hard.\”", 
                          "The prince was happy because he knew that only a real princess could be that sensitive. They were married the next day and lived happily ever after."];
        break;
    }
    this.changeStep(3);
    
  }



  changeStep(newStep: number) {
    this.step = newStep;
  }

  changePage() {
    if(this.page < 5) {
      this.page++;
    }
    else {
      this.page = 0;
    }
  }

  endExample() {
    this.page = 0;
    this.step = 2;
  }

  print() {
    if(!this.tale.storyRewrite) {
      this.tale.tester = "Print the changes";
    }
    else {
      this.tale.tester = "Print the rewrite";
    }
    this.changeStep(9);

  }

  getExamplePage(i: number) {
    return this.example.ex[i];
  }

  exitMenu() {
    this.prevStep = this.step;
    this.changeStep(10);
  }

  chooseADifferentStory() {
    this.tale = null;
    this.changeStep(2);
  }

}
