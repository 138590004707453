export class AnimalInquiry {

    graphicOrganizer?: string;
    title?: string;
    author?: string;

    animalName?: string;
    animalLooks?: string;
    animalMove?: string;
    animalEat?: string;

    animalBorn?: string;
    animalBabiesLooks?: string;
    animalChange?: string;

    animalEnemies?: string;
    animalProtection?: string;
    animalCommunicate?: string;

    animalLocation?: string;
    animalAdapt?: string;
    animalHabitat?: string;












}
