import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { CompContrastFile } from 'src/app/model/CompContrastFile';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-comp-contrast-map',
  templateUrl: './comp-contrast-map.component.html',
  styleUrls: ['./comp-contrast-map.component.scss']
})
export class CompContrastMapComponent implements OnInit {

  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printCanvas', null) printCanvas: ElementRef;

  step: number = 1;
  showAnswer: boolean = false;
  previewImage: any;
  nameError: boolean = false;
  item1Error: boolean = false;
  item2Error: boolean = false;

  wholeToWhole: boolean = true;
  similaritiesToDifferences: boolean = false;
  pointToPoint: boolean = false;

  selection: number = 1;

  map: CompContrastFile = new CompContrastFile();

  constructor(private fileUtil: FileUtilService, private printer: PrintService) { }

  ngOnInit() {
  }

  chooseType(type: string) {

    if (type == 'wholeToWhole') {
      this.wholeToWhole = true;
      this.similaritiesToDifferences = false;
      this.pointToPoint = false;
    }
    else if (type == 'similaritiesToDifferences') {
      this.wholeToWhole = false;
      this.similaritiesToDifferences = true;
      this.pointToPoint = false;
    }
    else {
      this.wholeToWhole = false;
      this.similaritiesToDifferences = false;
      this.pointToPoint = true;
    }

  }

  initMap() {
    let that = this;
    if (that.map.name && that.map.item1 && that.map.item2) {
      that.step = 3;
    }

    if (!that.map.name) {
      that.nameError = true;
    }

    if (!that.map.item1) {
      that.item1Error = true;
    }

    if (!that.map.item2) {
      that.item2Error = true;
    }

    if (that.wholeToWhole) {
      that.map.type = 'Whole-to-Whole';
    }
    else if (that.similaritiesToDifferences) {
      that.map.type = 'Similarities-to-Differences';
    }
    else if (that.pointToPoint) {
      that.map.type = 'Point-to-Point';
    }
  }

  initPrint() {
    this.step++;
    this.previewImage = null;
    let that = this;
    setTimeout(async () => {
      this.previewImage = await that.printer.initPrintPreview([that.printCanvas], PrintOrientation.Portrait);
    }, 100);
  }

  print() {
    this.printer.print([this.printCanvas], PrintOrientation.Portrait, true);
  }

  save() {
    this.fileUtil.save(this.map, 'Map.json');
  }

  fileInput: any;
  openMap(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.map = JSON.parse(json);
        this.initMap();
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }
}


