
export class ConstructAWordButtonInformation {
    chooseButtons: string[] = [
        "an",
        "ed",
        "ig",
        "at", 
        "et",
        "in", 
        "op",
        "ot",
        "un"
    ]

    gameButtons: string[] = "abcdefghijklmnopqrstuvwxyz".split("").concat([
        "bl",
        "ch",
        "cl",
        "dr",
        "sh",
        "sl"
    ]);
}