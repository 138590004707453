import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'stapleless-page',
  templateUrl: './stapleless-page.component.html',
  styleUrls: ['./stapleless-page.component.scss']
})
export class StaplelessPageComponent implements OnInit {
  @Input() printVersion: boolean = false;
  @Input() currentPage: any;
  @Output() currentPageChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
     
  }
  onchange(){
    this.currentPageChange.emit(this.currentPage);
  }

}
