import { DragUtilService } from "../service/drag-util.service";
import { ConstructAWordDragElement } from "./ConstructAWordDragElement";
import { ConstructAWordFullWords } from "./ConstructAWordFullWords";

export class ConstructAWordInteractiveFile {
    step: number = 1;
    name: string = "";
    dragUtil: DragUtilService = new DragUtilService();
    chooseDragElements: ConstructAWordDragElement[] = [];
    gameDragElements: ConstructAWordDragElement[] = [];
    gameChosenEnding: string = "an";
    showFinalWord: boolean = false;
    robotText: string = "Choose a beginning.";
    wordsLeft: Map<string, number> = new Map(new ConstructAWordFullWords().lenMap);
    fullWord: string = "";
    goBackToChoose = false;
    allEndingsUsed = false;
    usedGameButtons: Map<string, boolean[]> = new Map([
        ["an", new Array(32).fill(false)],
        ["ed", new Array(32).fill(false)],
        ["ig", new Array(32).fill(false)],
        ["at", new Array(32).fill(false)],
        ["et", new Array(32).fill(false)],
        ["in", new Array(32).fill(false)],
        ["op", new Array(32).fill(false)],
        ["ot", new Array(32).fill(false)],
        ["un", new Array(32).fill(false)]
    ]);
    usedChooseButtons: boolean[] = new Array(9).fill(false);

    chooseDragElementPositions: number[][] = [
        [189, 219],
        [279, 219],
        [370, 219],
        [460, 219],
        [138, 291],
        [228, 291],
        [319, 291],
        [408, 291],
        [498, 291]
    ];

    foundWords: Map<string, string[]> = new Map([
        ["an", []],
        ["ed", []],
        ["ig", []],
        ["at", []],
        ["et", []],
        ["in", []],
        ["op", []],
        ["ot", []],
        ["un", []]
    ]);

    constructor () {
        this.generateChooseButtons();
        this.generateGameButtons();
    }

    generateChooseButtons() {
        this.chooseDragElements = [];

        //Choose drag element generation
        for (let i = 0; i < 9; i++) {
            let dragElement = new ConstructAWordDragElement(this.chooseDragElementPositions[i][0], this.chooseDragElementPositions[i][1]);

            this.chooseDragElements.push(dragElement);
        }
    }

    generateGameButtons() {
        this.gameDragElements = [];
        
        //Game drag element generation
        let cornerX = 113; //Left margin of first button
        let cornerY = 196; //Top margin of first button
        let width = 60; //Width of button (slightly less to fit them all in)
        let height = 48; //Height of button (slightly less to fit them alll in)

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 8; j++) {
            let dragElement = new ConstructAWordDragElement(cornerX + (width * j), cornerY + (height * i));

            this.gameDragElements.push(dragElement);
            }
        }
    }
}