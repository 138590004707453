import { Component, OnInit, ViewChildren, QueryList, ElementRef, wtfStartTimeRange } from '@angular/core';
import { FlipAChipInteractiveFile } from 'src/app/model/FlipAChipInteractiveFile';
import { FlipAChipTextChoices } from 'src/app/model/FlipAChipTextChoices';
import { FlipAChipDropLocations } from 'src/app/model/FlipAChipDropLocations';
import { PrintService } from 'src/app/service/print.service';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';

@Component({
  selector: 'app-flip-a-chip',
  templateUrl: './flip-a-chip.component.html',
  styleUrls: ['./flip-a-chip.component.scss']
})
export class FlipAChipComponent implements OnInit {

  interactive: FlipAChipInteractiveFile = new FlipAChipInteractiveFile();

  //Placeholder arrays for ngFor's since only collections can be used
  backgroundElementLoopArray = [null, null, null, null, null, null]
  placementLoopArray = [null, null, null, null]

  constructor(private printer: PrintService) { }

  @ViewChildren("printPage") printPages: QueryList<ElementRef>;
  async checkPrint(index) {
    let nextStep = (index * 2 + 3)
    let pages = this.printPages.toArray();

    if (confirm("Would you like to print out this exercise?")) {
      this.printer.print([pages[index]], PrintOrientation.Portrait);
    }

    this.interactive.step = nextStep;
    

  }

  //Calculate distances to each placement area and set text to the correct one accordingly
  dragMouseUp(index) {
    let placementsWidth = 123;
    let placementsHeight = 32;
    let initialX = 653;
    let initialY = 166;

    let dragElement = this.interactive.dragElements[index];
    let refLocations = this.interactive.placements.locations[index];

    //Set reference location to middle of drag word
    let refDragX = dragElement.x + (placementsWidth / 2);
    let refDragY = dragElement.y + (placementsHeight / 2);

    let distances = []

    for (let i = 0; i < 4; i++) {
      let refPlaceX = refLocations[i][0] + (placementsWidth / 2);;
      let refPlaceY = refLocations[i][1] + (placementsHeight / 2);;
      distances.push(this.findDistance(refDragX - refPlaceX, refDragY - refPlaceY));
    }
    
    let distMin = Math.min(...distances);
    
    //If the distance is more than 70
    if (distMin < 70) {
      //Fill in word in corresponding placement area
      let shortestDistIndex = distances.indexOf(distMin);
      this.interactive.placements.text[index][shortestDistIndex] = this.getDragWord(index);

      //Hide the drag word so it can be changed
      this.interactive.showDragWord[index] = false;

      //Return coin states to default
      this.interactive.coinStates[index][0] = 0;
      this.interactive.coinStates[index][1] = 0;
    }

    //Return drag word to its inital spot
    dragElement.x = initialX;
    dragElement.y = initialY;
  }

  findDistance(xDist, yDist) {
    return Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2))
  }

  flipCoins(index) {
    let states = this.interactive.coinStates;

    //If still a question mark, init as first text choice for both coins
    for (let i = 0; i < 2; i++) {
      if (!states[index][i]) {
        states[index][i] = 1;
      }
    }

    this.interactive.showDragWord[index] = true;
    
    let r = Math.floor(Math.random() * 3);

    switch (r) {
      //Flip left coin
      case 0: {
        this.flip(index, 0);
        break;
      }
      //Flip right coin
      case 1: {
        this.flip(index, 1);
        break;
      }
      //Flip both
      case 2: {
        this.flip(index, 0);
        this.flip(index, 1);
        break;
      }
    }
  }

  flip(index, coin) {
    let state = this.interactive.coinStates[index][coin];

    if (state == 1) {
      this.interactive.coinStates[index][coin] = 2;
    } else {
      this.interactive.coinStates[index][coin] = 1;
    }
  }

  getLeftCoin(index) {
    return this.interactive.textChoices.choices[index][0][this.interactive.coinStates[index][0]]
  }

  getRightCoin(index) {
    return this.interactive.textChoices.choices[index][1][this.interactive.coinStates[index][1]]
  }

  getDragWord(index) {
    return this.getLeftCoin(index) + this.getRightCoin(index);
  }

  exit() {
    this.interactive = new FlipAChipInteractiveFile();
  }

  ngOnInit() {
  }

}
