import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-multigenre-mapper',
  templateUrl: './multigenre-mapper.component.html',
  styleUrls: ['./multigenre-mapper.component.scss']
})
export class MultigenreMapperComponent implements OnInit {

  step = 0;

  canvas: any;
  draw_color = "";
  draw_width = 2;
  is_drawing = false;
  vars: any = {};
  printing = false;
  action_array = [];
  action_index = -1;
  context: any;
  default_background_color = "white";

  appContext: MultigenreMapperComponent;

  @ViewChild('printPreview', null) printPreview: ElementRef;

  constructor(private printer: PrintService) { }

  async print(){
    document.getElementById('toolsTbl').style.display='none';
    document.getElementById('printBtn').style.display='none';
    await this.printer.print([this.printPreview], PrintOrientation.Portrait, true, false, false);
    document.getElementById('toolsTbl').style.display='table';
    document.getElementById('printBtn').style.display='block';
    this.printing = true;
  }
  ngOnInit() {
    this.appContext = this;

    let that = this;
    setTimeout(() => {
      console.log(document.getElementsByClassName('cool-this.canvas'));
      this.canvas = document.getElementById('canvas');
      this.context = this.canvas.getContext('2d');

      this.context.fillStyle = "white";

      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

      console.log("yo");

      //mobile initialize and draw
    //   this.canvas.addEventListener("touchstart", this.start, false);
    //   this.canvas.addEventListener("touchmove", this.draw, false);
    //   //PC initialize and draw
    //   this.canvas.addEventListener("mousedown", this.start, false);
    //   this.canvas.addEventListener("mousemove", this.draw, false);

    //   //When draw ends or leaves this.canvas
    //   this.canvas.addEventListener("touchend", stop, false);
    //   this.canvas.addEventListener("mouseup", stop, false);
    //   this.canvas.addEventListener("mouseout", stop, false);
    }, 100);



  }


hue_alter(color) {
  this.draw_color = color;
}
initNew(){
  this.vars={};
  this.step=0;
  this.canvas_clear();
}

//Create array of all paths created
action_undo() { 
  console.info('here');
  if (this.action_index <= 0) {
    this.canvas_clear();
  } else {
    this.action_index -= 1;
    this.action_array.pop();
    this.context.putImageData(this.action_array[this.action_index], 0, 0);
  }
}



//Intialize drawing definition
start(event) {
  let canvasTd = document.getElementById('canvasTd')
  this.is_drawing = true;
  this.context.beginPath(); 
  this.context.lineTo(event.clientX - this.canvas.getBoundingClientRect().x,
    event.clientY - this.canvas.getBoundingClientRect().y );
  event.preventDefault();
}

draw(event) {
  //if this.is_drawing, create line, set line properties
  if (this.is_drawing) {

    this.context.lineTo(event.clientX - this.canvas.getBoundingClientRect().x,
      event.clientY - this.canvas.getBoundingClientRect().y );
    //set the Path() to the draw color
    this.context.strokeStyle = this.draw_color;
    this.context.lineWidth = this.draw_width;
    this.context.lineCap = "round";
    this.context.lineJoin = "round";
    this.context.stroke();

  }
  event.preventDefault();
}

stop(event) {

  if (this.is_drawing) {

    this.context.stroke();
    this.context.closePath();
    this.is_drawing = false;

  }

  event.preventDefault();
  if (event.type != 'mouseout') {
    this.action_array.push(this.context.getImageData(0, 0, this.canvas.width, this.canvas.height));
    this.action_index += 1;
    console.log(this.action_array);
  }

}



canvas_clear() {
  this.context.fillStyle = this.default_background_color;
  this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

  this.action_array = [];
  this.action_index = -1;
}

}
