import { PersuasionMapReason } from 'src/app/model/PersuasionMapReason';

export class PersuasionMapInteractiveFile {
    step: number = 1;

    name: string = "";
    title: string = "";

    thesis: string = "";

    reasons: PersuasionMapReason[] = [
        new PersuasionMapReason(),
        new PersuasionMapReason(),
        new PersuasionMapReason()
    ];

    conclusion: string = "";

    nameValid: boolean = false;
    titleValid: boolean = false;
    hasBeenChecked: boolean = false;
    unGraySave: boolean = false;

}