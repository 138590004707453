<div [ngClass]="{'input-container': !printVersion, 'printoutline': !!printVersion}" *ngIf="currentPage.templateName">
    <div *ngIf="currentPage.templateName === 'headlineTopBulleted'" class="headline-bulleted">
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline"></textarea>
        <table class="bullet-table-top">
            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>

                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet1'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet1"
                        class="bullet-input"></textarea>
                </td>
            </tr>

            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>
                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet2'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet2"
                        class="bullet-input"></textarea>
                </td>
            </tr>
            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>
                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet3'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet3"
                        class="bullet-input"></textarea>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="currentPage.templateName === 'headlineBottomBulleted'" class="headline-bulleted">
        <table class="bullet-table-bottom">
            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>
                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet1'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet1"
                        class="bullet-input"></textarea>
                </td>
            </tr>

            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>
                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet2'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet2"
                        class="bullet-input"></textarea>
                </td>
            </tr>
            <tr>
                <td class="bullet">
                    <label>&bull;</label>
                </td>
                <td>
                    <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.bullet3'></div><textarea *ngIf='!printVersion'
                        (change)='onchange()' maxlength="75" [(ngModel)]="currentPage.bullet3"
                        class="bullet-input"></textarea>
                </td>
            </tr>
        </table>
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline"></textarea>

    </div>

    <div *ngIf="currentPage.templateName === 'headlineTopPicture'" class="headline-top-picture">
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline-top"></textarea>
        <div class="placeholder-img placeholder-img-top">
            <img *ngIf='!printVersion' src="/assets/stapleless/previewimage.jpg">
            <label *ngIf='!printVersion'>Picture Placeholder</label>
        </div>
    </div>

    <div *ngIf="currentPage.templateName === 'headlineBottomPicture'" class="headline-top-picture">
        <div class="placeholder-img placeholder-img-bottom">
            <img *ngIf='!printVersion' src="/assets/stapleless/previewimage.jpg">
            <label *ngIf='!printVersion'>Picture Placeholder</label>
        </div>
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline-bottom"></textarea>
    </div>

    <div *ngIf="currentPage.templateName === 'smallTextBottom'" class="headline-smalltext">
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline"></textarea>
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.smallText'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="400" [(ngModel)]="currentPage.smallText" class="smalltext"></textarea>
    </div>

    <div *ngIf="currentPage.templateName === 'smallTextTop'" class="headline-smalltext">
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.smallText'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="400" [(ngModel)]="currentPage.smallText" class="smalltext"></textarea>
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.header'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="60" [(ngModel)]="currentPage.header" class="headline"></textarea>
    </div>

    <div *ngIf="currentPage.templateName === 'mostText'" class="full-smalltext">
        <div *ngIf='!!printVersion'  class='printbox' [innerHTML]='currentPage.smallText'></div><textarea *ngIf='!printVersion'
            (change)='onchange()' maxlength="400" [(ngModel)]="currentPage.smallText" class="smalltext"></textarea>
    </div>
</div>