import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DragElement } from 'src/app/model/DragElement';
import * as _ from 'lodash';

@Component({
  selector: 'drag-toolbar',
  templateUrl: './drag-toolbar.component.html',
  styleUrls: ['./drag-toolbar.component.css']
})
export class DragToolbarComponent implements OnInit {
  @Input() dragElement: DragElement;
  @Input() dragElements: DragElement[];
  @Input() title: string;
  @Input() showEdit ?: boolean = true;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onClose = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  edit(){
    this.onEdit.emit(this.dragElement);
  }
  close(){
    DragElement.deselect(this.dragElement);
    this.onClose.emit();
  }
  delete(){
    for(var i=0; i< this.dragElements.length; i++){
      if(this.dragElements[i].id == this.dragElement.id){
        this.dragElements.splice(i, 1);
        break;
      }
    }
    this.onDelete.emit();

  }


}
