export class CompContrastFile{
    name: string;
    item1: string;
    item2: string;

    type: string;

    introduction: string;

    item1Description: string;
    item2Description: string;

    similarities: string;
    differences: string;

    point1: string;
    point2: string;

    conclusion: string;
}