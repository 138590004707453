<div class="app-border">
    <div class="title-bar">
        <span>Drama Map</span>
    </div>

    <!--------------------------------------------- Step 1 --------------------------------------------->
    <div *ngIf='step==1' class='step'>
        <h1 class='labels-header'>Project Labels</h1>
        <h3 class='labels-header2'>Enter a title for your project and your name.</h3>

        <div>
            <label class='label'>Title:</label>
            <input maxlength='30' [ngClass]="{'input-error': map.titleError}" (change)='titleError = false' type='text'
                [(ngModel)]='map.title'>
            <br>

            <label class='label'>By:</label>
            <input maxlength='30' [ngClass]="{'input-error': map.nameError}" (change)='nameError = false' type='text'
                [(ngModel)]='map.name'>
        </div>

        <div>
            <h1 class='type-select-header'>Choose A Graphic Organizer</h1>

            <div class='type-selectors'>
                <div class='selector'>
                    <img class='selector-img' src='assets/drama-map/character.png' (click)="initMap('character')">
                    <p class='selector-label'>Character<br>Map</p>
                </div>

                <div class='selector'>
                    <img class='selector-img' src='assets/drama-map/conflict.png' (click)="initMap('conflict')">
                    <p class='selector-label'>Conflict<br>Map</p>
                </div>

                <div class='selector'>
                    <img class='selector-img' src='assets/drama-map/resolution.png' (click)="initMap('resolution')">
                    <p class='selector-label'>Resolution<br>Map</p>
                </div>

                <div class='selector'>
                    <img class='selector-img' src='assets/drama-map/setting.png' (click)="initMap('setting')">
                    <p class='selector-label'>Setting<br>Map</p>
                </div>
            </div>
        </div>
    </div>

    <!--------------------------------------------- Step 2 --------------------------------------------->
    <div *ngIf='step==2'>
        <div class='toolbar'>
            <button (click)='step=1'><i class='fa fa-chevron-left'></i>&nbsp;Back</button>
            <button (click)='initPrint()'><i>&nbsp;</i>&nbsp;View & Print</button>
        </div>


        <!------------------------------------ Character Input ------------------------------------>
        <div *ngIf="map.type=='character'">
            <label class='character-name-label'>Character Name:</label>
            <input class='character-name-input' maxlength='30' [ngClass]="{'input-error': map.characterNameError}"
                (change)='map.characterNameError = false' type='text' [(ngModel)]='map.characterName'>
            <button class='character-name-button' (click)='enterCharacterName()'>Enter</button>

            <div *ngIf='map.characterNameEntered && !map.appearanceEntered' class='character-background-1'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What does the character look like?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.appearanceError}"
                            (change)='map.appearanceError = false' [(ngModel)]='map.appearance'></textarea>
                        <button class='input-button' (click)="enterInput('appearance')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.appearanceEntered && !map.behaviorEntered' class='character-background-2'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What does the character look like?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.appearanceError}"
                            (change)='map.appearanceError = false' [(ngModel)]='map.appearance'></textarea>
                        <button class='input-button' (click)="enterInput('appearance')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>How does the character behave?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.behaviorError}"
                            (change)='map.behaviorError = false' [(ngModel)]='map.behavior'></textarea>
                        <button class='input-button' (click)="enterInput('behavior')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.appearanceEntered && map.behaviorEntered' class='character-background-3'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What does the character look like?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.appearanceError}"
                            (change)='map.appearanceError = false' [(ngModel)]='map.appearance'></textarea>
                        <button class='input-button' (click)="enterInput('appearance')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>How does the character behave?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.behaviorError}"
                            (change)='map.behaviorError = false' [(ngModel)]='map.behavior'></textarea>
                        <button class='input-button' (click)="enterInput('behavior')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>How do other characters in the play react to this character?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.reactionError}"
                            (change)='map.reactionError = false' [(ngModel)]='map.reaction'></textarea>
                        <button class='input-button' (click)="enterInput('reaction')">Enter</button>
                    </div>
                </div>
            </div>
        </div>

        <!------------------------------------ Conflict Input ------------------------------------>
        <div *ngIf="map.type=='conflict'">
            <div *ngIf='!map.conflictEntered && !map.reasonEntered' class='conflict-background-1'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What is the conflict? (another person, a thing, or thoughts and feelings
                            of the character)</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.conflictError}"
                            (change)='map.conflictError = false' [(ngModel)]='map.conflict'></textarea>
                        <button class='input-button' (click)="enterInput('conflict')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.conflictEntered && !map.reasonEntered' class='conflict-background-2'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What is the conflict? (another person, a thing, or thoughts and feelings
                            of the character)</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.conflictError}"
                            (change)='map.reasonError = false' [(ngModel)]='map.conflict'></textarea>
                        <button class='input-button' (click)="enterInput('conflict')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>Why does this conflict occur?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.reasonError}"
                            (change)='map.reasonError = false' [(ngModel)]='map.reason'></textarea>
                        <button class='input-button' (click)="enterInput('reason')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.conflictEntered && map.reasonEntered' class='conflict-background-3'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>What is the conflict? (another person, a thing, or thoughts and feelings
                            of the character)</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.conflictError}"
                            (change)='conflictError = false' [(ngModel)]='map.conflict'></textarea>
                        <button class='input-button' (click)="enterInput('conflict')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>Why does this conflict occur?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.reasonError}"
                            (change)='map.reasonError = false' [(ngModel)]='map.reason'></textarea>
                        <button class='input-button' (click)="enterInput('reason')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>What are some ways the conflict could be resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.possibleResolutionError}"
                            (change)='map.possibleResolutionError = false'
                            [(ngModel)]='map.possibleResolution'></textarea>
                        <button class='input-button' (click)="enterInput('possibleResolution')">Enter</button>
                    </div>
                </div>
            </div>
        </div>

        <!------------------------------------ Resolution Input ------------------------------------>
        <div *ngIf="map.type=='resolution'">
            <div *ngIf='!map.resolutionEntered && !map.afterResolutionEntered' class='resolution-background-1'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>How is the conflict resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.resolutionError}"
                            (change)='map.resolutionError = false' [(ngModel)]='map.resolution'></textarea>
                        <button class='input-button' (click)="enterInput('resolution')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.resolutionEntered && !map.afterResolutionEntered' class='resolution-background-2'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>How is the conflict resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.resolutionError}"
                            (change)='map.resolutionError = false' [(ngModel)]='map.resolution'></textarea>
                        <button class='input-button' (click)="enterInput('resolution')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>What happens after the conflict is resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.afterResolutionError}"
                            (change)='map.afterResolutionError = false' [(ngModel)]='map.afterResolution'></textarea>
                        <button class='input-button' (click)="enterInput('afterResolution')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.resolutionEntered && map.afterResolutionEntered' class='resolution-background-3'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>How is the conflict resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.resolutionError}"
                            (change)='map.resolutionError = false' [(ngModel)]='map.resolution'></textarea>
                        <button class='input-button' (click)="enterInput('resolution')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>What happens after the conflict is resolved?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.afterResolutionError}"
                            (change)='map.afterResolutionError = false' [(ngModel)]='map.afterResolution'></textarea>
                        <button class='input-button' (click)="enterInput('afterResolution')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>How does the conflict and its resolution affect the character?</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.effectError}"
                            (change)='map.effectError = false' [(ngModel)]='map.effect'></textarea>
                        <button class='input-button' (click)="enterInput('effect')">Enter</button>
                    </div>
                </div>
            </div>
        </div>

        <!------------------------------------ Setting Input ------------------------------------>
        <div *ngIf="map.type=='setting'">
            <div *ngIf='!map.placeEntered && !map.timeEntered' class='setting-background-1'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>Where does the story take place? (city, castle, ship, cemetery, moon)
                        </h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.placeError}"
                            (change)='map.placeError = false' [(ngModel)]='map.place'></textarea>
                        <button class='input-button' (click)="enterInput('place')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.placeEntered && !map.timeEntered' class='setting-background-2'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>Where does the story take place? (city, castle, ship, cemetery, moon)
                        </h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.placeError}"
                            (change)='map.placeError = false' [(ngModel)]='map.place'></textarea>
                        <button class='input-button' (click)="enterInput('place')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>When does the story take place? (afternoon, fall, morning, date)</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.timeError}"
                            (change)='map.timeError = false' [(ngModel)]='map.time'></textarea>
                        <button class='input-button' (click)="enterInput('time')">Enter</button>
                    </div>
                </div>
            </div>

            <div *ngIf='map.placeEntered && map.timeEntered' class='setting-background-3'>
                <div class='inputs'>
                    <div class='input-box'>
                        <h3 class='input-label'>Where does the story take place? (city, castle, ship, cemetery, moon)
                        </h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.placeError}"
                            (change)='map.placeError = false' [(ngModel)]='map.place'></textarea>
                        <button class='input-button' (click)="enterInput('place')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>When does the story take place? (afternoon, fall, morning, date)</h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.timeError}"
                            (change)='map.timeError = false' [(ngModel)]='map.time'></textarea>
                        <button class='input-button' (click)="enterInput('time')">Enter</button>
                    </div>

                    <div class='input-box'>
                        <h3 class='input-label'>Write a detailed description of the setting (weather, noise, colors)
                        </h3>
                        <textarea class='input' maxlength='100' [ngClass]="{'input-error': map.descriptionError}"
                            (change)='map.descriptionError = false' [(ngModel)]='map.description'></textarea>
                        <button class='input-button' (click)="enterInput('description')">Enter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--------------------------------------------- Print --------------------------------------------->
    <div *ngIf='step==3' class='step'>
        <div class='toolbar'>
            <button (click)="step=2"><i class='fa fa-chevron-left'></i>&nbsp;Back</button>
            <button (click)='print()'><i>&nbsp;</i>&nbsp;Print</button>
        </div>

        <div class='print-preview-landscape'>
          <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
          <img *ngIf='previewImage' [src]='previewImage'>
        </div>

        <div #printCanvas class='print-page print-canvas'>
            <div class='print-container'>
                <!------------------------------------ Character Output ------------------------------------>
                <div *ngIf="map.type=='character'">
                    <div class='character-background-3'>
                        <p class='print-name-title'>{{ map.title }} by {{ map.name }}</p>
                        <p class='print-character-name'>{{ map.characterName }}</p>
                        <div class='inputs'>
                            <div class='input-box'>
                                <h3 class='input-label'>What does the character look like?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.appearance }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>How does the character behave?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.behavior }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>How do other characters in the play react to this character?
                                </h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.reaction }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------ Conflict Output ------------------------------------>
                <div *ngIf="map.type=='conflict'">
                    <div class='conflict-background-3'>
                        <p class='print-name-title'>{{ map.title }} by {{ map.name }}</p>
                        <div class='inputs'>
                            <div class='input-box'>
                                <h3 class='input-label'>What is the conflict? (another person, a thing, or thoughts and
                                    feelings
                                    of the character)</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.conflict }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>Why does this conflict occur?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.reason }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>What are some ways the conflict could be resolved?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.possibleResolution }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------ Resolution Output ------------------------------------>
                <div *ngIf="map.type=='resolution'">
                    <div class='resolution-background-3'>
                        <p class='print-name-title'>{{ map.title }} by {{ map.name }}</p>
                        <div class='inputs'>
                            <div class='input-box'>
                                <h3 class='input-label'>How is the conflict resolved?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.resolution }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>What happens after the conflict is resolved?</h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.afterResolution }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>How does the conflict and its resolution affect the character?
                                </h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.effect }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------ Setting Output ------------------------------------>
                <div *ngIf="map.type=='setting'">
                    <div class='setting-background-3'>
                        <h3 class='print-name-title'>{{ map.title }} by {{ map.name }}</h3>
                        <div class='inputs'>
                            <div class='input-box'>
                                <div class='input-box'>
                                    <h3 class='input-label'>Where does the story take place? (city, castle, ship,
                                        cemetery,
                                        moon)</h3>
                                    <div class='print-output-box'>
                                        <p class='print-output-text'>{{ map.place }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>When does the story take place? (afternoon, fall, morning, date)
                                </h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.time }}</p>
                                </div>
                            </div>

                            <div class='input-box'>
                                <h3 class='input-label'>Write a detailed description of the setting (weather, noise,
                                    colors)
                                </h3>
                                <div class='print-output-box'>
                                    <p class='print-output-text'>{{ map.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
