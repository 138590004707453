import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { WordMover } from "src/app/model/WordMover";
import { DragElement } from "../../model/DragElement";
import { DragUtilService } from "src/app/service/drag-util.service";
import * as _ from "lodash";
import { NONE_TYPE } from "@angular/compiler/src/output/output_ast";
import * as uuid from "uuid";
import { PrintOrientation } from "src/app/enum/PrintOrientation";
import { FileUtilService } from "src/app/service/file-util.service";
import { PrintService } from "src/app/service/print.service";

@Component({
  selector: "app-word-mover",
  templateUrl: "./word-mover.component.html",
  styleUrls: ["./word-mover.component.scss"],
})
export class WordMoverComponent implements OnInit {
  @ViewChild("plotCanvas", null) plotCanvas: ElementRef;
  @ViewChild("printCanvas", null) printCanvas: ElementRef;
  @ViewChild("printPreview", null) printPreview: ElementRef;
  @ViewChild("printPage2Canvas", null) printPage2Canvas: ElementRef;
  previewImage: any;

  now: Date = new Date();

  step: number = 0;
  poemType: string = "";
  poemData = new WordMover();
  poemBackground: number = 1;

  backgroundsMenuOpen: boolean = false;
  backgroundsMenuStart: boolean = true;
  backgroundMenuArrow: number = -90;
  backgroundQuantity: number[] = Array.from(Array(12).keys());

  wordBankOpen: boolean = false;
  wordBankStart: boolean = true;
  wordBankArrow: number = 0;
  words: string[] = [];

  emptyLabelError = false;

  draggingElement: boolean = false;
  checkTrashLoop: number;
  dragCanavas: any;

  showingInstructions: boolean = false;

  constructor(
    private dragUtil: DragUtilService,
    private fileUtil: FileUtilService,
    private printer: PrintService
  ) {}

  ngOnInit() {}

  reset() {
    this.poemData.title = "";
    this.poemData.name = "";
    this.poemType = "";
    this.step = 0;
    this.poemData.elements = [];
    this.clearSelection();
  }

  prnt: any;
  async print() { 
    await this.printer.print(
      [this.printCanvas],
      PrintOrientation.Portrait
    );
  }
  async export() {
    await this.printer.export(
      [this.printCanvas],
      "CirclePlot.pdf",
      PrintOrientation.Portrait
    );
  }
  save() {
    this.fileUtil.save(this.poemData, "WordMover.json");
  }

  moveEvent($event: MouseEvent, elem: DragElement) {
    console.log(elem);
  }

  addLabel(text, index) {
    let elem = this.dragUtil.createElement(this.poemData.elements);
    elem.type = "text";
    elem.text = text;
    this.poemData.editId = elem.id;
    this.poemData.elements.push(elem);
    this.words.splice(index, 1);
    this.saveLabel();
  }

  addNewLabel() {
    let elem = this.dragUtil.createElement(this.poemData.elements);
    elem.type = "text";
    this.poemData.editId = elem.id;
    this.poemData.elements.push(elem);
    this.editLabel(elem);
  }

  saveLabel() {
    // clear error
    this.emptyLabelError = false;

    // Ensure event name is entered.
    if (!this.poemData.inputLabelText) {
      this.emptyLabelError = true;
      return;
    }
    // update event
    let elem = _.find(
      this.poemData.elements,
      (x) => x.id == this.poemData.editId
    );
    elem.text = this.poemData.inputLabelText;
    elem.description = this.poemData.inputLabelDescription;
    elem.selected = true;

    this.clearSelection();
    this.poemData.editingLabel = false;
  }

  clearSelection() {
    this.poemData.inputLabelText = null;
    this.poemData.inputLabelDescription = null;
    this.poemData.editId = null;
  }

  editLabel(elem: DragElement) {
    DragElement.select(elem);
    this.poemData.editId = elem.id;
    this.poemData.inputLabelDescription = elem.description;
    this.poemData.inputLabelText = elem.text;
    this.poemData.editingLabel = true;
  }

  nextStep() {
    this.step++;
    this.previewImage = null;
    if (this.step == 3) {
      console.log(`../../../assets/word-mover/backgrounds/background${this.poemBackground}.jpg"`);
      this.dragUtil
        .initPrint(
          this.poemData.elements,
          "plotCanvas"
        )
        .then(async (x) => {
          this.previewImage = await this.printer.initPrintPreview(
            [this.printCanvas],
            PrintOrientation.Portrait
          );
        });
    }
  }

  prevStep() {
    this.step--;
  }

  initPoem(poem) {
    switch (poem) {
      case "wordBank":
        this.words = this.shuffleWordBank(this.getWordBankWords());
        break;
      case "americaPoem":
        this.words = this.shuffleWordBank(this.getAmericaWords());
        break;
      case "gettysburgPoem":
        this.words = this.shuffleWordBank(this.getGettysburgWords());
        break;
      case "dreamPoem":
        this.words = this.shuffleWordBank(this.getDreamWords());
        break;
      case "shakespearePoem":
        this.words = this.shuffleWordBank(this.getShakespeareWords());
    }

    this.step = 2;
    this.poemType = poem;
  }

  shuffleWordBank(wordsToShuffle: string[]) {
    wordsToShuffle.sort((a, b) => {
      let rand1 = uuid.v4();
      let rand2 = uuid.v4();
      return rand1 > rand2 ? 1 : -1;
    });

    let shuffledWords = [];
    let shuffleLength = 30;

    if (wordsToShuffle.length < 30) {
      shuffleLength = wordsToShuffle.length;
    }

    for (let i = 0; i < shuffleLength; i++) {
      shuffledWords.push(wordsToShuffle[i]);
    }

    return shuffledWords;
  }

  shuffleWords() {
    this.words.sort((a, b) => {
      let rand1 = uuid.v4();
      let rand2 = uuid.v4();
      return rand1 > rand2 ? 1 : -1;
    });
  }

  backgroundsMenuState() {
    if (this.backgroundsMenuOpen === false) {
      this.backgroundsMenuOpen = true;
      this.backgroundsMenuStart = false;
      this.backgroundMenuArrow = 90;
    } else {
      this.backgroundsMenuOpen = false;
      this.backgroundMenuArrow = -90;
    }
  }

  wordBankState() {
    if (this.wordBankOpen === false) {
      this.wordBankOpen = true;
      this.wordBankStart = false;
      this.wordBankArrow = -180;
    } else {
      this.wordBankOpen = false;
      this.wordBankArrow = 0;
    }
  }

  random_between(minimum: number, maximum: number) {
    let min = Math.ceil(0); // inclusive
    let max = Math.floor(4); // exclusive

    return Math.floor(Math.random() * (max - min) + min);
  }


  getWordBankWords() {
    return [
      "able",
      "accept",
      "act",
      "add",
      "addend",
      "addition",
      "adjective",
      "adverb",
      "afraid",
      "after",
      "again",
      "against",
      "age",
      "ago",
      "agree",
      "air",
      "all",
      "allow",
      "also",
      "always",
      "among",
      "anger",
      "animal",
      "answer",
      "any",
      "appear",
      "apple",
      "area",
      "arm",
      "arrange",
      "arrive",
      "art",
      "ask",
      "autumn",
      "baby",
      "back",
      "bad",
      "bake",
      "ball",
      "band",
      "bank",
      "bar",
      "base",
      "basic",
      "bat",
      "bear",
      "beat",
      "beauty",
      "bed",
      "been",
      "before",
      "begin",
      "behind",
      "believe",
      "bell",
      "best",
      "better",
      "between",
      "beverage",
      "big",
      "bird",
      "bit",
      "black",
      "block",
      "blood",
      "blow",
      "blue",
      "board",
      "boat",
      "body",
      "bone",
      "book",
      "boot",
      "born",
      "borrow",
      "both",
      "bottom",
      "bought",
      "box",
      "boy",
      "branch",
      "bread",
      "break",
      "bright",
      "bring",
      "broad",
      "broke",
      "brother",
      "brought",
      "brown",
      "build",
      "burn",
      "busy",
      "buy",
      "call",
      "came",
      "camp",
      "can",
      "cancel",
      "capital",
      "capitol",
      "captain",
      "car",
      "card",
      "care",
      "carry",
      "case",
      "cat",
      "catch",
      "caught",
      "cause",
      "cell",
      "cent",
      "center",
      "centimeter",
      "century",
      "certain",
      "chair",
      "chance",
      "change",
      "character",
      "charge",
      "chart",
      "check",
      "chick",
      "chief",
      "child",
      "children",
      "choose",
      "chord",
      "circle",
      "city",
      "claim",
      "class",
      "clean",
      "clear",
      "climb",
      "clock",
      "close",
      "cloud",
      "coast",
      "coat",
      "coin",
      "cold",
      "collect",
      "colony",
      "color",
      "column",
      "comb",
      "come",
      "comma",
      "common",
      "company",
      "compare",
      "complain",
      "complete",
      "condition",
      "connect",
      "consider",
      "consonant",
      "contain",
      "continent",
      "continue",
      "contrast",
      "control",
      "cook",
      "cool",
      "copy",
      "corn",
      "corner",
      "correct",
      "cost",
      "cotton",
      "cough",
      "could",
      "count",
      "country",
      "course",
      "cover",
      "cow",
      "crease",
      "create",
      "crop",
      "cross",
      "crowd",
      "cry",
      "cube",
      "current",
      "cut",
      "dad",
      "dance",
      "danger",
      "dark",
      "day",
      "daylight",
      "daytime",
      "dead",
      "deal",
      "dear",
      "decade",
      "decide",
      "decimal",
      "deep",
      "degree",
      "depend",
      "describe",
      "desert",
      "design",
      "dessert",
      "determine",
      "develop",
      "diamond",
      "dictionary",
      "differ",
      "difference",
      "difficult",
      "direct",
      "discuss",
      "divide",
      "dividend",
      "division",
      "doctor",
      "dog",
      "dollar",
      "done",
      "door",
      "double",
      "down",
      "draw",
      "dream",
      "dress",
      "drink",
      "drive",
      "drop",
      "dry",
      "duck",
      "during",
      "each",
      "ear",
      "ear",
      "early",
      "earth",
      "east",
      "eat",
      "edge",
      "effect",
      "egg",
      "eight",
      "either",
      "electric",
      "element",
      "else",
      "end",
      "enemy",
      "energy",
      "engine",
      "enough",
      "enter",
      "equal",
      "especially",
      "even",
      "evening",
      "event",
      "ever",
      "every",
      "exact",
      "example",
      "except",
      "excite",
      "exclamation",
      "exercise",
      "expect",
      "experience",
      "experiment",
      "explain",
      "exponent",
      "eye",
      "face",
      "fact",
      "factor",
      "fair",
      "fall",
      "family",
      "famous",
      "far",
      "farm",
      "fast",
      "fat",
      "father",
      "favor",
      "fear",
      "feed",
      "feel",
      "feet",
      "fell",
      "felt",
      "few",
      "field",
      "fight",
      "figure",
      "fill",
      "final",
      "find",
      "fine",
      "finger",
      "finish",
      "fire",
      "first",
      "fish",
      "fit",
      "five",
      "fix",
      "flat",
      "floor",
      "flow",
      "flower",
      "fly",
      "follow",
      "food",
      "foot",
      "force",
      "forest",
      "forget",
      "form",
      "forward",
      "found",
      "four",
      "fraction",
      "free",
      "fresh",
      "friend",
      "front",
      "fruit",
      "full",
      "fun",
      "galloon",
      "game",
      "garden",
      "gas",
      "gather",
      "gave",
      "general",
      "gentle",
      "get",
      "girl",
      "give",
      "glad",
      "glass",
      "go",
      "gold",
      "gone",
      "good",
      "got",
      "govern",
      "grand",
      "grass",
      "gray",
      "great",
      "green",
      "grew",
      "ground",
      "group",
      "grow",
      "guess",
      "guide",
      "hail",
      "hair",
      "half",
      "hand",
      "happen",
      "happy",
      "hard",
      "hate",
      "head",
      "hear",
      "heard",
      "heart",
      "heat",
      "heavy",
      "held",
      "help",
      "here",
      "high",
      "hill",
      "history",
      "hit",
      "hold",
      "hole",
      "home",
      "hope",
      "horse",
      "hot",
      "hour",
      "house",
      "how",
      "huge",
      "human",
      "hundred",
      "hurry",
      "hurt",
      "ice",
      "idea",
      "imagine",
      "in",
      "inch",
      "include",
      "index",
      "indicate",
      "industry",
      "insect",
      "instant",
      "instructor",
      "instrument",
      "integer",
      "interest",
      "invent",
      "iron",
      "island",
      "job",
      "join",
      "joy",
      "jump",
      "just",
      "keep",
      "kept",
      "key",
      "kid",
      "kilometer",
      "kind",
      "king",
      "knew",
      "know",
      "lady",
      "lake",
      "land",
      "language",
      "large",
      "last",
      "late",
      "laugh",
      "law",
      "lay",
      "lead",
      "learn",
      "least",
      "leave",
      "led",
      "left",
      "leg",
      "length",
      "less",
      "let",
      "letter",
      "level",
      "lie",
      "life",
      "lift",
      "light",
      "line",
      "liquid",
      "list",
      "listen",
      "liter",
      "little",
      "live",
      "locate",
      "log",
      "lone",
      "long",
      "look",
      "lose",
      "lost",
      "lot",
      "loud",
      "love",
      "low",
      "machine",
      "made",
      "magnet",
      "main",
      "major",
      "make",
      "man",
      "many",
      "map",
      "mark",
      "market",
      "mass",
      "master",
      "match",
      "material",
      "matter",
      "may",
      "mean",
      "meant",
      "measure",
      "meat",
      "median",
      "meet",
      "melody",
      "memory",
      "men",
      "metal",
      "meter",
      "method",
      "middle",
      "might",
      "mile",
      "milk",
      "million",
      "mind",
      "mine",
      "minor",
      "minute",
      "miss",
      "mix",
      "mode",
      "modern",
      "molecule",
      "mom",
      "moment",
      "money",
      "month",
      "moon",
      "moonlight",
      "more",
      "morning",
      "most",
      "mother",
      "motion",
      "mountain",
      "mouth",
      "move",
      "much",
      "multiplication",
      "multiply",
      "music",
      "must",
      "name",
      "nation",
      "natural",
      "nature",
      "near",
      "necessary",
      "neck",
      "need",
      "neighbor",
      "never",
      "new",
      "next",
      "nice",
      "night",
      "nighttime",
      "nine",
      "no",
      "noise",
      "noon",
      "north",
      "nose",
      "note",
      "nothing",
      "notice",
      "noun",
      "now",
      "number",
      "numeral",
      "object",
      "observe",
      "occur",
      "ocean",
      "odd",
      "off",
      "offer",
      "office",
      "often",
      "oil",
      "old",
      "on",
      "once",
      "one",
      "only",
      "open",
      "operate",
      "opposite",
      "order",
      "organ",
      "organize",
      "original",
      "other",
      "ounce",
      "ounce",
      "our",
      "out",
      "oval",
      "over",
      "own",
      "oxygen",
      "page",
      "pair",
      "paper",
      "paragraph",
      "parent",
      "part",
      "particular",
      "party",
      "pass",
      "past",
      "path",
      "pattern",
      "pay",
      "peninsula",
      "people",
      "perhaps",
      "period",
      "person",
      "phrase",
      "pick",
      "picture",
      "piece",
      "pint",
      "pitch",
      "place",
      "plain",
      "plan",
      "plane",
      "planet",
      "plant",
      "play",
      "please",
      "plural",
      "poem",
      "point",
      "pole",
      "poor",
      "populate",
      "port",
      "pose",
      "position",
      "possible",
      "post",
      "postcard",
      "pound",
      "power",
      "practice",
      "prepare",
      "present",
      "press",
      "pretty",
      "prince",
      "princess",
      "print",
      "problem",
      "process",
      "produce",
      "product",
      "proper",
      "property",
      "protect",
      "prove",
      "provide",
      "pull",
      "push",
      "put",
      "quart",
      "queen",
      "question",
      "quick",
      "quiet",
      "quite",
      "quotient",
      "race",
      "radio",
      "rail",
      "rain",
      "raise",
      "ran",
      "range",
      "rather",
      "reach",
      "read",
      "ready",
      "real",
      "reason",
      "receive",
      "record",
      "red",
      "region",
      "remainder",
      "remember",
      "repeat",
      "reply",
      "represent",
      "require",
      "rest",
      "result",
      "rich",
      "ride",
      "right",
      "ring",
      "rise",
      "river",
      "road",
      "rock",
      "roll",
      "room",
      "root",
      "rope",
      "rose",
      "round",
      "row",
      "rub",
      "rule",
      "run",
      "safe",
      "said",
      "sail",
      "salt",
      "same",
      "sand",
      "sat",
      "save",
      "saw",
      "say",
      "scale",
      "school",
      "science",
      "score",
      "sea",
      "search",
      "season",
      "seat",
      "second",
      "section",
      "see",
      "seed",
      "seem",
      "select",
      "self",
      "sell",
      "send",
      "sense",
      "sent",
      "sentence",
      "separate",
      "serve",
      "set",
      "settle",
      "seven",
      "several",
      "shall",
      "shape",
      "share",
      "sharp",
      "sheet",
      "shell",
      "shine",
      "ship",
      "shoe",
      "shop",
      "shore",
      "short",
      "should",
      "shoulder",
      "shout",
      "show",
      "shut",
      "sibling",
      "side",
      "sight",
      "sign",
      "silent",
      "silver",
      "similar",
      "simple",
      "since",
      "sing",
      "sing",
      "single",
      "singular",
      "sister",
      "sit",
      "six",
      "size",
      "skill",
      "skin",
      "skip",
      "sky",
      "slave",
      "sleep",
      "sleet",
      "slip",
      "slow",
      "small",
      "smell",
      "smile",
      "snow",
      "soft",
      "soil",
      "soldier",
      "solid",
      "solution",
      "solve",
      "some",
      "son",
      "song",
      "soon",
      "sound",
      "south",
      "speak",
      "special",
      "speech",
      "speed",
      "spell",
      "spend",
      "spoke",
      "spot",
      "spread",
      "spring",
      "square",
      "stale",
      "stall",
      "stand",
      "star",
      "start",
      "state",
      "station",
      "stay",
      "steam",
      "steel",
      "step",
      "stick",
      "still",
      "stone",
      "stood",
      "stop",
      "store",
      "story",
      "straight",
      "strange",
      "stream",
      "street",
      "stretch",
      "string",
      "strong",
      "student",
      "study",
      "subject",
      "substance",
      "subtract",
      "subtraction",
      "succeed",
      "success",
      "such",
      "sudden",
      "suffix",
      "sugar",
      "suggest",
      "suit",
      "sum",
      "summer",
      "sun",
      "sunlight",
      "supply",
      "support",
      "sure",
      "surface",
      "surprise",
      "swim",
      "syllable",
      "symbol",
      "table",
      "tail",
      "take",
      "talk",
      "tall",
      "teach",
      "teacher",
      "team",
      "teeth",
      "tell",
      "temperature",
      "ten",
      "term",
      "test",
      "thank",
      "thick",
      "thin",
      "thing",
      "think",
      "third",
      "though",
      "thought",
      "thousand",
      "three",
      "through",
      "throw",
      "thus",
      "tie",
      "time",
      "tiny",
      "together",
      "told",
      "tone",
      "took",
      "tool",
      "top",
      "total",
      "touch",
      "toward",
      "town",
      "track",
      "trade",
      "train",
      "translate",
      "travel",
      "tree",
      "triangle",
      "trip",
      "trouble",
      "truck",
      "true",
      "try",
      "tube",
      "turn",
      "turn off",
      "turn on",
      "twenty",
      "two",
      "type",
      "under",
      "understand",
      "unit",
      "until",
      "use",
      "usual",
      "valley",
      "value",
      "vary",
      "very",
      "view",
      "village",
      "voice",
      "vowel",
      "wait",
      "wake",
      "walk",
      "wall",
      "want",
      "warm",
      "wash",
      "watch",
      "water",
      "wave",
      "way",
      "weak",
      "wear",
      "weather",
      "week",
      "weight",
      "well",
      "went",
      "were",
      "west",
      "what",
      "wheel",
      "when",
      "where",
      "whether",
      "which",
      "while",
      "white",
      "who",
      "whole",
      "whose",
      "why",
      "wide",
      "wife",
      "wild",
      "win",
      "wind",
      "window",
      "wing",
      "winter",
      "wire",
      "wish",
      "with",
      "woman",
      "women",
      "wonder",
      "won't",
      "wood",
      "word",
      "work",
      "world",
      "worry",
      "would",
      "write",
      "written",
      "wrong",
      "wrote",
      "yard",
      "year",
      "yellow",
      "yes",
      "yet",
      "young",
    ];
  }

  getAmericaWords() {
    switch (this.random_between(0, 4)) {
      case 0:
        return [
          "o",
          "beautiful",
          "for",
          "spacious",
          "skies",
          "for",
          "amber",
          "waves",
          "of",
          "grain",
          "for",
          "purple",
          "mountains",
          "majesties",
          "above",
          "the",
          "fruited",
          "plain",
          "America",
          "America",
          "God",
          "shed",
          "his",
          "grace",
          "on",
          "thee",
          "and",
          "crown",
          "thy",
          "good",
          "with",
          "brotherhood",
          "from",
          "sea",
          "to",
          "shining",
          "sea",
        ];

      case 1:
        return [
          "o",
          "beautiful",
          "for",
          "pilgrim",
          "feet",
          "whose",
          "stern",
          "impassion'd",
          "stress",
          "a",
          "thoroughfare",
          "for",
          "freedom",
          "beat",
          "across",
          "the",
          "wilderness",
          "America",
          "America",
          "God",
          "mend",
          "thine",
          "ev'ry",
          "flaw",
          "confirm",
          "thy",
          "soul",
          "in",
          "self-control",
          "thy",
          "liberty",
          "in",
          "law",
        ];

      case 2:
        return [
          "o",
          "beautiful",
          "for",
          "heroes",
          "prov'd",
          "in",
          "liberating",
          "strife",
          "who",
          "more",
          "than",
          "self",
          "their",
          "country",
          "lov'd",
          "and",
          "mercy",
          "more",
          "than",
          "life",
          "America",
          "America",
          "may",
          "God",
          "thy",
          "gold",
          "refine",
          "till",
          "all",
          "success",
          "be",
          "nobleness",
          "and",
          "ev'ry",
          "gain",
          "divine",
        ];

      case 3:
        return [
          "o",
          "beautiful",
          "for",
          "patriot",
          "dream",
          "that",
          "sees",
          "beyond",
          "the",
          "years",
          "thine",
          "alabaster",
          "cities",
          "gleam",
          "undimmed",
          "by",
          "human",
          "tears",
          "America",
          "America",
          "God",
          "shed",
          "his",
          "grace",
          "on",
          "thee",
          "and",
          "crown",
          "they",
          "good",
          "with",
          "brotherhood",
          "from",
          "sea",
          "to",
          "shining",
          "sea",
        ];
    }
  }

  getGettysburgWords() {
    switch (this.random_between(0, 5)) {
      case 0:
        return [
          "four",
          "score",
          "and",
          "seven",
          "years",
          "ago",
          "our",
          "fathers",
          "brought",
          "forth",
          "on",
          "this",
          "continent",
          "a",
          "new",
          "nation",
          "conceived",
          "in",
          "liberty",
          "and",
          "dedicated",
          "to",
          "the",
          "proposition",
          "that",
          "all",
          "men",
          "are",
          "created",
          "equal",
        ];
      case 1:
        return [
          "but",
          "in",
          "a",
          "larger",
          "sense",
          "we",
          "can",
          "not",
          "dedicate",
          "we",
          "can",
          "not",
          "consecrate",
          "we",
          "can",
          "not",
          "hallow",
          "this",
          "ground",
          "the",
          "brave",
          "men",
          "living",
          "and",
          "dead",
          "who",
          "struggled",
          "here",
          "have",
          "consecrated",
          "it",
          "far",
          "above",
          "our",
          "poor",
          "power",
          "to",
          "add",
          "or",
          "detract",
        ];
      case 2:
        return [
          "the",
          "world",
          "will",
          "little",
          "note",
          "nor",
          "long",
          "remember",
          "what",
          "we",
          "say",
          "here",
          "but",
          "it",
          "can",
          "never",
          "forget",
          "what",
          "they",
          "did",
          "here",
        ];
      case 3:
        return [
          "it",
          "is",
          "for",
          "us",
          "the",
          "living",
          "rather",
          "to",
          "be",
          "dedicated",
          "here",
          "to",
          "the",
          "unfinished",
          "word",
          "which",
          "they",
          "who",
          "fought",
          "here",
          "have",
          "this",
          "far",
          "so",
          "nobly",
          "advanced",
        ];
      case 4:
        return [
          "that",
          "this",
          "nation",
          "under",
          "God",
          "shall",
          "have",
          "a",
          "new",
          "birth",
          "of",
          "freedom",
          "and",
          "that",
          "government",
          "of",
          "the",
          "people",
          "by",
          "the",
          "people",
          "for",
          "the",
          "people",
          "shall",
          "not",
          "perish",
          "from",
          "the",
          "earth",
        ];
    }
  }

  getDreamWords() {
    switch (this.random_between(0, 8)) {
      case 0:
        return [
          "I",
          "have",
          "a",
          "dream",
          "that",
          "one",
          "day",
          "this",
          "nation",
          "will",
          "rise",
          "up",
          "and",
          "live",
          "out",
          "the",
          "true",
          "meaning",
          "of",
          "its",
          "creed",
          "we",
          "hold",
          "these",
          "truths",
          "to",
          "be",
          "self-evident",
          "that",
          "all",
          "men",
          "are",
          "created",
          "equal",
        ];
      case 1:
        return [
          "I",
          "am",
          "happy",
          "to",
          "join",
          "with",
          "you",
          "today",
          "in",
          "what",
          "will",
          "go",
          "down",
          "in",
          "history",
          "as",
          "the",
          "greatest",
          "demonstration",
          "of",
          "freedom",
          "in",
          "the",
          "history",
          "of",
          "our",
          "nation",
        ];
      case 2:
        return [
          "we",
          "must",
          "not",
          "allow",
          "our",
          "creative",
          "protest",
          "to",
          "degenerate",
          "into",
          "physical",
          "violence",
          "again",
          "and",
          "again",
          "we",
          "must",
          "rise",
          "to",
          "the",
          "majestic",
          "heights",
          "of",
          "meeting",
          "physical",
          "force",
          "with",
          "soul",
          "force",
        ];
      case 3:
        return [
          "we",
          "cannot",
          "walk",
          "alone",
          "and",
          "as",
          "we",
          "walk",
          "we",
          "must",
          "make",
          "the",
          "pledge",
          "that",
          "we",
          "shall",
          "always",
          "march",
          "ahead",
          "we",
          "cannot",
          "turn",
          "back",
        ];
      case 4:
        return [
          "and",
          "so",
          "even",
          "though",
          "we",
          "face",
          "the",
          "difficulties",
          "of",
          "today",
          "and",
          "tomorrow",
          "I",
          "still",
          "have",
          "a",
          "dream",
          "it",
          "is",
          "a",
          "dream",
          "deeply",
          "rooted",
          "in",
          "the",
          "American",
          "dream",
        ];
      case 5:
        return [
          "with",
          "this",
          "faith",
          "we",
          "will",
          "be",
          "able",
          "to",
          "work",
          "together",
          "to",
          "pray",
          "together",
          "to",
          "struggle",
          "together",
          "to",
          "go",
          "to",
          "jail",
          "together",
          "to",
          "stand",
          "up",
          "for",
          "freedom",
          "together",
          "knowing",
          "that",
          "we",
          "will",
          "be",
          "free",
          "one",
          "day",
        ];
      case 6:
        return [
          "I",
          "have",
          "a",
          "dream",
          "that",
          "my",
          "four",
          "little",
          "children",
          "will",
          "one",
          "day",
          "live",
          "in",
          "a",
          "nation",
          "where",
          "they",
          "will",
          "not",
          "be",
          "judged",
          "by",
          "the",
          "color",
          "of",
          "their",
          "skin",
          "but",
          "by",
          "the",
          "content",
          "of",
          "their",
          "character",
        ];
      case 7:
        return [
          "free",
          "at",
          "last",
          "free",
          "at",
          "last",
          "thank",
          "God",
          "almighty",
          "we",
          "are",
          "free",
          "at",
          "last",
        ];
    }
  }

  getShakespeareWords() {
    switch (this.random_between(0, 4)) {
      case 0:
        return [
          "shall",
          "I",
          "compare",
          "thee",
          "to",
          "a",
          "summer's",
          "day",
          "thou",
          "art",
          "more",
          "lovely",
          "and",
          "more",
          "temperate",
          "rough",
          "winds",
          "do",
          "shake",
          "the",
          "darling",
          "buds",
          "of",
          "May",
          "and",
          "summer's",
          "lease",
          "hath",
          "all",
          "too",
          "short",
          "date",
        ];
      case 1:
        return [
          "sometime",
          "too",
          "hot",
          "the",
          "eye",
          "of",
          "heaven",
          "shines",
          "and",
          "often",
          "is",
          "his",
          "gold",
          "complexion",
          "dimm'd",
          "and",
          "every",
          "fair",
          "from",
          "fair",
          "sometime",
          "declines",
          "by",
          "chance",
          "or",
          "nature's",
          "changing",
          "course",
          "untrimm'd",
        ];
      case 2:
        return [
          "but",
          "thy",
          "eternal",
          "summer",
          "shall",
          "not",
          "fade",
          "nor",
          "lose",
          "possession",
          "of",
          "that",
          "fair",
          "thou",
          "owest",
          "nor",
          "shall",
          "Death",
          "brag",
          "thou",
          "wander'st",
          "in",
          "his",
          "shade",
          "when",
          "in",
          "eternal",
          "lines",
          "to",
          "time",
          "thou",
          "growest",
        ];
      case 3:
        return [
          "so",
          "long",
          "as",
          "men",
          "can",
          "breathe",
          "or",
          "eyes",
          "can",
          "see",
          "so",
          "long",
          "as",
          "lives",
          "this",
          "and",
          "this",
          "gives",
          "life",
          "to",
          "thee",
        ];
    }
  }
}
