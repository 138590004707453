import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, pattern: string, replacement: string): string {
    const regex: RegExp = new RegExp(pattern, 'g');
    const result: string = value.replace(regex, replacement);
    return result;
  }

}
