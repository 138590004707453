import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DragElement } from '../../model/DragElement';
import * as _ from 'lodash';
import { DragUtilService } from 'src/app/service/drag-util.service';
import { VennDiagramFile } from 'src/app/model/VennDiagramFile';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { FileUtilService } from 'src/app/service/file-util.service';
import { PrintService } from 'src/app/service/print.service'
import { Route } from '@angular/compiler/src/core';
import { NavigationEnd, Router } from '@angular/router';
(window as any).html2canvas = html2canvas;


@Component({
  selector: 'app-venn-diagram',
  templateUrl: './venn-diagram.component.html',
  styleUrls: ['./venn-diagram.component.scss']
})
export class VennDiagramComponent implements OnInit {
  @ViewChild('canvas', null) canvas: ElementRef;
  @ViewChild('printCanvas', null) printCanvas: ElementRef;
  @ViewChild('printPreview', null) printPreview: ElementRef;
  @ViewChild('printPage2Canvas', null) printPage2Canvas: ElementRef;

  // Selection
  step: number = 1;
  addingCircle: boolean = false;
  now: Date = new Date();
  nameRequiredError: boolean = false;
  titleRequiredError: boolean = false;
  previewImages: any[] = [];
  vars: VennDiagramFile = new VennDiagramFile();
  previewImage: any;

  //errors
  emptyLabelError = false;

  constructor(private dragUtil: DragUtilService, private fileUtil: FileUtilService, private printer: PrintService, private router: Router) {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.step = this.fileUtil.getStep() || 1; // initialize to step 1 if no step in query string
        this.step = parseInt(this.step + '');
        this.vars = this.fileUtil.getVars() || (new VennDiagramFile()); // initialize vars if none found
        this.setStep();
      }
    });
  }

  ngOnInit() {
    if (this.step == 1) {
      this.addCircle('green');
      this.addCircle('purple', 200, 50);
    }
  }


  fileInput: any;
  openVenn(event: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        let json = e.target.result;
        this.vars = JSON.parse(json);
        this.nextStep();
        this.fileInput = null;
      }
      catch (e) {
        alert('File cannot be opened');
        this.fileInput = null;
      }
    };
    reader.readAsText(event.target.files[0]);
  }

  moveEvent($event: MouseEvent, elem: DragElement) {

  }
  addLabel() {
    let elem = this.dragUtil.createElement(this.vars.elements);
    elem.type = 'text';
    this.vars.editId = elem.id;
    this.vars.elements.push(elem);
    this.editLabel(elem);
  }
  addCircle(color: string, x: number = null, y: number = null) {
    let elem = this.dragUtil.createElement(this.vars.elements);
    elem.color = color;
    elem.width = 250;
    elem.height = 250;
    elem.type = 'image';
    elem.imageSrc = "../../../assets/venn-diagram/circle-" + elem.color + ".gif";
    if (!!x) {
      elem.x = x;
      elem.y = y;
    }
    this.vars.elements.push(elem);
    this.vars.addingCircle = false;
  }

  // Clear and start over
  reset() {
    document.location.href = document.location.pathname;
  }

  // Navigate to previous step
  prevStep() { 
    this.fileUtil.setStep((this.step-1));
  }

  // navigate to next step
  nextStep() {

    this.nameRequiredError = false;
    this.titleRequiredError = false;
    switch (this.step) {
      case 1:
        if (!this.vars.inputDiagrammedBy) {
          this.nameRequiredError = true;
          return;
        }
        if (!this.vars.inputTitle) {
          this.titleRequiredError = true;
          return;
        }
        break;
    }

 
    this.vars.now = new Date();
    this.fileUtil.setStep((this.step+1), this.vars); 
  }

  setStep() {
    this.previewImages = null;
    if (this.step == 3) {
      this.previewImage = null;
      this.dragUtil.initPrint(this.vars.elements, 'vennCanvas').then(async x => {
         this.previewImage = await this.printer.initPrintPreview([this.printCanvas, this.printPage2Canvas], PrintOrientation.Landscape);
      });
    }
  }


  // Save the event
  saveLabel() {

    // clear error
    this.emptyLabelError = false;

    // Ensure event name is entered.
    if (!this.vars.inputLabelText) {
      this.emptyLabelError = true;
      return;
    }
    // update event
    let elem = _.find(this.vars.elements, x => x.id == this.vars.editId);
    elem.text = this.vars.inputLabelText;
    elem.description = this.vars.inputLabelDescription;

    this.clearSelection();
    this.vars.editingLabel = false;
    elem.selected = true;

  }

  getLabels() {
    return _.filter(this.vars.elements, x => x.type == 'text');
  }

  // clear the selected event
  clearSelection() {
    this.vars.inputLabelText = null;
    this.vars.inputLabelDescription = null;
    this.vars.editId = null;
  }

  // edit the event
  editLabel(elem: DragElement) {
    DragElement.select(elem);
    this.vars.editId = elem.id;
    this.vars.inputLabelDescription = elem.description;
    this.vars.inputLabelText = elem.text;
    this.vars.editingLabel = true;
  }

  async print() {
    this.printer.print([this.printCanvas, this.printPage2Canvas], PrintOrientation.Landscape);
  }
  async export() {
    this.printer.export([this.printCanvas, this.printPage2Canvas], 'VennDiagram.pdf', PrintOrientation.Landscape);
  }
  save() {
    this.fileUtil.save(this.vars, 'VennDiagram.json')
  }
}
