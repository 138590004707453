<div class="app-border">
  <div class="title-bar">
    <span>Letter Generator</span>
  </div>
  <!------------------------------------------ Sample Letter-------------------------------------------------------->
  <div *ngIf="step === -2">
    <div class="sample-page">
      <!--sample page is supposed to vary depending on what you put your name as, when blank should say friend. always using "friend" for now.-->
      <div class="sample-back">
        <button (click)="nextStep()"></button>
      </div>
      <!--if "yourname" is empty, advance 1 not 2-->
      <div class="sample-continue">
        <button (click)="writeYourOwnLetter()"></button>
      </div>
    </div>
  </div>
  <!----------------------------------------------- Start --------------------------------------------------------->
  <div *ngIf="step === -1" class="step">
    <div class="first-page">
      <h1 class="page-1-header">Are you ready to write a letter?</h1>
      <p class="page-1-header-2">
        Here you can learn the parts of a letter and how to write your own
        letter.
      </p>
      <div class="page1-sample">
        <button
          class="sample-page-button"
          (click)="showSampleLetter()"
        ></button>
      </div>
      <div class="step1-prompt">
        <p class="page-1-header-3">
          Enter your name and click Continue to get started.
        </p>
        <label class="page-1-header-4">Your Name:</label>
        <input
          type="text"
          id="yourName"
          name="yourName"
          [(ngModel)]="letterData.yourName"
        />
        <span class="error-exclamation" *ngIf="yourNameError">! </span>
      </div>
      <div class="step1-action">
        <button class="sample-arrow" (click)="showSampleLetter()">
          <img src="assets/letter-generator/sample-page-arrow.png" />
          View a sample letter
        </button>
        <button class="next-button" (click)="nextStep()"></button>
      </div>

      <hr />
      <h2>OPEN SAVED LETTER</h2>

      <input
        [(ngModel)]="fileInput"
        id="preview"
        type="file"
        (change)="openLetter($event)"
      />
      <div class="clear"></div>
    </div>
  </div>
  <!------------------------------------------------------  Friendly or Business Letter  ------------------------------------------------------------->
  <div *ngIf="step === 0">
    <div class="second-page">
      <h1 class="page2-header">Write your own letter!</h1>
      <p class="page2-header-2">
        Click on which type of letter you would like to write.
      </p>
      <div class="friendly-letter">
        <button
          class="friendly-letter-button"
          (click)="initFriendly()"
        ></button>
      </div>
      <div class="business-letter">
        <button
          class="business-letter-button"
          (click)="initBusiness()"
        ></button>
      </div>
      <div class="page2-back">
        <button (click)="prevStep()"></button>
      </div>
    </div>
  </div>
  <!---------------------------------------------------  Friendly Letter Step 1  -------------------------------------------------------->

  <div *ngIf="step >= 1">
    <div *ngIf="step < 7 && letterData.letterType == 'friendly'">
      <div class="friendly-interact">
        <div class="friendly-box1" (click)="friendlyInteractClick(1)"></div>
        <div class="friendly-box2" (click)="friendlyInteractClick(2)"></div>
        <div class="friendly-box3" (click)="friendlyInteractClick(3)"></div>
        <div class="friendly-box4" (click)="friendlyInteractClick(4)"></div>
        <div class="friendly-box5" (click)="friendlyInteractClick(5)"></div>
        <div class="friendly-box6" (click)="friendlyInteractClick(6)"></div>
      </div>
      <div class="friendly-interact-label">
        <label>
          Friendly <br />
          Letter
        </label>
      </div>
    </div>
    <div *ngIf="step < 7 && letterData.letterType == 'business'">
      <div class="friendly-interact">
        <div class="business-box1" (click)="friendlyInteractClick(1)"></div>
        <div class="business-box2" (click)="friendlyInteractClick(2)"></div>
        <div class="business-box3" (click)="friendlyInteractClick(3)"></div>
        <div class="business-box4" (click)="friendlyInteractClick(4)"></div>
        <div class="business-box5" (click)="friendlyInteractClick(5)"></div>
        <div class="business-box6" (click)="friendlyInteractClick(6)"></div>
      </div>
      <div class="friendly-interact-label">
        <label>
          Business <br />
          Letter
        </label>
      </div>
    </div>
    <div *ngIf="step < 7" class="friendly-buttons">
      <button class="friendly-back" (click)="prevStep()"></button>
      <button class="friendly-next" (click)="nextStep()"></button>
    </div>
    <div *ngIf="step === 1">
      <div class="friendly-letter1">
        <div class="page3-top">
          <h1 class="page3-header">Step {{ step }}. Heading</h1>
          <p class="page3-header-2">
            The heading appears in the upper right-hand corner. It contains the
            sender's address and the date the letter is written.
          </p>
        </div>
        <div class="page3-middle">
          <label class="page-3-header-3">Type your street address.</label>
          <input
            type="text"
            id="address"
            name="address"
            autocomplete="street-address"
            [(ngModel)]="letterData.address"
          />
          <label class="page-3-header-4"
            >Type your city, state, and zip code. Remember to include a comma
            after your city and to abbreivate your state.</label
          >
          <input
            type="text"
            id="address2"
            name="address2"
            autocomplete="address-level2"
            [(ngModel)]="letterData.address2"
          />
          <label class="page-3-header-5"
            >Type the date, including the month, day, and year.</label
          >
          <input
            type="text"
            id="date"
            name="date"
            [(ngModel)]="letterData.date"
          />
        </div>
      </div>
    </div>
    <!---------------------------------------------------------  Friendly Letter Step 2  -------------------------------------------------------------->
    <div
      *ngIf="
        (step === 2 && letterData.letterType == 'friendly') ||
        (step === 3 && letterData.letterType == 'business')
      "
    >
      <div class="friendly-letter2">
        <div class="page4-top">
          <h1 class="page4-header">Step {{ step }}. Salutation</h1>
          <p class="page4-header-2">The salutation is in the greeting.</p>
        </div>

        <div class="page4-middle">
          <label class="page-4-header-3">
            Type in the name of the person to whom you are writing. A comma
            should be included after the name.
          </label>
          <input
            type="text"
            id="salutation2"
            value="Dear"
            readonly="readonly"
          />
          <input
            type="text"
            id="salutation"
            name="salutation"
            [(ngModel)]="letterData.salutation"
          />
        </div>
      </div>
    </div>
    <div *ngIf="step === 2 && letterData.letterType == 'business'">
      <div class="inside-address">
        <div class="inside-address-top">
          <h1 class="inside-address-header">Step {{ step }}. Inside Address</h1>
          <p class="inside-address-header-2">
            This is the person's contact information.
          </p>
        </div>
        <div class="inside-address-middle">
          <label class="inside-address-header-3">
            Type in the name of the person to whom you are writing.
          </label>
          <input
            type="text"
            id="toName"
            name="toName"
            [(ngModel)]="letterData.toName"
          />
          <label class="inside-address-header-4">
            If you know the person's title, type it here.
          </label>
          <input
            type="text"
            id="toTitle"
            name="toTitle"
            [(ngModel)]="letterData.toTitle"
          />
          <label class="inside-address-header-5">
            Type the name of the company or organization where the person works.
          </label>
          <input
            type="text"
            id="toCompany"
            name="toCompany"
            [(ngModel)]="letterData.toCompany"
          />
          <label class="inside-address-header-6">
            Type the street address where you are sending the letter.
          </label>
          <input
            type="text"
            id="toAddress"
            name="toAddress"
            [(ngModel)]="letterData.toAddress"
          />
          <label class="inside-address-header-7">
            Type the city, state, and zip code.
          </label>
          <input
            type="text"
            id="toAddress2"
            name="toAddress2"
            [(ngModel)]="letterData.toAddress2"
          />
        </div>
      </div>
    </div>
    <!-------------------------------------------------------  Friendly Letter Step 3 -------------------------------------------------------------------->
    <div
      *ngIf="
        (step === 3 && letterData.letterType == 'friendly') ||
        (step === 4 && letterData.letterType == 'business')
      "
    >
      <div class="friendly-letter3">
        <div class="page5-top">
          <h1 class="page5-header">Step {{ step }}. Body</h1>
          <p class="page5-header-2">
            The body is the main part of the letter that tells what you have to
            say.
          </p>
        </div>
        <div class="page5-middle">
          <label class="page-5-header-3">
            You usually indent each paragraph and include a line space between
            paragraphs.
          </label>
          <textarea (change)='formatText()'
            type="text"
            id="body"
            name="body"
            [(ngModel)]="letterData.bodyUnformatted"
          ></textarea>
        </div>
      </div>
    </div>
    <!-------------------------------------------------------  Friendly Letter Step 4  -------------------------------------------------->
    <div
      *ngIf="
        (step === 4 && letterData.letterType == 'friendly') ||
        (step === 5 && letterData.letterType == 'business')
      "
    >
      <div class="friendly-letter4">
        <div class="page6-top">
          <h1 class="page6-header">Step {{ step }}. Closing</h1>
          <p class="page6-header-2">The closing is used to end your letter.</p>
        </div>
        <div class="page6-middle">
          <label class="page-6-header-3">
            The closing can change depending on to whom you are writing. Here
            are some examples:
            <ul>
              <li>Sincerely</li>
              <li *ngIf="letterData.letterType == 'friendly'">Your friend</li>
              <li *ngIf="letterData.letterType == 'friendly'">With love</li>
              <li *ngIf="letterData.letterType == 'business'">Yours truly</li>
              <li *ngIf="letterData.letterType == 'business'">Cordially</li>
            </ul>
            A comma is included after the closing.
          </label>
          <input
            type="text"
            id="closing"
            name="closing"
            [(ngModel)]="letterData.closing"
          />
        </div>
      </div>
    </div>
    <!-------------------------------------------------------  Friendly Letter Step 5  -------------------------------------------------->
    <div
      *ngIf="
        (step === 5 && letterData.letterType == 'friendly') ||
        (step === 6 && letterData.letterType == 'business')
      "
    >
      <div class="friendly-letter5">
        <div class="page7-top">
          <h1 class="page7-header">Step {{ step }}. Signature</h1>
          <p class="page7-header-2">This is where to include your name.</p>
        </div>
        <div class="page7-middle">
          <label class="page-7-header-3">
            Type your first name for the signature. Remember to sign your name
            under the closing after printing.
          </label>
          <input
            type="text"
            id="signature"
            name="signature"
            [(ngModel)]="letterData.signature"
          />
        </div>
      </div>
    </div>
    <!-----------------------------------------------------  Friendly Letter Step 6  -------------------------------------------------->

    <div *ngIf="step === 6 && letterData.letterType == 'friendly'">
      <div class="friendly-letter6">
        <div class="page8-top">
          <h1 class="page8-header">Step {{ step }}. Postscript</h1>
          <p class="page8-header-2">
            Postscripts are used to add more to your letter after you have
            signed your name.
          </p>
        </div>
        <div class="page8-middle">
          <label class="page-8-header-3">
            If you need to add a postscript, start by typing P.S. and then add
            your final message.
          </label>
          <textarea (change)='formatText()'
            type="text"
            id="postscript"
            name="postscript"
            [(ngModel)]="letterData.postscriptUnformatted"
          ></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="step === 7">
      <div class="toolbar">
        <button (click)="step = 2">
          <i class="fa fa-chevron-left"></i>&nbsp;Back
        </button>
        <button (click)="print()"><i>&nbsp;</i>&nbsp;Print</button>
      </div>
      <div class="step">
        <h1>You have finished your letter!</h1>
        <p>
          Review your letter. If you want to make changes, use the Back button.
        </p>
        <p>When your letter is final, you can ...</p>

        <div>
          <button (click)="print()">
            <i class="fa fa-print"></i>&nbsp;Print
          </button>
          <button (click)="export()">
            <i class="fa fa-download"></i>&nbsp;Export
          </button>
          <button (click)="save()"><i class="fa fa-save"></i>&nbsp;Save</button>
        </div>


        <div class='print-preview'>
          <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
          <img *ngIf='previewImage' [src]='previewImage'>
        </div>

        
        <div
          #printCanvasFriendly
          class="print-canvas print-content"
          *ngIf="letterData.letterType == 'friendly'"
        >
          <p class="print-col2">
            {{ letterData.address }}<br />
            {{ letterData.address2 }}<br />
            {{ letterData.date }}<br />
          </p>
          <p>
            {{ letterData.salutation }}
          </p>
          <p [innerHTML]='letterData.body'>
          </p>
          <p class="print-col2">
            {{ letterData.closing }}
          </p>
          <p class="print-col2">
            {{ letterData.signature }}
          </p>
          <p *ngIf="!!letterData.postscript" [innerHTML]='letterData.postscript'>
          </p>
        </div>

        <div
          #printCanvasBusiness
          class="print-canvas print-content"
          *ngIf="letterData.letterType == 'business'"
        >
          <p class="print-col2">
            {{ letterData.address }}<br />
            {{ letterData.address2 }}<br />
            {{ letterData.date }}
          </p>
          <p>
            {{ letterData.toName }}<br />
            {{ letterData.toTitle }}<br />
            {{ letterData.toCompany }}<br />
            {{ letterData.toAddress }}<br />
            {{ letterData.toAddress2 }}<br />
          </p>
          <p>
            {{ letterData.salutation }}
          </p>
          <p *ngIf="!!letterData.body" [innerHTML]='letterData.body'>
          </p>
          <p>
            {{ letterData.closing }}
          </p>
          <p>
            {{ letterData.signature }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
