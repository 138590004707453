<div *ngIf='dragElement.selected' class='drag-toolbar flex'>
  <div class='flex-auto'>
    <span class='event-name'>{{title}}</span>
  </div>
  <div class='edit'>
    <i *ngIf='showEdit' title='Edit' class='fa fa-edit' (click)='edit()'></i>
    <i title='Remove' class='fa fa-trash' (click)='delete()'></i>
    <i title='Close' class='fa fa-remove' (mouseup)='close()'></i>
  </div>
</div>
