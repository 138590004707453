
export class AbcMatchImageMatch {

    letterImage: string;
    pictureImage: string;
    selected: boolean = false;
    used: boolean = false;

    constructor (letterImage: string, pictureImage: string) {
        this.letterImage = letterImage;
        this.pictureImage = pictureImage;
    }

}