<div class='app-border'>
  <div class='title-bar'>
    <span>Alphabet Organizer</span>
  </div>

  <div *ngIf='step==1' class='step'>
    <div class='page-background'>
      <div class='page-1-block-1'></div>
      <div class='page-1-input'>
        <label class='page-1-label'>Your Name: </label>
        <input type='text' class='page-1-inputbox' [ngClass]="{errorclass: (nameEmpty && !vars.name)}"
          [(ngModel)]='vars.name'>

        <label class='page-1-label'>Project Title: </label>
        <input type='text' [ngClass]="{errorclass: (titleEmpty && !vars.title)}" [(ngModel)]='vars.title'>
      </div>

      <div class='open-block'>
        <h2 class='open-header'>Open Saved Organizer</h2>
        <div class='open-input'>
          <input [(ngModel)]='fileInput' id="preview" type="file" (change)="open($event)">
        </div>
      </div>

      <div class='page-1-button-block'>
        <img class='start-button' src='assets/alphabet-organizer/start-button.png' (click)="applyNameAndTitle()">
      </div>
    </div>
  </div>

  <div *ngIf='step==2' class='step'>
    <div class='page-background'>
      <div class='page-2-block-1'>
        <span class='page-2-header'>Click one of the options to begin making your alphabet organizer.</span>
      </div>

      <div class='page-2-block-2-left'>
        <div class='selection-border-1' (click)="initOrganizer('wordsOnly')">
          <span class='selection-text'>Words Only</span>
          <img class='selection-img' src='assets/alphabet-organizer/words-only.png'>
          <img class='choose-button' src='assets/alphabet-organizer/choose-button.png'>
        </div>
      </div>

      <div class='page-2-block-2-right'>
        <div class='selection-border-2' (click)="initOrganizer('wordAndPicture')">
          <span class='selection-text'>One Word and one picture per letter</span>
          <img class='selection-img-2' src='assets/alphabet-organizer/word-and-picture.png'>
          <img class='choose-button-2' src='assets/alphabet-organizer/choose-button.png'>
        </div>
      </div>

      <div class='page-2-block-3'>
        <img class='restart-button' src='assets/alphabet-organizer/restart-button.png' (click)="step='1'">
      </div>
    </div>
  </div>

  <div *ngIf='step==3' class='step'>
    <div class='page-background'>
      <div [ngClass]="{'dimmed': dim}"></div>
      <div [ngClass]="{'wrong-letter-dim': wrongLetterError}"></div>
      <div [ngClass]="{'wrong-letter-dim': wordError}"></div>
      <div class='page-3-block-1'>
        <span class='page-3-header'>Select letters to add words to your alphabet organizer.</span>
      </div>

      <div *ngFor='let row of letterRows' class='letter-select-images'>
        <div *ngFor='let l of row.letters' class='letter-select-img' (click)="this.letter=l; this.dim=true">
          <div
            *ngIf="this.wordsOnlyLetters[l.charCodeAt(0)-97].entered == true || this.wordAndImgLetters[l.charCodeAt(0)-97].word != ''"
            class='applied-img'></div>
          <div
            *ngIf="this.wordsOnlyLetters[l.charCodeAt(0)-97].entered == true || this.wordAndImgLetters[l.charCodeAt(0)-97].word != ''"
            class='applied-img-open'></div>
          <img class='letter-img' [src]='"assets/alphabet-organizer/letter-" + l + ".png"'>
          <img [ngClass]="{'hide-letter-open': !!letter}" class='letter-img-open'
            [src]='"assets/alphabet-organizer/letter-" + l + "-open.png"'>
        </div>

      </div>

      <div class='page-3-block-2'>
        <img class='finish-button' src='assets/alphabet-organizer/finish-button.png' (click)='finish()'>
        <img class='restart-button' src='assets/alphabet-organizer/restart-button.png' (click)='startOverConfirm = true'>
      </div>

      <div class='start-over' *ngIf='startOverConfirm'>
        <img class='confirm-button' src='assets/alphabet-organizer/confirm-button.png' (click)='restart()'>
        <img class='restart-cancel-button' src='assets/alphabet-organizer/restart-cancel-button.png' (click)='startOverConfirm = false'>
      </div>
      <div *ngFor='let char of alphabet'>
        <div *ngIf="organizerType=='wordsOnly' && this.letter==char" class='input'
          [ngStyle]='{"background-image": "url(\"../../../assets/alphabet-organizer/" + char + "-input.png\")"}'>
          <div class='input-block-1'>
            <label class='input-label'>Enter up to 5 words that begin with the letter:</label>
          </div>

          <div class='words-only-input-block-2'>
            <div class='input-line' *ngFor='let input of this.wordsOnlyLetters[char.charCodeAt(0)-97].inputs'>
              <p class='input-box-label'>{{input.num}}.</p>

              <div>
                <input class='letter-input' value={{input.inputValue}} [(ngModel)]='input.inputValue'>
              </div>

              <div class='word-button-div'
                *ngIf="input.entered || (input.num == this.wordsOnlyLetters[char.charCodeAt(0)-97].inputs.length && this.wordsOnlyLetters[char.charCodeAt(0)-97].inputs.length != 1)">
                <img class='word-button' src='assets/alphabet-organizer/delete-word-button.png'
                  (click)='deleteInput(char, input.num)'>
              </div>

              <div class='word-button-div' *ngIf="input.num != 5 && !input.entered">
                <img class='word-button' src='assets/alphabet-organizer/new-word-button.png'
                  (click)='newInput(char, input.num)'>
              </div>
            </div>
          </div>

          <div class='words-only-input-block-3'>
            <img class='ok-button' src='assets/alphabet-organizer/ok-button.png' (click)="applyInput(char)">
            <img class='cancel-button' src='assets/alphabet-organizer/cancel-button.png' (click)="clearInput(char)">
          </div>

          <div *ngIf="wrongLetterError" class='wrong-letter-error'>
            <p class='wrong-letter-header'>Oops</p>
            <p class='wrong-letter-text'>Please enter a word that starts with the letter {{char}}.</p>
            <img class='error-ok-button' src='assets/alphabet-organizer/error-ok-button.png'
              (click)='wrongLetterError=false'>
          </div>


        </div>
        <div *ngIf="organizerType=='wordAndPicture' && this.letter==char" class='input'
          [ngStyle]='{"background-image": "url(\"../../../assets/alphabet-organizer/" + char + "-input.png\")"}'>

          <div class='input-block-1'>
            <label class='input-label'>Enter a word and a description for the letter:</label>
          </div>

          <div class='word-picture-input-block-2'>
            <p class='word-label'>Word:</p>
            <input class='word-input' [(ngModel)]='wordAndImgLetters[char.charCodeAt(0)-97].word'>
          </div>

          <div class='word-picture-input-block-3'>
            <p class='image-label'>Image:</p>
            <p class='description-label'>Description:</p>
            <textarea class='description-input'
              [(ngModel)]='wordAndImgLetters[char.charCodeAt(0)-97].description'>{{wordAndImgLetters[char.charCodeAt(0)-97].description}}</textarea>

            <input class='image-input' type="file" (change)='selectImage($event)' accept="image/*"
              placeholder="Select Image">
            <img class="img" [src]='tempImageUrl' *ngIf='tempImageUrl' class='word-img'>
            <img class="img" [src]='this.wordAndImgLetters[char.charCodeAt(0)-97].imgUrl'
              *ngIf='!!this.wordAndImgLetters[char.charCodeAt(0)-97].imgUrl' class='word-img'>
          </div>

          <div class='word-picture-input-block-4'>
            <img class='ok-button' src='assets/alphabet-organizer/ok-button.png' (click)="applyWordImgInput(char)">
            <img class='cancel-button' src='assets/alphabet-organizer/cancel-button.png'
              (click)="clearWordImgInput(char)">
          </div>

          <div *ngIf="wordError" class='wrong-letter-error'>
            <p class='wrong-letter-header'>Oops</p>
            <p class='wrong-letter-text'>Please enter a word that starts with the letter {{char}}.</p>
            <img class='error-ok-button' src='assets/alphabet-organizer/error-ok-button.png' (click)='wordError=false'>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='step==4' class='step'>
    <div class='page-background'>
      <div class='page-2-block-1'>
        <span class='page-2-header'>How would you like to view your alphabet organizer?</span>
      </div>

      <div class='page-2-block-2-left'>
        <div class='selection-border-1' (click)="initPrint('word pages', 0)">
          <span class='selection-text'>Word Pages</span>
          <img class='selection-img' src='assets/alphabet-organizer/word-pages.png'>
          <img class='choose-button' src='assets/alphabet-organizer/choose-button.png'>
        </div>
      </div>

      <div class='page-2-block-2-right'>
        <div class='selection-border-2' (click)="initPrint('charts notes', 0)">
          <span class='selection-text'>Charts and Notes</span>
          <img class='selection-img' src='assets/alphabet-organizer/charts-notes.png'>
          <img class='choose-button' src='assets/alphabet-organizer/choose-button.png'>
        </div>
      </div>

      <div class='page-2-block-3'>
        <img class='back-button' src='assets/alphabet-organizer/back-button.png' (click)="step='3'">
      </div>
    </div>
  </div>

  <div *ngIf='step==5' class='step'>
    <div *ngIf="!!printType">
      <div class="toolbar">
        <button (click)="cancelPrint()">
          <i class='fa fa-chevron-left'></i> &nbsp; Back
        </button> 

        <button *ngIf='step != 5' (click)="incPrintPage()">
          Next &nbsp;<i class='fa fa-chevron-right'></i>
        </button>

        <button (click)="print()"><i class='fa fa-print'></i> Print</button>

        <button (click)='save()'><i class='fa fa-save'></i> Save</button>
      </div>


      <div class='print-preview-portrait'>
        <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
        <img *ngIf='previewImage' [src]='previewImage'>
      </div>

      <div #printCanvasWordPages class="print-canvas print-content" *ngIf="organizerType == 'wordsOnly' && printType == 'word pages'">
        <div class='word-pages-page-4-block-1-left'>
          <img class='word-pages-print-logo' src='assets/alphabet-organizer/logo.png'>
        </div>

        <div class='word-pages-page-4-block-1-right'>
          <h1 class='word-pages-title'>{{vars.title}}</h1>
          <h1 class='word-pages-name'>By: {{vars.name}}</h1>
        </div>

        <div class='words-only-page-4-block-2'>
          <div class='word-pages-background'>
            <p class='print-letter'>{{ letter }}</p>
            <div *ngFor='let item of inputtedWordsOnlyLetters'>
              <div class='print-word-container' *ngIf="item.letter == letter">
                <div *ngFor='let input of item.inputs'>
                  <p class='print-word'><b>{{ input.inputValue }}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div #printCanvasChartsNotes class="print-canvas print-content" *ngIf="organizerType == 'wordsOnly' && printType == 'charts notes'">
        <div class='charts-notes-page-4-block-1-left'>
          <img class='charts-notes-print-logo' src='assets/alphabet-organizer/logo.png'>
        </div>
      </div>

      <div #printCanvasWordPages class="print-canvas print-content" *ngIf="organizerType == 'wordAndPicture' && printType == 'word pages'">
        <div class='word-pages-page-4-block-1-left'>
          <img class='word-pages-print-logo' src='assets/alphabet-organizer/logo.png'>
        </div>

        <div class='words-only-page-4-block-1-right'>
          <h1 class='word-pages-title'>{{vars.title}}</h1>
          <h1 class='word-pages-name'>By: {{vars.name}}</h1>
        </div>

        <div class='words-only-page-4-block-2'>
          <div class='word-pages-background'>
            <p class='print-letter'>{{ letter }}</p>
            <div *ngFor='let item of inputtedWordImgLetters'>
              <div class='print-word-container' *ngIf="item.word.startsWith(letter)">
                <p class='print-word'><b>{{ item.word }}</b></p>
                <img class='print-img' src='{{ item.imgUrl }}'>
                <p class='print-description'>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
