import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader-service.service';
import { CirclePlotComponent } from './components/circle-plot/circle-plot.component';
import { DragDrop, DragDropModule, DragDropRegistry } from '@angular/cdk/drag-drop';
import { ViewportRuler, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Platform } from '@angular/cdk/platform';
import { VennDiagramComponent } from './components/venn-diagram/venn-diagram.component';
import { ConfigComponent } from './components/config/config.component';
import { DragUtilService } from './service/drag-util.service';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { DragDropDirective } from './directives/drag-drop.directive'
import { DragCanvasDirective } from './directives/drag-canvas.directive'
import { DragObjectDirective } from './directives/drag-object.directive';
import { DragStatusComponent } from './components/common/drag-status/drag-status.component';
import { DragToolbarComponent } from './components/common/drag-toolbar/drag-toolbar.component';
import { AlphabetOrganizerComponent } from './components/alphabet-organizer/alphabet-organizer.component';
import { TradingCardCreatorComponent } from "./components/trading-card-creator/trading-card-creator.component";
import { CardComponent } from './components/trading-card-creator/card/card.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { CubeCreatorComponent } from './components/cube-creator/cube-creator.component'
import { LetterGeneratorComponent } from './components/letter-generator/letter-generator.component';
import { AcrosticPoemsComponent } from './components/acrostic-poems/acrostic-poems.component';
import { AnimalInquiryComponent } from './components/animal-inquiry/animal-inquiry.component';
import { CompContrastMapComponent } from './components/comp-contrast-map/comp-contrast-map.component';
import { CompareContrastGuideComponent } from './components/compare-contrast-guide/compare-contrast-guide.component';
import { BookCoverGuideComponent } from './components/book-cover-guide/book-cover-guide.component';
import { PrintCardComponent } from './components/trading-card-creator/print-card/print-card.component';
import { FracturedFairytaleComponent } from './components/fractured-fairytale/fractured-fairytale.component';
import { DramaMapComponent } from './components/drama-map/drama-map.component';
import { HaikuPoemInteractiveComponent } from './components/haiku-poem-interactive/haiku-poem-interactive.component';
import { DiamantePoemsComponent } from './components/diamante-poems/diamante-poems.component';
import { GraffitiComponent } from './components/graffiti/graffiti.component';
import { WordMoverComponent } from './components/word-mover/word-mover.component';
import { ThemePoemsComponent } from './components/theme-poems/theme-poems.component';
import { KwlCreatorComponent } from './components/kwl-creator/kwl-creator.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { PlotDiagramComponent } from './components/plot-diagram/plot-diagram.component';
import { StoryMapComponent } from './components/story-map/story-map.component';
import { LitElementsComponent } from './components/lit-elements/lit-elements.component';
import { PictureMatchComponent } from './components/picture-match/picture-match.component';
import { PostcardComponent } from './components/postcard/postcard.component';
import { PersuasionMapComponent } from './components/persuasion-map/persuasion-map.component';
import { StaplelessComponent } from './components/stapleless/stapleless.component';
import { EssayMapComponent } from './components/essay-map/essay-map.component';
import { AbcMatchComponent } from './components/abc-match/abc-match.component';
import { ProfilePublisherComponent } from './components/profile-publisher/profile-publisher.component';
import { ConstructAWordComponent } from './components/construct-a-word/construct-a-word.component';
import { WordMatrixComponent } from './components/word-matrix/word-matrix.component';
import { FlipAChipComponent } from './components/flip-a-chip/flip-a-chip.component';
import { StaplelessPageComponent } from './components/stapleless/stapleless-page/stapleless-page.component';
import { GraphicMapComponent } from './components/graphic-map/graphic-map.component';
import { ResumeGeneratorComponent } from './components/resume-generator/resume-generator.component';
import { LiteraryGuideBeowulfComponent } from './components/literary-guide-beowulf/literary-guide-beowulf.component';
import { MultigenreMapperComponent } from './components/multigenre-mapper/multigenre-mapper.component';
import { RiddleComponent } from './components/riddle/riddle.component';
import { WordFamilyComponent } from './components/word-family/word-family.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    CirclePlotComponent,
    VennDiagramComponent,
    ConfigComponent,
    NumericOnlyDirective,
    DragDropDirective,
    DragCanvasDirective,
    DragObjectDirective,
    DragStatusComponent,
    DragToolbarComponent,
    CubeCreatorComponent,
    AlphabetOrganizerComponent,
    AcrosticPoemsComponent,
    TradingCardCreatorComponent,
    CardComponent,
    ReplacePipe,
    LetterGeneratorComponent,
    AcrosticPoemsComponent,
    AnimalInquiryComponent,
    CompContrastMapComponent,
    CompareContrastGuideComponent,
    PrintCardComponent,
    DramaMapComponent,
    BookCoverGuideComponent,
    PrintCardComponent,
    FracturedFairytaleComponent,
    HaikuPoemInteractiveComponent,
    DiamantePoemsComponent,
    GraffitiComponent,
    WordMoverComponent,
    ThemePoemsComponent,
    KwlCreatorComponent,
    TimelineComponent,
    PersuasionMapComponent,
    EssayMapComponent,
    PlotDiagramComponent,
    StoryMapComponent,
    LitElementsComponent,
    PictureMatchComponent,
    PostcardComponent,
    AbcMatchComponent,
    ProfilePublisherComponent,
    ConstructAWordComponent,
    FlipAChipComponent,
    StaplelessComponent,
    WordMatrixComponent,
    StaplelessPageComponent,
    GraphicMapComponent,
    AbcMatchComponent, 
    ResumeGeneratorComponent, 
    LiteraryGuideBeowulfComponent, 
    MultigenreMapperComponent,
    RiddleComponent,
    WordFamilyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    DragDropModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'circle-plot', component: CirclePlotComponent, pathMatch: 'full' },
      { path: 'venn-diagram', component: VennDiagramComponent, pathMatch: 'full' },
      { path: 'postcard', component: PostcardComponent },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'cube-creator', component: CubeCreatorComponent},
      { path: 'alphabet-organizer', component: AlphabetOrganizerComponent},
      { path: 'acrostic-poems', component: AcrosticPoemsComponent},
      { path: 'trading-card-creator', component: TradingCardCreatorComponent, pathMatch: 'full'},
      { path: 'letter-generator', component: LetterGeneratorComponent},
      { path: 'acrostic-poems', component: AcrosticPoemsComponent},
      { path: 'compare-contrast-guide', component: CompareContrastGuideComponent},
      { path: 'drama-map', component: DramaMapComponent},
      { path: 'animal-inquiry', component: AnimalInquiryComponent},
      { path: 'comp-contrast-map', component: CompContrastMapComponent},
      { path: 'compare-contrast-guide', component: CompareContrastGuideComponent},
      { path: 'book-cover-guide', component: BookCoverGuideComponent},
      { path: 'fractured-fairytale', component: FracturedFairytaleComponent},
      { path: 'haiku-poem-interactive', component: HaikuPoemInteractiveComponent},
      { path: 'diamante-poems', component: DiamantePoemsComponent},
      { path: 'graffiti', component: GraffitiComponent},
      { path: 'word-mover', component: WordMoverComponent},
      { path: 'theme-poems', component: ThemePoemsComponent},
      { path: 'kwl-creator', component: KwlCreatorComponent},
      { path: 'timeline', component: TimelineComponent},
      { path: 'persuasion-map', component: PersuasionMapComponent},
      { path: 'essay-map', component: EssayMapComponent},
      { path: 'plot-diagram', component: PlotDiagramComponent},
      { path: 'stapleless', component: StaplelessComponent},
      { path: 'story-map', component: StoryMapComponent},
      { path: 'lit-elements', component: LitElementsComponent},
      { path: 'picture-match', component: PictureMatchComponent},
      { path: 'abc-match', component: AbcMatchComponent},
      { path: 'profile-publisher', component: ProfilePublisherComponent},
      { path: 'word-matrix', component: WordMatrixComponent},
      { path: 'profile-publisher', component: ProfilePublisherComponent},
      { path: 'flip-a-chip', component: FlipAChipComponent},
      { path: 'abc-match', component: AbcMatchComponent},
      { path: 'graphic-map', component: GraphicMapComponent},
      { path: 'construct-a-word', component: ConstructAWordComponent},
      { path: 'flip-a-chip', component: FlipAChipComponent}, 
      { path: 'abc-match', component: AbcMatchComponent},
      { path: 'resume-generator', component: ResumeGeneratorComponent},
      { path: 'literary-guide-beowulf', component: LiteraryGuideBeowulfComponent}, 
      { path: 'multigenre-mapper', component: MultigenreMapperComponent},
      { path: 'riddle', component: RiddleComponent},
      { path: 'word-family', component: WordFamilyComponent}
    ])
  ],
  providers: [
    DynamicScriptLoaderService,
    ViewportRuler,
    Platform,
    DragDropRegistry,
    ScrollDispatcher,
    DragUtilService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
