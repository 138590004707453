import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { WordFamily } from 'src/app/model/WordFamily';
import { PrintOrientation } from 'src/app/enum/PrintOrientation';
import { PrintService } from 'src/app/service/print.service';

@Component({
    selector: 'app-word-family',
    templateUrl: './word-family.component.html',
    styleUrls: ['./word-family.component.scss']
})
export class WordFamilyComponent implements OnInit {
    step: number = 0;
    projectData: WordFamily = new WordFamily();
    headers: object = {
        "a": ["cat", "man", "cap", "back"],
        "e": ["pet", "red", "hen", "tell"],
        "i": ["hit", "big", "win", "sick"],
        "o": ["hot", "top", "Bob", "sock"],
        "u": ["cut", "bug", "run", "duck"]
    }

    currentVowel: string;
    currentWord: string;

    exitWarning: boolean = false;

    @ViewChildren('printCanvas', null) printCanvas: QueryList<ElementRef>;
    printing: boolean = false;
    printAll: boolean;
    printBox: boolean = false;


    constructor(
        private printer: PrintService,
    ) { }

    ngOnInit() {
        this.start();
    }


    // Todo: Make sure this works
    start() {
        this.projectData.availableWords = {
            "a": ["tap", "van", "plan", "sack", "sat", "clap", "can", "fan", "ran", "hat", "sap", "snack", "pat", "track", "nap", "snap", "black", "tack", "pan", 
                  "pack", "mat", "rack", "flat", "bat", "lap", "tan", "map", "rat"],
            "e": ["sled", "then", "den", "net", "men", "well", "shed", "get", "pen", "bell", "shell", "set", "bet", "met", "wet", "when", "bed", "sell", "fed", 
                  "led", "ten", "fell", "jet", "let"],
            "i": ["kit", "chin", "trick", "sit", "bit", "brick", "tin", "jig", "thick", "stick", "pig", "kick", "twig", "fig", "pick", "pin", "knit", "tick", 
                  "fin", "skin", "fit", "wig", "dig", "spin", "pit", "thin", "lick", "kin"],
            "o": ["spot", "clock", "dock", "rob", "shot", "dot", "cop", "hop", "pop", "got", "stop", "pot", "mob", "lock", "lot", "job", "chop", "lob", 
                  "drop", "cob", "not", "rock", "block", "mop", "knoc"],
            "u": ["tuck", "truck", "luck", "hut", "gun", "mug", "slug", "gun", "shut", "spun", "plug", "rug", "buck", "dug", "stuck", "suck", "fun", "tug", 
                  "hug", "sun", "nut", "but"]
        };

        /*{
            vowel: {
                header: [array of words]
            }
        }*/
        for (let vowel of ['a', 'e', 'i', 'o', 'u']) {
            this.projectData.currentWords[vowel] = {};

            for (let header of this.headers[vowel]) {
                this.projectData.currentWords[vowel][header] = [];
            }
        }

        delete this.currentWord;
        delete this.currentVowel;

        this.step = 0;
        this.exitWarning = false;
        this.projectData.name = "";
    }

    nextStep() {
        this.step++;
    }

    chooseVowel(vowel) {
        this.currentVowel = vowel;
        this.nextWord();
        this.nextStep();
    }

    range(num) {
        return [...Array(num).keys()];
    }

    randomChoice(array: string[]) {
        return array[Math.floor(Math.random() * array.length)];
    }

    nextWord() {
        this.currentWord = this.randomChoice(this.projectData.availableWords[this.currentVowel]);
    }

    removeWord(column: string, word: string) {
        let i = this.projectData.currentWords[this.currentVowel][column].indexOf(word);
        this.projectData.currentWords[this.currentVowel][column].splice(i, 1)
        
        this.projectData.availableWords[this.currentVowel].push(word);
        
        if (!this.currentWord) this.nextWord();
    }

    chooseColumn(column: string) {
        if (this.currentWord) {
            this.projectData.currentWords[this.currentVowel][column].push(this.currentWord);

            let i = this.projectData.availableWords[this.currentVowel].indexOf(this.currentWord);
            this.projectData.availableWords[this.currentVowel].splice(i, 1);
            this.nextWord();
        }
    }

    getVowelWords() {
        let words = [];
        for (let key of Object.keys(this.projectData.currentWords[this.currentVowel])) {
            for (let word of this.projectData.currentWords[this.currentVowel][key]) {
                words.push(word);
            }
        }

    return words;
    }

    currentWordExists() {
        if (this.currentWord) {
            return 1;
        }
        return 0;
    }

    differentVowel() {
        delete this.currentVowel;
        this.step = 1;
    }

    async print(printAll) {
        this.printAll = printAll;
        this.printing = true;

        //set a timeout to wait for elements to clear selected
        setTimeout(async () => { 
            await this.printer.print(this.printCanvas.toArray(), PrintOrientation.Portrait, true, true, false, false, true);
            this.printing = false;
        }, 100);
    }

    checkName() {
        if (this.projectData.name) this.nextStep();
    }

    
}
