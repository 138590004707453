<div class="app-border">
  <div class="title-bar">
    <span>Comparison and Contrast Guide</span>
  </div>
  <!------------------------------------------ Comparison and Contrast Guide  -------------------------------------------------------->
  <div *ngIf="currentApp === 'guide'" class="guide">
    <div class="guide-tabs-top">
      <button (click)="skipToArray([4, 0])" class="tab-links1">
        Graphic Organizers
      </button>
      <button (click)="skipToArray([5, 0])" class="tab-links1">
        Transitions
      </button>
      <button (click)="skipToArray([6, 0])" class="tab-links1">
        Checklist
      </button>
    </div>
    <br />
    <div class="guide-tabs-bottom">
      <button (click)="skipToArray([0, 0])" class="tab-links2">Overview</button>
      <button (click)="skipToArray([1, 0])" class="tab-links2">
        Definitions
      </button>
      <button (click)="skipToArray([2, 0])" class="tab-links2">Example</button>
      <button (click)="skipToArray([3, 0])" class="tab-links2">
        Organizing a Paper
      </button>
    </div>
    <br />

    <div class="in-frame" [ngStyle]="{ 'background-image': currentFrame }">
      <div class="top-bar">
        <div (click)="prevButton()" class="guide-prev-button"></div>
        <div class="guide-select">
          <select
            [(ngModel)]="mapData.guideSelection"
            (ngModelChange)="updatecurrentSelect()"
          >
            <option
              *ngFor="let option of guideOptions[step]"
              [value]="guideOptions[step].indexOf(option)"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div (click)="nextButton()" class="guide-next-button"></div>

        <h1 class="guide-header" [ngStyle]="{ color: currentColor }">
          Comparison and Contrast Guide
        </h1>

        <div *ngIf="warnEnd === true" class="end-box">
          <p>You are at the end.</p>
        </div>

        <!---------------------------------------------------  Text and Images  ----------------------------------------------------------------->
        <div class="guide-text">
          <div *ngIf="isEqual(currentSelect, [0, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image1.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Comparison and contrast are ways of looking at objects and
                  thinking about how they are alike and different.
                  <br />
                  For instance, all of these items are alike because they are
                  kinds of food, but there are many ways that they are
                  different. For instance, they belong to different food groups.
                  Some must be cooked before eating, and some can be eaten raw.
                  <br />
                  When you write comparison and contrast, you will pay attention
                  to these kinds of details.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [0, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image2.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>
                    There are two main reasons that people use comparison and
                    contrast:
                    <br />
                  </p>
                  <ol>
                    <li>
                      To Explain—You might compare and contrast kinds of food,
                      for instance, to help someone understand which food need
                      to be refrigerated and which can be stored in a cabinet or
                      in a bowl on the counter.
                    </li>
                    <li>
                      To Evaluate—You might compare and contrast kinds of food
                      to show why one kind of food or brand of food is better
                      than another. For example, apples are a better snack than
                      butter.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [0, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image3.jpg" />
            </div>
            <div>
              <div>
                <p>
                  When you choose items to compare and contrast, make sure that
                  you choose items that have similarities.
                  <br />
                  You have to choose things that will make sense for comparison
                  and contrast. For instance, it wouldn't make sense to compare
                  a truck with crayons or crayons with a birdhouse.
                  <br />
                  Be sure to compare things that belong together. Compare
                  crayons to pencils or pens, or compare trucks and cars.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [1, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image4.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>
                    When you compare items, you look for their similarities—the
                    things that make them the same.
                    <br />
                    <br />
                    For example:
                  </p>
                  <ul>
                    <li>Apples and oranges are both fruit.</li>
                    <li>They're both foods.</li>
                    <li>Both are made into juice.</li>
                    <li>Both grow on trees.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [1, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image4.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>
                    When you contrast items, you look at their differences.
                    <br />
                    <br />
                    For example:
                  </p>
                  <ul>
                    <li>Apples are red. Oranges are orange.</li>
                    <li>The fruits have different textures.</li>
                    <li>
                      Oranges need a warmer place to grow, like Florida. Apples
                      can grow in cooler states, like Washington.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [2, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image5.jpg" />
            </div>
            <div>
              <div>
                <p>
                  You probably use comparison all the time.
                  <br />
                  Maybe you want to buy some candy, so you go to the store and
                  look at all of the candy that is available.
                  <br />
                  You cant buy all the candy, so you have to narrow down your
                  choices.
                  <br />
                  You compare and contrast the different kinds of candy so that
                  you can make your decision.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [2, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image5.jpg" />
            </div>
            <div>
              <div>
                <p>
                  You can compare the kinds of candy by looking at the things
                  that makes the candy alike.
                  <br />
                  All these kinds of candy are sweet. None of them are sour.
                  <br />
                  All these kinds of candy are medium-sized. None are big or
                  small.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [2, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image5.jpg" />
            </div>
            <div>
              <div>
                <p>
                  You also look at the things that make the candy different.
                  <br />
                  Some of the candy is chocolate. Some is hard candy.
                  <br />
                  Some pieces of the candy have a filling, like caramel or
                  cookies. Some do not.
                  <br />
                  Some of the candy can be broken into smaller pieces while the
                  others are harder to divide if you want to share.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [2, 3])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image6.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Now it's time to make your decision.
                  <br />
                  If you're in the mood for chocolate, you wouldn't choose the
                  jelly beans or peppermint candy.
                  <br />
                  If you wanted to share the candy, one of the pieces that can
                  be broken into smaller pieces would be better.
                  <br />
                  So you could decide on the plain chocolate candy, because it
                  was closest to what you wanted.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image7.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Comparison and contrast are used in your writing to organize
                  an individual paragraph as well as to organize entire papers.
                  <br />
                  For instance, you might write a paper that compares a movie
                  and a book about the same topic. In your paper you can compare
                  and contrast the movie version with the book version.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image8.jpg" />
            </div>
            <div>
              <div>
                <p>
                  As you begin to organize your writing, it's important to make
                  sure that you balance the information about the items that
                  you're comparing and contrasting.
                  <br />
                  You need to be sure that you give them equal time in what you
                  write.
                  <br />
                  If you cover character, setting, and historical accuracy for
                  the book, for instance, you need to be sure that you cover the
                  same elements for the movie.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image9.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>
                    There are three strategies to organize comparison and
                    contrast papers:
                  </p>
                  <ol>
                    <li>Whole-to-Whole, or Block</li>
                    <li>Similarities-to-Differences</li>
                    <li>Point-by-Point</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 3])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image10.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Whole-to-Whole or Block Strategy
                  <br />
                  In this structure, you say everything about one item then
                  everything about the other.
                  <br />
                  For instance, say everything about the characters, setting,
                  and plot for the book then everything about the characters,
                  setting, and plot for the movie.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 4])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image11.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Whole-to-Whole comparison and contrast uses a separate section
                  or paragraph for each item you're discussing.
                  <br />
                  For a paper comparing and contrasting a book to a movie, the
                  section for Item #1 would include everything about the book
                  and the section for Item #2 would cover everything about the
                  movie.
                  <br />
                  The points in each of the sections should be the same and they
                  should be explained in the same order (for instance, you might
                  discuss character, setting, and plot for both, and in that
                  order for both).
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 5])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image12.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Similarities-to-Differences Strategy
                  <br />
                  In this structure, you explain all the similarities about the
                  items being compared and then you explain all the differences.
                  <br />
                  For instance, you might explain that the characters and plot
                  were similar in both the book and movie in the one section.
                  <br />
                  In the next section, you could explain that the settings were
                  different. The book took place during the summer while the
                  movie took place during the winter.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 6])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image13.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Similarities-to-Differences comparison and contrast uses a
                  separate section or paragraph for similarities and
                  differences.
                  <br />
                  In other words, the body of your paper would have two large
                  sections: one for similarities, and another for differences.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 7])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image14.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Point-by-Point Strategy
                  <br />
                  In this structure, you explain one point of comparison before
                  moving to the next point.
                  <br />
                  For instance, you would write about the characters in the book
                  and movie in one section; then you would write about the
                  setting in the book and movie in the next section.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [3, 8])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image15.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Point-by-Point comparison and contrast uses a separate section
                  or paragraph for each point.
                  <br />
                  Point #1 for your paper could be information about the
                  characters in the book and the movie. You'd begin a section or
                  paragraph for Point #2.
                  <br />
                  For consistency, begin with the same item in each section of
                  your point-by-point paper. For instance, for each point that
                  you discuss, explain the information about the book first and
                  then about the movie.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [4, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image16.jpg" />
            </div>
            <div>
              <div>
                <p>
                  Graphic organizers are useful tools for gathering details
                  about the items that you are comparing and contrasting.
                  <br />
                  Venn Diagrams help you think about where the various
                  characteristics of the items being compared and contrasted
                  fit.
                  <br />
                  The Compare and Contrast Chart is more like a listing tool,
                  where you can brainstorm a list of ways that the items are
                  alike and different.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [4, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image17.jpg" />
            </div>
            <div>
              <div>
                <p>
                  A 2-Circle Venn Diagram is made up of two overlapping circles
                  that allow you to organize information about two items that
                  you are comparing and contrasting.
                  <br />
                  You write the characteristics of the items inside the circles.
                  Features that apply to both go in the overlapping portion (the
                  middle) of the two circles.
                  <br />
                  When you're done, you'll have your information divided into
                  similarities (the overlapping part) and differences (the
                  non-intersecting parts).
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [4, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image18.jpg" />
            </div>
            <div>
              <div>
                <p>
                  A 3-Circle Venn Diagram is made up of three overlapping
                  circles. It is more complex because there are more ways for
                  items to overlap.
                  <br />
                  Features that apply to two items go in the overlapping portion
                  of their circles (for instance, the Sun and Moon are in the
                  Milky Way). Features shared by all three go in the middle (for
                  instance, the Sun, Moon, and Stars are in the sky).
                  <br />
                  As with the 2-Circle Diagram, when you're done, your
                  information will be divided into similarities (the overlapping
                  part) and differences (the non-intersecting parts).
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [4, 3])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image19.jpg" />
            </div>
            <div>
              <div>
                <p>
                  A Compare and Contrast Chart is made up of a series of boxes
                  that you can fill in to gather information about the two items
                  that you are comparing and contrasting.
                  <br />
                  You write the names of your items at the top then fill in the
                  boxes with details about how the items are alike and
                  different.
                  <br />
                  This chart is an easy way to divide the characteristics of the
                  items that you are comparing into similarities (items in the
                  upper box) and differences (items in the two bottom boxes).
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [5, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image20.jpg" />
            </div>
            <div>
              <div>
                <p>
                  In comparison and contrast, transition words tell a reader
                  that the writer is changing from talking about one item to the
                  other.
                  <br />
                  Transitional words and phrases help make a paper smoother and
                  more coherent by showing the reader the connections between
                  the ideas that are being presented.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [5, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image21.jpg" />
            </div>
            <div>
              <div>
                <p>
                  When you're comparing items, using a transition from this list
                  will signal to readers that you're changing from one item to
                  the next and it will also tell the reader that the two items
                  are similar.
                  <br />
                  Here are some examples: The characters in the movie were very
                  similar to the characters in the book.
                  <br />
                  Both the characters in the movie and in the book were
                  interested in detective work.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [5, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image22.jpg" />
            </div>
            <div>
              <div>
                <p>
                  On the other hand, using one of the transitions from this list
                  of words will signal readers that the two items you're
                  discussing are different.
                  <br />
                  Here are some examples: The setting in the book was summer
                  while the setting in the movie was winter.
                  <br />
                  The events in the book took place during several afternoons,
                  but the events in the movie took place during the evening.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [6, 0])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image23.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>
                    There are three main things to pay attention to as you write
                    a comparison and contrast paper:
                  </p>
                  <ol>
                    <li>Purpose & Supporting Details</li>
                    <li>Organization & Structure</li>
                    <li>Transitions & Coherence</li>
                  </ol>
                  <p>
                    In addition, be sure to pay attention to the usual
                    requirements for writing, such as spelling, punctuation, and
                    grammar.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [6, 1])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image23.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <p>Comparison & Contrast Checklist</p>
                  <ol>
                    <li>
                      Purpose & Supporting Details
                      <ol class="lower-list">
                        <li>The paper compares and contrasts items clearly.</li>
                        <li>
                          The paper points to specific examples to illustrate
                          the comparison.
                        </li>
                        <li>
                          The paper includes only the information relevant to
                          the comparison.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [6, 2])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image23.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      Organization & Structure
                      <ol class="lower-list">
                        <li>
                          The paper breaks the information into the
                          whole-to-whole, similarities-to-differences, or
                          point-by-point structure.
                        </li>
                        <li>
                          The paper follows a consistent order when discussing
                          the comparison.
                        </li>
                        <li>
                          The paper breaks the information into appropriate
                          sections or paragraphs to the ideas.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEqual(currentSelect, [6, 3])">
            <div class="img-sizing">
              <img src="/assets/compare-contrast-guide/guide/image23.jpg" />
            </div>
            <div>
              <div>
                <div>
                  <ol start="3">
                    <li>
                      Transitions & Coherence
                      <ol class="lower-list">
                        <li>
                          The paper moves smoothly from one idea to the next.
                        </li>
                        <li>
                          The paper uses comparison and contrast transition
                          words to show relationships between ideas.
                        </li>
                        <li>
                          The paper uses a variety of sentence structures and
                          transitions.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="help-button" (click)="helpClicked = true">Help</button>

      <!-- <h1>Current selection is {{ currentSelect }}</h1> -->
    </div>
    <div
      class="help-box"
      [ngStyle]="{ margin: helpBoxPosStr }"
      *ngIf="helpClicked === true"
    >
      <div
        class="menu-bar"
        (mousedown)="clickHelpBox($event)"
        (mousemove)="dragHelpBox($event)"
        (mouseup)="releaseHelpBox()"
      >
        <div class="help-label">
          <div>Help</div>
        </div>
        <div class="help-close" (click)="helpClicked = false"></div>
      </div>
      <div class="help-body">
        <label>Instructions</label>
        <p>
          Use the forward and backward arrows to move through the tool
          slide-by-slide.<br />
          Use the tabs and the drop-down menu to skip ahead or jump back.
        </p>
      </div>
    </div>
  </div>
</div>
