<div class='app-border'>
    <div class='title-bar'>
        <span>Theme Poems</span>
    </div>

    <div *ngIf="step==1" class='step'>
        <div class='page-background'>
            <div *ngIf='showInfo' class='dim'></div>
            <img class='info-button' src='assets/theme-poems/theme-show-info-button.png' (click)='showInfo = true'>
            <div *ngIf='showInfo' class='info-background'>
                <img src='assets/haiku-poem-interactive/producer.png'>
                <img src='assets/haiku-poem-interactive/partnership.png'>
                <img src='assets/haiku-poem-interactive/support.png'>
                <img class='ok-button' src='assets/haiku-poem-interactive/ok-button.png' (click)='showInfo = false'>
            </div>

            <div class='page-1-block-1'>
                <div class='cloud'>
                    <p class='welcome-header'>Welcome</p>
                    <p class='name-label'>Enter your name:</p>
                    <input class='name-input' maxlength='20' [ngClass]="{'input-error': nameError}" type='text'
                        [(ngModel)]='poem.name' (change)='nameError = false'>
                </div>
            </div>

            <img class='continue-button' src='assets/theme-poems/continue-button.png' (click)='initPoem()'>
        </div>
    </div>

    <div *ngIf="step==2" class='step'>
        <div class='page-background'>
            <div *ngIf='showInfo' class='dim'></div>
            <img class='info-button' src='assets/theme-poems/theme-show-info-button.png' (click)='showInfo = true'>
            <div *ngIf='showInfo' class='info-background'>
                <img src='assets/haiku-poem-interactive/producer.png'>
                <img src='assets/haiku-poem-interactive/partnership.png'>
                <img src='assets/haiku-poem-interactive/support.png'>
                <img class='ok-button' src='assets/haiku-poem-interactive/ok-button.png' (click)='showInfo = false'>
            </div>

            <img class='apple-example' src='assets/theme-poems/apple-example.png'>

            <div class='page-2-button-block'>
                <img class='back-button' src='assets/theme-poems/back-button.png' (click)='step=1'>
                <img class='select-theme-button' src='assets/theme-poems/select-theme-button.png' (click)='step=3'>
            </div>
        </div>
    </div>

    <div *ngIf="step==3" class='step'>
        <div class='page-background'>
            <div *ngIf='showInfo || selectedTheme' class='dim'></div>
            <img class='info-button' src='assets/theme-poems/theme-show-info-button.png' (click)='showInfo = true'>
            <div *ngIf='showInfo' class='info-background'>
                <img src='assets/haiku-poem-interactive/producer.png'>
                <img src='assets/haiku-poem-interactive/partnership.png'>
                <img src='assets/haiku-poem-interactive/support.png'>
                <img class='ok-button' src='assets/haiku-poem-interactive/ok-button.png' (click)='showInfo = false'>
            </div>

            <div class='select-theme-header-block'>
                <span class='select-theme-header'>Select a Theme</span>
                <span class='select-theme-header-2'>Click one of the themes below, then select a shape to get
                    started.</span>
            </div>

            <div class='select-theme-row-1'>
                <img class='theme' src='assets/theme-poems/nature-folder.png' (click)="selectedTheme = 'Nature'">
                <img class='theme' src='assets/theme-poems/school-folder.png' (click)="selectedTheme = 'School'">
                <img class='theme' src='assets/theme-poems/sports-folder.png' (click)="selectedTheme = 'Sports'">
            </div>
            <div class='select-theme-row-1'>
                <img class='theme' src='assets/theme-poems/celebrations-folder.png'
                    (click)="selectedTheme = 'Celebrations'">
                <img class='theme' src='assets/theme-poems/shapes-folder.png' (click)="selectedTheme = 'Shapes'">
            </div>

            <div class='page-3-button-block'>
                <img class='page-3-back-button' src='assets/theme-poems/back-button.png' (click)='step=2'>
            </div>

            <div class='select-theme-background' *ngIf='selectedTheme'>
                <div class='theme-header-box'>
                    <img class='close-theme' src='assets/theme-poems/close-theme.png' (click)="selectedTheme=''">
                    <span class='theme-header'>{{ selectedTheme }}</span>

                    <!-- Nature theme selection -->
                    <div class='theme-row' *ngIf="selectedTheme == 'Nature'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/sun.png'
                                (click)="poem.selectedShape = 'Sun'; step=4">
                            <span class='theme-label'>Sun</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='raindrop-img' src='assets/theme-poems/raindrop.png'
                                (click)="poem.selectedShape = 'Raindrop'; step=4">
                            <span class='theme-label'>Raindrop</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/flower.png'
                                (click)="poem.selectedShape = 'Flower'; step=4">
                            <span class='theme-label'>Flower</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/tree.png'
                                (click)="poem.selectedShape = 'Tree'; step=4">
                            <span class='theme-label'>Tree</span>
                        </div>
                    </div>
                    <div class='theme-row' *ngIf="selectedTheme == 'Nature'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/leaf.png'
                                (click)="poem.selectedShape = 'Leaf'; step=4">
                            <span class='theme-label'>Leaf</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/fish.png'
                                (click)="poem.selectedShape = 'Fish'; step=4">
                            <span class='theme-label'>Fish</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='cloud-img' src='assets/theme-poems/cloud.png'
                                (click)="poem.selectedShape = 'Cloud'; step=4">
                            <span class='theme-label'>Cloud</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/moon.png'
                                (click)="poem.selectedShape = 'Moon'; step=4">
                            <span class='theme-label'>Moon</span>
                        </div>
                    </div>

                    <!-- School Theme Selection -->
                    <div class='theme-row' *ngIf="selectedTheme == 'School'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/apple.png'
                                (click)="poem.selectedShape = 'Apple'; step=4">
                            <span class='theme-label'>Apple</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/book.png'
                                (click)="poem.selectedShape = 'Book'; step=4">
                            <span class='theme-label'>Book</span>
                        </div>
                    </div>

                    <div class='theme-row' *ngIf="selectedTheme == 'School'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/bus.png'
                                (click)="poem.selectedShape = 'Bus'; step=4">
                            <span class='theme-label'>Bus</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/computer.png'
                                (click)="poem.selectedShape = 'Computer'; step=4">
                            <span class='theme-label'>Computer</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/bell.png'
                                (click)="poem.selectedShape = 'Bell'; step=4">
                            <span class='theme-label'>Bell</span>
                        </div>
                    </div>

                    <!-- Sports Theme Selection -->
                    <div class='theme-row' *ngIf="selectedTheme == 'Sports'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/softball.png'
                                (click)="poem.selectedShape = 'Softball'; step=4">
                            <span class='theme-label'>Softball</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/football.png'
                                (click)="poem.selectedShape = 'Football'; step=4">
                            <span class='theme-label'>Football</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/pompom.png'
                                (click)="poem.selectedShape = 'PomPom'; step=4">
                            <span class='theme-label'>Pom Pom</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/basketball.png'
                                (click)="poem.selectedShape = 'Basketball'; step=4">
                            <span class='theme-label'>Basketball</span>
                        </div>
                    </div>

                    <div class='theme-row' *ngIf="selectedTheme == 'Sports'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/tennis.png'
                                (click)="poem.selectedShape = 'Tennis'; step=4">
                            <span class='theme-label'>Tennis</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/soccer.png'
                                (click)="poem.selectedShape = 'Soccer'; step=4">
                            <span class='theme-label'>Soccer</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/volleyball.png'
                                (click)="poem.selectedShape = 'Volleyball'; step=4">
                            <span class='theme-label'>Volleyball</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/sneaker.png'
                                (click)="poem.selectedShape = 'Sneaker'; step=4">
                            <span class='theme-label'>Sneaker</span>
                        </div>
                    </div>

                    <!-- Celebrations Theme Selection -->
                    <div class='theme-row' *ngIf="selectedTheme == 'Celebrations'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/heart.png'
                                (click)="poem.selectedShape = 'Heart'; step=4">
                            <span class='theme-label'>Heart</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/balloon.png'
                                (click)="poem.selectedShape = 'Balloon'; step=4">
                            <span class='theme-label'>Balloon</span>
                        </div>
                    </div>

                    <div class='theme-row' *ngIf="selectedTheme == 'Celebrations'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/flag.png'
                                (click)="poem.selectedShape = 'Flag'; step=4">
                            <span class='theme-label'>Flag</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/gift.png'
                                (click)="poem.selectedShape = 'Gift'; step=4">
                            <span class='theme-label'>Gift</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/cake.png'
                                (click)="poem.selectedShape = 'Cake'; step=4">
                            <span class='theme-label'>Cake</span>
                        </div>
                    </div>

                    <!-- Shapes Theme Selection -->
                    <div class='theme-row' *ngIf="selectedTheme == 'Shapes'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/square.png'
                                (click)="poem.selectedShape = 'Square'; step=4">
                            <span class='theme-label'>Square</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/circle.png'
                                (click)="poem.selectedShape = 'Circle'; step=4">
                            <span class='theme-label'>Circle</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/triangle.png'
                                (click)="poem.selectedShape = 'Triangle'; step=4">
                            <span class='theme-label'>Triangle</span>
                        </div>
                    </div>

                    <div class='theme-row' *ngIf="selectedTheme == 'Shapes'">
                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/diamond.png'
                                (click)="poem.selectedShape = 'Diamond'; step=4">
                            <span class='theme-label'>Diamond</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img rectangle-height' src='assets/theme-poems/rectangle.png'
                                (click)="poem.selectedShape = 'Rectangle'; step=4">
                            <span class='theme-label'>Rectangle</span>
                        </div>

                        <div class='theme-img-container'>
                            <img class='theme-img' src='assets/theme-poems/star.png'
                                (click)="poem.selectedShape = 'Star'; step=4">
                            <span class='theme-label'>Star</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Brainstorm words selection page -->
    <div *ngIf='step==4' class='step'>
        <div class='page-background'>
            <div *ngIf='showInfo || startOverConfirm' class='dim'></div>
            <img class='info-button' src='assets/theme-poems/theme-show-info-button.png' (click)='showInfo = true'>
            <div *ngIf='showInfo' class='info-background'>
                <img src='assets/haiku-poem-interactive/producer.png'>
                <img src='assets/haiku-poem-interactive/partnership.png'>
                <img src='assets/haiku-poem-interactive/support.png'>
                <img class='ok-button' src='assets/haiku-poem-interactive/ok-button.png' (click)='showInfo = false'>
            </div>

            <div class='select-theme-header-block'>
                <span class='select-theme-header'>Think of words</span>
                <span class='select-theme-header-2'>What are some words or phrases that remind you of this shape?</span>
            </div>

            <div class='shape-img-block'>
                <img class='shape-img' src="{{'assets/theme-poems/' + poem.selectedShape.toLowerCase() + '.png'}}">
            </div>

            <div class='brainstorm-words-block'>
                <div *ngFor='let w of poem.brainstormWords'>
                    <input style='border-radius: 10px' type='text' maxlength='25' [(ngModel)]='w.brainstormWord'>
                </div>
            </div>

            <div class='page-4-button-block'>
                <img class='new-shape-button' src='assets/theme-poems/new-shape-button.png'
                    (click)='startOverConfirm = true;'>
                <img class='continue-button' src='assets/theme-poems/continue-button.png'
                    (click)='submitBrainstormWords()'>
            </div>

            <div class='start-over' *ngIf='startOverConfirm'>
                <img class='confirm-button' src='assets/alphabet-organizer/confirm-button.png' (click)='restart()'>
                <img class='restart-cancel-button' src='assets/alphabet-organizer/restart-cancel-button.png'
                    (click)='startOverConfirm = false;'>
            </div>
        </div>
    </div>

    <div *ngIf="step==5" class='step'>
        <div class='page-background'>
            <div class='page-5-form-block'>
                <span class='page-5-form-header'>Write your poem</span>

                <span class='select-theme-header-2'>Enter a title for your poem.</span>
                <input class='title-input' type='text' maxlength='25' [(ngModel)]='poem.title'>

                <span class='select-theme-header-2'>Enter your poem here.</span>
                <textarea class='poem-input' type='text' maxlength='182' [(ngModel)]='poem.poemText'></textarea>

                <span class='page-5-form-header'>Your Words:</span>
                <span class='select-theme-header-2'>Use your words to help you write your poem.</span>

                <div class='brainstorm-words-container'>
                    <div class='brainstorm-words-box' *ngFor='let w of poem.brainstormWords'>
                        <span class='brainstorm-word'>{{ w.brainstormWord }}</span>
                    </div>
                </div>
            </div>

            <div class='page-5-shape-img-block'>
                <div class='page-5-shape-img'
                    [ngStyle]="{'background-image': 'url(../../../assets/theme-poems/' + poem.selectedShape.toLowerCase() + '.png'}">
                    <span class="{{ poem.selectedShape.toLowerCase() }}-title-preview">{{ poem.title }}</span>
                    <span class='{{ poem.selectedShape.toLowerCase() }}-poem-preview'>{{ poem.poemText }}</span>
                </div>
            </div>
            <div class='page-4-button-block'>
                <img class='new-shape-button' src='assets/theme-poems/back-button.png' (click)='cancelPoem()'>
                <img class='continue-button' src='assets/theme-poems/continue-button.png' (click)='initPrint()'>
            </div>
        </div>
    </div>

    <div *ngIf='step==6' class='step'>
        <div class='toolbar'>
            <button (click)="step=5">
                <i class='fa fa-chevron-left'></i> &nbsp; Back
            </button>

            <!-- <button (click)='save()'><i class='fa fa-save'></i> Save</button> -->

            <button (click)='print()'>
                <i class='fa fa-print'></i>&nbsp;Print
            </button>

        </div>

        <div class='print-preview-portrait'>
            <img *ngIf='!previewImage' src="/assets/common/loading-small.gif" class='loading-small'>
            <img *ngIf='previewImage' [src]='previewImage'>
        </div>

        <div #printCanvas class='print-canvas print-content' style='text-align: center'>
            <img class='print-logo' src='assets/theme-poems/print-logo.png'>
            <div class='print-author-container'>
                <a class='print-intro'>By: </a><a class='print-name'>{{poem.name}}</a>
            </div>
            <div class="{{poem.selectedShape.toLowerCase()}}-print-img print-background-image">
                <div class="{{poem.selectedShape.toLowerCase() }}-print-poem-box">
                    <span class="{{ poem.selectedShape.toLowerCase() }}-title-preview {{ poem.selectedShape.toLowerCase() }}-print-margin-top"
                    style='margin: 250px auto 0; font-size: 45px; color: black;'>{{ poem.title }}</span>
                <span class='{{ poem.selectedShape.toLowerCase() }}-poem-preview {{ poem.selectedShape.toLowerCase() }}-print-preview'
                    style='font-size: 40px; width: auto; margin-left: 0px; color: black;'>{{ poem.poemText }}</span>
                </div>
            </div>
        </div>
    </div>
</div>