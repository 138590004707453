import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { LiteraryGuideBeowulfInteractiveFile } from 'src/app/model/LiteraryGuideBeowulfInteractiveFile';

@Component({
  selector: 'app-literary-guide-beowulf',
  templateUrl: './literary-guide-beowulf.component.html',
  styleUrls: ['./literary-guide-beowulf.component.scss']
})
export class LiteraryGuideBeowulfComponent implements OnInit {
  
  interactive: LiteraryGuideBeowulfInteractiveFile = new LiteraryGuideBeowulfInteractiveFile();

  constructor() { }

  ngOnInit() {
  }

  getBackground(context: string) {
    let background = "";

    switch (context) {
      case "overview": {
        background = `lg-overview-${this.interactive.overviewBackgroundIndex}-background.png`;
        break;
      }

      case "language": {
        background = `lg-language-${this.interactive.languageBackgroundIndex}-background.png`;
        break;
      }

      case "poetics": {
        background = `lg-poetics-${this.interactive.poeticsBackgroundIndex}-background.png`;
        break;
      }

      case "translations": {
        background = `lg-translations-${this.interactive.translationsBackgroundIndex}-background.png`;
        break;
      }
    }

    let fullPath = "../../../assets/literary-guide-beowulf/" + background;
    return fullPath;
  }

  setBackground (context: string, index: number) {
    switch (context) {
      case "overview": {
        this.interactive.overviewBackgroundIndex = index;
        break;
      }

      case "language": {
        this.interactive.languageBackgroundIndex = index;
        break;
      }

      case "poetics": {
        this.interactive.poeticsBackgroundIndex = index;
        break;
      }

      case "translations": {
        this.interactive.translationsBackgroundIndex = index;
        break;
      }
    }
  }

}
