<div class="app-border">
    <div class="title-bar">
        <span>Literary Guide: Beowulf</span>
    </div>


    <div *ngIf="interactive.step==1" class="overview-background frame" [ngStyle]="{background: 'url(' + getBackground('overview') + ')'}">
        <button class="illustration-button" (click)="interactive.showIllustration=true"></button>
        <button *ngIf="!interactive.showMenu" class="menu-button" (click)="interactive.showMenu=true"></button>
        <div *ngIf="interactive.showMenu" class="menu-background">
            <button class="overview-menu-button" (click)="interactive.step=1"></button>
            <button class="language-menu-button" (click)="interactive.step=2"></button>
            <button class="poetics-menu-button" (click)="interactive.step=3"></button>
            <button class="translations-menu-button" (click)="interactive.step=4"></button>
            <button class="credits-menu-button" (click)="interactive.step=5"></button>
            <button class="close-menu-button" (click)="interactive.showMenu=false"></button>
        </div>
        <button class="overview-1-button nav-button nav-button-1" (click)="setBackground('overview', 1)"></button>
        <button class="overview-2-button nav-button nav-button-2" (click)="setBackground('overview', 2)"></button>
        <button class="overview-3-button nav-button nav-button-3" (click)="setBackground('overview', 3)"></button>
        <button class="overview-4-button nav-button nav-button-4" (click)="setBackground('overview', 4)"></button>
        <button class="overview-5-button nav-button nav-button-5" (click)="setBackground('overview', 5)"></button>
    </div>

    <div *ngIf="interactive.step==2" class="language-background frame" [ngStyle]="{background: 'url(' + getBackground('language') + ')'}">
        <button class="illustration-button" (click)="interactive.showIllustration=true"></button>
        <button *ngIf="!interactive.showMenu" class="menu-button" (click)="interactive.showMenu=true"></button>
        <div *ngIf="interactive.showMenu" class="menu-background">
            <button class="overview-menu-button" (click)="interactive.step=1"></button>
            <button class="language-menu-button" (click)="interactive.step=2"></button>
            <button class="poetics-menu-button" (click)="interactive.step=3"></button>
            <button class="translations-menu-button" (click)="interactive.step=4"></button>
            <button class="credits-menu-button" (click)="interactive.step=5"></button>
            <button class="close-menu-button" (click)="interactive.showMenu=false"></button>
        </div>
        <button class="language-1-button nav-button nav-button-1" (click)="setBackground('language', 1)"></button>
        <button class="language-2-button nav-button nav-button-2" (click)="setBackground('language', 2)"></button>
        <button class="language-3-button nav-button nav-button-3" (click)="setBackground('language', 3)"></button>
        <button class="language-4-button nav-button nav-button-4" (click)="setBackground('language', 4)"></button>
        <button class="language-5-button nav-button nav-button-5" (click)="setBackground('language', 5)"></button>
    </div>

    <div *ngIf="interactive.step==3" class="poetics-background frame" [ngStyle]="{background: 'url(' + getBackground('poetics') + ')'}">
        <button class="illustration-button" (click)="interactive.showIllustration=true"></button>
        <button *ngIf="!interactive.showMenu" class="menu-button" (click)="interactive.showMenu=true"></button>
        <div *ngIf="interactive.showMenu" class="menu-background">
            <button class="overview-menu-button" (click)="interactive.step=1"></button>
            <button class="language-menu-button" (click)="interactive.step=2"></button>
            <button class="poetics-menu-button" (click)="interactive.step=3"></button>
            <button class="translations-menu-button" (click)="interactive.step=4"></button>
            <button class="credits-menu-button" (click)="interactive.step=5"></button>
            <button class="close-menu-button" (click)="interactive.showMenu=false"></button>
        </div>
        <button class="poetics-1-button nav-button nav-button-1" (click)="setBackground('poetics', 1)"></button>
        <button class="poetics-2-button nav-button nav-button-2" (click)="setBackground('poetics', 2)"></button>
        <button class="poetics-3-button nav-button nav-button-3" (click)="setBackground('poetics', 3)"></button>
        <button class="poetics-4-button nav-button nav-button-4" (click)="setBackground('poetics', 4)"></button>
        <button class="poetics-5-button nav-button nav-button-5" (click)="setBackground('poetics', 5)"></button>
    </div>

    <div *ngIf="interactive.step==4" class="translations-background frame" [ngStyle]="{background: 'url(' + getBackground('translations') + ')'}">
        <button class="illustration-button" (click)="interactive.showIllustration=true"></button>
        <button *ngIf="!interactive.showMenu" class="menu-button" (click)="interactive.showMenu=true"></button>
        <div *ngIf="interactive.showMenu" class="menu-background">
            <button class="overview-menu-button" (click)="interactive.step=1"></button>
            <button class="language-menu-button" (click)="interactive.step=2"></button>
            <button class="poetics-menu-button" (click)="interactive.step=3"></button>
            <button class="translations-menu-button" (click)="interactive.step=4"></button>
            <button class="credits-menu-button" (click)="interactive.step=5"></button>
            <button class="close-menu-button" (click)="interactive.showMenu=false"></button>
        </div>
        <button class="translations-1-button nav-button nav-button-1" (click)="setBackground('translations', 1)"></button>
        <button class="translations-2-button nav-button nav-button-2" (click)="setBackground('translations', 2)"></button>
        <button class="translations-3-button nav-button nav-button-3" (click)="setBackground('translations', 3)"></button>
        <button class="translations-4-button nav-button nav-button-4" (click)="setBackground('translations', 4)"></button>
        <button class="translations-5-button nav-button nav-button-5" (click)="setBackground('translations', 5)"></button>
    </div>

    <div *ngIf="interactive.step==5" class="credits-background frame">
        <button class="illustration-button" (click)="interactive.showIllustration=true" (click)="interactive.showIllustration=true"></button>
        <button *ngIf="!interactive.showMenu" class="menu-button" (click)="interactive.showMenu=true"></button>
        <div *ngIf="interactive.showMenu" class="menu-background">
            <button class="overview-menu-button" (click)="interactive.step=1"></button>
            <button class="language-menu-button" (click)="interactive.step=2"></button>
            <button class="poetics-menu-button" (click)="interactive.step=3"></button>
            <button class="translations-menu-button" (click)="interactive.step=4"></button>
            <button class="credits-menu-button" (click)="interactive.step=5"></button>
            <button class="close-menu-button" (click)="interactive.showMenu=false"></button>
        </div>
    </div>

    <div *ngIf="interactive.showIllustration" class="illustration-background frame">
        <button class="illustration-continue-button" (click)="interactive.showIllustration=false"></button>
    </div>

</div>